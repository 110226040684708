<template>
  <div class="tw-relative" :class="{ 'language-dropdown': version === 'footer' }">
    <div v-if="version === 'footer'" class="custom-selected tw-flex tw-justify-end" :class="getClass()">
      <div class="tw-flex tw-flex-row tw-items-center">
        <span :class="getTextClass()">{{ selectedLanguageText }}</span>
        <BaseIcon
          icon-name="chevron-right"
          :height="24"
          :width="24"
          :viewbox-height="24"
          :viewbox-width="24"
          icon-color="#007CC2"
          class="tw-absolute arrow-dropdown"
        >
          <ChevronRight />
        </BaseIcon>
      </div>
    </div>
    <BaseSelect
      :id="'language-selector'"
      :class="{
        'language-selector': version !== 'light',
      }"
      :name="'language-selector'"
      :display-text-key="'displayText'"
      :value-key="'value'"
      :options="languages"
      :value="selectedLanguage"
      :version="version"
      :size="size"
      @onChange="e => onLanguageSelectedChange(e)"
      :data-test-id="'language-selector'"
      :error-test-id="'language-selector-error'"
      :select-test-id="'language-selector-select'"
    />
  </div>
</template>

<script setup>
import { computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import axios from 'axios'

import { setI18nLanguage } from '@/plugins/i18n'
import BaseSelect from '@/components/BaseSelect.vue'
import { languages } from '@/constants/locales'
import BaseIcon from '@/components/BaseIcon.vue'
import ChevronRight from '@/components/icons/ChevronRight.vue'
import useToastNotifications from '@/components/notifications/useToastNotifications'
import { useUserDetails } from '@/layouts/queries'
import { useGetUserCurrency } from './queries/index.js'
import { useUpdateUserLanguage } from '@/components/settings/profileSettings/queries'

const props = defineProps({
  version: {
    type: String,
    default: 'light',
  },
  size: {
    type: String,
    default: 'medium',
  },
  isLanding: Boolean,
})

const selectedLanguage = computed(() => store.state.locale.language)

const store = useStore()
const userId = computed(() => store.state.session.userId)
const token = computed(() => store.state.session?.studioToken)
const isGuest = computed(() => props.isLanding)
const { userDetails } = useUserDetails(userId)
const { updateUserLanguage } = useUpdateUserLanguage()
const { userLanguage } = useGetUserCurrency({ token, isGuest })
const { displayNotification } = useToastNotifications()

const selectedLanguageText = computed(() => {
  const selected = languages.find(language => language.value === selectedLanguage.value)?.displayText
  if (selected?.includes('United States')) return selected.replace('United States', 'US')
  if (selected?.includes('United Kingdom')) return selected.replace('United Kingdom', 'UK')
  if (selected?.includes('Portugal')) return selected.replace('Portugal', 'PT')
  if (selected?.includes('Brasil')) return selected.replace('Brasil', 'BR')
  if (selected?.includes('España')) return selected.replace('España', 'ES')
  if (selected?.includes('México')) return selected.replace('México', 'MX')
  return selected
})

async function setInitialSelectedLanguage() {
  if (userDetails.value?.preferredLanguage) {
    await updateAppLanguage(userDetails.value.preferredLanguage)
    return
  }

  const languageInLocalStorage = window.localStorage.getItem('stdio_locale_language')
  if (languageInLocalStorage) {
    await updateAppLanguage(languageInLocalStorage)
    return
  }

  if (userLanguage.value) {
    await updateAppLanguage(languageInLocalStorage)
    return
  }

  await updateAppLanguage('en-US')
}

async function updateAppLanguage(lang) {
  store.commit('SET_LANGUAGE', lang)
  await setI18nLanguage(lang)
  axios.defaults.headers.common['Accept-Language'] = lang
}

function onLanguageSelectedChange(lang) {
  updateAppLanguage(lang)
  if (userDetails.value) {
    sendUpdatedUserLanguage(lang)
  }
}

function sendUpdatedUserLanguage(newSelectedLanguage = '') {
  if (!newSelectedLanguage) return

  updateUserLanguage(
    {
      userId: userId.value,
      language: newSelectedLanguage,
    },
    {
      onError: () => {
        displayNotification({
          message: 'something_went_wrong',
          isTranslationKey: true,
          type: 'error',
        })
      },
    }
  )
}

watch(
  () => userDetails.value?.preferredLanguage,
  newValue => {
    if (newValue) updateAppLanguage(newValue)
  },
  { immediate: true }
)

onMounted(() => {
  setInitialSelectedLanguage()
})

function getClass() {
  if (props.version === 'footer') {
    return 'tw-right-0 tw-border-0 tw-max-h-[36px] tw-px-2 tw-py-1 tw-rounded-md tw-text-gray-800'
  } else if (props.version === 'transparent') {
    return 'tw-right-0 tw-border-0 tw-max-h-[36px] tw-px-2 tw-py-1 tw-rounded-md tw-text-gray-800'
  } else if (props.version === 'dark') {
    return 'custom-selected-border lg:tw-w-[200px] tw-px-3 tw-min-h-[46px] tw-justify-center'
  } else if (props.version === 'light') {
    return 'tw-border-gray-700 lg:tw-w-[200px] tw-w-full'
  }
}

function getTextClass() {
  if (props.version === 'footer') {
    return ' tw-mr-[25px] tw-text-gray-800 tw-text-sm tw-uppercase'
  } else if (props.version === 'transparent') {
    return 'tw-text-gray-800 tw-text-sm'
  } else if (props.version === 'dark') {
    return 'tw-text-gray-100'
  } else if (props.version === 'light') {
    return 'tw-text-gray-700 tw-font-[14px]'
  }
}
</script>

<style scoped>
.custom-selected {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.language-dropdown:hover .custom-selected {
  @apply tw-bg-gray-100 tw-max-w-[165px];
}

.language-dropdown {
  @apply tw-max-w-[165px];
}

.language-selector {
  opacity: 0;
}
.custom-selected-border {
  border: 1px solid rgba(255, 255, 255, 0.8);
  padding-right: 30px;
  border-radius: 10px;
}
.arrow-dropdown {
  top: 50%;
  transform: translateY(-45%) rotate(90deg);
  right: 6px;
  pointer-events: none;
}
</style>
