<template>
  <BaseChart
    v-if="prettyBookings && getXAxisData && getSeriesData.length > 0"
    :container="'bookings'"
    :series="getSeriesData"
    :xaxis="getXAxisData"
    :yaxis="getYAxisData"
    :options="getOptions"
  />
</template>
<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import BaseChart from '@/components/rateConnect/performance/charts/BaseChart.vue'
import { addSeries, columnChartOptions } from '@/components/rateConnect/performance/charts/chartsUtils.js'

const { t } = useI18n()
const props = defineProps({
  categories: {
    type: [Array, Object],
    default: () => [],
  },
  campaignTypes: {
    type: [Array, Object],
    default: () => [],
  },
  tickPositions: {
    type: [Array, Object],
    default: () => [],
  },
  prettyBookings: {
    type: Object,
    default: () => {},
  },
  currency: {
    type: String,
    default: '',
  },
})

const getSeriesData = computed(() => {
  if (
    !props.prettyBookings?.partnerClicks ||
    !props.prettyBookings?.click2BookRatio ||
    !props.prettyBookings?.bookings ||
    !props.prettyBookings?.cancelledBookings
  ) {
    return []
  }

  if (
    props.prettyBookings?.partnerClicks?.length === 0 ||
    props.prettyBookings?.click2BookRatio?.length === 0 ||
    props.prettyBookings?.bookings?.length === 0 ||
    props.prettyBookings?.cancelledBookings?.length === 0
  ) {
    let arrayWithZeros = new Array(props.categories.length).fill(null)
    return [
      addSeries(
        arrayWithZeros,
        t('rc_label_clicks'),
        'partnerClicks',
        {
          color: 'transparent',
          borderColor: 'transparent',
          highlighted: false,
        },
        props.currency
      ),
      addSeries(
        arrayWithZeros,
        t('rc_label_c2b'),
        'click2BookRatio',
        {
          color: 'transparent',
          borderColor: 'transparent',
          highlighted: false,
        },
        props.currency
      ),
      addSeries(
        arrayWithZeros,
        t('rc_label_total_bookings_pay_per_stay'),
        'bookings',
        {
          columnIndicator: true,
        },
        props.currency
      ),
      addSeries(
        arrayWithZeros,
        t('rc_label_cancellations_pay_per_stay'),
        'cancelledBookings',
        {
          color: '#FC9E15',
          highlighted: false,
          columnIndicator: true,
        },
        props.currency
      ),
    ]
  } else {
    return [
      addSeries(
        props.prettyBookings?.partnerClicks,
        t('rc_label_clicks'),
        'partnerClicks',
        {
          color: 'transparent',
          borderColor: 'transparent',
          highlighted: false,
        },
        props.currency
      ),
      addSeries(
        props.prettyBookings?.click2BookRatio,
        t('rc_label_c2b'),
        'click2BookRatio',
        {
          color: 'transparent',
          borderColor: 'transparent',
          highlighted: false,
        },
        props.currency
      ),
      addSeries(
        props.prettyBookings?.bookings,
        t('rc_label_total_bookings_pay_per_stay'),
        'bookings',
        {
          columnIndicator: true,
        },
        props.currency
      ),
      addSeries(
        props.prettyBookings?.cancelledBookings,
        t('rc_label_cancellations_pay_per_stay'),
        'cancelledBookings',
        {
          color: '#FC9E15',
          highlighted: false,
          columnIndicator: true,
        },
        props.currency
      ),
    ]
  }
})

const getXAxisData = computed(() => {
  if (!props.categories || !props.campaignTypes || !props.tickPositions) return null
  return {
    categories: props.categories,
    campaignTypes: props.campaignTypes,
    tickPositions: props.tickPositions,
  }
})

const getYAxisData = computed(() => {
  if (!props.prettyBookings?.bookings) return { max: 0 }
  return {
    min: 0,
    max: props.prettyBookings.bookings?.length > 0 ? Math.max(...Object.values(props.prettyBookings.bookings)) : 10,
  }
})
const getOptions = computed(() => {
  return columnChartOptions
})
</script>
