<template>
  <BaseChart
    v-if="prettyCpa && getXAxisData && getSeriesData.length > 0"
    container="commissionCost"
    :series="getSeriesData"
    :xaxis="getXAxisData"
    :yaxis="getYAxisData"
  />
</template>
<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import BaseChart from '@/components/rateConnect/performance/charts/BaseChart.vue'
import { addSeries, addHoverDatapoint } from '@/components/rateConnect/performance/charts/chartsUtils.js'

const { t } = useI18n()
const props = defineProps({
  categories: {
    type: [Array, Object],
    default: () => [],
  },
  campaignTypes: {
    type: [Array, Object],
    default: () => [],
  },
  tickPositions: {
    type: [Array, Object],
    default: () => [],
  },
  prettyCpa: {
    type: Object,
    default: () => {},
  },
  currency: {
    type: String,
    default: '',
  },
})
const getSeriesData = computed(() => {
  if (!props.prettyCpa?.costs || !props.prettyCpa?.bookingRevenue || !props.prettyCpa?.cpa) return []
  if (
    props.prettyCpa?.costs?.length === 0 ||
    !props.prettyCpa?.bookingRevenue?.length === 0 ||
    !props.prettyCpa?.cpa?.length === 0
  ) {
    let arrayWithZeros = new Array(props.categories.length).fill(null)
    return [
      addHoverDatapoint(arrayWithZeros, t('rc_label_budget_spent'), 'costs'),
      addHoverDatapoint(arrayWithZeros, t('rc_label_revenue'), 'bookingRevenue'),
      addSeries(arrayWithZeros, t('rc_label_cpa'), 'cpa', props.currency),
    ]
  } else {
    return [
      addHoverDatapoint(props.prettyCpa.costs, t('rc_label_budget_spent'), 'costs'),
      addHoverDatapoint(props.prettyCpa.bookingRevenue, t('rc_label_revenue'), 'bookingRevenue'),
      addSeries(props.prettyCpa.cpa, t('rc_label_cpa'), 'cpa', props.currency),
    ]
  }
})
const getXAxisData = computed(() => {
  if (!props.categories || !props.campaignTypes || !props.tickPositions) return null
  return {
    categories: props.categories,
    campaignTypes: props.campaignTypes,
    tickPositions: props.tickPositions,
  }
})
const getYAxisData = computed(() => {
  return {
    min: 0,
    max: props.prettyCpa?.cpa?.length > 0 ? Math.max(...Object.values(props.prettyCpa?.cpa)) : 10,
  }
})
</script>
