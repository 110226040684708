<template>
  <div
    class="tw-mx-auto tw-max-w-sm tw-py-4 tw-px-6 tw-shadow tw-bg-white tw-border-t-8 tw-border-solid tw-border-red-700 tw-rounded-lg"
    :data-testid="dataTestId"
  >
    <h4 class="tw-py-4 tw-text-2xl">
      {{ $t(headerKey) }}
    </h4>

    <p v-if="contentKey && appName" class="tw-text-md tw-pb-4">
      {{ $t(contentKey, { appName: appName }) }}
    </p>
    <p v-if="contentKey && !appName" class="tw-text-md tw-pb-4">{{ $t(contentKey) }}</p>

    <div v-if="allowRetry">
      <MainButton v-show="retryKey" class="tw-w-full" @click.prevent="$emit('retry')" :data-testid="buttonTestId">
        {{ $t(retryKey) }}
      </MainButton>
      <MainButtonOutline class="tw-w-full tw-mt-2" @click="logout" data-testid="error-message-logout-btn">{{
        $t('userMenu.signOut')
      }}</MainButtonOutline>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'

import MainButton from '@/components/base/buttons/MainButton.vue'
import MainButtonOutline from '../base/buttons/MainButtonOutline.vue'

defineProps({
  appName: {
    type: String,
    default: '',
  },
  headerKey: {
    type: String,
    default: '',
  },
  contentKey: {
    type: String,
    default: '',
  },
  retryKey: {
    type: String,
    default: '',
  },
  allowRetry: {
    type: Boolean,
    default: true,
  },
  dataTestId: {
    type: String,
    default: '',
  },
  buttonTestId: {
    type: String,
    default: '',
  },
})

const store = useStore()

const logout = () => {
  store.dispatch('session/logout')
}
</script>
