<template>
  <section class="tw-flex tw-flex-col tw-my-5 tw-max-w-[620px] tw-mx-auto tw-w-full">
    <div class="tw-my-[40px]">
      <div class="tw-mb-8">
        <p class="tw-font-bold tw-text-xl">
          {{ isUpdateMode ? $t('competitors_title_manage') : $t('competitors_title_search') }}
        </p>
        <p class="tw-text-gray-700">
          {{ $t('competitors_text_select') }}
        </p>
      </div>

      <SelectAccommodation
        v-if="!isSuggestionsLoading"
        :selectedValues="selectedCompetitors"
        :suggestions="suggestions"
        @onSelect="value => onSelect(value)"
      />
      <LaunchpadLoader class="tw-w-full tw-flex tw-items-center tw-justify-center" v-else />
      <Selected :selectedSuggestions="selectedCompetitors" @onSelect="value => onSelect(value)" />
      <div class="tw-flex tw-w-full tw-mt-4 tw-justify-end">
        <GhostButton
          v-if="isUpdateMode"
          @click="() => router.back()"
          @keydown.enter="() => router.back()"
          class="tw-mr-2"
        >
          {{ $t('assign_cancel_cta') }}
        </GhostButton>
        <MainButton
          class="tw-w-full md:tw-w-auto"
          :disabled="selectedCompetitors.length === 0"
          @click="value => saveCompetitors(value)"
          @keydown.enter="value => saveCompetitors(value)"
        >
          <span v-if="!buttonClicked">{{ isUpdateMode ? $t('save') : $t('setup_next') }}</span>
          <span v-if="buttonClicked">
            <RCInlineLoader color="#fff" />
          </span>
        </MainButton>
      </div>
    </div>
  </section>
</template>
<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useSuggestions, useCompetitorsUpdateMutation, useCompetitors } from '@/components/rateInsights/queries'

import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'
import LaunchpadLoader from '@/components/dashboard/common/LaunchpadLoader.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import Selected from '@/components/rateInsights/onboarding/Selected.vue'
import SelectAccommodation from '@/components/rateInsights/onboarding/SelectAccommodation.vue'

import { selectedAccommodationId } from '@/layouts/queries'

const router = useRouter()
const isUpdateMode = router.currentRoute?.value.query?.isUpdate
const { competitorsUpdate } = useCompetitorsUpdateMutation()
const { competitors } = useCompetitors(selectedAccommodationId)
const { isSuggestionsLoading, suggestions } = useSuggestions(selectedAccommodationId)
const competitorsArr = JSON.parse(JSON.stringify(competitors.value))
const selectedCompetitors = ref(isUpdateMode ? competitorsArr : [])
const buttonClicked = ref(false)

const onSelect = option => {
  const allSuggestions = JSON.parse(JSON.stringify(selectedCompetitors.value))
  const index = allSuggestions.findIndex(item => item.id === option.id)
  if (index < 0) {
    allSuggestions.splice(0, 0, option)
    selectedCompetitors.value = allSuggestions
  } else {
    allSuggestions.splice(index, 1)
    selectedCompetitors.value = allSuggestions
  }
}

const saveCompetitors = () => {
  const selectedCompetitorsArr = selectedCompetitors.value.map(item => item.id)
  buttonClicked.value = true
  competitorsUpdate(
    { accommodationId: selectedAccommodationId.value, competitors: selectedCompetitorsArr },
    {
      onSuccess: data => {
        buttonClicked.value = false
        if (data.status >= 200 && data.status <= 210) {
          router.push('/rate-insights/view')
        }
      },
      onError: () => (buttonClicked.value = false),
    }
  )
}
</script>
