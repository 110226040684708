<template>
  <div ref="root">
    <div>
      <GhostButton
        class="tw-flex tw-items-center tw-gap-2"
        @click="emit('openFilters')"
        :class="{ 'btn-active': isModalOpen }"
      >
        <BaseIcon class="filter tw-fill-gray-800" :viewbox-height="12" :viewbox-width="18">
          <FiltersIcon />
        </BaseIcon>
        {{ $t('filters') }}
        <span
          v-if="appliedFiltersAmount > 0"
          class="filter-badge tw-bg-blue-700 tw-text-white tw-rounded-full tw-w-[20px] tw-h-[20px] tw-text-sm"
        >
          {{ appliedFiltersAmount }}
        </span>
      </GhostButton>
    </div>
    <div class="tw-relative">
      <div
        v-if="isModalOpen"
        class="tw-absolute tw-top-[6px] tw-z-[39] modal-filters tw-mx-auto tw-w-full tw-min-w-[768px] tw-max-w-[980px] landing:tw-max-w-[860px] landing:tw-max-h-[500px] tw-bg-white tw-m-auto landing:tw-rounded-lg box-shadow"
        :class="{ 'modal-filters-booking': type === 'booking-flyout' }"
      >
        <div class="tw-relative tw-flex tw-flex-row tw-mt-0 tw-min-h-[500px]">
          <div class="tw-flex tw-border-r tw-border-gray-300 tw-p-5 tw-min-w-[260px] tw-flex-col tw-max-h-[500px]">
            <button
              v-for="filter in currentFilters"
              :key="filter.value"
              class="tw-flex tw-w-full tw-font-bold tw-px-4 tw-py-3 tw-mb-1 tw-rounded-lg tw-text-left"
              :class="
                filter.value === activeItem
                  ? 'tw-bg-blue-200 tw-text-blue-700'
                  : 'hover:tw-bg-gray-200 hover:tw-text-gray-800 tw-text-gray-600'
              "
              @click="scrollToElement(filter.value)"
            >
              {{ $t(filter.label) }}
            </button>
          </div>

          <div
            :key="tempFiltersArray"
            class="tw-flex tw-flex-col tw-px-8 tw-h-full tw-h-[calc(100vh-150px)] tw-w-full landing:tw-h-[450px] tw-overflow-auto"
          >
            <ModalSelectFilter
              v-if="currentFilters.find(item => item.value === 'timeframe')"
              ref="timeframe"
              :label="$t('rc_performance_timeframe')"
              :options="timeframeOptions"
              name="timeframe"
              :value="selectedTimeframe"
              @onSelect="onSelect"
              class="tw-pt-6"
            />
            <div
              v-if="currentFilters.find(item => item.value === 'timeframe')"
              class="tw-border-t tw-border-gray-200 tw-h-[1px] tw-w-full tw-mt-3"
            />

            <ModalSelectFilter
              v-if="currentFilters.find(item => item.value === 'dateType')"
              ref="dateType"
              :label="$t('rc_checkin_checkout_menu')"
              :options="dateTypes"
              name="dateType"
              :value="selectedDateType"
              @onSelect="onSelect"
              class="tw-pt-3"
            />
            <div
              v-if="currentFilters.find(item => item.value === 'dateType')"
              class="tw-border-t tw-border-gray-200 tw-h-[1px] tw-w-full tw-mt-3"
            />
            <ModalSelectFilter
              v-if="currentFilters.find(item => item.value === 'aggregation')"
              ref="aggregation"
              :label="$t('rc_performance_group_data')"
              :options="aggregationOptions"
              name="dateType"
              :value="selectedAggregation"
              @onSelect="onSelect"
              class="tw-pt-3"
            />
            <div
              v-if="currentFilters.find(item => item.value === 'aggregation')"
              class="tw-border-t tw-border-gray-200 tw-h-[1px] tw-w-full tw-mt-3"
            />
            <ModalSelectFilter
              v-if="currentFilters.find(item => item.value === 'category')"
              ref="category"
              :label="$t('rc_performance_category')"
              :options="showGraphOptions"
              name="category"
              :value="selectedShowGraphValue"
              @onSelect="onSelect"
              class="tw-pt-3"
            />
            <div
              v-if="currentFilters.find(item => item.value === 'category')"
              class="tw-border-t tw-border-gray-200 tw-h-[1px] tw-w-full tw-mt-3"
            />

            <ModalStatusSelectFilter
              v-if="currentFilters.find(item => item.value === 'status')"
              name="status"
              ref="status"
              :label="$t('rc_booking_status_header')"
              :options="statusOptions"
              :value="selectedStatus"
              @onSelect="onSelect"
              :class="type === 'booking-flyout' ? 'tw-pt-6' : 'tw-pt-3'"
            />
            <div
              v-if="currentFilters.find(item => item.value === 'status')"
              class="tw-border-t tw-border-gray-200 tw-h-[1px] tw-w-full tw-mt-3"
            />
            <ModalMarketSelectFilter
              name="markets"
              ref="markets"
              :label="$t('rc_performance_markets')"
              :options="marketsOptions"
              :value="selectedMarket"
              @onSelect="onSelect"
              class="tw-mb-6 tw-pt-3"
            />
          </div>
        </div>

        <div
          class="footer shadow tw-fixed landing:tw-absolute tw-bottom-0 tw-w-full tw-flex tw-flex-row tw-justify-between tw-items-center tw-py-4 tw-px-8 tw-text-gray-800 tw-bg-white landing:tw-rounded-b-lg"
        >
          <div class="tw-flex tw-w-full tw-justify-between tw-items-center">
            <div>
              <button
                :disabled="appliedFiltersAmount.length === 0"
                class="disabled:tw-text-gray-400 tw-font-bold"
                @click="resetAllFilters"
              >
                {{ $t('rc_filter_reset') }}
              </button>
            </div>
            <MainButton size="medium" @click="onApply">{{ $t('apply') }}</MainButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onDeactivated, onMounted, ref } from 'vue'

import ModalMarketSelectFilter from './ModalMarketSelectFilter.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'

import { useRateConnectPerformance } from '@/components/rateConnect/performance/useRateConnectPerformance.js'
import ModalSelectFilter from './ModalSelectFilter.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import FiltersIcon from '@/components/icons/FiltersIcon.vue'
import ModalStatusSelectFilter from './ModalStatusSelectFilter.vue'

const props = defineProps({
  isModalOpen: Boolean,
  selectedArea: String,
  currentFilters: Array,
  marketsOptions: Array,
  selectedMarket: Object,
  timeframeOptions: Array,
  selectedTimeframe: Object,
  dateTypes: Array,
  selectedDateType: Object,
  aggregationOptions: Array,
  selectedAggregation: Object,
  showGraphOptions: Array,
  selectedShowGraphValue: Object,
  type: String,
  selectedStatus: {
    type: Array,
    default: () => [],
  },
  statusOptions: {
    type: Array,
    default: () => [],
  },
})

const { resetFilters, appliedFiltersAmount } = useRateConnectPerformance()

const root = ref(null)
const emit = defineEmits(['closeFilters', 'onApply', 'openFilters'])
const closeFilters = () => {
  emit('closeFilters')
  activeItem.value = props.currentFilters[0].value
}
const onApply = () => {
  emit('onApply', tempFiltersArray)

  appliedFiltersAmount.value = tempFiltersArray.value.length
}
const onSelect = value => {
  const index = tempFiltersArray.value.findIndex(item => item.name === value.name)

  if (index !== -1) {
    tempFiltersArray.value[index] = value
  } else {
    tempFiltersArray.value.push(value)
  }
}
const resetAllFilters = () => {
  tempFiltersArray.value = []
  resetFilters()
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})
onDeactivated(() => {
  document.removeEventListener('click', handleClickOutside)
})
const handleClickOutside = evt => {
  if (root?.value !== null && !root?.value?.contains(evt.target)) {
    onClickAwayFn()
  }
}

const onClickAwayFn = () => {
  closeFilters()
  activeItem.value = props.currentFilters[0].value
}

const tempFiltersArray = ref([])
const markets = ref(null)
const timeframe = ref(null)
const dateType = ref(null)
const aggregation = ref(null)
const category = ref(null)
const status = ref(null)
const activeItem = ref(props.currentFilters[0].value)

const scrollToElement = value => {
  activeItem.value = value
  if (value === 'markets') markets.value?.$el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
  if (value === 'timeframe')
    timeframe.value?.$el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
  if (value === 'dateType')
    dateType.value?.$el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
  if (value === 'aggregation')
    aggregation.value?.$el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
  if (value === 'category')
    category.value?.$el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
  if (value === 'status') status.value?.$el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
}
</script>
<style scoped>
.shadow {
  box-shadow: 0px 4px 16px 0px #00000026;
}
.box-shadow {
  @media (min-width: theme('screens.landing')) {
    box-shadow: 0px 10px 30px 0px #293336bf;
  }
}
.modal-filters-booking {
  left: 50%;
  transform: translateX(-50%);
}

.btn-active {
  @apply tw-bg-gray-600 tw-text-white;
}
button:hover .filter,
.btn-active .filter {
  @apply tw-fill-white tw-stroke-white;
}

button:hover .filter-badge,
.btn-active .filter-badge {
  @apply tw-bg-white tw-text-blue-700;
}
</style>
