<template>
  <div>
    <p class="tw-font-bold tw-mb-3">{{ label }}</p>
    <div class="tw-relative tw-mb-3">
      <BaseIcon :height="17" :width="17" icon-name="search" class="tw-absolute tw-top-[15px] tw-right-[15px]">
        <Search />
      </BaseIcon>
      <input
        class="tw-border tw-box-size-44 tw-border-gray-500 tw-rounded-md tw-px-3 tw-py-1 tw-outline-none tw-w-full"
        type="text"
        :value="searchQuery"
        @input="e => search(e.target.value)"
        :placeholder="$t('search_placeholder')"
      />
    </div>
    <ul class="tw-flex tw-flex-wrap">
      <li
        v-for="option in filteredOptions"
        :key="option.id"
        :class="option.value === 'all' ? 'all-item' : 'item'"
        class="tw-flex tw-items-center tw-text-sm"
      >
        <input
          type="radio"
          :name="name"
          :value="option.id"
          :id="`${name}_${option.id}`"
          :checked="value.id === option.id"
          @change="onSelect({ name, option })"
          class="tw-cursor-pointer"
        />
        <label
          :for="`${name}_${option.id}`"
          class="tw-cursor-pointer tw-py-3 tw-m-0 tw-pl-4 tw-grow tw-flex tw-items-center tw-flex-wrap"
        >
          <span
            v-if="option.value !== 'all'"
            class="flag tw-w-[20px] tw-h-[12px] tw-scale-150 flagImage tw-mr-3"
            :class="`flag-${option.value.toLowerCase()}`"
          />
          {{ option.label }}</label
        >
      </li>
    </ul>
  </div>
</template>
<script setup>
import BaseIcon from '@/components/BaseIcon.vue'
import Search from '@/components/icons/Search.vue'
import { ref, watch } from 'vue'

const emit = defineEmits(['onSelect'])
const onSelect = value => emit('onSelect', value)

const props = defineProps({
  name: String,
  label: String,
  options: Array,
  value: Object,
})

const searchQuery = ref('')
const filteredOptions = ref(props.options)
const search = text => {
  searchQuery.value = text
}
watch(searchQuery, newSearchQuery => {
  filteredOptions.value =
    newSearchQuery.length === 0
      ? props.options
      : props.options.filter(item => item.label?.toLowerCase().includes(newSearchQuery.toLowerCase()))
})
</script>
<style scoped>
.item {
  flex-basis: 50%;
  box-sizing: border-box;
}
.all-item {
  flex-basis: 100%;
  box-sizing: border-box;
}
.flag {
  left: 1.6rem;
  background-size: 20px auto;
  top: 1.6rem;
  background-position: 0 -30px;
}
.flagImage {
  background-image: url(https://imgcy.trivago.com/q_auto/v1/hardcodedimages/imagemap_all_flags_21@2x.png);

  background-repeat: no-repeat;
}
.flag-at {
  background-position: 0 -810px;
}
.flag-de {
  background-position: 0 0;
}
.flag-en,
.flag-uk {
  background-position: 0 -30px;
  height: 11px;
}
.flag-es {
  background-position: 0 -60px;
}
.flag-fr {
  background-position: 0 -90px;
}
.flag-se {
  background-position: 0 -120px;
}
.flag-pl {
  background-position: 0 -150px;
}
.flag-ru {
  background-position: 0 -180px;
}
.flag-it {
  background-position: 0 -210px;
}
.flag-gr {
  background-position: 0 -240px;
}
.flag-nl {
  background-position: 0 -270px;
}
.flag-rs {
  background-position: 0 -300px;
}
.flag-tr {
  background-position: 0 -330px;
}
.flag-ro {
  background-position: 0 -360px;
}
.flag-ar {
  background-position: 0 -390px;
}
.flag-ie {
  background-position: 0 -870px;
}
.flag-id {
  background-position: 0 -1290px;
}
.flag-hu {
  background-position: 0 -900px;
}
.flag-br {
  background-position: 0 -450px;
}
.flag-zh,
.flag-cn {
  background-position: 0 -480px;
}
.flag-us {
  background-position: 0 -510px;
}
.flag-fi {
  background-position: 0 -540px;
}
.flag-jp {
  background-position: 0 -570px;
}
.flag-mx {
  background-position: 0 -600px;
}
.flag-pt {
  background-position: 0 -630px;
}
.flag-cs,
.flag-cz {
  background-position: 0 -840px;
}
.flag-bg {
  background-position: 0 -420px;
}
.flag-da,
.flag-dk {
  background-position: 0 -690px;
}
.flag-no {
  background-position: 0 -720px;
}
.flag-be {
  background-position: 0 -750px;
}
.flag-so,
.flag-si {
  background-position: 0 -780px;
}
.flag-ch {
  background-position: 0 -930px;
}
.flag-ca {
  background-position: 0 -990px;
  height: 12px;
}
.flag-nz {
  background-position: 0 -1050px;
}
.flag-au {
  background-position: 0 -960px;
  height: 12px;
}
.flag-co {
  background-position: 0 -1200px;
  height: 12px;
}
.flag-ko,
.flag-kr {
  background-position: 0 -1020px;
}
.flag-cl {
  background-position: 0 -1170px;
  height: 12px;
}
.flag-sg {
  background-position: 0 -1140px;
}
.flag-in {
  background-position: 0 -1080px;
}
.flag-hk {
  background-position: 0 -1110px;
}
.flag-ae {
  background-position: 0 -1320px;
}
.flag-ms,
.flag-my {
  background-position: 0 -1230px;
}
.flag-th {
  background-position: 0 -1260px;
}
.flag-he,
.flag-il {
  background-position: 0 -1350px;
}
.flag-za {
  background-position: 0 -1380px;
}
.flag-tw {
  background-position: 0 -1410px;
}
.flag-ph {
  background-position: 0 -1470px;
}
.flag-world_wide {
  background-position: 0 -1514px;
}
.flag-vn {
  background-position: 0 -1440px;
}
.flag-aa,
.flag-shabaka {
  background-position: 0 -1530px;
}
.flag-hr {
  background-position: 0 -1560px;
}
.flag-sk {
  background-position: 0 -1590px;
}
.flag-pe {
  background-position: 0 -1620px;
}
.flag-ec {
  background-position: 0 -1650px;
}
.flag-uy {
  background-position: 0 -1680px;
}
.flag-ng {
  background-position: 0 -1710px;
}
</style>
