<template>
  <ExportData :isOpen="isExportModalOpen" @closeModal="closeExportModal" :selectedArea="selectedArea" />
  <BaseCard noPadding class="tw-bg-white tw-rounded-lg">
    <div v-if="(hasNoBookings && selectedArea === 'bookings') || (hasNoData && selectedArea === 'overview')">
      <div v-if="hasNoBookings && selectedArea === 'bookings'" class="tw-mx-4 tw-py-6">
        <PerformanceEmptyWrapper :missingData="true" :type="'bookings'" />
      </div>
      <div v-if="hasNoData && selectedArea === 'overview'" class="tw-mx-4 tw-py-6">
        <PerformanceEmptyWrapper :missingData="true" :type="'overview'" />
      </div>
    </div>
    <div
      v-else
      class="tw-mx-4 tw-py-6 tw-flex tw-justify-between tw-flex-col"
      :class="{ 'tw-border-b tw-border-gray-300 tw-mb-6': selectedArea === 'overview' }"
    >
      <div class="tw-flex tw-justify-between">
        <PerformanceFilters
          :overviewTableCampaignType="overviewTableCampaignType"
          :markets="markets"
          :selectedArea="selectedArea"
          :selectedDisplayType="selectedDisplayType"
          :columns="columns"
        />

        <div class="tw-flex tw-flex-row tw-gap-4">
          <GhostButton
            @click="changeDisplayType"
            v-if="selectedArea === 'overview'"
            class="tw-flex tw-items-center tw-gap-2"
          >
            <BaseIcon v-if="selectedDisplayType === 'table'" class="table tw-fill-gray-800">
              <GraphIcon />
            </BaseIcon>
            <BaseIcon v-if="selectedDisplayType === 'graph'" class="graph tw-stroke-gray-800">
              <TableIcon />
            </BaseIcon>
            {{
              selectedDisplayType === 'table' ? $t('rc_label_viewas_graph') : $t('rc_label_viewas_table')
            }}</GhostButton
          >
          <MainButton
            :disabled="(hasNoData && selectedArea === 'overview') || hasEmptyTable"
            @click="openExportModal"
            class="tw-flex tw-items-center tw-gap-2"
          >
            <BaseIcon class="">
              <DownloadIcon />
            </BaseIcon>
            {{ $t('rc_data_download_cta') }}
          </MainButton>
        </div>
      </div>
      <div class="tw-mt-6" v-if="hasEmptyTable">
        <PerformanceEmptyWrapper type="filters" />
      </div>
    </div>

    <div>
      <div
        v-if="selectedArea === 'overview'"
        class="tw-px-4 tw-flex tw-gap-x-2 md:tw-gap-2"
        :class="{ 'md:tw-flex-wrap landing:tw-flex-nowrap': kpiTabs.length > 3 }"
      >
        <PerformanceCard
          v-for="(item, index) in kpiTabs"
          :hasEmptyTable="hasEmptyTable"
          :hasNoData="hasNoData"
          :id="`${item.key}-tab`"
          :key="item.key"
          :elementId="item.key"
          :label="item.label"
          :number="item.number"
          :index="index"
          :progression="item.progression"
          role="tab"
          :data-tabs-target="`#${item.key}`"
          :class="{ 'md:tw-w-[49%] landing:tw-w-1/4': kpiTabs.length > 3 }"
        />
      </div>
      <div :class="{ 'tw-mt-6': selectedArea === 'overview' }">
        <div v-if="selectedArea === 'overview'">
          <OverviewTable
            v-if="selectedDisplayType === 'table'"
            :overviewTableCampaignType="overviewTableCampaignType"
            :hasEmptyTable="hasEmptyTable || hasNoData"
          />

          <ClickStats
            v-if="selectedDisplayType === 'graph' && selectedGraph === 'clicks'"
            class="tw-p-6 tw-pt-0"
            :categories="categories"
            :campaignTypes="campaignTypes"
            :tickPositions="tickPositions"
            :prettyVisits="visits"
            :currency="currency"
          />
          <RevenueStats
            v-if="selectedDisplayType === 'graph' && selectedGraph === 'revenue'"
            class="tw-p-6 tw-pt-0"
            :categories="categories"
            :campaignTypes="campaignTypes"
            :tickPositions="tickPositions"
            :prettyRevenue="revenue"
            :currency="currency"
          />

          <CpaStats
            v-if="selectedDisplayType === 'graph' && selectedGraph === 'cpa'"
            class="tw-p-6 tw-pt-0"
            :categories="categories"
            :campaignTypes="campaignTypes"
            :tickPositions="tickPositions"
            :prettyCpa="cpa"
            :currency="currency"
          />
          <BookingStats
            v-if="selectedDisplayType === 'graph' && selectedGraph === 'bookings'"
            class="tw-p-6 tw-pt-0"
            :categories="categories"
            :campaignTypes="campaignTypes"
            :tickPositions="tickPositions"
            :prettyBookings="bookings"
            :currency="currency"
          />
        </div>
        <div v-if="selectedArea === 'bookings'">
          <BookingTable :columns="columns" :hasEmptyTable="hasEmptyTable || hasNoBookings" />
        </div>
      </div>
    </div>
  </BaseCard>
  <div class="tw-mt-8">
    <p class="tw-text-right tw-w-auto tw-text-sm tw-text-gray-800">
      {{ $t('rc_label_last_updated') }}{{ ' ' }}<span>{{ lastUpdated }}</span>
    </p>
  </div>
</template>
<script setup>
import { computed, ref } from 'vue'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import PerformanceCard from '@/components/rateConnect/performance/PerformanceCard.vue'
import BookingTable from '@/components/rateConnect/performance/BookingTable.vue'
import OverviewTable from '@/components/rateConnect/performance/OverviewTable.vue'
import PerformanceFilters from '@/components/rateConnect/performance/PerformanceFilters.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import TableIcon from '@/components/icons/TableIcon.vue'
import GraphIcon from '@/components/icons/GraphIcon.vue'
import ClickStats from '@/components/rateConnect/performance/ClickStats.vue'
import RevenueStats from '@/components/rateConnect/performance/RevenueStats.vue'
import BookingStats from '@/components/rateConnect/performance/BookingStats.vue'
import CpaStats from '@/components/rateConnect/performance/CpaStats.vue'
import PerformanceEmptyWrapper from './PerformanceEmptyWrapper.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import DownloadIcon from '@/components/icons/DownloadIcon.vue'
import ExportData from '@/components/rateConnect/performance/ExportData.vue'

import { useRateConnectPerformance } from '@/components/rateConnect/performance/useRateConnectPerformance.js'
import { cpcColumns, cpaColumns, bothColumns } from '@/constants/bookingLogColumns'

const props = defineProps({
  selectedArea: String,
  currentCampaignType: String,
  prettyKpis: Array,
  lastUpdated: String,
  markets: Array,
  categories: Array,
  visits: Array,
  bookings: Array,
  cpa: Array,
  revenue: Array,
  campaignTypes: Array,
  tickPositions: Array,
  currency: String,
  overviewTableCampaignType: String,
  bookingTableCampaignType: String,
  hasNoData: Boolean,
  hasEmptyTable: Boolean,
  hasNoBookings: Boolean,
})

const { selectedDisplayType, selectedGraph } = useRateConnectPerformance()

const isExportModalOpen = ref(false)
const openExportModal = () => {
  isExportModalOpen.value = true
}

const closeExportModal = () => {
  isExportModalOpen.value = false
}
const changeDisplayType = () => {
  selectedDisplayType.value = selectedDisplayType.value === 'table' ? 'graph' : 'table'
}

const getKpiOrder = name => {
  if (name === 'bookings') return 0
  if (name === 'visits') return 1
  if (name === 'revenue') return 2
  if (name === 'cpa') return 3
}
const kpiTabs = computed(() => {
  const tabMap = ['bookings', 'visits', 'revenue']
  if (props.overviewTableCampaignType === 'cpc') {
    tabMap.push('cpa')
  }
  const kpis = props.prettyKpis
    .map(item => {
      return {
        ...item,
        order: getKpiOrder(item.key),
      }
    })
    .filter(item => tabMap.includes(item.key))

  return kpis.sort((a, b) => a.order - b.order)
})

const columns = computed(() => {
  // Check type type to display proper columns
  if (props.bookingTableCampaignType === 'cpa') {
    return cpaColumns
  } else if (props.bookingTableCampaignType === 'cpc') {
    return cpcColumns
  } else {
    return bothColumns
  }
})
</script>
<style scoped>
button:hover .table {
  @apply tw-fill-white tw-stroke-white;
}
button:hover .graph {
  @apply tw-stroke-white;
}
</style>
