<template>
  <div
    v-click-outside="onClickaway"
    class="tw-w-full tw-bg-white tw-absolute tw-min-h-[100px] tw-rounded-lg listing tw-flex-col tw-z-2"
    :class="{
      'tw-flex tw-justify-center tw-items-center': isSearching || (data && data.length < 1 && isSearching === false),
    }"
  >
    <div v-if="isSearching">
      <LaunchpadLoader />
    </div>
    <div v-else-if="data && data.length < 1 && isSearching === false" class="tw-items-center tw-flex tw-max-h-[100px]">
      <p class="tw-gray-700 tw-font-bold">{{ $t('no_results') }}</p>
    </div>
    <div v-for="(item, index) in data" v-else :key="item.id">
      <PropertyListItem
        :id="item.id"
        class="property-item"
        :class="getClass(index)"
        :img="getImage(item.imageUrl)"
        :name="item.highlightedName"
        :address="item.address"
        :assigned="item.assigned"
        :reassignable="item.reassignable"
        @onClick="selectItem(item)"
      />
      <div v-if="index <= data.length - 1" class="divider" />
    </div>
    <div v-if="!isSearching" class="footer tw-flex tw-w-full tw-px-[11px] tw-py-3">
      <BaseIcon
        icon-name="information-outline"
        :height="16"
        :width="16"
        class="tw-fill-none tw-stroke-blue-700 tw-h-[20px] tw-w-[20px]"
      >
        <InformationOutline />
      </BaseIcon>
      <p class="tw-ml-2 tw-text-sm tw-text-gray-700" v-html="$t('tbs_registration_faqs')" />
    </div>
  </div>
</template>
<script setup>
import { onMounted, nextTick, onUnmounted, watch } from 'vue'
import PropertyListItem from '@/components/assignedProperties/PropertyListItem.vue'
import LaunchpadLoader from '@/components/dashboard/common/LaunchpadLoader.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import InformationOutline from '@/components/icons/InformationOutline.vue'
import { useAssignedProperty } from './useAssignedProperty.js'

import appAnalytics from '@/utils/tracking'

const { addHotelAssignment } = useAssignedProperty()
const emit = defineEmits(['onClickaway'])
const onClickaway = () => emit('onClickaway')
const props = defineProps({
  data: Array,
  isSearching: Boolean,
  isOnboarding: Boolean,
})

const onLinkClick = event => {
  if (props.isOnboarding) {
    let from = (event.target || event.srcElement).closest('a')
    if (
      from?.href.includes('What-should-I-do-when-I-cant-find-my-property-while-registering-on-trivago-Business-Studio')
    ) {
      appAnalytics.track(appAnalytics.events.ONBOARDING_CANNOT_FIND_PROPERTY)
    }
    if (from?.href.includes('What-can-I-do-if-my-property-is-already-assigned-to-another-account')) {
      appAnalytics.track(appAnalytics.events.ONBOARDING_PROPERTY_ASSIGNED_LINK)
    }
  }
}
onMounted(() => {
  nextTick(() => {
    window.addEventListener('click', onLinkClick)
  })
})

onUnmounted(() => {
  window.removeEventListener('click', onLinkClick)
})

watch(
  () => props.data,
  newData => {
    if (props.isOnboarding) {
      if (newData.length === 1) {
        appAnalytics.track(appAnalytics.events.ONBOARDING_ASSIGNED_ITEM_STATE, {
          assignedItem: newData[0],
        })
      }
    }
  }
)

const selectItem = item => {
  if (!item.assigned || item.reassignable) {
    addHotelAssignment(item)
  }
}
const getClass = index => {
  if (index === 0) {
    return 'tw-rounded-t-lg'
  }
  if (index === props.data?.length - 1) {
    return 'tw-rounded-b-lg'
  }
}
const getImage = image => {
  const imagePath = image?.length > 0 ? image : 'null'
  if (image) {
    return `https://imgcy.trivago.com/c_fill,d_dummy.jpeg,e_sharpen:60,f_auto,h_40,q_auto,w_40/${imagePath}`
  } else {
    return 'https://imgcy.trivago.com/c_fill,d_dummy.jpeg,e_sharpen:60,f_auto,h_40,q_auto,w_40/dummy.jpeg'
  }
}
</script>
<style scoped>
.listing {
  box-shadow:
    0 2px 4px 0 rgba(63, 87, 189, 0.1),
    0 2px 10px 0 rgba(108, 112, 122, 0.15);
}
.divider {
  width: 100%;
  height: 1px;
  @apply tw-bg-gray-200;
}
.footer {
  border-top: 1px solid theme('colors.gray.200');
}
.footer p :deep(a) {
  @apply tw-text-blue-800;
}
</style>
