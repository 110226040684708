export const allColumns = {
  bookingId: {
    header: 'rc_bookings_list_booking_id',
    hint: 'rc_booking_id_tooltip',
    id: 'bookingId',
    colType: 'string',
    sortable: false,
    className: 'col-bookingId',
    mandatory: true,
  },
  bookingDate: {
    header: 'rc_bookings_list_booking_date',
    id: 'bookingDate',
    colType: 'date',
    sortable: true,
    className: 'col-bookingDate',
    mandatory: true,
  },
  status: {
    header: 'rc_bookings_list_status',
    hint: 'rc_status_tooltip',
    id: 'status',
    colType: 'status',
    sortable: true,
    className: 'col-status',
    mandatory: false,
  },
  checkinDate: {
    header: 'rc_bookings_label_arr_date',
    id: 'arrivalDate',
    colType: 'date',
    sortable: true,
    className: 'col-stayDate',
    mandatory: false,
  },
  checkoutDate: {
    header: 'rc_bookings_label_dep_date',
    id: 'departureDate',
    colType: 'date',
    sortable: true,
    className: 'col-stayDate',
    mandatory: false,
  },
  market: {
    header: 'rc_bookings_list_market',
    id: 'market',
    colType: 'flag',
    sortable: true,
    className: 'col-market',
    mandatory: false,
  },
  bookingValue: {
    header: 'rc_bookings_list_booking_value',
    id: 'bookingValue.amountCampaignCurrency',
    colType: 'currency',
    sortable: true,
    className: 'col-bookingValue',
    mandatory: false,
  },
  commissionCost: {
    header: 'rc_bookings_commission',
    hint: 'rc_commissions_tooltip',
    id: 'bookingValue.commissionCost',
    colType: 'currency',
    sortable: true,
    className: 'col-commissionCost',
    mandatory: false,
  },
  avgRoomRate: {
    header: 'rc_bookings_list_arr',
    hint: 'rc_def_arr',
    id: 'arr',
    colType: 'currency',
    sortable: true,
    className: 'col-arr',
    mandatory: false,
  },
  refundAmount: {
    header: 'rc_label_refunded_revenue_pay_per_stay',
    hint: 'rc_refunds_tooltip',
    id: 'refundAmount',
    colType: 'currency',
    sortable: true,
    className: 'col-refundAmount',
    mandatory: false,
  },
  campaignType: {
    header: 'rc_campaign_type_head',
    hint: 'rc_campaign_tooltip',
    id: 'campaignType',
    colType: 'campaignType',
    sortable: true,
    className: 'col-campaignType',
    mandatory: false,
  },
  cancelledDate: {
    header: 'rc_label_cancellation_date_pay_per_stay',
    id: 'cancelledDate',
    colType: 'date',
    sortable: true,
    className: 'col-cancelledDate',
    mandatory: false,
  },
}

export const cpaColumns = [
  allColumns.bookingId,
  allColumns.bookingDate,
  allColumns.status,
  allColumns.checkinDate,
  allColumns.checkoutDate,
  allColumns.bookingValue,
  allColumns.commissionCost,
  allColumns.cancelledDate,
  allColumns.refundAmount,
  allColumns.market,
]

export const cpcColumns = [
  allColumns.bookingId,
  allColumns.bookingDate,
  allColumns.status,
  allColumns.checkinDate,
  allColumns.checkoutDate,
  allColumns.bookingValue,
  allColumns.market,
]

export const bothColumns = [
  allColumns.bookingId,
  allColumns.bookingDate,
  allColumns.status,
  allColumns.checkinDate,
  allColumns.checkoutDate,
  allColumns.bookingValue,
  allColumns.commissionCost,
  allColumns.cancelledDate,
  allColumns.refundAmount,
  allColumns.campaignType,
  allColumns.market,
]
