<template>
  <div>
    <div
      class="md:tw-m-auto"
      :class="currentStep === 3 ? 'tw-w-auto' : 'tw-mx-auto tw-max-w-[440px] md:tw-w-screen-md md:tw-max-w-screen-md'"
    >
      <Stepper :current-step="currentStep" @onNext="onNextAction" @onBack="onBackAction" />
      <div class="tw-my-8">
        <SetupSelectCampaignType
          v-if="currentStep === 1"
          :eligibleTypeOfCPACampaign="eligibleTypeOfCPACampaign"
          :selectedCampaignType="selectedCampaignType"
          @onSelectedCampaignTypeChange="onSelectedCampaignTypeChange"
        />
        <CPACampaignSetup
          v-if="currentStep === 2 && isSelectedCampaignTypeCPA"
          :eligibleCommissionLevels="eligibleCommissionLevels"
          :selectedCampaignType="selectedCampaignType"
          :selectedCommissionLevel="selectedCommissionLevel"
          @onSelectedCommissionChange="onSelectedCommissionChange"
        />
        <PaymentPage
          v-if="currentStep === 3"
          :selectedCommissionLevel="selectedCommissionLevel"
          :rcCampaignCreationInProgress="rcCampaignCreationInProgress"
          :selectedPartner="selectedPartner"
          @onCheckoutSuccess="onCheckoutSuccess"
          @setRCCampaignCreationInProgress="setRCCampaignCreationInProgress"
        />
      </div>
      <SetupFooter
        :currentStep="currentStep"
        :next-disabled="isNextDisabled"
        :is-loading="rcCampaignCreationInProgress"
        @onNext="onNextAction"
        @onBack="onBackAction"
      />
    </div>
    <FullScreenLoader v-show="isStartUpFetching || rcCampaignCreationInProgress" />
  </div>
</template>
<script setup>
import { computed, ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import Stepper from '@/components/rateConnect/setup/SetupStepper.vue'
import SetupFooter from '@/components/rateConnect/setup/SetupFooter.vue'
import CPACampaignSetup from '@/components/rateConnect/setup/CPACampaignSetup.vue'
import PaymentPage from '@/components/rateConnect/setup/PaymentPage.vue'
import SetupSelectCampaignType from '@/components/rateConnect/setup/SetupSelectCampaignType.vue'
import FullScreenLoader from '@/components/base/loaders/FullScreenLoader.vue'
import { useSetup } from './useSetup'
import { useCommissionLevels, useStartUp } from '@/components/rateConnect/queries'
import { selectedAccommodationId } from '@/layouts/queries'
import appAnalytics from '@/utils/tracking'

const router = useRouter()
const route = useRoute()
const {
  currentStep,
  selectedCampaignType,
  isSelectedCampaignTypeCPA,
  selectedCommissionLevel,
  selectedPartner,
  resetSetup,
} = useSetup()
const { isFetching: isStartUpFetching, isItemNetCPAEligible } = useStartUp(selectedAccommodationId)
const { eligibleCommissionLevels } = useCommissionLevels()

const rcCampaignCreationInProgress = ref(false)

const setRCCampaignCreationInProgress = loading => {
  rcCampaignCreationInProgress.value = loading
}

const isNextDisabled = computed(() => {
  if (currentStep.value === 1) {
    return selectedCampaignType.value === null
  } else if (currentStep.value === 2) {
    return selectedCommissionLevel.value === null
  }
  return false
})

const eligibleTypeOfCPACampaign = computed(() => (isItemNetCPAEligible.value === true ? 'netCpa' : null))

const onCheckoutSuccess = () => {
  resetSetup()
}

const onNextAction = () => {
  if (currentStep.value === 1) {
    if (isSelectedCampaignTypeCPA.value) selectedCommissionLevel.value = 18
    currentStep.value = 2
  } else if (currentStep.value === 2) {
    currentStep.value = 3
  }
}
const onBackAction = () => {
  if (currentStep.value === 1) {
    router.push('/rate-connect')
  } else if (currentStep.value > 1) {
    currentStep.value -= 1
  }
}

const onSelectedCampaignTypeChange = type => (selectedCampaignType.value = type)
const onSelectedCommissionChange = level => (selectedCommissionLevel.value = level)

onMounted(() => {
  resetSetup()
  selectedCampaignType.value = isItemNetCPAEligible.value === true ? 'netCpa' : 'cpc'
  if (route.query?.source) {
    selectedCommissionLevel.value = 18
    currentStep.value = 2
  }
  const trafficSource = window.sessionStorage?.getItem?.('traffic_source')
  appAnalytics.track(appAnalytics.events.RC_ONBOARDING_STARTED, {
    item_id: selectedAccommodationId.value,
    partner_id: selectedPartner.value?.value,
    source: route.query?.source ? route.query.source : 'RCCreateCampaign',
    traffic_source: trafficSource ?? '',
  })
})
</script>
<style scoped>
.app-container-loader {
  @apply tw-absolute tw-inset-0 tw-z-50;
  background: theme('colors.white');
  opacity: 0.8;
}
.app-container {
  @apply tw-absolute tw-inset-0 tw-block tw-w-full tw-h-screen;
}
.app-container-checkout-page-active {
  top: 0;
  @apply tw-h-checkout-min-height tw-z-50;
}
</style>
