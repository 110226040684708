<template>
  <section>
    <div
      class="tw-flex tw-flex-col tw-gap-5 md:tw-gap-8 tw-w-full lg:tw-max-w-screen-landing md:tw-max-w-[638px] tw-py-6 lg:tw-px-0 tw-mx-auto lg:tw-py-8"
    >
      <div class="tw-flex md:tw-flex-row tw-flex-col tw-gap-x-11 tw-mx-2.5 xs:tw-mx-6 md:tw-mx-0">
        <div
          class="tw-order-1 md:tw-order-0 tw-rounded-xl tw-border tw-border-black line-shadow tw-flex-grow tw-min-h-[170px] md:tw-max-h-full plus_image md:tw-self-stretch lg:tw-self-auto tw-max-w-full md:tw-min-w-[294px] lg:tw-min-w-[420px]"
        />
        <div class="tw-order-0 md:tw-order-1">
          <img :src="StudioPlusLogo" alt="Business Studio+" class="tw-h-[20px] lg:tw-h-[23px] tw-mx-auto md:tw-mx-0" />
          <h3
            class="tw-font-bold lg:tw-text-3.5xl tw-text-1xl tw-mt-2 tw-text-center md:tw-text-left"
            v-html="$t('studio_plus_mp_subheader')"
          />
          <p class="tw-text-center md:tw-text-left tw-text-sm lg:tw-text-xl tw-mt-2 tw-mb-4 md:tw-mb-0">
            {{ $t('studio_plus_mp_product_overview') }}
          </p>
        </div>
      </div>

      <!-- Web Version -->
      <div class="tw-hidden md:tw-flex tw-flex-col lg:tw-flex-row lg:tw-mt-[30px] tw-gap-6">
        <div
          v-for="item in cards"
          :key="item.id"
          :id="item.id"
          class="tw-relative shadow tw-bg-white tw-rounded-xl tw-px-6 tw-py-5 tw-border tw-border-gray-300 tw-w-full"
        >
          <div class="tw-h-full lg:tw-pt-[28px]">
            <div class="tw-flex tw-items-center">
              <div
                class="tw-bg-decorative-orange tw-min-w-[45px] tw-w-[45px] tw-h-[45px] lg:tw-min-w-[60px] lg:tw-w-[60px] lg:tw-h-[60px] tw-flex tw-justify-center tw-items-center tw-rounded-full icon tw-mr-4"
              >
                <img :src="item.icon" :alt="$t(item.title)" />
              </div>
              <h2 class="lg:tw-mx-auto tw-font-bold lg:tw-text-xl">{{ $t(item.title) }}</h2>
            </div>
            <ul class="tw-list-disc tw-ml-4 tw-pt-4 lg:tw-pt-0">
              <li v-for="benefit in item.benefits" :key="$t(benefit)" class="tw-mt-2" v-html="$t(benefit)" />
            </ul>
          </div>
        </div>
      </div>

      <!-- Mobile Version -->
      <div
        ref="containerRef"
        class="md:tw-hidden tw-flex tw-flex-col md:tw-flex-row tw-w-vw tw-overflow-hidden carousel-container tw-ml-2.5 xs:tw-ml-6"
      >
        <div class="inner plus_carousel-mobile" ref="inner" :style="innerStyles">
          <div
            ref="boxesRef"
            v-for="item in cards"
            :key="item.id"
            :id="item.id"
            class="card shadow tw-bg-white tw-rounded-xl tw-px-6 tw-py-5 tw-border tw-border-gray-300"
          >
            <div class="tw-h-full lg:tw-pt-[28px]">
              <div class="tw-flex tw-items-center">
                <div
                  class="tw-bg-decorative-orange tw-min-w-[45px] tw-w-[45px] tw-h-[45px] lg:tw-min-w-[60px] lg:tw-w-[60px] lg:tw-h-[60px] tw-flex tw-justify-center tw-items-center tw-rounded-full icon tw-mr-4"
                >
                  <img :src="item.icon" :alt="$t(item.title)" />
                </div>
                <h2 class="lg:tw-mx-auto tw-font-bold lg:tw-text-xl">{{ $t(item.title) }}</h2>
              </div>
              <ul class="tw-list-disc tw-ml-4 tw-pt-4 lg:tw-pt-0">
                <li v-for="benefit in item.benefits" :key="$t(benefit)" class="tw-mt-2" v-html="$t(benefit)" />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { onMounted, onBeforeUnmount, ref, watch, computed, nextTick, onUpdated } from 'vue'
import { useStore } from 'vuex'
import TouchEvent from '@/utils/touchEvents.js'

const StudioPlusLogo = new URL('/src/assets/img/landing/landing_studio_plus.svg', import.meta.url)

const HotelConnect = new URL('/src/assets/img/landing/icons/hotel_connect_icon.svg', import.meta.url)
const RateInsights = new URL('/src/assets/img/landing/icons/rate_insights_icon.svg', import.meta.url)
const VisitorsProfile = new URL('/src/assets/img/landing/icons/visitors_profile_icon.svg', import.meta.url)

const cards = [
  {
    id: 0,
    title: 'tbs_mp_plus_benefits_3',
    benefits: ['plus_product_page_ri_point_1', 'plus_product_page_ri_point_2', 'plus_product_page_ri_point_3'],
    icon: RateInsights,
  },
  {
    id: 1,
    title: 'tbs_mp_plus_benefits_1',
    benefits: ['plus_product_page_hc_point_1', 'plus_product_page_hc_point_2', 'plus_product_page_hc_point_3'],
    icon: HotelConnect,
  },
  {
    id: 2,
    title: 'tbs_mp_plus_benefits_2',
    benefits: ['plus_product_page_vp_point_1', 'plus_product_page_vp_point_2', 'plus_product_page_vp_point_3'],
    icon: VisitorsProfile,
  },
]

const store = useStore()
const containerRef = ref(null)
const boxesRef = ref([])
const language = computed(() => store.state.locale.language)

const touchEvent = ref(null)
const current = ref(1)
const timer = ref('')
const inner = ref(null)
const innerStyles = ref({})
const step = ref('')
const transitioning = ref(false)

const setEqualHeight = () => {
  if (window.innerWidth > 767) {
    boxesRef.value.forEach(box => (box.style.height = 'auto'))
    return
  }

  const boxes = boxesRef.value
  if (!boxes || boxes.length === 0) return

  boxes.forEach(box => {
    box.style.height = 'auto'
  })

  let maxHeight = 0
  boxes.forEach(box => {
    if (box.offsetHeight > maxHeight) {
      maxHeight = box.offsetHeight
    }
  })

  boxes.forEach(box => {
    box.style.height = maxHeight + 'px'
  })
}

watch(language, () => {
  nextTick(() => {
    setEqualHeight()
  })
})

onUpdated(() => {
  setEqualHeight()
})

onMounted(async () => {
  await nextTick()
  setEqualHeight()
  setStep()
  autoplay()
  document.addEventListener('touchstart', event => {
    touchEvent.value = new TouchEvent(event)
  })

  document.addEventListener('touchend', handleSwipe)
  window.addEventListener('resize', setEqualHeight)
})

onBeforeUnmount(() => {
  clearInterval(timer)
  window.removeEventListener('resize', setEqualHeight)
  document.removeEventListener('touchend', handleSwipe)
})

const afterTransition = callback => {
  const listener = () => {
    callback()
    inner.value?.removeEventListener('transitionend', listener)
  }
  inner.value?.addEventListener('transitionend', listener)
}

const resetTranslate = () => {
  innerStyles.value = {
    transition: 'none',
    transform: 'translateX(0)',
  }
}

const setStep = () => {
  const innerWidth = inner.value?.scrollWidth
  const totalCards = cards.length

  step.value = innerWidth / totalCards
}

const moveLeft = () => {
  innerStyles.value = {
    transition: 'transform 0.5s ease',
    transform: `translateX(-${step.value}px)`,
  }
}

const moveRight = () => {
  innerStyles.value = {
    transition: 'transform 0.5s ease',
    transform: `translateX(${step.value}px)`,
  }
}

const next = () => {
  if (transitioning.value) return
  transitioning.value = true
  moveLeft()
  afterTransition(() => {
    const firstElement = containerRef.value.querySelector('.plus_carousel-mobile')?.firstElementChild
    containerRef.value.querySelector('.plus_carousel-mobile').appendChild(firstElement)

    updateCurrent()
    resetTranslate()
    transitioning.value = false
  })
}

const prev = () => {
  if (transitioning.value) return
  transitioning.value = true
  moveRight()
  afterTransition(() => {
    const lastElement = containerRef.value.querySelector('.plus_carousel-mobile').lastElementChild
    containerRef.value.querySelector('.plus_carousel-mobile').prepend(lastElement)

    updateCurrent()
    resetTranslate()
    transitioning.value = false
  })
}

const updateCurrent = () => {
  const firstElementId = containerRef.value.querySelector('.plus_carousel-mobile').firstElementChild.id
  current.value = Number(firstElementId.split('_')[1])
}

const autoplay = () => {
  next()
  timer.value = setTimeout(autoplay, 5000)
}

const handleSwipe = event => {
  if (!touchEvent.value) {
    return
  }
  touchEvent.value.setEndEvent(event)

  if (touchEvent.value.isSwipeRight()) {
    prev()
  } else if (touchEvent.value.isSwipeLeft()) {
    next()
  }
  touchEvent.value = null
}
</script>
<style scoped>
.line-shadow {
  box-shadow: 3px 3px 0px 0px #000;
}
.shadow {
  box-shadow: 2px 2px 4px 0px #00000040;
}
.icon {
  @media (min-width: theme('screens.lg')) {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.plus_carousel-mobile {
  @apply tw-py-2;
  display: flex;
  margin: auto;
  white-space: nowrap;
  transition: transform 1s;
  @media (max-width: theme('screens.md')) {
    width: 100%;
  }
}

.card {
  @apply tw-bg-white tw-min-w-[300px] xs:tw-min-w-[300px] tw-min-h-[100%]  tw-rounded-xl tw-relative;
  width: 300px;
  margin-right: 18px;
  display: inline-flex;
  white-space: wrap;
}
.plus_image {
  background-image: url('../../../assets/img/studio-plus-landing/studio_plus_img.jpg');
  background-size: cover;
  background-position: center;
  width: 100%;
}
</style>
