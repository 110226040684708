<template>
  <Table
    :columns="columns"
    :data="tableData"
    :currency="currency"
    :getSelectedPeriodCampaignType="overviewTableCampaignType"
    :getSelectedAggregation="selectedAggregation"
    :hasEmptyTable="hasEmptyTable"
  />
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import Table from '@/components/rateConnect/performance/table/Table.vue'
import { useStats } from '@/components/rateConnect/queries'
import { useRateConnectPerformance } from './useRateConnectPerformance'
import { CAMPAIGN_MODES, inferCampaignAggregateType } from '@/utils/rateConnectUtils'
import { selectedAccommodationId } from '@/layouts/queries'

const { selectedMarket, formattedStartDate, formattedEndDate, selectedAggregation } = useRateConnectPerformance()
const store = useStore()
const currency = computed(() => store.state.currency)

const { statsData } = useStats(
  selectedAccommodationId,
  currency,
  formattedStartDate,
  formattedEndDate,
  selectedMarket,
  selectedAggregation
)

const props = defineProps({
  overviewTableCampaignType: String,
  hasEmptyTable: Boolean,
})

const allColumns = {
  dateColumn: {
    header: 'rc_table_date',
    id: 'date',
    colType: 'date',
    className: 'col--date',
    sortable: true,
  },
  clicksColumn: {
    header: 'rc_table_clicks',
    hint: 'rc_def_clicks',
    id: 'clicks',
    colType: 'number',
    className: 'col--clicks',
    sortable: true,
  },
  bookingsColumn: {
    header: 'rc_total_bookings_pay_per_stay',
    hint: 'rc_def_bookings',
    id: 'bookings',
    colType: 'number',
    className: 'col--bookings',
    sortable: true,
  },
  revenueColumn: {
    header: 'rc_table_revenue',
    hint: 'rc_def_revenue',
    id: 'revenue',
    colType: 'currency',
    className: 'col--revenue',
    sortable: true,
  },
  commissionCostsColumn: {
    header: 'rc_table_commissions',
    hint: 'rc_def_commissions',
    id: 'commissionCost',
    colType: 'currency',
    className: 'col--commissionCost',
    sortable: true,
  },
  estimatedCPAColumn: {
    header: 'rc_table_ecpa',
    hint: 'rc_def_ecpa',
    id: 'ecpa',
    colType: 'percentage',
    className: 'col--cpa',
    sortable: true,
  },
  budgetSpentColumn: {
    header: 'rc_table_costs',
    hint: 'rc_def_costs',
    id: 'costs',
    colType: 'currency',
    className: 'col--costs',
    sortable: true,
  },
  averageBookingValueColumn: {
    header: 'rc_table_abv',
    hint: 'rc_def_abv',
    id: 'abv',
    colType: 'currency',
    className: 'col--abv',
    sortable: true,
  },
  cpcColumn: {
    header: 'rc_table_cpc',
    hint: 'rc_def_cpc',
    id: 'cpc',
    colType: 'currency',
    className: 'col--cpc',
    sortable: true,
  },
  unavailabilityColumn: {
    header: 'rc_table_unavailability',
    hint: 'rc_def_unavailability',
    id: 'unavailability',
    colType: 'percentage',
    className: 'col--unavailability',
    sortable: true,
  },
  cancelledBookingsColumn: {
    header: 'rc_cancellations_pay_per_stay',
    id: 'cancelledBookings',
    colType: 'number',
    className: 'col--cancelledBookings',
    sortable: true,
  },
  bookingRefundedRevenueColumn: {
    header: 'rc_label_refunded_revenue_pay_per_stay',
    id: 'bookingRefundedRevenue',
    colType: 'currency',
    className: 'col--bookingRefundedRevenue',
    sortable: true,
  },
}

const columns = computed(() => {
  if (!props.overviewTableCampaignType) return

  // Check type type to display proper columns
  if (props.overviewTableCampaignType === CAMPAIGN_MODES.CPC) {
    return [
      allColumns.dateColumn,
      allColumns.clicksColumn,
      allColumns.bookingsColumn,
      allColumns.revenueColumn,
      allColumns.budgetSpentColumn,
      allColumns.cpcColumn,
      allColumns.estimatedCPAColumn,
      allColumns.averageBookingValueColumn,
      allColumns.unavailabilityColumn,
    ]
  } else if (props.overviewTableCampaignType === CAMPAIGN_MODES.CPA) {
    return [
      allColumns.dateColumn,
      allColumns.clicksColumn,
      allColumns.bookingsColumn,
      allColumns.revenueColumn,
      allColumns.cancelledBookingsColumn,
      allColumns.bookingRefundedRevenueColumn,
      allColumns.commissionCostsColumn,
      allColumns.averageBookingValueColumn,
      allColumns.unavailabilityColumn,
    ]
  } else {
    return [
      allColumns.dateColumn,
      allColumns.clicksColumn,
      allColumns.bookingsColumn,
      allColumns.revenueColumn,
      allColumns.cancelledBookingsColumn,
      allColumns.bookingRefundedRevenueColumn,
      allColumns.budgetSpentColumn,
      allColumns.cpcColumn,
      allColumns.estimatedCPAColumn,
      allColumns.commissionCostsColumn,
      allColumns.averageBookingValueColumn,
      allColumns.unavailabilityColumn,
    ]
  }
})

const generateEmptyData = () => {
  const emptyData = Array(6)
    .fill(null)
    .map(() => {
      return {
        date: {
          start: null,
          end: null,
        },
        clicks: null,
        bookings: null,
        revenue: null,
        ecpa: null,
        costs: null,
        abv: null,
        cpc: null,
        unavailability: null,
        commissionCost: null,
        campaignType: null,
        cancelledBookings: null,
        bookingRefundedRevenue: null,
      }
    })

  return emptyData
}

const tableData = computed(() => {
  if (!statsData.value || !Array.isArray(statsData.value)) return null

  const newData = statsData.value.map(row => {
    const aggregateCampaignType = inferCampaignAggregateType(row)
    const bookingRefundedRevenue = row.bookingRefundedRevenue >= 0 ? row.bookingRefundedRevenue / 100 : undefined
    return {
      date: {
        start: row.startDate,
        end: row.endDate,
      },
      clicks: row.partnerClicks,
      bookings: row.bookings,
      revenue: row.bookingRevenue / 100,
      ecpa: row.cpa / 100,
      costs: row.costs / 100,
      abv: row.avgBookingRevenue / 100,
      cpc: row.cpc / 100,
      unavailability: row.unavailabilityScore,
      commissionCost: row.commissionCost / 100,
      campaignType: aggregateCampaignType,
      cancelledBookings: row.cancelledBookings,
      bookingRefundedRevenue,
    }
  })

  newData.sort(function (a, b) {
    var c = new Date(a.date.start)
    var d = new Date(b.date.start)
    return d - c
  })

  return newData.length > 0 ? newData : generateEmptyData()
})
</script>
