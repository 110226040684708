<template>
  <div
    class="base-mobile-shadow tw-border-2 tw-border-blue-800 tw-rounded-xl tw-bg-blue-100 tw-p-7 tw-text-center md:tw-hidden"
  >
    <h3 class="upsell-title tw-font-bold tw-text-3xl tw-mb-3 tw-mt-2 tw-px-3" v-html="$t('rc_upgrade_header')" />
    <p class="upsell-subtext tw-mb-4" v-html="isAdminUser ? $t('rc_upgrade_subtext') : $t('rc_admin_log_in_text')" />
    <div class="tw-text-xs tw-font-bold tw-mb-6">
      <ul class="tw-flex tw-justify-center tw-gap-2 tw-flex-wrap">
        <li>
          <CheckMark height="10px" width="10px" class="tw-inline tw-mr-1" />
          <span>
            {{ $t('rc_upgrade_ncpa_highlight_1') }}
          </span>
        </li>
        <li>
          <CheckMark height="10px" width="10px" class="tw-inline tw-mr-1" />
          <span>
            {{ $t('rc_upgrade_ncpa_highlight_2') }}
          </span>
        </li>
        <li>
          <CheckMark height="10px" width="10px" class="tw-inline tw-mr-1" />
          <span>
            {{ $t('rc_upgrade_ncpa_highlight_3') }}
          </span>
        </li>
      </ul>
    </div>
    <MainButton class="tw-w-full" @click="onUpgradeClick">{{
      isAdminUser ? $t('rc_upgrade_cta') : $t('rc_admin_log_in_cta')
    }}</MainButton>
  </div>
  <div
    class="tw-hidden tw-max-w-screen-xxl tw-mx-auto md:tw-flex md:tw-justify-between tw-px-9 tw-items-center tw-gap-6"
  >
    <div class="tw-w-1/2 tw-max-w-md">
      <h3 class="upsell-title tw-font-bold tw-text-3xl tw-mb-7" v-html="$t('rc_upgrade_header')"></h3>
      <p class="upsell-subtext tw-mb-8" v-html="isAdminUser ? $t('rc_upgrade_subtext') : $t('rc_admin_log_in_text')" />

      <MainButton @click="onUpgradeClick" class="tw-w-full">{{
        isAdminUser ? $t('rc_upgrade_cta') : $t('rc_admin_log_in_cta')
      }}</MainButton>
    </div>
    <RateConnectUpsellBannerCard class="tw-w-1/2" />
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import MainButton from '@/components/base/buttons/MainButton.vue'
import RateConnectUpsellBannerCard from '@/components/dashboard/rateConnectUpsell/RateConnectUpsellBannerCard.vue'
import CheckMark from '@/components/icons/CheckMark.vue'

import { selectedAccommodationId } from '@/layouts/queries'

import appAnalytics from '@/utils/tracking'

const store = useStore()
const router = useRouter()
const props = defineProps({
  isAdminUser: Boolean,
})

const onUpgradeClick = () => {
  if (props.isAdminUser) {
    router.push({ name: 'rate-connect-no-campaign' })
  } else {
    appAnalytics.track(appAnalytics.events.RC_LOGIN_AS_ADMIN_CLICKED, {
      accommodationId: selectedAccommodationId.value,
    })
    store.dispatch('session/logout', '/rate-connect/no-campaign?loginAsAdmin=true')
  }
}
</script>

<style scoped>
.upsell-title :deep(span) {
  @apply tw-text-blue-700;
}
.upsell-subtext :deep(b) {
  @apply tw-font-bold;
}
.base-mobile-shadow {
  box-shadow:
    0 2px 4px 0 rgba(63, 87, 189, 0.1),
    0 2px 10px 0 rgba(108, 112, 122, 0.15);
}
</style>
