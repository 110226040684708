<template>
  <BaseCard class="tw-my-6" v-if="assignment.organisation.id">
    <div class="tw-flex tw-items-center tw-mb-4 tw-text-xl">
      <BaseIcon :height="24" :width="24" icon-name="arrow-repeat" class="tw-fill-gray-800 tw-mr-1">
        <Organization />
      </BaseIcon>
      <p class="tw-font-bold">{{ 'Organization' }}</p>
    </div>
    <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-items-center tw-justify-between">
      <div class="tw-bg-gray-100 tw-py-2 tw-px-4 tw-rounded-lg">
        <p>{{ assignment.organisation?.name }}</p>
        <p class="tw-text-xs tw-mt-1">{{ assignment.organisation?.id }}</p>
      </div>
      <div class="tw-flex tw-items-center tw-mt-2 md:tw-mt-0 tw-w-full md:tw-w-auto">
        <DangerButton :disabled="campaigns.length > 0" @click="toggleModal(true)" class="tw-w-full">{{
          'Delete assignment'
        }}</DangerButton>
        <UiTooltip
          v-if="campaigns.length > 0"
          tooltip-position="top"
          :label="'You can\'t delete this assignment because the accommodation owns paid products. Please cancel them first.'"
        >
          <BaseIcon
            :height="16"
            :width="16"
            icon-name="information-outline"
            class="tw-fill-none tw-stroke-gray-800 tw-h-6 tw-w-6 tw-ml-2"
          >
            <InformationOutline />
          </BaseIcon>
        </UiTooltip>
      </div>
    </div>
  </BaseCard>
  <BaseCard class="tw-my-6" v-if="assignment.organisation.id && assignment.canInvite">
    <div class="tw-flex tw-items-center tw-mb-4 tw-text-xl">
      <BaseIcon :height="24" :width="24" icon-name="arrow-repeat" class="tw-fill-gray-800 tw-mr-1">
        <Users />
      </BaseIcon>
      <p class="tw-font-bold">{{ 'Invite user' }}</p>
    </div>
    <div>
      <InviteUser :organisationId="assignment.organisation.id" @fetchData="fetchData" :users="assignment.users" />
    </div>
  </BaseCard>
  <BaseCard class="tw-my-6" v-if="assignment.users?.length > 0 || assignment.invitations?.length > 0">
    <div class="tw-flex tw-items-center tw-mb-4 tw-text-xl">
      <BaseIcon :height="24" :width="24" icon-name="arrow-repeat" class="tw-fill-gray-800 tw-mr-1">
        <Users />
      </BaseIcon>
      <p class="tw-font-bold">{{ 'Users' }}</p>
    </div>
    <OrgUsers :users="assignment.users" :invitations="assignment.invitations" />
  </BaseCard>
  <BaseCard class="tw-my-6" v-if="campaigns?.length > 0">
    <div class="tw-flex tw-items-center tw-mb-4 tw-text-xl">
      <BaseIcon :height="24" :width="24" icon-name="arrow-repeat" class="tw-fill-gray-800 tw-mr-1">
        <Ownerships />
      </BaseIcon>
      <p class="tw-font-bold">{{ 'Campaigns' }}</p>
    </div>
    <ul>
      <li v-for="(campaign, index) in campaigns" :key="index" class="tw-border-b tw-py-4 last:tw-border-none">
        <div>
          <span class="tw-font-bold tw-text-gray-700">{{ 'App Name: ' }}</span
          >{{ $t(campaign.productName) }}
        </div>
        <div>
          <span class="tw-font-bold tw-text-gray-700">{{ 'Type: ' }}</span
          >{{ campaign.type }}
        </div>
        <div v-if="campaign.endDate">
          <span class="tw-font-bold tw-text-gray-700">{{ 'End Date: ' }}</span
          >{{ campaign.endDate }}
        </div>
      </li>
    </ul>
  </BaseCard>
</template>
<script setup>
import { computed } from 'vue'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import Organization from '@/components/icons/Organization.vue'
import Ownerships from '@/components/icons/Ownerships.vue'
import Users from '@/components/icons/Users.vue'
import DangerButton from '@/components/base/buttons/DangerButton.vue'
import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'
import OrgUsers from '@/components/adminTool/OrgUsers.vue'
import InformationOutline from '@/components/icons/InformationOutline.vue'
import InviteUser from '@/components/adminTool/InviteUser.vue'

const props = defineProps({
  assignment: Object,
  subscriptions: Object,
  isModalOpen: Boolean,
})

const emit = defineEmits(['toggleModal', 'fetchData'])
const fetchData = () => emit('fetchData')
const toggleModal = value => emit('toggleModal', value)
const campaigns = computed(() => props.subscriptions.campaigns)
</script>
