<template>
  <section>
    <div
      class="tw-flex tw-flex-col tw-w-full lg:tw-max-w-screen-landing md:tw-max-w-[638px] sm:tw-max-w-[480px] tw-max-w-[300px] tw-py-6 lg:tw-px-0 tw-mx-auto lg:tw-py-8"
    >
      <div>
        <div class="tw-w-full tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-end tw-justify-center">
          <TrivagoLogo class="tw-h-[24px] lg:tw-mr-4 tw-mb-2 md:tw-mb-0 lg:tw-mb-[2px]" />
          <p class="tw-text-2xl tw-font-bold tw-text-center lg:tw-text-left">{{ $t('tbs_mp_social_proof_header') }}</p>
        </div>
        <p class="tw-text-sm md:tw-text-base lg:tw-text-xl tw-my-2 tw-text-center tw-font-normal">
          {{ $t('tbs_mp_social_proof_text') }}
        </p>
      </div>
      <div class="tw-flex-wrap tw-mt-2 tw-bg-blue-100 tw-border tw-border-blue-600 tw-flex tw-rounded-[20px]">
        <p
          class="social-proof tw-relative tw-font-bold tw-text-xs lg:tw-text-base tw-w-1/4 tw-text-center tw-p-4"
          v-for="(item, index) in data"
          :class="index !== 0 ? 'social-border' : ''"
          :key="item"
          v-html="$t(item)"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import TrivagoLogo from '@/components/icons/TrivagoLogo.vue'

const data = ['tbs_mp_social_proof_2', 'tbs_mp_social_proof_3', 'tbs_mp_social_proof_1', 'tbs_mp_social_proof_4']
</script>

<style scoped>
.social-proof :deep(span) {
  @apply tw-text-2xl;
  @media (max-width: theme('screens.md')) {
    @apply tw-text-base;
    line-height: 1.5;
  }
}

.social-border:before {
  content: '';
  height: 70%;
  width: 1px;
  background-color: theme('colors.blue.600');
  display: block;
  position: absolute;
  left: 0;
}

.social-border:nth-child(3):before {
  @media (max-width: 767px) {
    display: none;
  }
}

.social-proof {
  flex: 50% 0 0;
  @media (min-width: theme('screens.md')) {
    flex: 25% 0 0;
  }
}
</style>
