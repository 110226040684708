<template>
  <div>
    <div class="header tw-flex tw-w-full tw-flex-row tw-mt-14 tw-border-b tw-border-gray-300 tw-pb-2 tw-text-sm">
      <div class="tw-w-full md:tw-w-2/6 tw-text-gray-800 tw-font-bold">{{ $t('rc_available_providers_header') }}</div>
      <div class="tw-w-2/6 tw-text-gray-800 tw-font-bold md:tw-flex tw-justify-center tw-hidden">
        {{ $t('rc_supported_campaigns_header') }}
        <UiTooltip
          tooltip-position="top"
          :label="$t('rc_supported_campaigns_tooltip')"
          class="tw-cursor-pointer tw-ml-1"
        >
          <BaseIcon
            icon-name="information-outline"
            :height="16"
            :width="16"
            class="tw-fill-none tw-stroke-blue-800 tw-h-[20px] tw-w-[20px]"
          >
            <InformationOutline />
          </BaseIcon>
        </UiTooltip>
      </div>
      <div class="tw-w-2/6" />
    </div>
    <div class="tw-relative">
      <div
        v-for="partner in filteredPartners"
        :key="partner.partnerId"
        class="tw-border tw-border-gray-300 tw-rounded-xl tw-mt-4 tw-px-4 tw-py-3 tw-flex tw-items-start md:tw-items-center tw-flex-col md:tw-flex-row"
      >
        <div class="tw-w-full md:tw-w-2/6">
          <div class="tw-flex-col tw-flex">
            <strong class="tw-text-sm">{{ partner.partnerName.toUpperCase() }}</strong>
            <span
              v-if="currentCampaignType === 'cpc'"
              class="tw-text-xs md:tw-text-sm"
              :class="providerRequiresCampaignChange(partner) ? 'tw-text-orange-700' : 'tw-text-green-700'"
            >
              {{
                providerRequiresCampaignChange(partner)
                  ? $t('rc_change_campaign_types')
                  : $t('rc_no_campaign_type_change')
              }}
            </span>
          </div>
        </div>
        <div class="tw-w-full md:tw-w-2/6">
          <div class="tw-text-gray-800 tw-font-bold md:tw-hidden tw-flex tw-justify-start tw-mt-5">
            {{ $t('rc_supported_campaigns_header') }}
            <UiTooltip
              tooltip-position="top"
              :label="$t('rc_supported_campaigns_tooltip')"
              class="tw-cursor-pointer tw-ml-2"
            >
              <BaseIcon
                icon-name="information-outline"
                :height="16"
                :width="16"
                class="tw-fill-none tw-stroke-blue-700 tw-h-[20px] tw-w-[20px]"
              >
                <InformationOutline />
              </BaseIcon>
            </UiTooltip>
          </div>
          <p
            class="tw-text-sm tw-text-left md:tw-text-center"
            :class="{ 'tw-text-red-700': currentCampaignType === 'netCpa' && !partner.canNetCpa }"
          >
            {{ $t(getProviderEligibilityLabel(partner)) }}
          </p>
        </div>
        <div class="tw-w-full md:tw-w-2/6 tw-flex tw-justify-start md:tw-justify-end tw-mt-5 md:tw-mt-0">
          <MainButton v-if="showSelectButton(partner)" size="small" @click="onUpdatePartnerClick(partner)">
            {{ $t('rc_activate') }}
          </MainButton>
          <p v-if="partner?.partnerStatus === 'inactive'" class="tw-text-red-700 tw-font-bold tw-text-base">
            {{ $t('rc_provider_status_inactive') }}
          </p>
        </div>
      </div>
    </div>
    <ConnectivityProviderSelectModal
      :partner="selectedNewPartner?.partnerName"
      :is-connectivity-provider-select-modal-open="isConnectivityProviderSelectModalOpen"
      :close-connectivity-provider-select-modal="toggleConnectivityProviderSelectModal"
      :continue-process="continueChangeConnectivityProvider"
    />
    <ChangeCampaignModal ref="changeCampaignModalRef" @onSubmitCampaignClick="onUpdatePartnerConfirm" />
    <FullScreenLoader v-show="isChangingPartner" />
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'

import BaseIcon from '@/components/BaseIcon.vue'
import InformationOutline from '@/components/icons/InformationOutline.vue'
import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import ChangeCampaignModal from '@/components/rateConnect/settings/wizards/ChangeCampaignModal.vue'
import ConnectivityProviderSelectModal from '@/components/rateConnect/settings/wizards/ConnectivityProviderSelectModal.vue'
import FullScreenLoader from '@/components/base/loaders/FullScreenLoader.vue'
import appAnalytics from '@/utils/tracking'
import { usePartners, useStartUp, useCampaignsMutation, usePartnersMutation } from '@/components/rateConnect/queries'
import { useChangeCampaign } from '@/components/rateConnect/settings/wizards/useChangeCampaign'
import useToastNotifications from '@/components/notifications/useToastNotifications'
import { selectedAccommodationId } from '@/layouts/queries'

const props = defineProps({
  currentPartner: Object,
  currentPartnerCampaigns: Array,
})

const { filteredPartnersData } = usePartners(selectedAccommodationId)
const { currentCampaignType } = useStartUp(selectedAccommodationId)
const { updateCampaignType } = useCampaignsMutation()
const { changePartner } = usePartnersMutation()
const { resetFlow, selectedCampaignType, selectedCommissionLevel, selectedBudget, selectedNewPartner } =
  useChangeCampaign()
const { displayNotification } = useToastNotifications()
const availablePartners = computed(() =>
  filteredPartnersData.value?.filter(item => item.partnerId !== props.currentPartner?.partnerId)
)
const isConnectivityProviderSelectModalOpen = ref(false)
const changeCampaignModalRef = ref(null)
const isChangingPartner = ref(false)
const filteredPartners = computed(() => {
  let partners = availablePartners.value
  const inactive = availablePartners.value?.filter(partner => {
    if (currentCampaignType === 'cpc') {
      return []
    } else {
      return !partner.canNetCpa
    }
  })
  partners = partners?.filter(partner => {
    if (currentCampaignType === 'cpc') {
      return []
    } else {
      return partner.canNetCpa
    }
  })

  return [...partners, ...inactive]
})

const getProviderEligibilityLabel = partner => {
  if (currentCampaignType.value === 'cpc') {
    return partner.canNetCpa ? 'rc_supported_campaigns_scenario_I' : 'rc_supported_campaigns_scenario_II'
  }
  if (currentCampaignType.value === 'netCpa') {
    return partner.canNetCpa ? 'Pay-per-stay' : 'Pay-per-stay not supported'
  }
}

const showSelectButton = partner => {
  if (partner?.partnerStatus === 'inactive') return false
  if (currentCampaignType.value === 'netCpa' && !partner.canNetCpa) return false
  else return true
}

const toggleConnectivityProviderSelectModal = () => {
  isConnectivityProviderSelectModalOpen.value = !isConnectivityProviderSelectModalOpen.value
}
const providerRequiresCampaignChange = partner => currentCampaignType.value === 'netCpa' && !partner.canNetCpa
const continueChangeConnectivityProvider = () => {
  isConnectivityProviderSelectModalOpen.value = false
  changeCampaignModalRef.value?.showModal()
}

const onUpdatePartnerClick = partner => {
  selectedNewPartner.value = partner
  const requiresCampaignChange = providerRequiresCampaignChange(selectedNewPartner.value)
  if (requiresCampaignChange) toggleConnectivityProviderSelectModal()
  else onUpdatePartnerConfirm()
}

const onUpdatePartnerConfirm = () => {
  isChangingPartner.value = true
  const partnerName = selectedNewPartner.value?.partnerName
  const currentPartnerName = props.currentPartner?.partnerName
  const currentCampaigns = props.currentPartnerCampaigns
  const currentCampaign = currentCampaignType.value
  changePartner(
    {
      accommodationId: selectedAccommodationId.value,
      partnerId: selectedNewPartner.value?.partnerId,
    },
    {
      onSuccess: data => {
        displayNotification({
          message: 'rc_connectivity_provider_change_success',
          translationParams: { name: partnerName },
          isTranslationKey: true,
          isHtml: true,
          type: 'success',
        })

        if (providerRequiresCampaignChange(selectedNewPartner.value))
          updateCampaign({
            partnerName,
            currentPartnerName,
            currentCampaigns,
            currentCampaign,
            isNewPartnerNetCPA: data.data.canNetCpa,
          })
        else isChangingPartner.value = false

        appAnalytics.track(appAnalytics.events.RC_PROVIDER_CHANGE_SUCCESS, {
          prev_provider: currentPartnerName,
          campaigns_supported_by_prev_provider: currentCampaigns,
          new_provider: data.data.partnerName,
          campaigns_supported_by_new_provider: data.data.canNetCpa ? ['CPC, netCPA'] : ['CPC'],
          campaign_type_change_forced: providerRequiresCampaignChange(selectedNewPartner.value),
        })
        resetFlow()
      },
      onError: error => {
        appAnalytics.track(appAnalytics.events.RC_PROVIDER_CHANGE_FAILED, {
          prev_provider: currentPartnerName,
          campaigns_supported_by_prev_provider: currentCampaigns,
          new_provider: selectedNewPartner.value?.partnerName,
          campaigns_supported_by_new_provider: selectedNewPartner.value?.canNetCpa ? ['CPC, netCPA'] : ['CPC'],
          campaign_type_change_forced: providerRequiresCampaignChange(selectedNewPartner.value),
          status_code: error?.response?.status,
          error: error?.response?.data?.errors,
        })
        displayNotification({
          message: 'save_error',
          isTranslationKey: true,
          type: 'error',
        })
        isChangingPartner.value = false
        resetFlow()
      },
    }
  )
}

const updateCampaign = ({ partnerName, currentPartnerName, currentCampaigns, currentCampaign, isNewPartnerNetCPA }) => {
  const newCampaignType = selectedCampaignType.value
  let updatedCampaignDetails
  let successMessage
  if (selectedCampaignType.value === 'netCpa') {
    updatedCampaignDetails = { netCpa: selectedCommissionLevel.value }
    successMessage = 'rc_change_campaign_type_pay_per_stay_success_message'
  } else if (selectedCampaignType.value === 'cpc') {
    updatedCampaignDetails = { budget: selectedBudget.value * 100 }
    successMessage = 'rc_change_campaign_type_CPC_success_message'
  }
  updateCampaignType(
    {
      accommodationId: selectedAccommodationId.value,
      campaignType: selectedCampaignType.value,
      body: updatedCampaignDetails,
    },
    {
      onSuccess: data => {
        displayNotification({
          message: successMessage,
          isTranslationKey: true,
          type: 'success',
        })
        isChangingPartner.value = false
        appAnalytics.track(appAnalytics.events.RC_PROVIDER_CHANGE_CAMPAIGN_CHANGE_SUCCESS, {
          prev_provider: currentPartnerName,
          campaigns_supported_by_prev_provider: currentCampaigns,
          new_provider: partnerName,
          campaigns_supported_by_new_provider: isNewPartnerNetCPA ? ['CPC, netCPA'] : ['CPC'],
          prev_campaign_type: currentCampaign,
          new_campaign_type: data.data.overall.campaignType,
        })
      },
      onError: error => {
        appAnalytics.track(appAnalytics.events.RC_PROVIDER_CHANGE_CAMPAIGN_CHANGE_FAILED, {
          prev_provider: currentPartnerName,
          campaigns_supported_by_prev_provider: currentCampaigns,
          new_provider: partnerName,
          campaigns_supported_by_new_provider: isNewPartnerNetCPA ? ['CPC,  netCPA'] : ['CPC'],
          prev_campaign_type: currentCampaign,
          new_campaign_type: newCampaignType,
          status_code: error?.response?.status,
          error: error?.response?.data?.errors,
        })
        displayNotification({
          message: 'save_error',
          isTranslationKey: true,
          type: 'error',
        })
        isChangingPartner.value = false
      },
    }
  )
}
</script>
<style scoped>
.loader-positioning {
  z-index: 99;
  bottom: 0;
  top: 34%;
  right: 0;
  left: 0;
}
</style>
