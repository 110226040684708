import * as Sentry from '@sentry/vue'

export class UserNotFoundError extends Error {
  constructor(message) {
    super(message)
    this.name = 'UserNotFoundError'
  }
}

export class GetOrganisationListApiError extends Error {
  constructor(message) {
    super(message)
    this.name = 'JLoUnauthorizedError'
  }
}

export class RefreshTokenError extends Error {
  constructor(message) {
    super(message)
    this.name = 'RefreshTokenError'
  }
}

export class AuthStateValidationError extends Error {
  constructor({ savedState, responseState }) {
    super('State response validation failed')
    this.name = 'AuthStateValidationError'
    Sentry.withScope(scope => {
      scope.setContext('CustomContext', {
        savedState: savedState ?? 'N/A',
        responseState: responseState ?? 'N/A',
      })
      scope.setTag('feature', 'OAuth2JLoService')
      Sentry.captureException(this)
    })
  }
}

export class SetStateError extends Error {
  constructor(error, { appStateData, CSRFValue }) {
    super(error)
    this.name = 'setStateError'
    Sentry.withScope(scope => {
      scope.setContext('CustomContext', {
        appStateData: appStateData ?? 'N/A',
        CSRFValue: CSRFValue ?? 'N/A',
      })
      scope.setTag('feature', 'OAuth2JLoService')
      Sentry.captureException(this)
    })
  }
}
