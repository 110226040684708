import { computed } from 'vue'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import { prepareNewImagesCollection } from '@/utils/propertyDetailsHelpers.js'
import AccommodationService from '@/services/accommodationService/Accommodation.js'
import PropertyDetailsService from '@/services/PropertyDetailsService.js'
import CountriesService from '@/services/CountriesService.js'

const fetchAccommodationBasicInfo = async accommodationId => {
  const response = await AccommodationService.fetchNewAccommodationBasicInfo(accommodationId)
  return response.data
}

export function useAccommodationBasicInfo(selectedAccommodationId) {
  const { isLoading, isFetching, isError, data } = useQuery({
    queryKey: ['accommodationBasicInfo', selectedAccommodationId],
    queryFn: ({ queryKey }) => fetchAccommodationBasicInfo(queryKey[1]),
  })

  return {
    isAccommodationBasicInfoLoading: isLoading,
    accommodationBasicInfo: data,
    isAccommodationBasicInfoFetching: isFetching,
    isAccommodationBasicInfoError: isError,
  }
}

export function useHotelChains(languageCode, namespace) {
  const { isLoading, data } = useQuery({
    queryKey: ['hotelChains', languageCode.value, namespace],
    queryFn: ({ queryKey }) => AccommodationService.fetchListTranslations(queryKey[1], queryKey[2]),
  })

  const hotelChainsData = computed(() => data?.value?.data?.[0]?.concepts)
  return { hotelChainsLoading: isLoading, hotelChains: hotelChainsData }
}

export function useLodgingTypes(languageCode, namespace) {
  const { isLoading, data } = useQuery({
    queryKey: ['hotelChains', languageCode, namespace],
    queryFn: ({ queryKey }) => AccommodationService.fetchListTranslations(queryKey[1], queryKey[2]),
  })
  const lodgingTypesData = computed(() => data?.value?.data?.[0]?.concepts)
  return { lodgingTypesLoading: isLoading, lodgingTypes: lodgingTypesData }
}

export function useGetCountryCodes() {
  const { isLoading, data } = useQuery({
    queryKey: ['countryCodes'],
    queryFn: () => CountriesService.fetchCountriesCodes(),
  })

  const countriesCodes = computed(() => data?.value?.data)
  return { countryCodesLoading: isLoading, countriesCodes }
}

export function useGetImages(accommodationId) {
  const { isLoading, data, isError } = useQuery({
    queryKey: ['images', accommodationId],
    queryFn: ({ queryKey }) => AccommodationService.fetchImages(queryKey[1]),
  })
  const images = computed(() => prepareNewImagesCollection(data?.value))

  return { imagesLoading: isLoading, isImagesError: isError, images }
}

export function useGetMainImage(accommodationId) {
  const { isLoading, data, isError } = useQuery({
    queryKey: ['mainImage', accommodationId],
    queryFn: ({ queryKey }) => AccommodationService.getMainImage(queryKey[1]),
  })

  const mainImage = computed(() => data.value?.data)

  return { isMainImageLoading: isLoading, isMainImageError: isError, mainImage }
}

export function useSavePropertyBasicInfoUpdate() {
  const queryClient = useQueryClient()
  const {
    isLoading: savePropertyBasicInfoUpdateLoading,
    isSuccess: savePropertyBasicInfoUpdateSuccess,
    isError: savePropertyBasicInfoUpdateError,
    mutate: savePropertyBasicInfoUpdate,
  } = useMutation({
    mutationFn: ({ accommodationId, body }) => AccommodationService.updatePropertyDetails({ accommodationId, body }),
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: ['accommodationBasicInfo'] })
      queryClient.invalidateQueries({ queryKey: ['images'] })
      const responseData = data?.data
      if (responseData) syncAccommodationBasicInfoUpdateWithOrgAccommodationQuery(responseData, queryClient)
    },
  })

  return {
    savePropertyBasicInfoUpdateLoading,
    savePropertyBasicInfoUpdateSuccess,
    savePropertyBasicInfoUpdateError,
    savePropertyBasicInfoUpdate,
  }
}

export function useSetMainImage() {
  const queryClient = useQueryClient()
  const {
    isLoading: setMainImageLoading,
    isSuccess: setMainImageSuccess,
    isError: setMainImageError,
    mutate: setMainImage,
  } = useMutation({
    mutationFn: ({ accommodationId, imageId }) => AccommodationService.setNewMainImage({ accommodationId, imageId }),
    onSuccess: (_, mutationInput) => {
      if (mutationInput.organisationId && mutationInput.imagePath && mutationInput.accommodationId) {
        queryClient.setQueryData(['organisationAccommodations', mutationInput.organisationId], oldData => {
          if (!oldData || !Array.isArray(oldData)) return oldData
          return oldData.map(accommodation =>
            accommodation.accommodationId === mutationInput.accommodationId
              ? {
                  ...accommodation,
                  mainImageUrls: { ...accommodation.mainImageUrls, imagePath: mutationInput.imagePath },
                }
              : accommodation
          )
        })
      }
      queryClient.invalidateQueries({ queryKey: ['images'] })
      queryClient.invalidateQueries({ queryKey: ['accommodationBasicInfo'] })
    },
  })

  return {
    setMainImageLoading,
    setMainImageSuccess,
    setMainImageError,
    setMainImage,
  }
}

export function useDeleteImages() {
  const queryClient = useQueryClient()
  const {
    isLoading: deleteImagesLoading,
    isSuccess: deleteImagesSuccess,
    isError: deleteImagesError,
    mutate: deleteImages,
  } = useMutation({
    mutationFn: ({ accommodationId, body }) => AccommodationService.deleteImage({ accommodationId, body }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['images'] })
    },
  })

  return {
    deleteImagesLoading,
    deleteImagesSuccess,
    deleteImagesError,
    deleteImages,
  }
}

export function usePropertyFeatures(accommodationId, locale) {
  const { isLoading, data } = useQuery({
    queryKey: ['propertyFeatures', accommodationId, locale],
    queryFn: async ({ queryKey }) => await AccommodationService.fetchAccommodationFields(queryKey[1], queryKey[2]),
  })

  const accommodationFeatures = computed(() => {
    if (!data.value) return groups
    return groups.map(item => {
      return {
        ...item,
        fields: data?.value?.find(element => element.studioGroupId == item.fieldGroupIds)?.features || null,
      }
    })
  })

  return { accommodationFeaturesLoading: isLoading, accommodationFeatures }
}

export function useGetFields(fieldGroupId, locale) {
  const { isLoading, data } = useQuery({
    queryKey: ['sectionFields', fieldGroupId, locale],
    queryFn: ({ queryKey }) => AccommodationService.fetchFields(queryKey[1], queryKey[2]),
  })

  const sectionFields = computed(() => data?.value)

  return { sectionFieldsLoading: isLoading, sectionFields }
}

export function useSaveFeatures() {
  const queryClient = useQueryClient()
  const {
    isLoading: saveFeaturesLoading,
    isSuccess: saveFeaturesSuccess,
    isError: saveFeaturesError,
    mutate: saveFeatures,
  } = useMutation({
    mutationFn: ({ accommodationId, groupId, editObj }) =>
      AccommodationService.updatePropertyFeatures(accommodationId, groupId, editObj),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['propertyFeatures'] })
    },
  })

  return {
    saveFeaturesLoading,
    saveFeaturesSuccess,
    saveFeaturesError,
    saveFeatures,
  }
}

export function usePropertyDescriptions(selectedAccommodationId) {
  const { isLoading, isFetching, isError, data } = useQuery({
    queryKey: ['propertyDescriptions', selectedAccommodationId],
    queryFn: ({ queryKey }) => PropertyDetailsService.fetchDescriptionsByItem({ accommodationId: queryKey[1] }),
  })

  const propertyDescriptions = computed(() => data?.value?.data?._embedded)

  return {
    isPropertyDescriptionsLoading: isLoading,
    isPropertyDescriptionsFetching: isFetching,
    isPropertyDescriptionsError: isError,
    propertyDescriptions,
  }
}

export function usePropertyDescriptionMutation() {
  const queryClient = useQueryClient()
  const {
    isLoading: isUpdatingPropertyDescription,
    isSuccess: isUpdatingPropertyDescriptionSuccess,
    isError: isUpdatingPropertyDescriptionError,
    mutate: updatePropertyDescription,
  } = useMutation({
    mutationFn: ({ accommodationId, language, body }) =>
      PropertyDetailsService.updateDescription({ accommodationId, language, body }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['propertyDescriptions'] })
    },
  })

  return {
    isUpdatingPropertyDescription,
    isUpdatingPropertyDescriptionSuccess,
    isUpdatingPropertyDescriptionError,
    updatePropertyDescription,
  }
}

const syncAccommodationBasicInfoUpdateWithOrgAccommodationQuery = (newAccommodationBasicInfo, queryClient) => {
  const orgAccommodationQueries = queryClient.getQueriesData({ queryKey: ['organisationAccommodations'] })
  for (let [orgAccommodationQueryKey, orgAccommodationQueryData] of orgAccommodationQueries) {
    if (orgAccommodationQueryKey?.[0] === 'organisationAccommodations') {
      const orgAccommodationQueryOrgId = orgAccommodationQueryKey?.[1]
      if (!orgAccommodationQueryOrgId || !orgAccommodationQueryData) continue
      const accommodationData = orgAccommodationQueryData.find(accommodation => {
        return accommodation.accommodationId === newAccommodationBasicInfo.accommodationId
      })

      if (accommodationData) {
        queryClient.setQueryData(['organisationAccommodations', orgAccommodationQueryOrgId], oldData => {
          if (!oldData || !Array.isArray(oldData)) return oldData
          return oldData.map(accommodation =>
            accommodation.accommodationId === newAccommodationBasicInfo.accommodationId
              ? { accommodation, ...newAccommodationBasicInfo }
              : accommodation
          )
        })
        return
      }
    }
  }
}

let groups = [
  {
    fieldGroupIds: 1,
    name: 'app_hotel_details_facilities_services',
    slug: 'facilities-and-services',
    icon: 'icon-icn_hotel2_line_dark',
    modifier: 'facilities',
    teaser: 'app_hotel_details_teaser_facilities_and_services',
    hint: 'app_hotel_details_fields_hint',
  },
  {
    fieldGroupIds: 2,
    name: 'app_hotel_details_health',
    slug: 'health-and-wellness',
    icon: 'icon-icn_spa_line_dark',
    modifier: 'health',
    teaser: 'app_hotel_details_teaser_hotel_details_health',
  },
  {
    fieldGroupIds: 3,
    name: 'app_hotel_details_food_beverage',
    slug: 'food-and-beverage',
    icon: 'icon-icn_restaurant_line_dark',
    modifier: 'food',
    teaser: 'app_hotel_details_teaser_intro_food',
  },
  {
    fieldGroupIds: 4,
    name: 'app_hotel_details_family',
    slug: 'family',
    icon: 'icon-icn_family_line_dark',
    modifier: 'family',
    teaser: 'app_hotel_details_teaser_intro_family',
  },
  {
    fieldGroupIds: 5,
    name: 'app_hotel_details_room_amenities',
    slug: 'room-amenities',
    icon: 'icon-icn_bed_double_line_dark',
    modifier: 'amenities',
    teaser: 'app_hotel_details_teaser_intro_room',
  },
  {
    fieldGroupIds: 6,
    name: 'app_hotel_details_accessibility',
    slug: 'accessibility',
    icon: 'icon-icn_disabled_dark',
    modifier: 'accessibility',
    teaser: 'app_hotel_details_teaser_intro_accessibility',
  },
]
