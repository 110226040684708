<template>
  <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.75 4.5H5.25002C4.83602 4.5 4.50002 4.836 4.50002 5.25V9H3.00002C2.69702 9 2.42327 9.183 2.30702 9.46275C2.19077 9.74325 2.25527 10.0657 2.46977 10.2802L8.46977 16.2802C8.61602 16.4265 8.80802 16.5 9.00002 16.5C9.19202 16.5 9.38402 16.4265 9.53027 16.2802L15.5303 10.2802C15.7448 10.0657 15.8093 9.74325 15.693 9.46275C15.5768 9.183 15.303 9 15 9H13.5V5.25C13.5 4.836 13.164 4.5 12.75 4.5ZM12.75 5.25V9.75H15L9.00002 15.75L3.00002 9.75H5.25002V5.25H12.75Z"
      fill="white"
    />
    <path
      d="M12.75 1.5H5.25C4.836 1.5 4.5 1.836 4.5 2.25V3C4.5 3.414 4.836 3.75 5.25 3.75H12.75C13.164 3.75 13.5 3.414 13.5 3V2.25C13.5 1.836 13.164 1.5 12.75 1.5ZM5.25 2.25H12.75V3H5.25V2.25Z"
      fill="white"
    />
  </svg>
</template>
