<template>
  <div class="tw-mt-10">
    <p class="tw-text-lg tw-font-bold tw-mb-3">{{ $t('selected_property') }}</p>
    <SelectedPropertyItem
      v-for="item in data"
      :id="item.id"
      :key="item.id"
      :name="item.name"
      :img="getImage(item.imageUrl)"
      :address="item.address"
      :assigned="item.assigned"
      :reassignable="item.reassignable"
      :dataTestId="`selected-property-item-${item.id}`"
    />
  </div>
</template>
<script setup>
import SelectedPropertyItem from '@/components/assignedProperties/SelectedPropertyItem.vue'

defineProps({
  data: Array,
})
const getImage = image => {
  const imagePath = image?.length > 0 ? image : 'null'
  if (image) {
    return `https://imgcy.trivago.com/c_fill,d_dummy.jpeg,e_sharpen:60,f_auto,h_40,q_auto,w_40/${imagePath}`
  } else {
    return 'https://imgcy.trivago.com/c_fill,d_dummy.jpeg,e_sharpen:60,f_auto,h_40,q_auto,w_40/partner-images-stage/69/84/dummy.jpeg'
  }
}
</script>
