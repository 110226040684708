<template>
  <div class="tw-flex tw-items-center">
    <p class="tw-mr-5">{{ $t('rc_markets_potential') }}</p>
    <div class="tw-mr-3 tw-flex tw-gap-1">
      <div class="tw-bg-blue-300 tw-h-[14px] tw-w-[6px] tw-rounded"></div>
      <div v-if="potentialValueNbr > 1" class="tw-bg-blue-500 tw-h-[14px] tw-w-[6px] tw-rounded"></div>
      <div v-if="potentialValueNbr > 2" class="tw-bg-blue-700 tw-h-[14px] tw-w-[6px] tw-rounded"></div>
    </div>
    <p class="tw-text-blue-700">{{ potentialValueDesc }}</p>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

const { t } = useI18n()

const props = defineProps({
  averageClicks: {
    type: Number,
    required: true,
    default: 0,
  },
  estimatedClicks: {
    type: Number,
    required: true,
    default: 0,
  },
})
const potentialValueNbr = computed(() => {
  if (props.estimatedClicks > props.averageClicks * 1.25) return 3
  if (props.estimatedClicks <= props.averageClicks * 1.25 && props.estimatedClicks >= props.averageClicks * 0.75)
    return 2
  return 1
})
const potentialValueDesc = computed(() => {
  if (potentialValueNbr.value === 3) return t('rc_markets_potential_high')
  if (potentialValueNbr.value === 2) return t('rc_markets_potential_average')
  return t('rc_markets_potential_low')
})
</script>
