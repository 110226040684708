import axios from 'axios'

import BaseService from './BaseService.js'

class PremiumService extends BaseService {
  constructor() {
    super({
      baseUrl: import.meta.env.VITE_PREMIUM_API_URL,
    })
  }

  fetchZuoraAccount() {
    return axios({
      url: `${this.baseUrl}/api/zuora/account`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  sendZuoraAccountCreation({ body, language }) {
    return axios({
      url: `${this.baseUrl}/api/zuora/account`,
      method: 'POST',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
        'X-Trv-Language': language ?? 'en-US',
      },
    })
  }

  sendZuoraAccountUpdate({ body, language }) {
    return axios({
      url: `${this.baseUrl}/api/zuora/account`,
      method: 'PUT',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
        'X-Trv-Language': language ?? 'en-US',
      },
    })
  }

  fetchStudioPlusCampaign({ accommodationId }) {
    return axios({
      url: `${this.baseUrl}/api/premium/${accommodationId}/campaign`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchAvailablePaymentMethods(currencyCode) {
    const params = {
      currencyCode,
    }
    if (window.location.hostname === 'studio.dev.trv.cloud') params.env = 'dev'
    return axios({
      url: `${this.baseUrl}/api/zuora/payment-methods`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
      params,
    })
  }

  fetchExistingPaymentMethods() {
    return axios({
      url: `${this.baseUrl}/api/zuora/account/payment-methods`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  validateTaxId(payload) {
    return axios({
      url: `${this.baseUrl}/api/validation/tax`,
      method: 'POST',
      data: payload,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchStudioPlusCampaignsForOrganisation() {
    return axios({
      url: `${this.baseUrl}/api/premium/campaigns`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchTransactionsHistory(id, productType) {
    return axios({
      url: `${this.baseUrl}/api/zuora/account/invoices?itemId=${id}&productType=${productType}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  getInvoice(id) {
    return axios({
      url: `${this.baseUrl}/api/zuora/account/invoices/${id}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchStudioPlusPrice(params) {
    if (!this.studioToken) throw new Error('Error in GET api/premium/${accommodationId}/price: No studioToken provided')
    if (!params.accommodationId) return

    const searchParams = {}

    for (const key in params) {
      if (key !== 'accommodationId' && params[key] !== undefined && params[key] !== null && params[key] !== '') {
        searchParams[key] = params[key]
      }
    }

    return axios({
      url: `${this.baseUrl}/api/premium/${params.accommodationId}/price`,
      method: 'GET',
      params: searchParams,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  sendStudioPlusSubscription({ accommodationId, body }) {
    return axios({
      url: `${this.baseUrl}/api/premium/${accommodationId}/campaign`,
      method: 'POST',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  cancelSubscription({ accommodationId, body }) {
    return axios({
      url: `${this.baseUrl}/api/premium/${accommodationId}/deactivate`,
      method: 'PUT',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  getPaidSubscriptions(accommodationId) {
    if (!accommodationId)
      throw new Error('Error in GET /api/premium/admin/${accommodationId}/campaigns: No accommodationId provided')
    return axios({
      url: `${this.baseUrl}/api/premium/admin/${accommodationId}/campaigns`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  sendErrorMetrics(body) {
    return axios({
      url: `${this.baseUrl}/api/zuora/error/log`,
      method: 'POST',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  getGuestCurrency() {
    return axios({
      url: `${this.baseUrl}/locale/guest`,
      method: 'GET',
    })
  }

  getUserCurrency() {
    return axios({
      url: `${this.baseUrl}/locale/user`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }
}

export default new PremiumService()
