<template>
  <div class="autocomplete" ref="root">
    <p v-if="label" class="tw-font-bold">{{ label }}</p>
    <div class="tw-relative" tabindex="1" @focus="showResultsOnFocus">
      <span
        contenteditable="true"
        :id="id"
        type="text"
        :class="`${sizeClass} tw-border-gray-500`"
        class="tw-appearance-none tw-relative z-index-10 tw-border tw-w-full focus:tw-outline-0 tw-text-gray-800 focus:tw-border-blue-800 tw-py-2 tw-px-6 tw-rounded-lg tw-bg-white tw-inline-block tw-min-w-[200px]"
        @input="onChange"
        @focus="showResultsOnFocus"
        >{{ searchValue }}</span
      >

      <div
        v-if="showExampleText"
        :class="`${sizingPosition} tw-bg-transparent tw-absolute tw-text-gray-300 tw-pr-4 tw-py-2 tw-rounded-lg`"
        type="text"
      >
        {{ tempCurrentValue }}
      </div>
      <BaseIcon
        v-if="!isOpen || searchValue.length === 0"
        :height="20"
        :width="20"
        icon-name="search"
        class="tw-absolute tw-right-[10px]"
        :class="size === 'medium' ? 'tw-top-[10px] tw-bottom-[8px]' : 'tw-top-[14px] tw-bottom-[8px]'"
      >
        <Search />
      </BaseIcon>
      <button @click="() => resetSelection()">
        <BaseIcon
          v-show="searchValue.length > 0 && isOpen"
          :height="14"
          :width="14"
          viewbox-height="20"
          viewbox-width="20"
          icon-name="search"
          class="tw-absolute tw-right-[10px]"
          :class="size === 'medium' ? 'tw-top-[12px] tw-bottom-[8px]' : 'tw-top-[16px] tw-bottom-[8px]'"
        >
          <Close class="tw-fill-gray-700" />
        </BaseIcon>
      </button>
    </div>
    <small v-if="error" class="tw-text-red-700">{{ error }}</small>
    <BaseCard
      v-show="isOpen"
      id="autocomplete-results"
      no-padding
      class="autocomplete-results tw-max-h-[250px] tw-overflow-auto tw-absolute tw-left-0 tw-right-0 tw-z-[9999999]"
      :class="label ? 'tw-top-[65px]' : 'tw-top-[54px]'"
    >
      <ul>
        <li v-if="isLoading" class="tw-p-2 tw-min-h-[30px]">{{ $t('appTileList.loading') }}</li>
        <li v-else-if="suggestedOptions.length === 0" class="tw-p-2 tw-min-h-[30px]">{{ $t('no_results') }}</li>
        <li
          v-for="result in suggestedOptions"
          v-else
          :key="result.value"
          :class="$t(result.name) === value ? 'tw-font-bold' : ''"
          class="autocomplete-result tw-p-2 tw-px-6 hover:tw-cursor-pointer hover:tw-bg-gray-100 tw-border-b tw-border-gray-100"
          @click="setResult(result)"
        >
          {{ result.withTranslation ? $t(result.name) : result.name }}
        </li>
      </ul>
    </BaseCard>
  </div>
</template>
<script setup>
import { ref, computed, watch, onMounted, onUnmounted } from 'vue'
import { useI18n } from 'vue-i18n'
import cloneDeep from 'lodash/cloneDeep'

import BaseCard from '@/components/dashboard/BaseCard.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import Search from '@/components/icons/Search.vue'
import Close from '@/components/icons/Close.vue'

const { t } = useI18n()
const props = defineProps({
  id: {
    type: String,
    default: 'autocomplete',
  },
  label: {
    type: String,
    required: false,
    default: '',
  },
  ariaLabel: {
    type: String,
    required: true,
  },
  value: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    required: false,
    default: 'text',
  },
  error: {
    type: [Boolean, String],
    default: false,
  },
  size: {
    type: String,
    default: 'medium',
  },
  options: {
    type: Array,
    default: () => [],
  },
  isAsync: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const emit = defineEmits(['onSelect'])
const root = ref(null)
const isOpen = ref(false)
const isLoading = ref(false)
const suggestedOptions = ref(cloneDeep(props.options))
const searchValue = ref(cloneDeep(props.value))
const tempCurrentValue = ref(cloneDeep(props.value))
const showExampleText = ref(false)

const sizeClass = computed(() => {
  if (props.size === 'medium') return 'tw-px-4 tw-box-size-36 tw-text-sm tw-rounded-lg '
  else return 'tw-px-6 tw-box-size-44 tw-text-base tw-rounded-lg'
})

const sizingPosition = computed(() => {
  if (props.size === 'medium') return 'tw-right-1 tw-top-[1px] tw-bottom-1 tw-left-[18px] tw-text-sm'
  else return 'tw-right-1 tw-top-[2px] tw-bottom-1 tw-left-[25px] tw-text-base'
})
watch(
  () => props.options,
  (newOptions, oldOptions) => {
    if (newOptions.length !== oldOptions.length) {
      suggestedOptions.value = newOptions
      isLoading.value = false
    }
  }
)

watch(searchValue, (newVal, oldVal) => {
  if (oldVal === '') {
    showExampleText.value = false
  }
  if (newVal === '') {
    showExampleText.value = true
  }
})

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})
onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside)
})

const resetSelection = () => {
  searchValue.value = ''
  suggestedOptions.value = cloneDeep(props.options)
  isOpen.value = true
}
const onChange = e => {
  searchValue.value = e.target.innerText
  if (props.isAsync) {
    isLoading.value = true
  } else {
    filterResults()
    isOpen.value = true
  }
}
const filterResults = () => {
  suggestedOptions.value = props.options.filter(item => {
    const name = item.withTranslation ? t(item.name) : item.name
    return name.toLowerCase().indexOf(searchValue.value.toLowerCase()) > -1
  })
}
const setResult = result => {
  emit('onSelect', result)
  searchValue.value = result.withTranslation ? t(result.name) : result.name
  isOpen.value = false
  suggestedOptions.value = cloneDeep(props.options)
}
const showResultsOnFocus = () => {
  tempCurrentValue.value = props.value
  searchValue.value = ''
  isOpen.value = true
}

const handleClickOutside = evt => {
  if (root?.value !== null && !root?.value?.contains(evt.target)) {
    isOpen.value = false
    if (searchValue.value === '') {
      const defaultValue = props.options.find(item => t(item.name) === props.value)

      setResult(defaultValue)
    }
  }
}
</script>
