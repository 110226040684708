<template>
  <div>
    <p class="tw-font-bold tw-mb-3">{{ label }}</p>
    <ul class="tw-flex tw-flex-wrap">
      <li class="tw-cursor-pointer all-item tw-flex tw-items-center tw-flex-wrap">
        <input
          type="checkbox"
          :value="'all'"
          :id="'all'"
          :checked="selectedColumns === null"
          @change="onSelectAll"
          class="tw-cursor-pointer"
        />
        <label
          :for="'all'"
          class="tw-cursor-pointer tw-py-3 tw-m-0 tw-pl-4 tw-grow tw-flex tw-items-center tw-flex-wrap"
        >
          {{ $t('rc_filter_select_all') }}</label
        >
      </li>
      <li v-for="option in options" :key="option.id" class="item tw-flex tw-items-center tw-text-sm">
        <input
          type="checkbox"
          :name="option.header"
          :value="option.id"
          :id="option.id"
          :disabled="option.mandatory"
          :checked="selectedColumns === null || selectedColumns.some(item => item === option.id) || option.mandatory"
          @change="onSelect(option)"
          class="tw-cursor-pointer"
        />
        <label
          :for="option.id"
          class="tw-cursor-pointer tw-py-3 tw-m-0 tw-pl-4 tw-grow tw-flex tw-items-center tw-flex-wrap"
        >
          {{ $t(option.header) }}</label
        >
      </li>
    </ul>
  </div>
</template>
<script setup>
import { useRateConnectPerformance } from '@/components/rateConnect/performance/useRateConnectPerformance.js'
import { computed, onMounted } from 'vue'

const { selectedColumns } = useRateConnectPerformance()

const localStorageColumns = computed(() => {
  const customConfig = window.localStorage.getItem('custom_cols_config')
  return customConfig ? customConfig.split(',') : null
})

onMounted(() => {
  selectedColumns.value = localStorageColumns.value
})

const onSelect = column => {
  let newCols = selectedColumns.value
  const colIndex = selectedColumns.value?.indexOf(column.id)
  if (selectedColumns.value !== null) {
    if (colIndex >= 0) {
      newCols.splice(colIndex, 1)
    } else {
      newCols.push(column.id)
    }
  } else {
    newCols = []
    props.options.forEach(item => {
      if (item.id !== column.id) {
        newCols.push(item.id)
      }
    })
  }

  selectedColumns.value = newCols
  window.localStorage.setItem('custom_cols_config', newCols.join(','))
}

const onSelectAll = () => {
  if (selectedColumns.value === null) {
    let newCols = []
    props.options.forEach(item => {
      if (item.mandatory) {
        newCols.push(item.id)
      }
    })
    selectedColumns.value = newCols
    window.localStorage.setItem('custom_cols_config', newCols.join(','))
  } else {
    selectedColumns.value = null
    window.localStorage.removeItem('custom_cols_config')
  }
}

const props = defineProps({
  name: String,
  label: String,
  options: Array,
  value: Object,
})
</script>
<style scoped>
.item {
  flex-basis: 50%;
  box-sizing: border-box;
}
.all-item {
  flex-basis: 100%;
  box-sizing: border-box;
}
</style>
