<template>
  <div>
    <div
      :class="
        selectedArea === 'overview' && selectedDisplayType === 'graph'
          ? 'tw-hidden xl:tw-flex tw-gap-3'
          : 'tw-hidden lg:tw-flex tw-gap-3'
      "
    >
      <FilterSelect
        v-if="selectedArea === 'bookings'"
        :id="'rate-connect-performance-time-select'"
        class="tw-w-[14%] tw-min-w-[200px]"
        :options="periodDatesOptions"
        @select="onSelectedPeriodChange"
        name="timeframe"
        :label="$t('rc_performance_timeframe')"
        :disabled="false"
        :value="selectedPeriodValue"
        :valueLabel="selectedPeriodValue?.label"
      />
      <FilterSelect
        v-if="selectedArea === 'bookings'"
        :id="'rate-connect-performance-time-select'"
        class="tw-w-[14%] tw-min-w-[200px]"
        :options="dateTypes"
        @select="onSelectedDateTypeChange"
        name="dateType"
        :label="$t('rc_checkin_checkout_menu')"
        :disabled="false"
        :value="selectedDateTypeValue"
        :valueLabel="selectedDateTypeValue?.label"
      />
      <FilterSelect
        v-if="selectedArea === 'overview'"
        :id="'rate-connect-performance-time-select'"
        class="tw-w-[14%] tw-min-w-[200px]"
        :options="periodDatesOptions"
        @select="onSelectedPeriodChange"
        name="timeframe"
        :label="$t('rc_performance_timeframe')"
        :disabled="false"
        :value="selectedPeriodValue"
        :valueLabel="selectedPeriodValue?.label"
      />
      <FilterSelect
        v-if="selectedArea === 'overview'"
        :id="'rate-connect-performance-time-select'"
        class="tw-w-[14%] tw-min-w-[200px]"
        :options="aggregationOptions"
        @select="onSelectedAggregationChange"
        name="aggregation"
        :label="$t('rc_performance_group_data')"
        :disabled="false"
        :value="selectedAggregationValue"
        :valueLabel="selectedAggregationValue?.label"
      />
      <FilterSelect
        v-if="selectedArea === 'overview' && selectedDisplayType === 'graph'"
        :id="'rate-connect-performance-show-graph'"
        class="tw-w-[14%] tw-min-w-[200px]"
        :options="showGraphOptions"
        @select="onSelectedGraphChange"
        name="category"
        :label="$t('rc_performance_category')"
        :disabled="false"
        :value="selectedShowGraphValue"
        :valueLabel="selectedShowGraphValue?.label"
      />
      <MarketsFilter
        v-if="selectedArea === 'overview'"
        id="rate-connect-performance-market-select"
        ariaLabel="rate-connect-performance-market-select"
        size="big"
        class="tw-w-[14%] tw-min-w-[200px]"
        name="markets"
        :label="$t('rc_performance_markets')"
        :options="marketOptions"
        :value="selectedMarketValue"
        :valueLabel="selectedMarketValue?.label"
        @select="onSelectedMarketChange"
      />

      <!-- Web Bookings Flyout   -->
      <div class="tw-relative tw-hidden lg:tw-flex">
        <ModalFilters
          v-if="selectedArea === 'bookings'"
          @openFilters="openBookingFilters"
          @closeFilters="closeBookingFilters"
          @onApply="applyFilters"
          :isModalOpen="bookingFiltersOpened"
          :currentFilters="webCurrentFilters"
          :selectedArea="selectedArea"
          :marketsOptions="marketOptions"
          :selectedMarket="selectedMarketValue"
          :selectedStatus="selectedStatusValue"
          :statusOptions="statusOptions"
          type="booking-flyout"
        />
      </div>
      <div v-if="selectedArea === 'bookings'" class="tw-border-r tw-border-gray-200 tw-h-[44px] tw-w-[1px]" />
      <ColumnsCustomizationField
        v-if="selectedArea === 'bookings'"
        :columns="columns"
        class="tw-w-[14%] tw-min-w-[200px]"
      />
    </div>

    <!-- Mobile Flyout -->
    <div
      :class="
        selectedArea === 'overview' && selectedDisplayType === 'graph'
          ? 'tw-hidden lg:tw-flex xl:tw-hidden'
          : 'tw-hidden landing:tw-flex lg:tw-hidden'
      "
    >
      <ModalFilters
        type="mobile-flyout"
        @openFilters="openFlyoutsFilters"
        @closeFilters="closeFlyoutsFilters"
        @onApply="applyFilters"
        :isModalOpen="filtersFlayoutOpened"
        :currentFilters="modalFlyoutFilters"
        :selectedArea="selectedArea"
        :marketsOptions="marketOptions"
        :selectedMarket="selectedMarketValue"
        :selectedDisplayType="selectedDisplayType"
        :timeframeOptions="periodDatesOptions"
        :selectedTimeframe="selectedPeriodValue"
        :aggregationOptions="aggregationOptions"
        :selectedAggregation="selectedAggregationValue"
        :showGraphOptions="showGraphOptions"
        :selectedShowGraphValue="selectedShowGraphValue"
        :dateTypes="dateTypes"
        :selectedDateType="selectedDateTypeValue"
        :selectedStatus="selectedStatusValue"
        :statusOptions="statusOptions"
      />
    </div>

    <!-- Mobile -->
    <div class="tw-flex landing:tw-hidden">
      <div>
        <GhostButton class="tw-flex tw-items-center tw-gap-2" @click="openMobileFilters">
          <BaseIcon class="filter tw-fill-gray-800" :viewbox-height="12" :viewbox-width="18">
            <FiltersIcon />
          </BaseIcon>
          {{ $t('filters') }}
          <span
            v-if="appliedFiltersAmount > 0"
            class="filter-badge tw-bg-blue-700 tw-text-white tw-rounded-full tw-w-[20px] tw-h-[20px] tw-text-sm"
          >
            {{ appliedFiltersAmount }}
          </span>
        </GhostButton>
      </div>
      <ModalFiltersMobile
        @closeFilters="closeMobileFilters"
        @onApply="applyFilters"
        :currentFilters="modalFlyoutFilters"
        :selectedArea="selectedArea"
        :selectedDisplayType="selectedDisplayType"
        :isModalOpen="mobileFiltersOpened"
        :timeframeOptions="periodDatesOptions"
        :selectedTimeframe="selectedPeriodValue"
        :aggregationOptions="aggregationOptions"
        :selectedAggregation="selectedAggregationValue"
        :marketsOptions="marketOptions"
        :selectedMarket="selectedMarketValue"
        :showGraphOptions="showGraphOptions"
        :selectedShowGraphValue="selectedShowGraphValue"
        :dateTypes="dateTypes"
        :selectedDateType="selectedDateTypeValue"
        :selectedStatus="selectedStatusValue"
        :statusOptions="statusOptions"
        :columnsOptions="columns"
      />
    </div>
  </div>
</template>
<script setup>
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import { useRateConnectPerformance } from './useRateConnectPerformance'

import FilterSelect from '@/components/base/filters/FilterSelect.vue'
import MarketsFilter from '@/components/rateConnect/performance/MarketsFilter.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import FiltersIcon from '@/components/icons/FiltersIcon.vue'
import ModalFilters from '@/components/rateConnect/performance/modalFilters/ModalFilters.vue'
import ModalFiltersMobile from './modalFilters/ModalFiltersMobile.vue'
import ColumnsCustomizationField from './ColumnsCustomizationField.vue'

const { t } = useI18n()
const props = defineProps({
  markets: Array,
  lastUpdated: String,
  selectedArea: String,
  selectedDisplayType: String,
  overviewTableCampaignType: String,
  columns: Array,
})

const webCurrentFilters = computed(() => {
  const filters = []
  if (props.selectedArea === 'bookings') {
    filters.push({ value: 'status', label: 'rc_booking_status_header' })
    filters.push({ value: 'markets', label: 'rc_performance_markets' })
  }

  return filters
})

const modalFlyoutFilters = computed(() => {
  const filters = [{ value: 'timeframe', label: 'rc_performance_timeframe' }]
  if (props.selectedArea === 'bookings') {
    filters.push({ value: 'dateType', label: 'rc_checkin_checkout_menu' })
  }
  if (props.selectedArea === 'overview') {
    filters.push({ value: 'aggregation', label: 'rc_performance_group_data' })
  }
  if (props.selectedArea === 'overview' && props.selectedDisplayType === 'graph') {
    filters.push({ value: 'category', label: 'rc_performance_category' })
  }
  if (props.selectedArea === 'bookings') {
    filters.push({ value: 'status', label: 'rc_booking_status_header' })
  }
  filters.push({ value: 'markets', label: 'rc_performance_markets' })
  if (props.selectedArea === 'bookings') {
    filters.push({ value: 'columns', label: 'rc_column_picker' })
  }

  return filters
})

const filtersFlayoutOpened = ref(false)
const mobileFiltersOpened = ref(false)
const bookingFiltersOpened = ref(false)
const {
  selectedPeriod,
  selectedAggregation,
  selectedGraph,
  selectedDateType,
  selectedMarket,
  selectedStatus,
  appliedFiltersAmount,
} = useRateConnectPerformance()

const openMobileFilters = () => {
  mobileFiltersOpened.value = true
}
const closeMobileFilters = () => {
  mobileFiltersOpened.value = false
}

const openFlyoutsFilters = () => {
  filtersFlayoutOpened.value = true
}
const closeFlyoutsFilters = () => {
  filtersFlayoutOpened.value = false
}

const openBookingFilters = () => {
  bookingFiltersOpened.value = true
}
const closeBookingFilters = () => {
  bookingFiltersOpened.value = false
}

const onSelectedDateTypeChange = dateType => {
  selectedDateType.value = dateType.option.value
}

const statusOptions = computed(() => [
  { id: 0, name: 'rc_confirmed_booking_pay_per_stay', value: 'CONFIRMED' },
  { id: 1, name: 'rc_completed_stay_pay_per_stay', value: 'COMPLETED' },
  { id: 2, name: 'rc_canceled_booking_pay_per_stay', value: 'CANCELLED' },
])
const periodDatesOptions = computed(() => [
  {
    id: 0,
    name: 'trc_timeframe_last_14_days',
    value: 'last_14_days',
    label: t('trc_timeframe_last_14_days'),
    default: false,
  },
  {
    id: 1,
    value: 'current_month',
    name: 'trc_timeframe_this_month',
    label: t('trc_timeframe_this_month'),
    default: false,
  },
  { id: 2, value: 'last_30_days', name: 'last_30_days', label: t('last_30_days'), default: true },
  { id: 3, value: 'last_3_months', name: 'last_3_months', label: t('last_3_months'), default: false },
  { id: 4, value: 'last_6_months', name: 'last_6_months', label: t('last_6_months'), default: false },
  { id: 5, value: 'last_12_months', name: 'last_12_months', label: t('last_12_months'), default: false },
])

const aggregationOptions = computed(() => [
  {
    id: 0,
    name: 'rc_label_groupby',
    value: 'DAILY',
    label: t('rc_day'),
    default: true,
  },
  {
    id: 1,
    name: 'rc_label_groupby',
    value: 'WEEKLY',
    label: t('rc_week'),
    default: false,
  },
  {
    id: 2,
    value: 'MONTHLY',
    name: 'rc_label_groupby',
    label: t('rc_month'),
    default: false,
  },
])

const showGraphOptions = computed(() => {
  const statsOptions = [
    { id: 0, label: t('rc_bookings'), name: 'bookings', value: 'bookings' },
    { id: 1, label: t('kpi_chart_clicks'), name: 'clicks', value: 'clicks' },
    { id: 2, label: t('rc_revenue'), name: 'revenue', value: 'revenue' },
  ]
  if (props.overviewTableCampaignType === 'cpc') {
    statsOptions.push({ id: 3, label: t('rc_cpa'), name: 'cpa', value: 'cpa' })
  }
  return statsOptions
})

const dateTypes = computed(() => [
  { id: 0, value: 'bookingDate', name: 'bookingDate', label: t('rc_bookings_list_booking_date') },
  { id: 1, value: 'arrivalDate', name: 'arrivalDate', label: t('rc_booking_checkin') },
  { id: 2, value: 'departureDate', name: 'departureDate', label: t('rc_booking_checkout') },
])

const marketsArray = computed(() => {
  return [
    ...props.markets.map((market, index) => ({
      value: market.marketCode,
      label: t('locale_' + market.marketCode),
      id: index + 1,
    })),
  ].sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
})

const marketOptions = computed(() => {
  if (!Array.isArray(marketsArray.value)) return [{ value: 'all', name: t('rc_markets_all'), id: 0 }]
  return [{ value: 'all', label: t('rc_markets_all'), id: 0 }, ...marketsArray.value]
})

const applyFilters = tempFilters => {
  tempFilters.value.forEach(item => {
    onSelect(item)
  })
  closeMobileFilters()
  closeFlyoutsFilters()
  closeBookingFilters()
}

const onSelect = option => {
  if (option.name === 'markets') {
    onSelectedMarketChange(option)
  }
  if (option.name === 'timeframe') {
    onSelectedPeriodChange(option)
  }
  if (option.name === 'aggregation') {
    onSelectedAggregationChange(option)
  }
  if (option.name === 'category') {
    onSelectedGraphChange(option)
  }
  if (option.name === 'dateType') {
    onSelectedDateType(option)
  }
  if (option.name === 'status') {
    onSelectedStatus(option)
  }
}

const onSelectedMarketChange = e => {
  selectedMarket.value = e.option.value
}
const onSelectedPeriodChange = newPeriod => {
  selectedPeriod.value = newPeriod.option.value
}
const onSelectedAggregationChange = aggregation => {
  selectedAggregation.value = aggregation.option.value
}
const onSelectedGraphChange = graph => {
  selectedGraph.value = graph.option.value
}
const onSelectedDateType = dateType => {
  selectedDateType.value = dateType.option.value
}
const onSelectedStatus = status => {
  const statusValue = status.option.map(item => item.value)
  selectedStatus.value = statusValue
}

const selectedPeriodValue = computed(() => periodDatesOptions.value.find(item => item.value === selectedPeriod.value))
const selectedAggregationValue = computed(() =>
  aggregationOptions.value.find(item => item.value === selectedAggregation.value)
)
const selectedDateTypeValue = computed(() => dateTypes.value.find(item => item.value === selectedDateType.value))

const selectedShowGraphValue = computed(() => showGraphOptions.value.find(item => item.value === selectedGraph.value))
const selectedMarketValue = computed(() => marketOptions.value.find(item => item.value === selectedMarket.value))
const selectedStatusValue = computed(() => {
  return statusOptions.value.filter(item => selectedStatus.value.includes(item.value))
})
</script>
<style scoped>
button:hover .filter {
  @apply tw-fill-white tw-stroke-white;
}

button:hover .filter-badge {
  @apply tw-bg-white tw-text-blue-700;
}
</style>
