<template>
  <div class="tw-bg-blue-200 tw-py-4 tw-px-4 tw-rounded-xl tw-text-blue-800 tw-text-sm">
    <p class="tw-font-bold">
      {{ $t(getTitle) }}
    </p>
    <p>{{ $t(getText) }}</p>
  </div>
</template>
<script setup>
import { computed } from 'vue'

const props = defineProps({
  type: String,
})

const getTitle = computed(() => {
  if (props.type === 'filters') return 'rc_booking_details_no_data_header'
  else return 'rc_overview_no_data_header'
})

const getText = computed(() => {
  if (props.type === 'filters') return 'rc_booking_details_no_data_info'
  else return 'rc_overview_no_data_info'
})
</script>
