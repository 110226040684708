import { computed } from 'vue'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import AccommodationService from '@/services/accommodationService/Accommodation'
import PropertyDetailsService from '@/services/PropertyDetailsService.js'

export function useLanguageFields(selectedAccommodationId) {
  const { isLoading, isFetching, isError, data } = useQuery({
    queryKey: ['languages', selectedAccommodationId],
    queryFn: ({ queryKey }) => AccommodationService.fetchLanguagesFields({ accommodationId: queryKey[1] }),
  })
  const useLanguageFieldsData = computed(() => data?.value?.data)

  return {
    isLanguageFieldsLoading: isLoading,
    useLanguageFieldsData,
    isLanguageFieldsFetching: isFetching,
    isLanguageFieldsError: isError,
  }
}

export function useAvailableLanguages() {
  const { isLoading, isFetching, isError, data } = useQuery({
    queryKey: ['availableLanguages'],
    queryFn: () => PropertyDetailsService.fetchAvailableLanguages(),
  })

  const allAvailableLanguages = computed(() => data?.value?.data)

  return {
    isAvailableLanguagesLoading: isLoading,
    allAvailableLanguages,
    isAvailableLanguagesFetching: isFetching,
    isAvailableLanguagesError: isError,
  }
}

export function useRemoveLanguage() {
  const queryClient = useQueryClient()
  const {
    isLoading: removeLanguageLoading,
    isSuccess: removeLanguageSuccess,
    isError: removeLanguageError,
    mutate: removeLanguage,
  } = useMutation({
    mutationFn: ({ accommodationId, language }) => AccommodationService.deleteLanguage({ accommodationId, language }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['languages'] })
    },
  })

  return {
    removeLanguageLoading,
    removeLanguageSuccess,
    removeLanguageError,
    removeLanguage,
  }
}

export function useUpdateLanguage() {
  const queryClient = useQueryClient()
  const {
    isLoading: updateLanguageLoading,
    isSuccess: updateLanguageSuccess,
    isError: updateLanguageError,
    mutate: updateLanguage,
  } = useMutation({
    mutationFn: ({ accommodationId, language, body }) =>
      AccommodationService.updateLanguage({ accommodationId, language, body }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['languages'] })
    },
  })

  return {
    updateLanguageLoading,
    updateLanguageSuccess,
    updateLanguageError,
    updateLanguage,
  }
}

export function useAddLanguage() {
  const queryClient = useQueryClient()
  const {
    isLoading: addLanguageLoading,
    isSuccess: addLanguageSuccess,
    isError: addLanguageError,
    mutate: addLanguage,
  } = useMutation({
    mutationFn: ({ accommodationId, body }) => AccommodationService.addNewLanguage({ accommodationId, body }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['languages'] })
    },
  })

  return {
    addLanguageLoading,
    addLanguageSuccess,
    addLanguageError,
    addLanguage,
  }
}
