<template>
  <div v-if="isLoading || isExistingPaymentMethodsLoading || isAvailablePaymentMethodsLoading">
    <div class="tw-animate-pulse tw-bg-gray-300 tw-rounded tw-w-full tw-h-80 tw-mt-6" aria-hidden="true"></div>
  </div>
  <div v-else-if="isError" class="tw-text-red-700 tw-mt-6" v-html="$t('errors.wrong_data_from_app')" />
  <div v-else class="tw-mt-6 tw-flex tw-flex-col" :key="selectedAccommodationId">
    <h1 class="tw-text-center tw-text-2xl tw-font-bold tw-mb-9">{{ $t('check_out_page_billing_info') }}</h1>
    <div class="rc-payment-grid">
      <div class="rc-payment-form">
        <h3 class="tw-font-bold tw-mb-1">{{ $t('business_studio_plus_checkout_payment_details_header') }}</h3>
        <div class="tw-px-4 tw-py-5 tw-pb-4 tw-px-4 tw-bg-red-200 tw-mb-4 tw-rounded-lg" v-if="errorInfo">
          <p class="tw-text-sm">{{ $t(errorInfo) }}</p>
        </div>
        <BaseCard noPadding>
          <PaymentMethodForm
            source="rate-connect-form"
            :currency="currencyCode"
            :accommodationId="selectedAccommodationId"
            :productName="zuoraMetricConstants.productName.rateConnect"
            :fetchingPaymentMethods="isFetching || isExistingPaymentMethodsLoading"
            :existingPaymentMethods="existingPaymentMethodsData"
            :availablePaymentMethods="availablePaymentMethodsData"
            :selectedPaymentMethod="selectedPaymentMethod"
            @onSelectedValueChange="e => (selectedPaymentMethod = e)"
            @onShowNewFormCancel="onNewPaymentMethodFormCancel"
            @onZuoraCallbackSuccess="onZuoraCallbackSuccess"
            @onZuoraCallbackError="onZuoraCallbackError"
            @onZuoraClientValidationError="onZuoraClientValidationError"
            @setError="error => setError(error)"
          />
        </BaseCard>
      </div>
      <div class="rc-billing-form tw-mt-6">
        <h3 class="tw-font-bold tw-mb-1" ref="billingFormTitle">
          {{ $t('paymentMethods.billingAddress.heading') }}
        </h3>
        <BaseCard noPadding class="tw-px-4 tw-py-6">
          <BillingInformationForm
            ref="billingForm"
            :initialBillingFormValues="existingBillingInformation"
            @onValidateAllSuccess="onValidateBillingSuccess"
            @onValidateAllError="onValidateBillingError"
            @setBillingFormValid="setBillingFormValid"
          />
        </BaseCard>
      </div>
      <div class="rc-payment-footer">
        <div class="sticky">
          <h3 class="tw-font-bold tw-mb-1">{{ $t('business_studio_plus_checkout_summary') }}</h3>
          <BaseCard noPadding class="tw-px-4 tw-py-6">
            <div class="tw-flex tw-items-start tw-flex-col">
              <p class="tw-font-bold tw-text-xl">{{ $t('navigation_menu_rate_connect') }}</p>
              <p class="tw-mb-6">
                {{ $t('check_out_page_rc_campaign') }}
              </p>
              <p
                class="tw-mb-6 tw-font-bold tw-w-full tw-flex tw-justify-between tw-border-b tw-mb-6 tw-pb-5 tw-border-gray-300"
              >
                {{ $t('check_out_page_rc_commission_rate') }}
                <span>{{
                  Intl.NumberFormat(language, {
                    style: 'percent',
                  }).format(selectedCommissionLevel / 100)
                }}</span>
              </p>
              <div class="tw-flex tw-gap-3 tw-items-center tw-mb-6">
                <input
                  id="rate-connect-terms"
                  type="checkbox"
                  class="hover:tw-cursor-pointer tw-h-4 tw-w-4"
                  :value="termsAccepted"
                  @change="e => (termsAccepted = e.target.checked)"
                />

                <label for="rate-connect-terms" class="tw-grow tw-text-sm tw-leading-4 tw-mb-0 terms">
                  <i18n-t keypath="termsAndPolicies.accept1" tag="p" scope="global" class="tw-inline-block tw-mb-0">
                    <template class="tw-inline-flex">
                      <a :href="termsUrl" target="_blank" class="tw-text-blue-700 tw-ml-[2px]">{{
                        $t('legal.termsAndConditions')
                      }}</a>
                    </template>
                  </i18n-t>
                </label>
              </div>
              <MainButton class="tw-w-full tw-mb-6" :disabled="!paymentEnabled" @click="onStartCampaign">{{
                $t('check_out_page_rc_cta')
              }}</MainButton>
              <div class="tw-flex tw-items-start tw-mb-4 tw-gap-3">
                <BaseIcon :height="24" :width="24" icon-name="lock"> <Lock /> </BaseIcon>
                <span class="tw-text-base tw-text-gray-800">{{
                  $t('checkoutBasket.detailsEntry.secureConnection.recurringFee')
                }}</span>
              </div>
              <div class="tw-flex tw-items-start tw-mb-4 tw-gap-3">
                <BaseIcon :height="24" :width="24" icon-name="lock"> <Coins /> </BaseIcon
                ><span class="tw-text-base tw-text-gray-800">{{
                  $t('checkoutBasket.detailsEntry.payment.usageBased')
                }}</span>
              </div>
              <div class="tw-flex tw-items-start tw-mb-4 tw-gap-3">
                <BaseIcon :height="24" :width="24" icon-name="information-outline" class="tw-fill-none">
                  <CircleInfo />
                </BaseIcon>
                <span class="tw-text-base tw-text-gray-800">{{ $t('check_out_page_rc_bookings_info') }}</span>
              </div>
              <div class="tw-flex tw-items-start tw-mb-4 tw-gap-3">
                <BaseIcon :height="24" :width="24" icon-name="calendar">
                  <CalendarIcon />
                </BaseIcon>
                <span class="tw-text-base tw-text-gray-800">{{ $t('check_out_page_rc_invoicing') }}</span>
              </div>
              <div class="tw-flex tw-items-start tw-gap-3">
                <BaseIcon :height="24" :width="24" icon-name="gear-wheel">
                  <GearWheel />
                </BaseIcon>
                <span class="tw-text-base tw-text-gray-800">{{ $t('check_out_page_rc_settings') }}</span>
              </div>
            </div>
          </BaseCard>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref, nextTick, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import BaseIcon from '@/components/BaseIcon.vue'
import Lock from '@/components/icons/Lock.vue'
import Coins from '@/components/icons/Coins.vue'
import CalendarIcon from '@/components/icons/CalendarIcon.vue'
import CircleInfo from '@/components/icons/CircleInfo.vue'
import GearWheel from '@/components/icons/GearWheel.vue'
import BillingInformationForm from '@/components/payment/BillingInformationForm.vue'
import PaymentMethodForm from '@/components/payment/PaymentMethodForm.vue'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'

import {
  useAvailablePaymentMethods,
  useExistingPaymentMethods,
  useZuoraAccount,
  useZuoraUpdateAccountMutation,
  useZuoraNewAccountMutation,
  useCreateRateConnectCampaign,
  useErrorMetrics,
} from '@/components/payment/queries'

import { selectedAccommodationId, useAccommodationsByOrgId } from '@/layouts/queries'
import { countries } from '@/constants/countries.js'
import { useSetup } from './useSetup'

import useToastNotifications from '@/components/notifications/useToastNotifications'
import zuoraMetricConstants from '@/constants/zuoraMetricConstants'
import appAnalytics from '@/utils/tracking'

const router = useRouter()
const store = useStore()
const { displayNotification } = useToastNotifications()

const props = defineProps({
  selectedCommissionLevel: Number,
  rcCampaignCreationInProgress: Boolean,
  selectedPartner: Object,
})

const emit = defineEmits(['onCheckoutSuccess', 'setRCCampaignCreationInProgress'])
const onCheckoutSuccess = zuoraSubscriptionNumber => emit('onCheckoutSuccess', zuoraSubscriptionNumber)
const { sendErrorMetrics } = useErrorMetrics()
const { resetSetup } = useSetup()

const termsAccepted = ref(false)
const billingForm = ref(null)
const billingFormTitle = ref(null)
const selectedPaymentMethod = ref(null)
const isBillingFormValid = ref(true)
const newBillingInformation = ref(null)
const isZuoraHppLoadError = ref(false)
const errorInfo = ref(false)
const countriesWithState = ['US', 'MX', 'CA', 'BR']

const currencyCode = computed(() => store.state.currency)
const language = computed(() => store.state.locale.language)
const token = computed(() => store.state.session?.studioToken)
const selectedOrganisationId = computed(() => store.state.session.selectedOrganisation?.id)
const { accommodationsData } = useAccommodationsByOrgId(selectedOrganisationId)
const currentAccommodation = computed(() =>
  accommodationsData.value?.find(accommodation => accommodation.accommodationId === selectedAccommodationId.value)
)
const { isLoading, isError, zuoraAccountData } = useZuoraAccount(token)
const { existingPaymentMethodsData, isLoading: isExistingPaymentMethodsLoading } =
  useExistingPaymentMethods(selectedAccommodationId)
const {
  isFetching,
  isLoading: isAvailablePaymentMethodsLoading,
  availablePaymentMethodsData,
  refetch,
} = useAvailablePaymentMethods(selectedAccommodationId, currencyCode)
const { updateZuoraAccount } = useZuoraUpdateAccountMutation()
const { createNewZuoraAccount } = useZuoraNewAccountMutation()
const { createNewRateConnectCampaign } = useCreateRateConnectCampaign()

const paymentEnabled = computed(
  () => termsAccepted.value && selectedPaymentMethod.value !== null && isBillingFormValid.value
)

const existingBillingInformation = computed(() => {
  if (
    !zuoraAccountData.value ||
    zuoraAccountData.value?.basicInfo === null ||
    zuoraAccountData.value?.billToContact === null
  )
    return null
  const countryCode = countries.find(country => country.name === zuoraAccountData.value?.billToContact?.country)?.code
  return {
    companyName: zuoraAccountData.value.basicInfo.name,
    email: zuoraAccountData.value.billToContact.workEmail,
    firstName: zuoraAccountData.value.billToContact.firstName,
    lastName: zuoraAccountData.value.billToContact.lastName,
    address: zuoraAccountData.value.billToContact.address1,
    city: zuoraAccountData.value.billToContact.city,
    zipCode: zuoraAccountData.value.billToContact.zipCode,
    country: countryCode,
    state: zuoraAccountData.value.billToContact.state,
    taxId: zuoraAccountData.value.taxInfo?.exemptCertificateId,
    noTaxId: zuoraAccountData.value.taxInfo?.exemptCertificateId ? false : true,
  }
})

const onNewPaymentMethodFormCancel = () => {
  errorInfo.value = false
  if (isSelectedPaymentMethodNew.value) {
    selectedPaymentMethod.value = defaultPaymentMethodId.value
  }
}

const defaultPaymentMethodId = computed(
  () => existingPaymentMethodsData.value?.find(paymentMethod => paymentMethod.isDefault)?.id
)

const isSelectedPaymentMethodNew = computed(
  () => selectedPaymentMethod.value === 'CREDIT_CARD' || selectedPaymentMethod.value === 'SEPA'
)

const setBillingFormValid = isValid => {
  isBillingFormValid.value = isValid
}

const onValidateBillingError = () => {
  displayNotification({
    message: 'subscriptions.details.transactionHistory.error',
    isTranslationKey: true,
    type: 'error',
  })
  emit('setRCCampaignCreationInProgress', false)
}

const onStartCampaign = () => {
  if (isSelectedPaymentMethodNew.value && isZuoraHppLoadError.value) {
    displayNotification({
      message: 'subscriptions.details.transactionHistory.error',
      isTranslationKey: true,
      type: 'error',
    })
    return
  }
  emit('setRCCampaignCreationInProgress', true)
  billingForm.value?.validateAll()
}

const onValidateBillingSuccess = billingInfo => {
  newBillingInformation.value = billingInfo
  if (!selectedPaymentMethod.value) {
    displayNotification({
      message: 'subscriptions.details.transactionHistory.error',
      isTranslationKey: true,
      type: 'error',
    })
    emit('setRCCampaignCreationInProgress', false)
    return
  }
  if (isSelectedPaymentMethodNew.value) {
    Z.submit()
    return
  }
  if (selectedPaymentMethod.value !== defaultPaymentMethodId.value) {
    sendZuoraAccount(selectedPaymentMethod.value)
    return
  }
  sendZuoraAccount()
}

const setError = error => {
  errorInfo.value = error
}

const onZuoraCallbackSuccess = async refId => {
  sendZuoraAccount(refId)
}

const forceRender = async () => {
  await nextTick()
  refetch()
  isZuoraHppLoadError.value = false
}

const onZuoraClientValidationError = (_, isAttemptExceedLimitationError) => {
  emit('setRCCampaignCreationInProgress', false)
  if (isAttemptExceedLimitationError) forceRender()
  displayNotification({
    message: 'subscriptions.details.transactionHistory.error',
    isTranslationKey: true,
    type: 'error',
  })
}

const onZuoraCallbackError = (widthRerender = false) => {
  if (props.rcCampaignCreationInProgress) {
    displayNotification({
      message: 'subscriptions.details.transactionHistory.error',
      isTranslationKey: true,
      type: 'error',
    })
  } else {
    if (widthRerender) {
      forceRender()
    }
    isZuoraHppLoadError.value = true
  }
  emit('setRCCampaignCreationInProgress', false)
}

const termsUrl = computed(() => {
  return `${window.location.origin}/home/terms-and-conditions?app=rate-connect`
})
const createRateConnectSubscription = async ({ lastName, firstName }) => {
  await createNewRateConnectCampaign(
    {
      accommodationId: selectedAccommodationId.value,
      body: {
        itemName: currentAccommodation.value.name,
        campaignType: 'netCpa',
        currencyCode: currencyCode.value,
        partnerId: props.selectedPartner.value,
        cpa: props.selectedCommissionLevel,
        language: language.value,
        lastName,
        firstName,
      },
    },
    {
      onSuccess: () => {
        router.push({ name: 'rate-connect-running-performance' })
        appAnalytics.track(appAnalytics.events.RC_ONBOARDING_PAYMENT_SUCCESS, {
          item_id: selectedAccommodationId.value,
          partner_id: props.selectedPartner.value,
          campaign_type: 'netCpa',
          commission_level: props.selectedCommissionLevel,
        })
        onCheckoutSuccess()
      },
      onError: () => {
        emit('setRCCampaignCreationInProgress', false)
        resetSetup()
        displayNotification({
          message: 'app_error_title',
          isTranslationKey: true,
          type: 'error',
        })
      },
    }
  )
}

const sendZuoraAccount = async refId => {
  emit('setRCCampaignCreationInProgress', true)
  if (!existingBillingInformation.value) {
    const body = {
      name: newBillingInformation.value?.companyName,
      organization_id__c: selectedOrganisationId.value,
      thm_account_type__c: 'business studio',
      billCycleDay: 0,
      currency: currencyCode.value,
      thm_item_id__c: selectedAccommodationId.value,
      autoPay: true,
      hpmCreditCardPaymentMethodId: refId,
      billToContact: {
        address1: newBillingInformation.value?.address,
        city: newBillingInformation.value?.city,
        country: newBillingInformation.value?.country,
        firstName: newBillingInformation.value?.firstName,
        lastName: newBillingInformation.value?.lastName,
        state: newBillingInformation.value?.state ?? '',
        workEmail: newBillingInformation.value?.email,
        zipCode: newBillingInformation.value?.zipCode,
      },
      taxInfo: {
        exemptCertificateId: newBillingInformation.value?.taxId ?? '',
      },
    }

    await createNewZuoraAccount(
      { body, language: language.value },
      {
        onSuccess: data => {
          if (data?.data?.success) {
            createRateConnectSubscription({
              lastName: body.billToContact.lastName,
              firstName: body.billToContact.firstName,
            })
            emit('setRCCampaignCreationInProgress', true)
          } else {
            forceRender()
            displayNotification({
              message: 'subscriptions.details.transactionHistory.error',
              isTranslationKey: true,
              type: 'error',
            })
            sendErrorMetrics({
              itemId: selectedAccommodationId.value,
              productName: zuoraMetricConstants.productName.rateConnect,
              code: zuoraMetricConstants.metricErrorCodes.billingInformation,
              message: data?.data,
            })
            emit('setRCCampaignCreationInProgress', false)
          }
        },
        onError: response => {
          displayNotification({
            message: 'subscriptions.details.transactionHistory.error',
            isTranslationKey: true,
            type: 'error',
          })
          sendErrorMetrics({
            itemId: selectedAccommodationId.value,
            productName: zuoraMetricConstants.productName.rateConnect,
            code: zuoraMetricConstants.metricErrorCodes.billingInformation,
            message: response.response.data?.message,
          })
          emit('setRCCampaignCreationInProgress', false)
        },
      }
    )
  } else {
    if (!newBillingInformation.value) return
    const body = {
      billToContact: {},
      taxInfo: {},
    }

    if (refId) body.defaultPaymentMethodId = refId
    if (
      newBillingInformation.value.companyName &&
      newBillingInformation.value.companyName !== existingBillingInformation.value?.companyName
    )
      body.name = newBillingInformation.value.companyName
    if (
      newBillingInformation.value.address &&
      newBillingInformation.value.address !== existingBillingInformation.value?.address
    )
      body.billToContact.address1 = newBillingInformation.value.address
    if (newBillingInformation.value.city && newBillingInformation.value.city !== existingBillingInformation.value?.city)
      body.billToContact.city = newBillingInformation.value.city
    if (
      newBillingInformation.value.firstName &&
      newBillingInformation.value.firstName !== existingBillingInformation.value?.firstName
    )
      body.billToContact.firstName = newBillingInformation.value.firstName
    if (
      newBillingInformation.value.lastName &&
      newBillingInformation.value.lastName !== existingBillingInformation.value?.lastName
    )
      body.billToContact.lastName = newBillingInformation.value.lastName
    if (
      newBillingInformation.value.email &&
      newBillingInformation.value.email !== existingBillingInformation.value?.email
    )
      body.billToContact.workEmail = newBillingInformation.value.email

    if (
      newBillingInformation.value.state &&
      newBillingInformation.value.state !== existingBillingInformation.value?.state
    )
      body.billToContact.state = newBillingInformation.value.state

    body.billToContact.zipCode = newBillingInformation.value.zipCode ?? existingBillingInformation.value?.zipCode
    body.billToContact.country = newBillingInformation.value.country ?? existingBillingInformation.value?.country
    body.billToContact.state = countriesWithState.includes(body.billToContact.country)
      ? newBillingInformation.value.state
      : ''
    body.taxInfo.exemptCertificateId = newBillingInformation.value.taxId ?? existingBillingInformation.value?.taxId

    if (Object.keys(body).length > 0) {
      await updateZuoraAccount(
        { body, language: language.value },
        {
          onSuccess: data => {
            if (data?.data?.success) {
              createRateConnectSubscription({
                lastName: body.billToContact.lastName
                  ? body.billToContact.lastName
                  : existingBillingInformation.value?.lastName,
                firstName: body.billToContact.firstName
                  ? body.billToContact.firstName
                  : existingBillingInformation.value?.firstName,
              })
            } else {
              sendErrorMetrics({
                itemId: selectedAccommodationId.value,
                productName: zuoraMetricConstants.productName.rateConnect,
                code: zuoraMetricConstants.metricErrorCodes.billingInformation,
                message: data?.data,
              })
              forceRender()
              displayNotification({
                message: 'subscriptions.details.transactionHistory.error',
                isTranslationKey: true,
                type: 'error',
              })
              emit('setRCCampaignCreationInProgress', false)
            }
          },
          onError: response => {
            sendErrorMetrics({
              itemId: selectedAccommodationId.value,
              productName: zuoraMetricConstants.productName.rateConnect,
              code: zuoraMetricConstants.metricErrorCodes.billingInformation,
              message: response.response.data?.message,
            })
            emit('setRCCampaignCreationInProgress', false)
            forceRender()
            displayNotification({
              message: 'subscriptions.details.transactionHistory.error',
              isTranslationKey: true,
              type: 'error',
            })
          },
        }
      )
    }
  }
}

watch(defaultPaymentMethodId, newVal => {
  selectedPaymentMethod.value = newVal
})

onMounted(() => {
  if (defaultPaymentMethodId.value) {
    selectedPaymentMethod.value = defaultPaymentMethodId.value
  }
})
</script>
<style scoped>
.rc-payment-grid {
  display: block;

  @media (min-width: theme('screens.md')) {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-template-areas:
      'payment-form payment-footer'
      'billing-form payment-footer';
    column-gap: 4rem;
  }
}
.rc-payment-form {
  grid-area: payment-form;
}
.rc-payment-footer {
  grid-area: payment-footer;
}
.sticky {
  position: sticky;
  top: 120px;
  right: 0;
}
.rc-billing-form {
  grid-area: billing-form;
}
</style>
