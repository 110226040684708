<template>
  <div class="tw-mt-2">
    <BaseCard>
      <p class="tw-font-bold">
        {{
          $t('competitors_competitors_counter_label', { counterSelected: selectedSuggestions.length, counterTotal: 5 })
        }}
      </p>
      <div class="tw-mt-4">
        <AccommodationItem
          v-for="suggestion in selectedSuggestions"
          :isClickable="false"
          :key="suggestion.id"
          :accommodation="suggestion"
          :location="`${suggestion.city}, ${suggestion.countryCode ? $t('country_' + suggestion.countryCode?.toLowerCase()) : suggestion.countryName}`"
          :values="selectedSuggestions"
          @onSelect="option => onSelect(option)"
        />
        <AccommodationItemPlaceholder v-for="item in placeholderElements" :competitorNumber="item" :key="item" />
      </div>
    </BaseCard>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import AccommodationItem from '@/components/rateInsights/onboarding/AccommodationItem.vue'
import AccommodationItemPlaceholder from '@/components/rateInsights/onboarding/AccommodationItemPlaceholder.vue'

const props = defineProps({
  selectedSuggestions: Object,
})

const placeholderElements = computed(() =>
  Array.from({ length: 5 - props.selectedSuggestions.length }, (v, k) => k + 1 + props.selectedSuggestions.length)
)
const emit = defineEmits(['onSelect'])
const onSelect = value => emit('onSelect', value)
</script>
