<template>
  <CustomModal
    class="export-data-modal"
    :is-modal-open="isOpen"
    @toggleModal="emit('closeModal')"
    @onClickAway="emit('closeModal')"
    :overlay="'dark'"
    :with-header="true"
    :with-footer="true"
    custom-classes="tw-h-screen md:tw-h-auto tw-w-[100vw] md:tw-max-w-[636px] md:tw-w-[636px] md:tw-rounded-md tw-rounded-none"
  >
    <template #modalHeader
      ><p class="tw-text-xl">{{ $t('rc_data_download_header') }}</p></template
    >

    <div class="tw-flex tw-flex-col">
      <p class="tw-pb-6">{{ $t('rc_data_download_info') }}</p>
      <ul>
        <li v-for="option in options" :key="option.id" class="tw-flex tw-items-center tw-w-full tw-mb-1">
          <input
            type="radio"
            :value="option.id"
            :id="option.id"
            :checked="exportType.id === option.id"
            @change="onChange(option)"
            class="tw-cursor-pointer tw-mr-4 tw-mb-2"
          />
          <label class="tw-text-sm tw-leading-5" :for="option.id"> {{ $t(option.label) }}</label>
        </li>
      </ul>
    </div>

    <template #modalFooter>
      <div class="tw-flex tw-w-full tw-justify-end">
        <MainButton size="medium" @click="exportData">{{ $t('rc_data_download_cta') }}</MainButton>
      </div>
    </template>
  </CustomModal>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import * as Sentry from '@sentry/vue'

import CustomModal from '@/components/base/CustomModal.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import RateConnectService from '@/services/RateConnectService.js'

import useToastNotifications from '@/components/notifications/useToastNotifications'
import { selectedAccommodationId } from '@/layouts/queries'
import { useRateConnectPerformance } from './useRateConnectPerformance'

const store = useStore()
const props = defineProps({
  isOpen: Boolean,
  selectedArea: String,
})
const emit = defineEmits(['closeModal'])
const { displayNotification } = useToastNotifications()

const {
  selectedMarket,
  formattedStartDate,
  formattedEndDate,
  selectedAggregation,
  selectedDateType,
  selectedStatus,
  yearlyStartDate,
} = useRateConnectPerformance()

const currency = computed(() => store.state.currency)
const marketValue = computed(() => (selectedMarket.value === 'all' ? '' : selectedMarket.value))
const exportType = ref({ id: 0, value: 'filtered', label: 'rc_data_download_filtered' })
const options = ref([
  { id: 0, value: 'filtered', label: 'rc_data_download_filtered' },
  { id: 1, value: 'all', label: 'rc_data_download_year' },
])

const onChange = value => {
  exportType.value = value
}

const exportData = () => {
  if (props.selectedArea === 'bookings') {
    exportBookingLogData()
  } else if (props.selectedArea === 'overview') {
    exportOverviewData()
  }
}
const exportBookingLogData = async () => {
  try {
    const response = await RateConnectService.exportBookingLogData({
      accommodationId: selectedAccommodationId.value,
      currency: currency.value,
      startDate: exportType.value.value === 'all' ? yearlyStartDate.value : formattedStartDate.value,
      endDate: formattedEndDate.value,
      marketCodes: exportType.value.value === 'all' ? '' : marketValue.value,
      filterDate: exportType.value.value === 'all' ? 'bookingDate' : selectedDateType.value,
      status: exportType.value.value === 'all' ? 'CONFIRMED,CANCELLED,COMPLETED' : selectedStatus.value.join(','),
    })
    if (response.data) {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute(
        'download',
        `booking_details_${exportType.value.value === 'all' ? yearlyStartDate.value : formattedStartDate.value}_${formattedEndDate.value}.csv`
      )
      document.body.appendChild(fileLink)
      fileLink.click()
      document.body.removeChild(fileLink)
    }
  } catch (error) {
    Sentry.withScope(scope => {
      scope.setTag('feature', 'export booking log data')
      scope.setExtra('Response error', error)
      Sentry.captureException(error)
    })
    displayNotification({
      message: 'something_went_wrong',
      isTranslationKey: true,
      type: 'error',
    })
  }
}

const exportOverviewData = async () => {
  try {
    const response = await RateConnectService.exportStatisticsData({
      accommodationId: selectedAccommodationId.value,
      currency: currency.value,
      aggregation: exportType.value.value === 'all' ? 'DAILY' : selectedAggregation.value,
      startDate: exportType.value.value === 'all' ? yearlyStartDate.value : formattedStartDate.value,
      endDate: formattedEndDate.value,
      marketCodes: exportType.value.value === 'all' ? '' : marketValue.value,
    })
    if (response.data) {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute(
        'download',
        `performance_overview_${exportType.value.value === 'all' ? yearlyStartDate.value : formattedStartDate.value}_${formattedEndDate.value}.csv`
      )
      document.body.appendChild(fileLink)
      fileLink.click()
      document.body.removeChild(fileLink)
    }
  } catch (error) {
    Sentry.withScope(scope => {
      scope.setTag('feature', 'export performance overview data')
      scope.setExtra('Response error', error)
      Sentry.captureException(error)
    })
    displayNotification({
      message: 'something_went_wrong',
      isTranslationKey: true,
      type: 'error',
    })
  }
}
</script>

<style scoped></style>
