<template>
  <div class="tw-flex md:tw-hidden">
    <MobileInformationCard />
  </div>
  <div class="md:tw-flex tw-hidden tw-flex-col">
    <div v-if="requiredDataHasErrors">
      <DataErrorView />
    </div>
    <div v-if="!requiredDataIsBeingFetched && !requiredDataHasErrors">
      <SectionsNav
        class="tw-mb-8"
        :items="performanceAreas"
        :value="selectedPerformanceArea"
        :hasNoData="hasNoData"
        @changePerformanceView="changePerformanceView"
      />
      <PerformanceArea
        :hasNoBookings="hasNoBookings"
        :hasEmptyTable="hasEmptyTable"
        :hasNoData="hasNoData"
        :overviewTableCampaignType="overviewTableCampaignType"
        :bookingTableCampaignType="bookingTableCampaignType"
        :selectedArea="selectedPerformanceArea"
        :currentCampaignType="currentCampaignType"
        :prettyKpis="prettyKpis"
        :lastUpdated="lastUpdatedLocalized"
        :selectedPeriod="selectedPeriod"
        :markets="markets"
        :bookings="bookings"
        :cpa="cpa"
        :visits="visits"
        :revenue="revenue"
        :categories="categories"
        :campaignTypes="campaignTypes"
        :tickPositions="tickPositions"
        :currency="currency"
      />
    </div>
  </div>
  <FullScreenLoader v-show="requiredDataIsBeingFetched" />
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import PerformanceArea from '@/components/rateConnect/performance/PerformanceArea.vue'
import DataErrorView from '@/components/rateConnect/performance/DataErrorView.vue'
import MobileInformationCard from '@/components/rateConnect/performance/MobileInformationCard.vue'
import FullScreenLoader from '@/components/base/loaders/FullScreenLoader.vue'
import SectionsNav from '@/components/rateConnect/performance/SectionsNav.vue'

import { useBookingLog, useKpis, useStats } from '@/components/rateConnect/queries'
import { useRateConnectPerformance, usePrettyStats } from './useRateConnectPerformance'
import { selectedAccommodationId } from '@/layouts/queries'
import { campaignsAggregateType } from '@/utils/rateConnectUtils'

const { t } = useI18n()
const props = defineProps({
  markets: {
    type: Object,
    default: null,
  },
  currentCampaignType: {
    type: String,
    default: '',
  },
})

const performanceAreas = computed(() => [
  { label: t('rc_performance_overview'), value: 'overview' },
  {
    label: t('rc_booking_details'),
    value: 'bookings',
  },
])

const selectedPerformanceArea = ref('overview')
const changePerformanceView = view => {
  selectedPerformanceArea.value = view
}

const {
  selectedPeriod,
  selectedMarket,
  formattedStartDate,
  formattedEndDate,
  selectedAggregation,
  selectedDateType,
  selectedStatus,
  resetData,
} = useRateConnectPerformance()

const store = useStore()
const currency = computed(() => store.state.currency)
const language = computed(() => store.state.locale?.language)

const {
  isFetching: isKpisFetching,
  isError: isKpisError,
  KpisData,
  prettyKpis,
} = useKpis(selectedAccommodationId, currency, formattedStartDate, formattedEndDate, selectedMarket)
const {
  isFetching: isBookingLogFetching,
  isError: isBookingLogError,
  bookingLogData,
  hasBookings,
} = useBookingLog(
  selectedAccommodationId,
  selectedDateType,
  currency,
  formattedStartDate,
  formattedEndDate,
  selectedMarket,
  selectedStatus
)
const {
  isFetching: isStatsFetching,
  isError: isStatsError,
  statsData,
  hasStatistics,
} = useStats(
  selectedAccommodationId,
  currency,
  formattedStartDate,
  formattedEndDate,
  selectedMarket,
  selectedAggregation
)
const lastUpdated = computed(() => KpisData.value?.lastUpdated)
const lastUpdatedLocalized = computed(() => {
  return KpisData.value?.lastUpdated
    ? new Date(KpisData.value?.lastUpdated).toLocaleDateString(language.value, {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      })
    : null
})

const overviewTableCampaignType = computed(() => {
  if (!statsData.value || !props.currentCampaignType) return null
  return campaignsAggregateType(statsData.value, props.currentCampaignType)
})

const bookingTableCampaignType = computed(() => {
  if (bookingLogData.value?.every(item => item.campaignType === 'netCpa')) return 'cpa'
  if (bookingLogData.value?.every(item => item.campaignType === 'cpc')) return 'cpc'
  else return 'both'
})

const { bookings, visits, revenue, cpa, categories, campaignTypes, tickPositions } = usePrettyStats(
  statsData,
  lastUpdated,
  language
)

const requiredDataHasErrors = computed(() => isKpisError.value || isBookingLogError.value || isStatsError.value)
const requiredDataIsBeingFetched = computed(
  () => isKpisFetching.value || isBookingLogFetching.value || isStatsFetching.value
)

const hasEmptyTable = computed(() => {
  if (requiredDataIsBeingFetched.value) {
    return false
  }
  if (selectedPerformanceArea.value === 'overview') {
    return statsData.value?.length === 0 && hasStatistics.value
  } else {
    return hasBookings.value && bookingLogData.value?.length === 0
  }
})

const hasNoData = computed(() => {
  if (requiredDataIsBeingFetched.value) {
    return false
  }

  return (
    statsData.value?.length === 0 && !hasStatistics.value && !hasBookings.value && bookingLogData.value?.length === 0
  )
})

const hasNoBookings = computed(() => {
  if (requiredDataIsBeingFetched.value) {
    return false
  }

  return (
    (statsData.value?.length > 0 || hasStatistics.value) && !hasBookings.value && bookingLogData.value?.length === 0
  )
})

onMounted(() => {
  resetData()
})
</script>
