<template>
  <mask
    id="mask0_10403_33888"
    style="mask-type: luminance"
    maskUnits="userSpaceOnUse"
    x="9"
    y="6"
    width="11"
    height="12"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 6H20V18H9V6Z" fill="white" />
  </mask>
  <g mask="url(#mask0_10403_33888)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 9L17.5 7L19 9H16ZM19.8 8.4L18.3 6.4C18.111 6.148 17.815 6 17.5 6C17.185 6 16.889 6.148 16.7 6.4L15.2 8.4C14.973 8.703 14.936 9.108 15.105 9.447C15.275 9.786 15.621 10 16 10H16.975C16.715 13.902 13.467 17 9.5 17C9.224 17 9 17.224 9 17.5C9 17.776 9.224 18 9.5 18C14.018 18 17.714 14.454 17.975 10H19C19.379 10 19.725 9.786 19.895 9.447C20.064 9.108 20.027 8.703 19.8 8.4Z"
      fill="#171717"
    />
  </g>
  <mask
    id="mask1_10403_33888"
    style="mask-type: luminance"
    maskUnits="userSpaceOnUse"
    x="0"
    y="0"
    width="11"
    height="12"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H11V12H0V0Z" fill="white" />
  </mask>
  <g mask="url(#mask1_10403_33888)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.00012 9L2.50012 11L1.00012 9H4.00012ZM0.200116 9.6L1.70012 11.6C1.88912 11.852 2.18512 12 2.50012 12C2.81512 12 3.11112 11.852 3.30012 11.6L4.80012 9.6C5.02712 9.297 5.06412 8.892 4.89512 8.553C4.72512 8.214 4.37912 8 4.00012 8H3.02512C3.28512 4.098 6.53312 1 10.5001 1C10.7761 1 11.0001 0.776 11.0001 0.5C11.0001 0.224 10.7761 0 10.5001 0C5.98212 0 2.28712 3.546 2.02512 8H1.00012C0.621116 8 0.275116 8.214 0.105116 8.553C-0.0638842 8.892 -0.0268842 9.297 0.200116 9.6Z"
      fill="#171717"
    />
  </g>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.5 8.50977V5.01977C11.33 5.16977 11.95 5.86977 12 6.70977C12 6.98977 12.22 7.20977 12.5 7.20977C12.78 7.20977 13 6.98977 13 6.70977C12.949 5.31577 11.886 4.16877 10.5 4.00977V3.50977C10.5 3.22977 10.28 3.00977 10 3.00977C9.72 3.00977 9.5 3.22977 9.5 3.50977V4.00977C8.114 4.16877 7.051 5.31577 7 6.70977C7.051 8.10477 8.114 9.25177 9.5 9.40977V12.8998C8.67 12.7498 8.051 12.0518 8 11.2098C8 10.9298 7.78 10.7098 7.5 10.7098C7.22 10.7098 7 10.9298 7 11.2098C7.03 12.6218 8.097 13.7958 9.5 13.9598V14.4898C9.5 14.7698 9.72 14.9898 10 14.9898C10.28 14.9898 10.5 14.7698 10.5 14.4898V13.9598C11.886 13.8018 12.949 12.6548 13 11.2598C12.97 9.84777 11.903 8.67477 10.5 8.50977ZM8 6.70977C8.05 5.86977 8.67 5.16977 9.5 5.01977V8.39977C8.67 8.24977 8.051 7.55177 8 6.70977ZM10.5 12.8998V9.51977C11.33 9.66977 11.95 10.3698 12 11.2098C11.949 12.0518 11.33 12.7498 10.5 12.8998Z"
    fill="#171717"
  />
</template>
