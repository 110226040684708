<template>
  <div class="tw-flex tw-flex-wrap xl:tw-flex-nowrap xl:tw-flex-row tw-gap-2">
    <FilterSelect
      class="tw-w-[14%] tw-min-w-[200px]"
      :options="dateRateOptions"
      @select="onSelect"
      name="time-range"
      :label="$t('select_timeframe')"
      :disabled="disabled"
      :hint="getTooltip('time-range', selectedTimeRange)"
      :value="selectedTimeRange"
      :valueLabel="$t(selectedTimeRange.name, { amount: selectedTimeRange.amount })"
    />
    <FilterSelect
      class="tw-w-[14%] tw-min-w-[160px]"
      :options="aggregation"
      @select="onSelect"
      name="aggregation"
      :label="$t('ri_group_time_frame')"
      :disabled="disabled"
      :hint="getTooltip('aggregation', selectedAggregation)"
      :value="selectedAggregation"
      :valueLabel="$t(`aggregation_${selectedAggregation.name.toLowerCase()}`)"
      :withTranslations="true"
    />
    <FilterMultiselect
      name="competitors"
      :tagline="$t('competitor_search_header')"
      :options="competitors"
      class="tw-w-[14%] tw-min-w-[160px]"
      :value="selectedCompetitors"
      @select="onMultipleValuesSelect"
    />
    <FilterMultiselect
      name="channels"
      :options="channels"
      :tagline="$t('channels_select_amount')"
      class="tw-w-[14%] tw-min-w-[160px]"
      :value="selectedChannels"
      :disabled="disabled"
      @select="onMultipleValuesSelect"
    />

    <div v-for="filterObj in filters" :key="filterObj.id" class="tw-w-[14%] tw-min-w-[160px]">
      <FilterSelect
        :key="filterObj.id"
        :options="filterObj.options"
        :name="filterObj.name"
        :label="$t(filterObj.name)"
        :disabled="disabled"
        :hint="getTooltip(filterObj.name, filterValues[filterObj.name].name)"
        :value="filterValues[filterObj.name]"
        @select="onSelect"
        :valueLabel="$t(`${filterValues[filterObj.name].label}`)"
        :withTranslations="true"
      />
    </div>
  </div>
</template>
<script setup>
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import FilterSelect from '@/components/base/filters/FilterSelect.vue'
import FilterMultiselect from './FilterMultiselect.vue'
import { useRateInsights } from '@/components/rateInsights/useRateInsights.js'

const { t } = useI18n()
const props = defineProps({
  filters: Array,
  aggregation: Object,
  competitors: Array,
  channels: Array,
  marketRate: String,
  disabled: Boolean,
  selectedAggregation: Object,
  dateRateOptions: Array,
  selectedTimeRange: Object,
})

const emit = defineEmits(['setMarketRate', 'setAggregation', 'setChannelsIds', 'setCompetitorsIds', 'setTimeRange'])

const setMarketRate = value => {
  emit('setMarketRate', value)
}
const setAggregation = value => {
  emit('setAggregation', value)
}
const setChannelsIds = value => {
  emit('setChannelsIds', value)
}
const setCompetitorsIds = value => {
  emit('setCompetitorsIds', value)
}

const setTimeRange = value => {
  emit('setTimeRange', value)
}

const { setFilters } = useRateInsights()
const selectedCompetitors = ref(props.competitors)
const selectedChannels = ref(props.channels)
const filterValues = ref(
  Object.assign({}, ...props.filters.map(item => ({ [item.name]: item.options.filter(item => item.default)[0] })))
)
watch(
  () => props,
  newProps => {
    selectedChannels.value = newProps.channels
    selectedCompetitors.value = newProps.competitors
  },
  { deep: true, immediate: true }
)
setFilters(
  Object.assign({}, ...props.filters.map(item => ({ [item.name]: item.options.filter(item => item.default)[0] })))
)

const getTooltip = (name, value) => {
  if (name === 'time-range') {
    return t(value.name, { amount: value.amount })
  } else if (name === 'aggregation') {
    return (
      t('ri_group_time_frame') +
      ' ' +
      t(`aggregation_${value.name}`).charAt(0).toUpperCase() +
      t(`aggregation_${value.name}`).slice(1)
    )
  } else {
    return t(name)
  }
}

const onSelect = selected => {
  if (selected.name === 'aggregation') {
    setAggregation(selected.option)
  } else {
    if (selected.name === 'market_rates') {
      setMarketRate(selected.option.name)
    } else if (selected.name === 'time-range') {
      setTimeRange(selected.option)
    }
    filterValues.value[selected.name] = selected.option
    setFilters(filterValues.value)
  }
}

const onCompetitorsSelectChange = selected => {
  const allCompetitors = JSON.parse(JSON.stringify(selectedCompetitors.value))
  const index = allCompetitors.findIndex(item => item.id === selected.option.id)

  if (index < 0) {
    allCompetitors.splice(0, 0, selected.option)
    selectedCompetitors.value = allCompetitors
  } else {
    allCompetitors.splice(index, 1)
    selectedCompetitors.value = allCompetitors
  }
  let competitorsIds = allCompetitors.map(item => item.id)
  setCompetitorsIds(competitorsIds.toString())
}

const onChannelsSelectChange = selected => {
  const allChannels = JSON.parse(JSON.stringify(selectedChannels.value))
  const index = allChannels.findIndex(item => item.id === selected.option.id)

  if (index < 0) {
    allChannels.splice(0, 0, selected.option)
    selectedChannels.value = allChannels
  } else {
    allChannels.splice(index, 1)
    selectedChannels.value = allChannels
  }
  let channelsIds = allChannels.map(item => item.id)
  setChannelsIds(channelsIds.toString())
}

const onMultipleValuesSelect = selected => {
  if (selected.name === 'competitors') {
    onCompetitorsSelectChange(selected)
  } else if (selected.name === 'channels') {
    onChannelsSelectChange(selected)
  }
}
</script>
