<template>
  <BaseChart
    v-if="prettyVisits && getXAxisData && getSeriesData.length > 0"
    :container="'clicks'"
    :series="getSeriesData"
    :xaxis="getXAxisData"
    :yaxis="getYAxisData"
  />
</template>
<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import BaseChart from '@/components/rateConnect/performance/charts/BaseChart.vue'
import { addHoverDatapoint, addSeries } from '@/components/rateConnect/performance/charts/chartsUtils.js'

const { t } = useI18n()

const props = defineProps({
  categories: {
    type: [Array, Object],
    default: () => [],
  },
  campaignTypes: {
    type: [Array, Object],
    default: () => [],
  },
  tickPositions: {
    type: [Array, Object],
    default: () => [],
  },
  prettyVisits: {
    type: Object,
    default: () => {},
  },
  currency: {
    type: String,
    default: '',
  },
})

const getSeriesData = computed(() => {
  if (
    !props.prettyVisits?.totalImpressions ||
    !props.prettyVisits?.partnerClicks ||
    !props.prettyVisits?.cpc ||
    !props.prettyVisits?.clickThroughRate ||
    !props.prettyVisits?.unavailabilityScore
  ) {
    return []
  }
  if (
    props.prettyVisits?.totalImpressions?.length === 0 ||
    props.prettyVisits?.partnerClicks?.length === 0 ||
    props.prettyVisits?.cpc?.length === 0 ||
    props.prettyVisits?.clickThroughRate?.length === 0 ||
    props.prettyVisits?.unavailabilityScore?.length === 0
  ) {
    let arrayWithZeros = new Array(props.categories.length).fill(null)

    return [
      addHoverDatapoint(arrayWithZeros, t('rc_label_impressions'), 'totalImpressions'),
      addSeries(arrayWithZeros, t('rc_label_clicks'), 'partnerClicks', {}, props.currency),
      addHoverDatapoint(arrayWithZeros, t('rc_label_cpc'), 'cpc', {}, props.currency),
      addHoverDatapoint(arrayWithZeros, t('rc_label_ctr'), 'clickThroughRate'),
      addHoverDatapoint(arrayWithZeros, t('rc_label_unavailability'), 'unavailabilityScore', {
        warningThreshold: 0.3,
      }),
    ]
  } else {
    return [
      addHoverDatapoint(props.prettyVisits.totalImpressions, t('rc_label_impressions'), 'totalImpressions'),
      addSeries(props.prettyVisits.partnerClicks, t('rc_label_clicks'), 'partnerClicks', {}, props.currency),
      addHoverDatapoint(props.prettyVisits.cpc, t('rc_label_cpc'), 'cpc', {}, props.currency),
      addHoverDatapoint(props.prettyVisits.clickThroughRate, t('rc_label_ctr'), 'clickThroughRate'),
      addHoverDatapoint(props.prettyVisits.unavailabilityScore, t('rc_label_unavailability'), 'unavailabilityScore', {
        warningThreshold: 0.3,
      }),
    ]
  }
})
const getXAxisData = computed(() => {
  if (!props.categories || !props.campaignTypes || !props.tickPositions) return null
  return {
    categories: props.categories,
    campaignTypes: props.campaignTypes,
    tickPositions: props.tickPositions,
  }
})

const getYAxisData = computed(() => {
  return {
    max:
      props.prettyVisits?.partnerClicks.length > 0 ? Math.max(...Object.values(props.prettyVisits?.partnerClicks)) : 10,
    min: 0,
  }
})
</script>
