<template>
  <section>
    <div
      class="tw-flex tw-flex-col lg:tw-flex-row tw-gap-5 md:tw-gap-8 tw-w-full lg:tw-max-w-screen-landing md:tw-max-w-[638px] tw-py-6 lg:tw-px-0 tw-mx-auto lg:tw-py-8"
    >
      <div
        class="lg:tw-w-1/2 tw-flex tw-flex-col md:tw-flex-row lg:tw-flex-col tw-items-center tw-mx-2.5 xs:tw-mx-6 md:tw-mx-0"
      >
        <div class="tw-order-0 md:tw-order-1 lg:tw-order-0 md:tw-w-1/2 lg:tw-w-auto md:tw-ml-8 lg:tw-ml-0 tw-w-full">
          <img :src="RateConnectLogo" class="tw-w-[183px] md:tw-w-[203px] lg:tw-w-[280px] tw-mx-auto md:tw-mx-0" />
          <h3
            class="tw-font-bold lg:tw-text-3.5xl tw-text-2xl tw-mt-2 tw-text-center md:tw-text-left"
            v-html="$t('rc_mp_overview_header')"
          />
          <p class="tw-text-center md:tw-text-left tw-text-sm lg:tw-text-xl tw-mt-2 tw-mb-4 md:tw-mb-0">
            {{ $t('rc_mp_overview_subtext') }}
          </p>
        </div>
        <div
          class="tw-order-0 lg:tw-order-1 tw-rounded-xl tw-border tw-border-black line-shadow lg:tw-mt-6 tw-flex-grow md:tw-w-1/2 md:tw-max-w-[310px] lg:tw-max-w-full lg:tw-w-full tw-w-full tw-min-h-[170px] md:tw-max-h-full rc_image md:tw-self-stretch lg:tw-self-auto"
        />
      </div>

      <!-- Web Version -->
      <div ref="containerRef" class="lg:tw-w-1/2 tw-hidden md:tw-flex tw-flex-wrap tw-gap-4 lg:tw-gap-6 tw-self-start">
        <div
          v-for="item in cardsWeb"
          :key="item.id"
          ref="boxesRef"
          class="rc_item lg:tw-ml-[30px] tw-relative shadow tw-bg-white last:tw-mb-0 tw-rounded-xl tw-px-6 tw-py-5 tw-border tw-border-gray-300 lg:tw-pl-12"
        >
          <div class="tw-h-full">
            <div class="tw-flex tw-items-center">
              <div
                class="lg:tw-absolute tw-bg-decorative-yellow tw-min-w-[45px] tw-h-[45px] lg:tw-h-[60px] lg:tw-w-[60px] tw-flex tw-justify-center tw-items-center tw-rounded-full icon tw-mr-4 lg:tw-mr-0"
              >
                <img :src="item.icon" :alt="$t(item.title)" />
              </div>
              <h2 class="tw-font-bold lg:tw-text-xl">{{ $t(item.title) }}</h2>
            </div>
            <p class="tw-text-sm lg:tw-text-base tw-mt-2 lg:tw-mt-0">{{ $t(item?.text) }}</p>
          </div>
        </div>
      </div>

      <!-- Mobile Version -->
      <div
        ref="containerRef"
        class="md:tw-hidden tw-flex tw-flex-col md:tw-flex-row tw-w-vw tw-overflow-hidden carousel-container tw-ml-2.5 xs:tw-ml-6"
      >
        <div class="inner rc_carousel-mobile" ref="inner" :style="innerStyles">
          <div
            ref="boxesRef"
            v-for="item in cards"
            :key="item.id"
            :id="item.id"
            class="card shadow tw-bg-white tw-rounded-xl tw-px-6 tw-py-5 tw-border tw-border-gray-300"
          >
            <div class="tw-h-full">
              <div class="tw-flex tw-items-center">
                <div
                  class="tw-bg-decorative-yellow tw-min-w-[45px] tw-w-[45px] tw-h-[45px] tw-flex tw-justify-center tw-items-center tw-rounded-full tw-mr-4"
                >
                  <img :src="item.icon" :alt="$t(item.title)" />
                </div>
                <h2 class="tw-font-bold">{{ $t(item.title) }}</h2>
              </div>
              <p class="tw-text-sm tw-mt-2">{{ $t(item?.text) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { onMounted, onBeforeUnmount, ref, watch, computed, nextTick, onUpdated } from 'vue'
import { useStore } from 'vuex'
import TouchEvent from '@/utils/touchEvents.js'

const RateConnectLogo = new URL('/src/assets/img/landing/landing_rate_connect.svg', import.meta.url)

const RevenueIcon = new URL('/src/assets/img/rate-connect-landing/icons/revenue_icon.svg', import.meta.url)
const FiltersIcon = new URL('/src/assets/img/rate-connect-landing/icons/filters_icon.svg', import.meta.url)
const LocationIcon = new URL('/src/assets/img/rate-connect-landing/icons/location_icon.svg', import.meta.url)
const LockIcon = new URL('/src/assets/img/rate-connect-landing/icons/lock_icon.svg', import.meta.url)

const cardsWeb = [
  {
    id: 'sliderc_0',
    title: 'rc_mp_overview_benefit_1_header',
    text: 'rc_mp_overview_benefit_1_text',
    icon: FiltersIcon,
  },
  { id: 'sliderc_1', title: 'rc_mp_overview_benefit_2_header', text: 'rc_mp_overview_benefit_2_text', icon: LockIcon },
  {
    id: 'sliderc_2',
    title: 'rc_mp_overview_benefit_3_header',
    text: 'rc_mp_overview_benefit_3_text',
    icon: LocationIcon,
  },
  {
    id: 'sliderc_3',
    title: 'rc_mp_overview_benefit_4_header',
    text: 'rc_mp_overview_benefit_4_text',
    icon: RevenueIcon,
  },
]
const cards = [
  {
    id: 'sliderc_0',
    title: 'rc_mp_overview_benefit_1_header',
    text: 'rc_mp_overview_benefit_1_text',
    icon: FiltersIcon,
  },
  { id: 'sliderc_1', title: 'rc_mp_overview_benefit_2_header', text: 'rc_mp_overview_benefit_2_text', icon: LockIcon },
  {
    id: 'sliderc_2',
    title: 'rc_mp_overview_benefit_3_header',
    text: 'rc_mp_overview_benefit_3_text',
    icon: LocationIcon,
  },
  {
    id: 'sliderc_3',
    title: 'rc_mp_overview_benefit_4_header',
    text: 'rc_mp_overview_benefit_4_text',
    icon: RevenueIcon,
  },
]

const store = useStore()
const containerRef = ref(null)
const boxesRef = ref([])
const language = computed(() => store.state.locale.language)

const touchEvent = ref(null)
const current = ref(1)
const timer = ref('')
const inner = ref(null)
const innerStyles = ref({})
const step = ref('')
const transitioning = ref(false)

const setEqualHeight = () => {
  if (window.innerWidth > 767) {
    boxesRef.value.forEach(box => (box.style.height = 'auto'))
    return
  }

  const boxes = boxesRef.value
  if (!boxes || boxes.length === 0) return

  boxes.forEach(box => {
    box.style.height = 'auto'
  })

  let maxHeight = 0
  boxes.forEach(box => {
    if (box.offsetHeight > maxHeight) {
      maxHeight = box.offsetHeight
    }
  })

  boxes.forEach(box => {
    box.style.height = maxHeight + 'px'
  })
}

watch(language, () => {
  nextTick(() => {
    setEqualHeight()
  })
})

onUpdated(() => {
  setEqualHeight()
})

onMounted(async () => {
  await nextTick()
  setEqualHeight()
  setStep()
  autoplay()
  document.addEventListener('touchstart', event => {
    touchEvent.value = new TouchEvent(event)
  })

  document.addEventListener('touchend', handleSwipe)
  window.addEventListener('resize', setEqualHeight)
})

onBeforeUnmount(() => {
  clearInterval(timer)
  window.removeEventListener('resize', setEqualHeight)
  document.removeEventListener('touchend', handleSwipe)
})

const afterTransition = callback => {
  const listener = () => {
    callback()
    inner.value?.removeEventListener('transitionend', listener)
  }
  inner.value?.addEventListener('transitionend', listener)
}

const resetTranslate = () => {
  innerStyles.value = {
    transition: 'none',
    transform: 'translateX(0)',
  }
}

const setStep = () => {
  const innerWidth = inner.value.scrollWidth
  const totalCards = cards.length

  step.value = innerWidth / totalCards
}

const moveLeft = () => {
  innerStyles.value = {
    transition: 'transform 0.5s ease',
    transform: `translateX(-${step.value}px)`,
  }
}

const moveRight = () => {
  innerStyles.value = {
    transition: 'transform 0.5s ease',
    transform: `translateX(${step.value}px)`,
  }
}

const next = () => {
  if (transitioning.value) return
  transitioning.value = true
  moveLeft()
  afterTransition(() => {
    const firstElement = containerRef.value.querySelector('.rc_carousel-mobile')?.firstElementChild
    containerRef.value.querySelector('.rc_carousel-mobile').appendChild(firstElement)

    updateCurrent()
    resetTranslate()
    transitioning.value = false
  })
}

const prev = () => {
  if (transitioning.value) return
  transitioning.value = true
  moveRight()
  afterTransition(() => {
    const lastElement = containerRef.value.querySelector('.rc_carousel-mobile').lastElementChild
    containerRef.value.querySelector('.rc_carousel-mobile').prepend(lastElement)

    updateCurrent()
    resetTranslate()
    transitioning.value = false
  })
}

const updateCurrent = () => {
  const firstElementId = containerRef.value.querySelector('.rc_carousel-mobile').firstElementChild.id
  current.value = Number(firstElementId.split('_')[1])
}

const autoplay = () => {
  next()
  timer.value = setTimeout(autoplay, 5000)
}

const handleSwipe = event => {
  if (!touchEvent.value) {
    return
  }
  touchEvent.value.setEndEvent(event)

  if (touchEvent.value.isSwipeRight()) {
    prev()
  } else if (touchEvent.value.isSwipeLeft()) {
    next()
  }
  touchEvent.value = null
}
</script>
<style scoped>
.line-shadow {
  box-shadow: 3px 3px 0px 0px #000;
}
.shadow {
  box-shadow: 2px 2px 4px 0px #00000040;
}
.icon {
  @media (min-width: theme('screens.lg')) {
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.image-column {
  flex: 0 0 auto;
}
.rc_item {
  flex: calc(50% - 8px) 0 0;
  @media (min-width: theme('screens.lg')) {
    flex: calc(100% - 30px) 0 0;
  }
}

.rc_carousel-mobile {
  @apply tw-py-2;
  display: block;
  margin: auto;
  white-space: nowrap;
  transition: transform 1s;
  @media (max-width: theme('screens.md')) {
    width: 100%;
  }
}

.card {
  @apply tw-bg-white tw-min-w-[300px] xs:tw-min-w-[330px] tw-min-h-[100%]  tw-rounded-xl tw-relative;
  width: 330px;
  margin-right: 18px;
  display: inline-flex;
  white-space: wrap;
}
.rc_image {
  background-image: url('../../../assets/img/rate-connect-landing/rate_connect_benefits_img.jpg');
  background-size: cover;
  background-position: center top;
  width: 100%;
}
</style>
