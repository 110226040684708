<template>
  <div class="tw-py-8">
    <h2 class="tw-text-xl tw-font-bold tw-pb-2">{{ $t('rc_settings_provider_head') }}</h2>
    <div
      class="tw-relative tw-flex tw-items-center tw-flex-wrap md:tw-flex-nowrap tw-flex-row tw-w-full tw-text-base tw-text-gray-800 tw-text-left tw-border-0 tw-rounded-none tw-focus:outline-none"
    >
      <div class="tw-flex tw-items-start tw-flex-col tw-w-3/5 main-info md:tw-mb-0 tw-mb-5 tw-pr-3">
        <p
          class="tw-text-sm tw-p-0 tw-m-0 tw-mr-4"
          v-html="
            $t('/snippets/hgw/rc_settings_status_active_info.inc', {
              partnerName: currentRCPartner?.partnerName,
            })
          "
        />
      </div>
      <div class="tw-flex tw-w-1/5 tw-justify-start partner-logo">
        <img
          :src="`https://imgcy.trivago.com/e_trim,f_auto,q_auto,h_50/partnerlogos-s3/${currentPartner}`"
          :alt="`${currentRCPartner?.partnerName} Logo`"
        />
      </div>
      <div
        class="tw-flex tw-justify-end action-btn tw-items-center"
        :class="
          currentRCPartner?.partnerStatus !== 5 || currentRCPartner.connectionStatus !== 5 ? 'tw-w-2/5' : 'tw-w-1/5'
        "
      >
        <p
          v-if="currentRCPartner?.partnerStatus !== 5 || currentRCPartner.connectionStatus !== 5"
          class="tw-text-red-700 tw-text-base tw-mr-2"
        >
          {{ $t('rc_provider_status_inactive') }}
        </p>
        <BaseLinkButton
          v-if="
            (currentRCPartner?.partnerStatus !== 5 || currentRCPartner.connectionStatus !== 5) &&
            !shouldShowPartnerChangeButton
          "
          :href="supportCenterUrl"
          rel="noopener noreferrer"
          target="_blank"
          class="tw-font-bold tw-border tw-border-gray-700 hover:tw-bg-gray-700 tw-text-gray-800 tw-bg-white tw-block hover:tw-no-underline tw-text-gray-700 hover:tw-text-white tw-font-bold tw-px-9 tw-py-2 tw-text-sm tw-rounded-lg"
          >{{ $t('notification_action_contact_us') }}</BaseLinkButton
        >
        <GhostButton
          v-if="shouldShowPartnerChangeButton"
          :class="'tw-px-9 tw-py-2'"
          size="medium"
          @click="toggleConnectivityProviderChange"
          >{{ $t('rc_campaign_type_button') }}</GhostButton
        >
      </div>
    </div>
    <ConnectivityProviderChange
      v-if="isConnectivtyProviderChangeOpen"
      :currentPartner="currentRCPartner"
      :currentPartnerCampaigns="currentRCPartner?.canNetCpa ? ['CPC, CPA, netCPA'] : ['CPC', 'CPA']"
    />
    <div v-if="isConnectivtyProviderChangeOpen" class="tw-flex tw-justify-end tw-mt-4">
      <GhostButton :class="'tw-px-9 tw-py-2'" size="medium" @click="toggleConnectivityProviderChange">{{
        $t('rc_bookings_modal_cancel')
      }}</GhostButton>
    </div>
  </div>
</template>
<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

import BaseLinkButton from '@/components/base/buttons/BaseLinkButton.vue'
import ConnectivityProviderChange from '@/components/rateConnect/settings/ConnectivityProviderChange.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import { useCampaigns, usePartners, useStartUp } from '@/components/rateConnect/queries'
import { selectedAccommodationId } from '@/layouts/queries'

import { getSupportCenterUrl } from '@/utils/Utility.js'

const { partnersData } = usePartners(selectedAccommodationId)
const { currentCampaignType } = useStartUp(selectedAccommodationId)
const { currentPartner } = useCampaigns(selectedAccommodationId)
const currentRCPartner = computed(() => {
  return partnersData.value?.find(item => {
    return item.partnerId === currentPartner.value
  })
})
const selectablePartners = computed(() =>
  partnersData.value?.filter(
    item => item.partnerId !== currentPartner.value && item?.partnerStatus === 5 && item.connectionStatus === 5
  )
)
const store = useStore()

const isConnectivtyProviderChangeOpen = ref(false)

const supportCenterUrl = computed(() => getSupportCenterUrl(store.state.locale.language, 'contactUs'))

const toggleConnectivityProviderChange = () =>
  (isConnectivtyProviderChangeOpen.value = !isConnectivtyProviderChangeOpen.value)

const shouldShowPartnerChangeButton = computed(() => {
  if (currentCampaignType.value !== 'cpc' && !selectablePartners.value?.find(item => item.canNetCpa)) return false
  return selectablePartners.value?.length > 0
})
</script>
<style scoped>
.main-info {
  @media (max-width: theme('screens.md')) {
    flex: 0 0 100%;
  }
}
.partner-logo,
.action-btn {
  @media (max-width: theme('screens.md')) {
    flex: 0 0 50%;
  }
}
</style>
