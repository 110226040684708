<template>
  <section class="tw-flex tw-flex-col tw-my-5 tw-max-w-[620px] tw-mx-auto tw-w-full">
    <div class="tw-my-[40px]">
      <div class="tw-mb-8">
        <p class="tw-font-bold tw-text-xl">{{ $t('manage_channels') }}</p>
        <p class="tw-text-gray-700">
          {{ $t('channels_search_title') }}
        </p>
      </div>
      <SelectChannel
        v-if="!isBookingSitesLoading && !isSelectedChannelsLoading"
        :selectedValues="newChannels"
        :options="bookingSites"
        @onSelect="value => onSelect(value)"
      />

      <SelectedChannels
        v-if="!isBookingSitesLoading && !isSelectedChannelsLoading"
        :selected="newChannels"
        @onSelect="value => onSelect(value)"
      />
      <CardLoadingSkeleton v-else height="400px" />
      <div class="tw-flex tw-w-full tw-mt-4 tw-justify-end">
        <GhostButton @click="() => router.back()" @keydown.enter="() => router.back()" class="tw-mr-2">
          {{ $t('assign_cancel_cta') }}
        </GhostButton>
        <MainButton
          class="tw-w-full md:tw-w-auto"
          :disabled="newChannels.length === 0"
          @click="() => saveBookingSites()"
          @keydown.enter="() => saveBookingSites()"
        >
          <span v-if="!buttonClicked">{{ $t('save') }}</span>
          <span v-if="buttonClicked">
            <RCInlineLoader color="#fff" />
          </span>
        </MainButton>
      </div>
    </div>
  </section>
</template>
<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import {
  useSelectedBookingSites,
  useSelectedBookingSitesUpdateMutation,
  useAvailableBookingSites,
} from '@/components/rateInsights/queries'

import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import SelectedChannels from '@/components/rateInsights/channels/SelectedChannels.vue'
import SelectChannel from '@/components/rateInsights/channels/SelectChannel.vue'
import CardLoadingSkeleton from '@/components/loadingSkeletons/CardLoadingSkeleton.vue'

import { selectedAccommodationId } from '@/layouts/queries'

const router = useRouter()
const { bookingSites, isBookingSitesLoading } = useAvailableBookingSites(selectedAccommodationId)
const { selectedChannels, isSelectedChannelsLoading } = useSelectedBookingSites(selectedAccommodationId)
const { bookingSitesUpdate } = useSelectedBookingSitesUpdateMutation()
const buttonClicked = ref(false)

const selectedChannelsArr = JSON.parse(JSON.stringify(selectedChannels.value))
const newChannels = ref(selectedChannelsArr)

const onSelect = option => {
  const allChannels = JSON.parse(JSON.stringify(newChannels.value))

  const index = allChannels.findIndex(item => item.id === option.id)
  if (index < 0) {
    allChannels.splice(0, 0, option)
    newChannels.value = allChannels
  } else {
    allChannels.splice(index, 1)
    newChannels.value = allChannels
  }
}

const saveBookingSites = () => {
  const channels = newChannels.value.map(item => item.id)
  buttonClicked.value = true
  bookingSitesUpdate(
    { accommodationId: selectedAccommodationId.value, bookingSites: channels },
    {
      onSuccess: data => {
        buttonClicked.value = false
        if (data.status >= 200 && data.status <= 210) {
          router.push('/rate-insights/view')
        }
      },
      onError: () => (buttonClicked.value = false),
    }
  )
}
</script>
