<template>
  <section>
    <div
      class="tw-flex tw-flex-col md:tw-flex-row tw-w-full lg:tw-max-w-screen-landing md:tw-max-w-[638px] sm:tw-max-w-[480px] tw-max-w-[300px] md:tw-py-4 lg:tw-px-0 tw-mx-auto tw-py-4"
    >
      <div class="tw-flex tw-flex-col md:tw-flex-row tw-w-full tw-gap-4 tw-items-center">
        <div class="tw-flex tw-justify-center tw-items-center">
          <img :src="LandingBanner" alt="trivago" class="tw-w-[96px] md:tw-min-w-[135px] lg:tw-min-w-[170px]" />
        </div>
        <p
          class="tw-text-center md:tw-text-left tw-text-base lg:tw-text-xl tw-mt-2 md:tw-mt-0"
          v-html="$t('tbs_mp_studio_description')"
        />
      </div>
    </div>
  </section>
</template>
<script setup>
const LandingBanner = new URL('/src/assets/img/landing/landing_banner.svg', import.meta.url)
</script>
