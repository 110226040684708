<template>
  <section>
    <div
      class="tw-flex tw-flex-col md:tw-flex-row tw-w-full lg:tw-max-w-screen-landing md:tw-max-w-[638px] sm:tw-max-w-[480px] tw-max-w-[300px] md:tw-py-4 lg:tw-px-0 tw-mx-auto tw-py-4"
    >
      <div class="tw-flex tw-flex-col md:tw-flex-row tw-w-full tw-gap-4 md:tw-gap-6 lg:tw-gap-11 md:tw-items-end">
        <div class="tw-flex tw-justify-center tw-items-center">
          <img :src="StudioPromo" alt="trivago" class="tw-w-[150px] md:tw-min-w-[145px] lg:tw-min-w-[235px]" />
        </div>
        <div class="tw-flex tw-flex-col tw-items-center md:tw-items-start">
          <div
            class="tw-bg-red-700 tw-text-white tw-text-xs lg:tw-text-sm tw-font-bold tw-leading-0 tw-px-2 tw-py-1 tw-rounded-tl-xl tw-rounded-br-xl tw-mb-2 tw-uppercase"
          >
            {{ $t('best_value_tag') }}
          </div>
          <p
            class="tw-text-center md:tw-text-left tw-text-sm md:tw-text-base lg:tw-text-xl tw-mb-4"
            v-html="$t('studio_plus_mp_key_message')"
          />
          <MainButton @click="onLoginClick" class="lg:tw-flex tw-hidden">{{ $t('studio_plus_start_cta') }}</MainButton>
          <MainButton @click="onLoginClick" size="medium" class="tw-flex lg:tw-hidden">{{
            $t('studio_plus_start_cta')
          }}</MainButton>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import MainButton from '@/components/base/buttons/MainButton.vue'

const StudioPromo = new URL('/src/assets/img/studio-plus-landing/studio_plus_promo.png', import.meta.url)

const emit = defineEmits(['onLoginClick'])
const onLoginClick = e => emit('onLoginClick', e.target.value)
</script>
