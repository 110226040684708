<template>
  <div ref="root">
    <UiTooltip theme="material" tooltip-position="top" :label="hint">
      <div
        @click="showDropdown"
        class="tw-relative tw-cursor-pointer tw-border tw-rounded-lg tw-flex tw-box-size-44 tw-items-center tw-px-3 tw-py-1 tw-pr-8 tw-h-[41px]"
        :class="getFieldClass(show, disabled)"
      >
        <span class="tw-truncate tw-absolute tw-top-[3px] tw-text-xs tw-font-semibold">{{ $t(label) }}</span>
        <span class="tw-truncate tw-absolute tw-bottom-[3px] tw-text-sm tw-max-w-[120px]">{{ valueLabel }}</span>

        <BaseIcon
          icon-name="chevron-right"
          :height="24"
          :width="24"
          :viewbox-height="24"
          :viewbox-width="24"
          class="tw-absolute"
          :class="show ? 'arrow-dropdown-open' : 'arrow-dropdown'"
        >
          <ChevronRight />
        </BaseIcon>
      </div>
    </UiTooltip>
    <div class="tw-relative tw-top-[5px]" v-if="show && !disabled">
      <div
        class="tw-absolute tw-min-h-[50px] tw-min-w-full tw-w-auto tw-rounded tw-bg-white dropdown-card tw-z-[20]"
        :class="'tw-left-0'"
      >
        <ul>
          <li
            v-for="(option, index) in options"
            :key="index"
            @click="onSelect({ option, name })"
            class="tw-cursor-pointer hover:tw-bg-blue-100 hover:tw-text-blue-700 tw-p-4 nowrap tw-text-sm"
            :class="getOptionClass(option.id === value.id, index)"
          >
            {{ withTranslations ? $t(option.label) : option.label }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, onDeactivated } from 'vue'
import BaseIcon from '@/components/BaseIcon.vue'
import ChevronRight from '@/components/icons/ChevronRight.vue'
import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'

const props = defineProps({
  options: Array,
  name: String,
  value: Object,
  disabled: Boolean,
  hint: String,
  valueLabel: String,
  label: String,
  withTranslations: {
    type: Boolean,
    default: false,
  },
})

const root = ref(null)
const show = ref(false)
const showDropdown = () => {
  if (!props.disabled) {
    show.value = !show.value
  }
}
const emit = defineEmits(['select'])
const onSelect = ({ option, name }) => {
  emit('select', { option, name })
  showDropdown()
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})
onDeactivated(() => {
  document.removeEventListener('click', handleClickOutside)
})
const handleClickOutside = evt => {
  if (root?.value !== null && !root?.value?.contains(evt.target)) {
    show.value = false
  }
}

const getFieldClass = (show, disabled) => {
  const classes = []
  if (disabled) {
    classes.push('tw-border-gray-300 tw-text-gray-300')
  }
  if (show) {
    classes.push('tw-border-blue-700')
  }
  if (!show && !disabled) {
    classes.push('tw-border-gray-500')
  }
  return classes.join(' ')
}

const getOptionClass = (selected, position) => {
  const classes = []
  if (position === 0) {
    classes.push('tw-rounded-t')
  } else if (props.options.length - 1 === position) {
    classes.push('tw-rounded-b')
  }
  if (selected) {
    classes.push('tw-text-blue-700 tw-bg-blue-100')
  }

  return classes.join(' ')
}
</script>
<style scoped>
.arrow-dropdown {
  top: 50%;
  transform: translateY(-45%) rotate(90deg);
  right: 6px;
  pointer-events: none;
}
.arrow-dropdown-open {
  top: 50%;
  transform: translateY(-45%) rotate(270deg);
  right: 6px;
  pointer-events: none;
}
.dropdown-card {
  box-shadow:
    0 2px 4px 0 rgba(63, 87, 189, 0.1),
    0 2px 10px 0 rgba(108, 112, 122, 0.15);
}
.nowrap {
  text-wrap: nowrap;
}
</style>
