<template>
  <div class="tw-mt-6">
    <p class="tw-font-bold tw-mb-3">{{ $t('confirm_cancellation_subheader') }}</p>
    <p class="tw-mb-6" v-if="type === 'PLUS'" v-html="$t('confirm_cancellation_subtext_1')" />
    <p class="tw-mb-4">
      {{ $t('confirm_cancellation_subtext_2') }}
    </p>
    <ul class="tw-ml-6 tw-mb-6">
      <li
        class="tw-list-disc tw-py-1 first:tw-pt-0 last:tw-pb-0 tw-leading-5"
        v-for="(item, index) in benefits"
        :key="index"
      >
        {{ $t(item) }}
      </li>
    </ul>
    <p>{{ $t('confirm_cancellation_discount_disclaimer') }}</p>
  </div>
</template>
<script setup>
import { onUnmounted, onMounted, nextTick } from 'vue'
import appAnalytics from '@/utils/tracking'

const props = defineProps({
  accommodationId: Number,
  type: String,
})
const benefits = ['subscription_benefits_2', 'subscription_benefits_3', 'subscription_benefits_4']

const onBookingLinkClick = event => {
  let from = (event.target || event.srcElement).closest('a')
  if (from?.href.includes('outlook.office365.com')) {
    appAnalytics.track(appAnalytics.events.SP_BOOK_APPOINTMENT, {
      accommodationId: props.accommodationId,
    })
  }
}
onMounted(() => {
  nextTick(() => {
    window.addEventListener('click', onBookingLinkClick)
  })
})

onUnmounted(() => {
  window.removeEventListener('click', onBookingLinkClick)
})
</script>
<style scoped>
p :deep(a) {
  text-decoration: underline;
}
</style>
