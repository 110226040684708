<template>
  <dialog
    ref="changeCampaignModalRef"
    class="tw-drop-shadow-md tw-z-40 sm:tw-rounded-[20px] tw-m-0 sm:tw-m-auto tw-max-w-full sm:tw-max-w-[revert] tw-max-h-[100dvh] tw-max-h-[100vh] tw-min-h-[100dvh] tw-min-h-[100vh] sm:tw-min-h-[revert] sm:tw-max-h-[revert]"
  >
    <div class="tw-text-right">
      <button @click="onCloseClick">
        <BaseIcon icon-name="cross" :height="24" :width="24" :viewbox-height="24" :viewbox-width="24">
          <Cross />
        </BaseIcon>
      </button>
    </div>

    <div v-if="currentStep === 1" class="md:tw-px-5 tw-pb-4">
      <SetupSelectCampaignType
        isChangingCampaign
        :selectedCampaignType="newSelectedCampaignType"
        :eligibleTypeOfCPACampaign="eligibleTypeOfCPACampaign"
        @onSelectedCampaignTypeChange="onSelectedCampaignTypeChange"
        :currentlyActiveCampaignType="currentCampaignType"
      />
    </div>
    <div v-else-if="currentStep === 2 && isSelectedCampaignTypeCPA" class="sm:tw-px-5 sm:tw-py-4">
      <CPACampaignSetup
        isChangingCampaign
        :eligibleCommissionLevels="selectableCommissionLevels"
        :selectedCampaignType="selectedCampaignType"
        :selectedCommissionLevel="selectedCommissionLevel"
        @onSelectedCommissionChange="onSelectedCommissionChange"
      />
    </div>
    <CPCCampaignSetup
      v-else-if="currentStep === 2 && !isSelectedCampaignTypeCPA"
      @onBudgetChange="onBudgetChange"
      isChangingCampaign
      :newPartner="selectedNewPartner"
    />
    <div v-else-if="currentStep === 3 && isSelectedCampaignTypeCPA" class="sm:tw-px-5 sm:tw-pb-4">
      <ChangeCampaignCPAConfirmation :isNetCPA="selectedCampaignType === 'netCpa'" />
    </div>
    <div v-else-if="currentStep === 3 && !isSelectedCampaignTypeCPA" class="sm:tw-px-5 sm:tw-pb-4">
      <ChangeCampaignCPCConfirmation />
    </div>
    <div class="tw-hidden md:tw-block tw-mt-4">
      <hr />
      <div class="tw-px-5 tw-pb-4 tw-pt-8 tw-flex tw-justify-between tw-items-center">
        <GhostButton @click="onBackClick">{{
          currentStep === 1 ? $t('rc_campaign_type_change_cancel_button') : $t('tbs_modal_back')
        }}</GhostButton>
        <Stepper
          class="tw-w-full md:tw-w-auto tw-justify-center tw-items-center"
          type="v2"
          :with-text="true"
          :steps="3"
          :value="currentStep - 1"
        />
        <MainButton
          @click="onNextClick"
          :disabled="!selectedCampaignType || currentCampaignType === newSelectedCampaignType"
        >
          {{
            currentStep === 3
              ? $t('rc_campaign_type_change_save_button')
              : $t('rc_campaign_type_change_continue_button')
          }}
        </MainButton>
      </div>
    </div>
    <div class="md:tw-hidden tw-flex tw-flex-col tw-gap-2 tw-my-4">
      <MainButton
        @click="onNextClick"
        :disabled="!selectedCampaignType || currentCampaignType === newSelectedCampaignType"
        >{{
          currentStep === 3 ? $t('rc_campaign_type_change_save_button') : $t('rc_campaign_type_change_continue_button')
        }}</MainButton
      >
      <BaseButton @click="onBackClick" class="tw-text-gray-700 tw-font-bold">{{
        currentStep === 1 ? $t('rc_campaign_type_change_cancel_button') : $t('tbs_modal_back')
      }}</BaseButton>
    </div>
    <div
      class="tw-fixed tw-h-full tw-bg-white tw-opacity-80 tw-z-50 tw-inset-0"
      v-show="isStartUpFetching || isSubmittingChangedCampaign"
    >
      <LaunchpadLoader class="tw-h-full tw-flex tw-items-center tw-justify-center" />
    </div>
  </dialog>
</template>

<script setup>
import { ref, computed, onUpdated, onMounted } from 'vue'

import BaseButton from '@/components/base/buttons/BaseButton.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import Stepper from '@/components/base/Stepper.vue'
import SetupSelectCampaignType from '@/components/rateConnect/setup/SetupSelectCampaignType.vue'
import LaunchpadLoader from '@/components/dashboard/common/LaunchpadLoader.vue'
import CPACampaignSetup from '@/components/rateConnect/setup/CPACampaignSetup.vue'
import CPCCampaignSetup from '@/components/rateConnect/setup/CPCCampaignSetup.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import Cross from '@/components/icons/Cross.vue'
import { useStartUp, useCampaignsMutation, useCommissionLevels } from '@/components/rateConnect/queries'
import { useChangeCampaign } from '@/components/rateConnect/settings/wizards/useChangeCampaign'
import ChangeCampaignCPAConfirmation from '@/components/rateConnect/settings/wizards/ChangeCampaignCPAConfirmation.vue'
import ChangeCampaignCPCConfirmation from '@/components/rateConnect/settings/wizards/ChangeCampaignCPCConfirmation.vue'
import useToastNotifications from '@/components/notifications/useToastNotifications'
import { selectedAccommodationId } from '@/layouts/queries'
import appAnalytics from '@/utils/tracking'

const {
  currentStep,
  resetFlow,
  selectedCampaignType,
  selectedCommissionLevel,
  isSelectedCampaignTypeCPA,
  selectedBudget,
  selectedNewPartner,
  source,
} = useChangeCampaign()
const {
  isFetching: isStartUpFetching,
  isItemNetCPAEligible,
  currentCampaignType,
  hotelierData,
} = useStartUp(selectedAccommodationId)
const { eligibleCommissionLevels } = useCommissionLevels()
const { updateCampaignType } = useCampaignsMutation()
const { displayNotification } = useToastNotifications()

const emit = defineEmits(['onSubmitCampaignClick'])

const changeCampaignModalRef = ref(null)
const isSubmittingChangedCampaign = ref(false)

const newSelectedCampaignType = computed(() => selectedCampaignType.value ?? currentCampaignType.value)
const eligibleTypeOfCPACampaign = computed(() => {
  if (selectedNewPartner.value) return selectedNewPartner.value.canNetCpa ? 'netCpa' : currentCampaignType.value
  else return isItemNetCPAEligible.value ? 'netCpa' : currentCampaignType.value
})
const selectableCommissionLevels = computed(() => {
  if (selectedNewPartner.value) {
    return eligibleTypeOfCPACampaign.value === 'netCpa'
      ? hotelierData.value?.cpaCommissionLevels?.netCpa
      : hotelierData.value?.cpaCommissionLevels?.cpa
  } else {
    return eligibleCommissionLevels.value
  }
})

const onSelectedCampaignTypeChange = type => (selectedCampaignType.value = type)
const onSelectedCommissionChange = level => (selectedCommissionLevel.value = level)
const onBudgetChange = budget => (selectedBudget.value = budget)

onMounted(() => {
  onSelectedCampaignTypeChange('netCpa')
})

onUpdated(() => {
  if (!selectedCampaignType.value && currentCampaignType.value === 'cpc') {
    onSelectedCampaignTypeChange('netCpa')
  }
})

const onNextClick = () => {
  if (currentStep.value === 1 && isSelectedCampaignTypeCPA.value) selectedCommissionLevel.value = 18
  if (currentStep.value < 3) currentStep.value += 1
  else if (currentStep.value === 3) {
    if (selectedNewPartner.value) {
      emit('onSubmitCampaignClick')
      changeCampaignModalRef.value?.close()
    } else {
      submitUpdatedCampaign()
    }
  }
}
const onBackClick = () => {
  if (currentStep.value === 1) onCloseClick()
  else currentStep.value -= 1
}

const submitUpdatedCampaign = () => {
  isSubmittingChangedCampaign.value = true
  let updatedCampaignDetails
  let successMessage
  if (selectedCampaignType.value === 'netCpa') {
    updatedCampaignDetails = { netCpa: selectedCommissionLevel.value }
    successMessage = 'rc_change_campaign_type_pay_per_stay_success_message'
  } else if (selectedCampaignType.value === 'cpc') {
    updatedCampaignDetails = { budget: selectedBudget.value * 100 }
    successMessage = 'rc_change_campaign_type_CPC_success_message'
  }
  updateCampaignType(
    {
      accommodationId: selectedAccommodationId.value,
      campaignType: selectedCampaignType.value,
      body: updatedCampaignDetails,
    },
    {
      onSuccess: () => {
        displayNotification({
          message: successMessage,
          isTranslationKey: true,
          type: 'success',
        })
        onCloseClick()
        if (source === 'pauseFlow') {
          appAnalytics.track(appAnalytics.events.RC_PAUSE_CAMPAIGN_TYPE_CHANGE_SUCCESS, {
            accommodationId: selectedAccommodationId.value,
            campaignType: currentCampaignType.value,
            isNetCPAEligible: isItemNetCPAEligible.value,
          })
        }
      },
      onError: () => {
        displayNotification({
          message: 'save_error',
          isTranslationKey: true,
          type: 'error',
        })
        onCloseClick()
      },
    }
  )
}

const showModal = () => {
  changeCampaignModalRef.value?.showModal()
}

const onCloseClick = () => {
  resetFlow()
  isSubmittingChangedCampaign.value = false
  changeCampaignModalRef.value?.close()
}

defineExpose({
  showModal,
})
</script>
