<template>
  <div :id="container"></div>
</template>

<script setup>
import { nextTick, watch } from 'vue'
import Highcharts from 'highcharts'
import * as Sentry from '@sentry/vue'

import { splineChartOptions } from '@/components/rateConnect/performance/charts/chartsUtils.js'

const props = defineProps({
  container: {
    type: String,
    required: true,
  },
  series: {
    type: Array,
    default() {
      return []
    },
  },
  xaxis: {
    type: Object,
    default() {
      return {}
    },
  },
  yaxis: {
    type: Object,
    default() {
      return {}
    },
  },
  options: {
    type: Object,
    default: () => splineChartOptions,
  },
})
const drawChart = (container, data, xaxis, yaxis, options) => {
  if (!container || !data) return
  try {
    // without nextTick, highcharts may use the container before it exists in the DOM, it is important!
    nextTick(() => {
      // if for some reason the container is not yet mounted, go back.
      if (!document.getElementById(container)) return
      Highcharts.chart(container, {
        ...options,
        series: data,
        plotOptions: {
          series: {
            connectNulls: true,
          },
        },
        xAxis: {
          ...xaxis,
          lineColor: '#C4E3F4',
          tickColor: '#C4E3F4',
          labels: { style: { color: '#6C707A', fontSize: '14px' } },
        },
        yAxis: {
          ...yaxis,
          gridLineColor: '#C4E3F4',
          title: { text: null },
          labels: { style: { color: '#368CDC', fontSize: '14px' } },
        },
      })
    })
  } catch (error) {
    Sentry.captureException(error)
  }
}

watch(
  () => props,
  newProps => {
    if (newProps.series?.length > 0 && newProps.container) {
      drawChart(newProps.container, newProps.series, newProps.xaxis, newProps.yaxis, newProps.options)
    }
  },
  { deep: true, immediate: true }
)
</script>
