<template>
  <section>
    <div
      class="tw-flex tw-flex-col tw-gap-8 md:tw-gap-8 tw-w-full lg:tw-max-w-screen-landing md:tw-max-w-[638px] sm:tw-max-w-[480px] tw-max-w-[300px] tw-py-6 lg:tw-px-0 tw-mx-auto lg:tw-py-8"
    >
      <div
        class="tw-flex tw-flex-col-reverse md:tw-flex-row md:tw-justify-between tw-items-center md:tw-items-end tw-mb-4 md:tw-mb-0"
      >
        <div class="tw-flex tw-flex-col tw-items-center md:tw-items-start">
          <div
            class="tw-bg-orange-600 tw-text-white tw-font-bold tw-text-xs tw-px-3 tw-py-1 tw-rounded-tl-[12px] tw-rounded-br-[12px] tw-mb-3"
          >
            {{ $t('tbs_mp_rc_tag') }}
          </div>
          <p class="tw-text-xl md:tw-text-2xl tw-font-bold tw-text-center md:tw-text-left">
            {{ $t('rc_mp_steps_banner') }}
          </p>
        </div>
        <img :src="rate_connect_img" alt="Rate Connect" class="tw-h-[66px] md:tw-h-[71px] tw-mb-2 md:tw-mb-0 tw-ml-4" />
      </div>

      <div class="tw-flex tw-flex-col md:tw-gap-y-6 tw-gap-y-8">
        <div
          v-for="card in cards"
          :key="card.id"
          :id="card.id"
          class="tw-flex tw-flex-col md:tw-flex-row tw-bg-white tw-rounded-xl tw-border tw-border-blue-700 tw-p-6 tw-pt-8 md:tw-pt-6 md:tw-gap-x-6 tw-items-center tw-relative"
        >
          <img :src="card.icon" :alt="$t(card.title)" class="tw-absolute md:tw-static tw-top-[-22px]" />
          <div>
            <h3 class="tw-text-center md:tw-text-left tw-font-bold md:tw-text-xl tw-mb-1">{{ $t(card.title) }}</h3>
            <p class="tw-text-center md:tw-text-left md:tw-text-xl">{{ $t(card.text) }}</p>
          </div>
        </div>
      </div>
      <div class="tw-justify-end tw-w-full tw-hidden lg:tw-flex">
        <MainButton @click="onLoginClick">{{ $t('rc_mp_get_started') }}</MainButton>
      </div>
      <div class="tw-justify-center md:tw-justify-end tw-w-full lg:tw-hidden tw-flex">
        <MainButton size="medium" @click="onLoginClick">{{ $t('rc_mp_get_started') }}</MainButton>
      </div>
    </div>
  </section>
</template>
<script setup>
import MainButton from '@/components/base/buttons/MainButton.vue'

const rate_connect_img = new URL('/src/assets/img/rate-connect-landing/rate_connect_start.svg', import.meta.url)

const connectivity_provider_icon = new URL(
  '/src/assets/img/rate-connect-landing/icons/connectivity_provider.svg',
  import.meta.url
)
const business_studio_account_icon = new URL(
  '/src/assets/img/rate-connect-landing/icons/business_studio_account.svg',
  import.meta.url
)
const commission_level_icon = new URL(
  '/src/assets/img/rate-connect-landing/icons/commission_level.svg',
  import.meta.url
)

const payment_method_icon = new URL('/src/assets/img/rate-connect-landing/icons/payment_method.svg', import.meta.url)

const cards = [
  {
    id: 'card_1',
    icon: connectivity_provider_icon,
    title: 'rc_lp_step_1_header',
    text: 'rc_lp_step_1_subtext',
  },
  {
    id: 'card_2',
    icon: business_studio_account_icon,
    title: 'rc_lp_step_2_header',
    text: 'rc_lp_step_2_subtext',
  },
  {
    id: 'card_3',
    icon: commission_level_icon,
    title: 'rc_lp_step_3_header',
    text: 'rc_lp_step_3_subtext',
  },
  {
    id: 'card_4',
    icon: payment_method_icon,
    title: 'rc_lp_step_4_header',
    text: 'rc_lp_step_4_subtext',
  },
]

const emit = defineEmits(['onLoginClick'])
const onLoginClick = e => emit('onLoginClick', e.target.value)
</script>
