<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M9.5875 1.5C10.075 1.5 10.495 1.815 10.645 2.25C10.69 2.37 10.7125 2.49 10.7125 2.625C10.7125 2.76 10.69 2.88 10.645 3C10.495 3.435 10.075 3.75 9.5875 3.75C9.1 3.75 8.68 3.435 8.53 3C8.485 2.88 8.4625 2.76 8.4625 2.625C8.4625 2.49 8.485 2.37 8.53 2.25C8.68 1.815 9.1 1.5 9.5875 1.5ZM0.6475 3H7.75C7.9225 3.855 8.68 4.5 9.5875 4.5C10.495 4.5 11.2525 3.855 11.425 3H13.3525C13.57 3 13.75 2.835 13.75 2.625C13.75 2.415 13.57 2.25 13.3525 2.25H11.425C11.2525 1.395 10.495 0.75 9.5875 0.75C8.68 0.75 7.9225 1.395 7.75 2.25H0.6475C0.43 2.25 0.25 2.415 0.25 2.625C0.25 2.835 0.43 3 0.6475 3Z"
    fill="#171717"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.355 9.75C3.31 9.63 3.2875 9.51 3.2875 9.375C3.2875 9.24 3.31 9.12 3.355 9C3.505 8.565 3.925 8.25 4.4125 8.25C4.9 8.25 5.32 8.565 5.47 9C5.515 9.12 5.5375 9.24 5.5375 9.375C5.5375 9.51 5.515 9.63 5.47 9.75C5.32 10.185 4.9 10.5 4.4125 10.5C3.925 10.5 3.505 10.185 3.355 9.75ZM0.25 9.375C0.25 9.585 0.43075 9.75 0.6475 9.75H2.575C2.7475 10.605 3.505 11.25 4.4125 11.25C5.32 11.25 6.0775 10.605 6.25 9.75H13.3525C13.57 9.75 13.75 9.585 13.75 9.375C13.75 9.165 13.57 9 13.3525 9H6.25C6.0775 8.145 5.32 7.5 4.4125 7.5C3.505 7.5 2.7475 8.145 2.575 9H0.6475C0.43075 9 0.25 9.165 0.25 9.375Z"
    fill="#171717"
  />
</template>
