<template>
  <section>
    <div
      ref="heroContainerRef"
      class="tw-flex tw-w-full lg:tw-max-w-screen-landing md:tw-max-w-[638px] sm:tw-max-w-[480px] tw-max-w-[300px] tw-py-6 tw-mx-auto lg:tw-h-[450px] md:tw-h-[270px] tw-h-[430px] tw-overflow-hidden"
    >
      <div
        class="tw-flex tw-items-start md:tw-items-center tw-relative tw-flex-col md:tw-flex-row md:tw-justify-end md:tw-justify-between tw-w-full tw-gap-y-4 md:tw-gap-y-0 md:tw-gap-4 lg:tw-gap-8"
      >
        <div
          ref="textContainerRef"
          class="tw-flex tw-flex-col lg:tw-w-1/2 md:tw-w-1/2 md:tw-max-w-1/2 tw-max-w-full tw-w-full tw-items-center md:tw-items-start"
        >
          <h1
            class="lg:tw-text-3.5xl md:tw-text-1xl tw-text-1xl tw-font-bold tw-mb-2 md:tw-mb-4 md:tw-text-left tw-text-center"
            v-html="$t('studio_plus_mp_header')"
          />
          <p class="tw-text-xs md:tw-text-sm lg:tw-text-lg md:tw-text-left tw-text-center">
            {{ $t('studio_plus_mp_subtext') }}
          </p>
          <MainButton @click="onLoginClick" class="lg:tw-flex tw-hidden tw-mt-10">{{
            $t('business_studio_plus_cta')
          }}</MainButton>
          <MainButton @click="onLoginClick" size="medium" class="tw-flex lg:tw-hidden tw-mt-4">{{
            $t('business_studio_plus_cta')
          }}</MainButton>
        </div>
        <div
          class="tw-flex tw-w-auto lg:tw-min-w-[560px] md:tw-min-w-[311px] tw-min-w-[250px] tw-justify-end tw-bottom-0 md:tw-right-0 tw-h-[137px] md:tw-h-[172px] lg:tw-h-[310px] tw-mx-auto"
        >
          <img :src="heroImage" alt="Business Studio+" class="md:tw-h-[172px] lg:tw-h-[310px] tw-h-[137px]" />
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import MainButton from '@/components/base/buttons/MainButton.vue'
import { computed, nextTick, onMounted, onUpdated, ref, watch } from 'vue'
import { useStore } from 'vuex'

const heroImage = new URL('/src/assets/img/studio-plus-landing/plus_hero_banner.png', import.meta.url)

const store = useStore()
const language = computed(() => store.state.locale.language)
const textContainerRef = ref(null)
const heroContainerRef = ref(null)

const emit = defineEmits(['onLoginClick'])
const onLoginClick = e => emit('onLoginClick', e.target.value)

const setEqualHeight = async () => {
  if (window.innerWidth > 767) {
    textContainerRef.value.style.height = 'auto'
    return
  }
  const box = textContainerRef.value
  if (!box || !heroContainerRef.value) return

  box.style.height = 'auto'

  await nextTick()

  heroContainerRef.value.style.minHeight = box.offsetHeight + 68 + 137 + 'px'
  heroContainerRef.value.style.height = box.offsetHeight + 68 + 137 + 'px'
}

watch(language, () => {
  nextTick(() => {
    setEqualHeight()
  })
})

onUpdated(() => setEqualHeight())

onMounted(() => {
  setEqualHeight()
})
</script>
