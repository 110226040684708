<template>
  <BaseCard>
    <div
      v-if="accommodationInfo !== null && !lodgingTypesLoading && !hotelChainsLoading && !countryCodesLoading"
      class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between"
    >
      <div
        class="tw-mr-4 tw-h-full tw-w-full md:tw-h-[162px] md:tw-max-w-[162px] tw-overflow-hidden tw-rounded-lg tw-mr-0 md:tw-mr-3"
      >
        <img
          v-if="accommodationInfo.mainImageUrls"
          class="tw-rounded-lg tw-w-auto tw-h-full tw-object-cover tw-m-auto"
          :src="mainImage"
          :alt="accommodationInfo.name"
        />
        <div v-else class="tw-bg-gray-200 tw-h-full tw-w-full tw-flex tw-items-center tw-justify-center">
          <BaseIcon icon-name="image" :height="24" :width="24">
            <ImageIcon />
          </BaseIcon>
        </div>
      </div>
      <div class="tw-flex-col tw-w-full md:tw-w-5/6">
        <div class="tw-w-full tw-flex tw-justify-between tw-items-start">
          <PropertyName
            :star-rating="accommodationInfo.starRating"
            :name="accommodationInfo.name"
            :lodging-type-label="getLodgingTypeLabel"
          />
          <GhostButton
            size="medium"
            class="tw-px-6 tw-py-2 tw-w-full tw-hidden md:tw-flex md:tw-w-auto tw-mb-2"
            @click="startEditing"
            >{{ $t('app_description_edit') }}</GhostButton
          >
        </div>
        <hr class="tw-bg-gray-300" />
        <PropertyCheckinInfo :checkin="checkIn" :checkout="checkOut" />
        <hr class="tw-bg-gray-300" />
        <PropertyContactInfo
          :address="address"
          :phone="accommodationInfo.phone"
          :fax="accommodationInfo.fax"
          :website="accommodationInfo.homepageUrl"
          :email="accommodationInfo.email"
          :propertyId="accommodationInfo.propertyId"
        />
      </div>
      <div class="md:tw-hidden tw-flex">
        <GhostButton size="medium" class="tw-px-6 tw-py-2 tw-w-full md:tw-w-auto" @click="startEditing">{{
          $t('app_description_edit')
        }}</GhostButton>
      </div>
      <PropertyBasicInfoEditModal
        @toggleEditing="closeEditing"
        :is-open="editing"
        :hotel-chains="hotelChainsList"
        :lodging-types="lodgingTypesArray"
        :phone-codes="getPhoneLocales"
      />
    </div>
  </BaseCard>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useLodgingTypes, useHotelChains, useGetCountryCodes } from '@/components/propertyDetails/queries'
import { usePropertyDetailsBasicInfo } from '@/components/propertyDetails/propertyBasicInfo/usePropertyDetailsBasicInfo.js'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import PropertyName from '@/components/propertyDetails/propertyBasicInfo/PropertyName.vue'
import PropertyCheckinInfo from '@/components/propertyDetails/propertyBasicInfo/PropertyCheckinInfo.vue'
import PropertyContactInfo from '@/components/propertyDetails/propertyBasicInfo/PropertyContactInfo.vue'
import PropertyBasicInfoEditModal from '@/components/propertyDetails/propertyBasicInfo/edit/PropertyBasicInfoEditModal.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import ImageIcon from '@/components/icons/ImageIcon.vue'

const route = useRoute()
const store = useStore()
const props = defineProps({
  accommodationInfo: Object,
})
const { setEditObject, clearEditObject } = usePropertyDetailsBasicInfo()

const editing = ref(false)
const address = computed(() => {
  const streetAddress = props.accommodationInfo?.streetAddress ?? ''
  const postalCode = props.accommodationInfo?.postalCode ?? ''
  const city = props.accommodationInfo?.city ?? ''
  return `${streetAddress} ${postalCode} ${city}`
})

const mainImage = computed(
  () =>
    `https://imgcy.trivago.com/c_fill,d_dummy.jpeg,e_sharpen:60,f_auto,h_208,q_auto,w_231/${props.accommodationInfo?.mainImageUrls?.imagePath}`
)
const checkIn = computed(() =>
  props.accommodationInfo.checkInHour !== null
    ? props.accommodationInfo.checkInHour.toString() + ':00'
    : props.accommodationInfo.checkInHour
)
const checkOut = computed(() =>
  props.accommodationInfo.checkOutHour !== null
    ? props.accommodationInfo.checkOutHour.toString() + ':00'
    : props.accommodationInfo.checkOutHour
)
const languageCode = computed(() => store.state.locale?.language)
const { lodgingTypesLoading, lodgingTypes } = useLodgingTypes(languageCode, [101])
const { hotelChainsLoading, hotelChains } = useHotelChains('en-US', [150])
const { countryCodesLoading, countriesCodes } = useGetCountryCodes()

const lodgingTypesArray = computed(() => {
  let lodgingTypesArr = lodgingTypes.value ?? []

  if (!lodgingTypesArr.length) {
    return
  }
  lodgingTypesArr = lodgingTypesArr?.map(lodgingType => {
    return {
      name: lodgingType.translatedName.value,
      id: lodgingType.nsid.id,
      withTranslation: false,
    }
  })
  return lodgingTypesArr.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
})
const getLodgingTypeLabel = computed(() => {
  return Array.isArray(lodgingTypesArray?.value)
    ? lodgingTypesArray.value.find(item => item.id === props.accommodationInfo.accommodationTypeId)?.name
    : ''
})
const getPhoneLocales = computed(() => {
  let countriesCodesArr =
    Array.isArray(countriesCodes?.value) &&
    countriesCodes?.value?.length &&
    JSON.parse(JSON.stringify(countriesCodes.value))
  let phoneLocaleList = []
  if (!countriesCodesArr.length) {
    return
  }
  countriesCodesArr.map((item, index) => {
    phoneLocaleList.push({
      phoneCode: item.phoneCode,
      countryCode: item.alpha2,
      placeholder: item.placeholder,
      index: item.alpha2 + index,
    })
  })
  return phoneLocaleList
})

const hotelChainsList = computed(() => {
  let hotelChainsArray = hotelChains.value ?? []
  let hotelChainList = []

  if (!hotelChainsArray.length) {
    return
  }

  hotelChainsArray = hotelChainsArray.map(item => {
    hotelChainList.push({
      name: item.translatedName.value,
      value: item.nsid.id,
      withTranslation: false,
    })
  })
  return hotelChainList.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
})

const startEditing = () => {
  editing.value = true
  setEditObject(props.accommodationInfo)
}

const closeEditing = () => {
  clearEditObject()
  editing.value = false
}
onMounted(() => {
  if (route.query.open_update) {
    startEditing()
  }
})
</script>
