<template>
  <div>
    <div class="tw-mt-[60px] tw-relative">
      <label class="tw-font-bold tw-text-sm" for="property_search">{{ $t('search_label') }}</label>
      <div
        class="tw-flex tw-items-center tw-border tw-border-gray-500 tw-rounded-lg tw-bg-white tw-py-[9px] tw-px-3 tw-gap-2 tw-font-base"
      >
        <input
          data-hj-allow
          id="property_search"
          type="search"
          class="focus:tw-outline-none tw-grow"
          :placeholder="$t('tbs_registration_search_field_example')"
          :value="searchValue"
          @focus="showResultsOnFocus"
          @input="triggerUpdate"
          @blur="isInputFocused = false"
          :data-testid="`property-search`"
        />
        <BaseIcon :height="17" :width="17" icon-name="search">
          <Search />
        </BaseIcon>
      </div>
      <HotelAssignmentSearchResults
        v-if="openResults"
        :isOnboarding="isOnboarding"
        :data="results"
        :isSearching="isSearching"
        @onClickaway="hideResults"
      />
      <p class="tw-text-sm tw-text-gray-700">{{ $t('assign_search_hint') }}</p>
    </div>
    <SelectedProperties v-if="selectedItems?.length > 0" :data="selectedItems" />
    <div class="tw-flex tw-mt-10" v-if="selectedItems?.length > 0">
      <Checkbox
        name="property_confirm"
        :label="$t('footer_authorize')"
        :value="isAuthorizedCheckboxChecked"
        :class="'tw-text-sm tw-text-gray-800'"
        @onChange="toggleCheckbox"
        :dataTestId="'onboarding-assign-property-checkbox'"
      />
    </div>
    <div
      class="tw-flex tw-justify-center md:tw-justify-end"
      :class="isOnboarding ? 'tw-mt-8 md:tw-mt-14' : 'tw-mt-4 md:tw-mt-14'"
    >
      <MainButton
        class="tw-w-full md:tw-w-auto"
        :disabled="!isValid"
        @click="assignPropertyMutation"
        :dataTestId="'onboarding-assign-property-btn'"
      >
        <span>{{ $t('footer_assignhotel') }}</span>
      </MainButton>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import debounce from 'lodash/debounce'

import HotelAssignmentSearchResults from '@/components/assignedProperties/HotelAssignmentSearchResults.vue'
import SelectedProperties from '@/components/assignedProperties/SelectedProperties.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import Checkbox from '@/components/base/inputs/Checkbox.vue'
import Search from '@/components/icons/Search.vue'

import AccommodationService from '@/services/accommodationService/Accommodation'

import { useAssignedProperty } from './useAssignedProperty'

const store = useStore()
defineProps({
  isOnboarding: Boolean,
})

const emit = defineEmits(['assignPropertyClick'])

const openResults = ref(false)
const searchValue = ref('')
const isInputFocused = ref(false)
const results = ref(null)
const isSearching = ref(false)
const isAuthorizedCheckboxChecked = ref(false)

const { selectedItems } = useAssignedProperty()

const isValid = computed(() => isAuthorizedCheckboxChecked.value === true && selectedItems.value?.length > 0)
const language = computed(() => store.state.locale.language)

const getSearchResults = async () => {
  try {
    if (searchValue?.value?.length > 0) {
      isSearching.value = true
      await AccommodationService.newSearch({ searchTerm: searchValue.value, language: language.value }).then(res => {
        results.value = res.data
        isSearching.value = false
      })
    }
  } catch {
    isSearching.value = false
  }
}

const debouncedFilter = debounce(getSearchResults, 500)

watch(searchValue, debouncedFilter)

const triggerUpdate = event => {
  searchValue.value = event.target.value
}
const showResultsOnFocus = () => {
  isInputFocused.value = true
  if (searchValue.value.length > 0 || (results?.value !== null && results?.value?.length > 0)) {
    openResults.value = true
  }
}

const hideResults = () => {
  if (!isInputFocused.value) {
    openResults.value = false
  }
}

const toggleCheckbox = () => {
  isAuthorizedCheckboxChecked.value = !isAuthorizedCheckboxChecked.value
}

watch(results, newValue => {
  if (newValue !== null && newValue?.length > 0) {
    openResults.value = true
  }
})

watch(selectedItems, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    openResults.value = false
    isAuthorizedCheckboxChecked.value = false
  }
})

onUnmounted(() => {
  selectedItems.value = []
  results.value = null
})

const assignPropertyMutation = () => {
  const selectedItemId = selectedItems.value[0]?.id
  emit('assignPropertyClick', selectedItemId)
}
</script>
