<template>
  <section>
    <div
      ref="heroContainerRef"
      class="tw-flex tw-w-full lg:tw-max-w-screen-landing md:tw-max-w-[638px] sm:tw-max-w-[480px] tw-max-w-[300px] tw-py-6 lg:tw-px-0 tw-mx-auto lg:tw-pt-8 tw-pb-0 lg:tw-h-[450px] md:tw-h-[270px] tw-h-[430px] tw-overflow-hidden"
    >
      <div
        class="tw-flex tw-items-start md:tw-items-center tw-relative tw-flex-col md:tw-flex-row tw-justify-end md:tw-justify-between tw-w-full"
      >
        <div
          ref="textBoxRef"
          class="tw-flex tw-flex-col lg:tw-w-1/2 md:tw-w-full md:tw-max-w-[401px] lg:tw-max-w-[50%] tw-max-w-full tw-w-full tw-items-center md:tw-items-start tw-pb-4 lg:tw-mb-0 md:tw-mr-[20px] tw-mr-0"
        >
          <h1
            class="lg:tw-text-3.5xl md:tw-text-2xl tw-text-1xl tw-font-bold tw-mb-2 md:tw-mb-4 md:tw-text-left tw-text-center"
            v-html="$t('rc_mp_header')"
          />
          <p class="tw-text-xs md:tw-text-sm lg:tw-text-lg md:tw-text-left tw-text-center">{{ $t('rc_mp_subtext') }}</p>
          <MainButton @click="onLoginClick" class="lg:tw-flex tw-hidden tw-mt-10">{{
            $t('check_out_page_rc_cta')
          }}</MainButton>
          <MainButton @click="onLoginClick" size="medium" class="tw-flex lg:tw-hidden tw-mt-4">{{
            $t('check_out_page_rc_cta')
          }}</MainButton>
        </div>
        <div
          class="tw-flex tw-w-auto lg:tw-w-1/2 tw-justify-end md:tw-absolute tw-bottom-0 md:tw-right-0 tw-h-[194px] md:tw-h-[237px] lg:tw-h-[413px] md:tw-ml-auto tw-mx-auto"
        >
          <img :src="heroImage" alt="Rate Connect" class="md:tw-h-[237px] lg:tw-h-[413px] tw-h-[194px]" />
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import MainButton from '@/components/base/buttons/MainButton.vue'

const store = useStore()
const language = computed(() => store.state.locale.language)
const langCode = computed(() => language.value.split('-')[1].toLowerCase())

const emit = defineEmits(['onLoginClick'])
const onLoginClick = e => emit('onLoginClick', e.target.value)

const slide_1_gb = new URL('/src/assets/img/rate-connect-landing/hero/rc_hero_uk.svg', import.meta.url)
const slide_1_us = new URL('/src/assets/img/rate-connect-landing/hero/rc_hero_us.svg', import.meta.url)
const slide_1_br = new URL('/src/assets/img/rate-connect-landing/hero/rc_hero_br.svg', import.meta.url)
const slide_1_de = new URL('/src/assets/img/rate-connect-landing/hero/rc_hero_de.svg', import.meta.url)
const slide_1_es = new URL('/src/assets/img/rate-connect-landing/hero/rc_hero_es.svg', import.meta.url)
const slide_1_fr = new URL('/src/assets/img/rate-connect-landing/hero/rc_hero_fr.svg', import.meta.url)
const slide_1_it = new URL('/src/assets/img/rate-connect-landing/hero/rc_hero_it.svg', import.meta.url)
const slide_1_jp = new URL('/src/assets/img/rate-connect-landing/hero/rc_hero_jp.svg', import.meta.url)
const slide_1_gr = new URL('/src/assets/img/rate-connect-landing/hero/rc_hero_gr.svg', import.meta.url)
const slide_1_mx = new URL('/src/assets/img/rate-connect-landing/hero/rc_hero_mx.svg', import.meta.url)
const slide_1_nl = new URL('/src/assets/img/rate-connect-landing/hero/rc_hero_nl.svg', import.meta.url)
const slide_1_pl = new URL('/src/assets/img/rate-connect-landing/hero/rc_hero_pl.svg', import.meta.url)
const slide_1_pt = new URL('/src/assets/img/rate-connect-landing/hero/rc_hero_pt.svg', import.meta.url)
const slide_1_ru = new URL('/src/assets/img/rate-connect-landing/hero/rc_hero_ru.svg', import.meta.url)
const slide_1_tr = new URL('/src/assets/img/rate-connect-landing/hero/rc_hero_tr.svg', import.meta.url)

const heroImage = computed(() => {
  let image = slide_1_us
  switch (langCode.value) {
    case 'de':
      image = slide_1_de
      break
    case 'gr':
      image = slide_1_gr
      break
    case 'us':
      image = slide_1_us
      break
    case 'gb':
      image = slide_1_gb
      break
    case 'es':
      image = slide_1_es
      break
    case 'mx':
      image = slide_1_mx
      break
    case 'fr':
      image = slide_1_fr
      break
    case 'it':
      image = slide_1_it
      break
    case 'pl':
      image = slide_1_pl
      break
    case 'nl':
      image = slide_1_nl
      break
    case 'br':
      image = slide_1_br
      break
    case 'jp':
      image = slide_1_jp
      break
    case 'pt':
      image = slide_1_pt
      break
    case 'ru':
      image = slide_1_ru
      break
    case 'tr':
      image = slide_1_tr
      break
    default:
      image = slide_1_us
      break
  }
  return image
})

const textBoxRef = ref(null)
const heroContainerRef = ref(null)

const setEqualHeight = async () => {
  if (window.innerWidth > 767) {
    textBoxRef.value.style.height = 'auto'
    return
  }
  const box = textBoxRef.value
  if (!box || !heroContainerRef.value) return

  box.style.height = 'auto'

  await nextTick()

  heroContainerRef.value.style.minHeight = box.offsetHeight + 40 + 194 + 'px'
  heroContainerRef.value.style.height = box.offsetHeight + 40 + 194 + 'px'
}

watch(language, () => {
  nextTick(() => {
    setEqualHeight()
  })
})

onMounted(() => {
  setEqualHeight()
})
</script>
