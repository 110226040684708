<template>
  <section>
    <div
      class="tw-flex tw-flex-col tw-w-full lg:tw-max-w-screen-landing md:tw-max-w-[638px] sm:tw-max-w-[480px] tw-max-w-[300px] tw-py-6 md:tw-py-4 lg:tw-py-6 lg:tw-px-0 tw-mx-auto"
    >
      <h3 class="tw-font-bold tw-mx-auto tw-text-xl tw-text-blue-800 tw-mb-6 tw-text-center md:tw-text-left">
        {{ $t('rc_mp_connectivity_providers') }}
      </h3>
      <div
        class="tw-flex tw-flex-row tw-flex-wrap md:tw-flex-nowrap tw-justify-around md:tw-justify-between lg:tw-mx-20"
      >
        <div v-for="logo in connectivityProviders" :key="logo.name" class="logo" :class="`logo_${logo.name}`">
          <img :src="logo.logo" :alt="logo.name" class="tw-mx-auto tw-max-h-[32px]" />
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
const webhotelier_logo = new URL('/src/assets/img/rate-connect-landing/logos/webhotelier.png', import.meta.url)
const dirs21_logo = new URL('/src/assets/img/rate-connect-landing/logos/dirs21.png', import.meta.url)
const roomcloud_logo = new URL('/src/assets/img/rate-connect-landing/logos/roomcloud.png', import.meta.url)
const beddy_logo = new URL('/src/assets/img/rate-connect-landing/logos/beddy.png', import.meta.url)
const wubook_logo = new URL('/src/assets/img/rate-connect-landing/logos/wubook.png', import.meta.url)
const connectivityProviders = [
  { logo: webhotelier_logo, name: 'Webhotelier' },
  {
    logo: dirs21_logo,
    name: 'Dirs21',
  },
  { logo: roomcloud_logo, name: 'RoomCloud' },
  {
    logo: beddy_logo,
    name: 'Beddy',
  },
  {
    logo: wubook_logo,
    name: 'Wubook',
  },
]
</script>
<style scoped>
.logo {
  @media (max-width: 767px) {
    flex: 50% 0 0;
    margin: auto;
    margin-top: 24px;
  }
}

.logo_Webhotelier {
  @media (max-width: 767px) {
    flex: 70% 0 0;
    margin-top: 0;
  }
}

.logo_Dirs21 {
  @media (max-width: 767px) {
    flex: 30% 0 0;
    margin-top: 0;
  }
}
</style>
