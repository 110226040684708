<template>
  <BookingTable
    v-if="tableData"
    :columns="columnsConfig"
    :data="tableData"
    :currency="currency"
    :hasEmptyTable="hasEmptyTable"
  />
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import BookingTable from '@/components/rateConnect/performance/table/BookingTable.vue'
import { useBookingLog } from '@/components/rateConnect/queries'
import { useRateConnectPerformance } from '@/components/rateConnect/performance/useRateConnectPerformance.js'
import { selectedAccommodationId } from '@/layouts/queries'

const store = useStore()
const currency = computed(() => store.state.currency)

const { selectedMarket, formattedStartDate, formattedEndDate, selectedDateType, selectedStatus, selectedColumns } =
  useRateConnectPerformance()

const { bookingLogData } = useBookingLog(
  selectedAccommodationId,
  selectedDateType,
  currency,
  formattedStartDate,
  formattedEndDate,
  selectedMarket,
  selectedStatus
)

const props = defineProps({
  columns: Array,
  hasEmptyTable: Boolean,
})

const columnsConfig = computed(() => {
  if (!selectedColumns.value) {
    return props.columns
  } else {
    return props.columns.filter(item => {
      if (item.mandatory) return true
      else if (selectedColumns.value.includes(item.id)) return true
      else return false
    })
  }
})

const generateEmptyData = () => {
  const emptyData = Array(6)
    .fill(null)
    .map(() => {
      return {
        bookingId: null,
        requestId: null,
        market: null,
        bookingDate: null,
        confirmedAt: null,
        campaignType: null,
        arrivalDate: null,
        departureDate: null,
        bookingValue: {
          amountCampaignCurrency: null,
          amountLocalCurrency: null,
          localCurrency: 'EUR',
          commissionCost: null,
        },
        confirmed: null,
        cpaLevel: null,
        confirmedByTrivago: null,
        arr: null,
        status: null,
        refundAmount: null,
        cancelledDate: null,
      }
    })

  return emptyData
}

const tableData = computed(() => {
  if (!bookingLogData.value || !Array.isArray(bookingLogData.value)) return null

  return bookingLogData.value.length > 0
    ? bookingLogData.value.map(
        ({
          requestId,
          marketCode,
          bookingId,
          bookingDate,
          arrivalDate,
          departureDate,
          amountCampaignCurrency,
          bookingAmountLocalCurrency,
          localCurrency,
          confirmed,
          confirmedBy,
          confirmedAt,
          campaignType,
          commissionCost,
          averageAmount,
          cpaLevel,
          status,
          refundAmount: originalRefundAmount,
          cancelledDate: originalCancelledDate,
        }) => {
          const refundAmount = originalRefundAmount ? originalRefundAmount / 100 : undefined
          const cancelledDate = originalCancelledDate
          return {
            bookingId,
            requestId,
            market: marketCode,
            bookingDate,
            confirmedAt,
            campaignType,
            arrivalDate,
            departureDate,
            bookingValue: {
              amountCampaignCurrency: amountCampaignCurrency / 100,
              amountLocalCurrency: Number(bookingAmountLocalCurrency) / 100,
              localCurrency,
              commissionCost: commissionCost / 100,
            },
            confirmed,
            cpaLevel,
            confirmedByTrivago: confirmedBy === 'trivago',
            arr: averageAmount / 100,
            status,
            refundAmount,
            cancelledDate,
          }
        }
      )
    : generateEmptyData()
})
</script>
