<template>
  <div>
    <div class="tw-pb-0" v-if="!loading">
      <AuthMenu isLanding @onRegisterClick="onAuthorize('signup')" />
      <div>
        <LandingPageHero class="hero-padding tw-pt-16" @onLoginClick="onAuthorize('login')" />
      </div>
      <LandingPageSocialProof class="tw-bg-white" />
      <LandingPageFreeBusinessAccount />
      <LandingPageInfoBanner class="tw-bg-white" />
      <LandingPageRateConnect />
      <LandingPageStudioPlus class="tw-bg-white" />
      <LandingPageProductsComparison
        title="tbs_mp_header_premium"
        product="both"
        @onLoginClick="onAuthorize('login')"
      />
      <LandingPageFaq id="landing-page-products" class="tw-bg-white" />
    </div>
    <div v-else>
      <LaunchpadLoader class="tw-h-screen tw-flex tw-items-center tw-justify-center" />
    </div>
    <ThePageFooter isLanding />
    <TheCookieNotice />
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import AuthMenu from '@/components/landingPage/AuthMenu.vue'
import LandingPageHero from '@/components/landingPage/LandingPageHero.vue'
import LandingPageSocialProof from '@/components/landingPage/LandingPageSocialProof.vue'
import LandingPageFreeBusinessAccount from '@/components/landingPage/LandingPageFreeBusinessAccount.vue'
import LandingPageInfoBanner from '@/components/landingPage/LandingPageInfoBanner.vue'
import LandingPageRateConnect from '@/components/landingPage/LandingPageRateConnect.vue'
import LandingPageStudioPlus from '@/components/landingPage/LandingPageStudioPlus.vue'
import LandingPageProductsComparison from '@/components/landingPage/LandingPageProductsComparison.vue'
import LaunchpadLoader from '@/components/dashboard/common/LaunchpadLoader.vue'

import LandingPageFaq from '@/components/landingPage/LandingPageFaq.vue'
import ThePageFooter from '@/components/page/ThePageFooter.vue'
import TheCookieNotice from '@/components/legal/TheCookieNotice.vue'
import appAnalytics from '@/utils/tracking'

const route = useRoute()
const store = useStore()
const locale = computed(() => store.state.locale)
const loading = ref(true)
const authorize = params => store.dispatch('session/authorize', params)

onMounted(() => {
  if (route.query?.redirectFrom?.includes('loginAsAdmin')) {
    onAuthorize('login')
  } else {
    loading.value = false
  }
})

const onAuthorize = async (type = 'login') => {
  appAnalytics.track(type === 'login' ? appAnalytics.events.BUTTON_CLICKED : appAnalytics.events.BUTTON_CLICKED, {
    button: type,
    property_id: null,
  })
  await authorize({
    type,
    stateParams: {
      redirectFrom: route.query.redirectFrom,
    },
    locale: locale.value.language,
  })
}
</script>
<style scoped>
.hero-padding {
  padding-top: 4rem;
  @media (max-width: theme('screens.lg')) {
    padding-top: 72px;
  }
  @media (min-width: theme('screens.md')) {
    padding-top: 67px;
  }
}
</style>
