<template>
  <div ref="root">
    <UiTooltip theme="material" tooltip-position="top" :label="$t(columnsValueLabel)">
      <div
        @click="showDropdown"
        class="tw-relative tw-cursor-pointer tw-border tw-rounded-lg tw-flex tw-box-size-44 tw-items-center tw-px-3 tw-py-1 tw-pr-8 tw-h-[41px]"
        :class="getFieldClass(show, disabled)"
      >
        <span class="tw-truncate tw-absolute tw-top-[3px] tw-text-xs tw-font-semibold">{{
          $t('rc_column_picker')
        }}</span>
        <span class="tw-truncate tw-absolute tw-bottom-[3px] tw-text-sm tw-max-w-[130px]">{{
          $t(columnsValueLabel)
        }}</span>

        <BaseIcon
          icon-name="chevron-right"
          :height="24"
          :width="24"
          :viewbox-height="24"
          :viewbox-width="24"
          class="tw-absolute"
          :class="show ? 'arrow-dropdown-open' : 'arrow-dropdown'"
        >
          <ChevronRight />
        </BaseIcon>
      </div>
    </UiTooltip>

    <div class="tw-relative tw-top-[5px]" v-if="show && !disabled">
      <div
        class="tw-absolute tw-min-h-[50px] tw-max-h-[350px] tw-overflow-auto tw-min-w-full tw-w-auto tw-rounded tw-bg-white dropdown-card tw-z-[20]"
        :class="'tw-left-0'"
      >
        <ul>
          <li
            class="tw-cursor-pointer hover:tw-bg-blue-100 hover:tw-text-blue-700 tw-p-4 nowrap tw-text-sm tw-flex tw-items-center tw-rounded-t"
            :class="{ 'tw-text-blue-700 tw-bg-blue-100': selectedColumns === null }"
          >
            <input
              type="checkbox"
              :value="'all'"
              :id="'all'"
              :checked="selectedColumns === null"
              @change="onSelectAll"
              class="tw-cursor-pointer"
            />
            <label :for="'all'" class="tw-cursor-pointer tw-m-0 tw-pl-4 tw-grow tw-flex tw-items-center tw-flex-wrap">
              {{ $t('rc_filter_select_all') }}</label
            >
          </li>

          <li
            v-for="(column, index) in columns"
            :key="index"
            class="tw-cursor-pointer hover:tw-bg-blue-100 hover:tw-text-blue-700 tw-p-4 nowrap tw-text-sm tw-flex tw-items-center"
            :class="getOptionClass(column, index)"
          >
            <input
              type="checkbox"
              :value="column.id"
              :id="column.id"
              :disabled="column.mandatory"
              :checked="
                selectedColumns === null || selectedColumns.some(item => item === column.id) || column.mandatory
              "
              @change="onSelect(column)"
              class="tw-cursor-pointer"
            />
            <label
              :for="column.id"
              class="tw-cursor-pointer tw-m-0 tw-pl-4 tw-grow tw-flex tw-items-center tw-flex-wrap"
            >
              {{ $t(column.header) }}</label
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, onDeactivated, computed } from 'vue'
import BaseIcon from '@/components/BaseIcon.vue'
import ChevronRight from '@/components/icons/ChevronRight.vue'

import { useRateConnectPerformance } from '@/components/rateConnect/performance/useRateConnectPerformance.js'
import { useI18n } from 'vue-i18n'
import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'

const { t } = useI18n()
const props = defineProps({
  columns: {
    type: Array,
    default: () => [],
  },
})

const { selectedColumns } = useRateConnectPerformance()

const localStorageColumns = computed(() => {
  try {
    const customConfig = window.localStorage.getItem('custom_cols_config')
    return customConfig ? customConfig.split(',') : null
  } catch {
    return null
  }
})

const columnsValueLabel = computed(() => {
  if (selectedColumns.value === null) {
    return 'rc_filter_all_selected'
  } else {
    let selectedColumnsLabel = []
    props.columns.forEach(item => {
      if (selectedColumns.value.includes(item.id)) {
        selectedColumnsLabel.push(t(item.header))
      }
    })

    selectedColumnsLabel = selectedColumnsLabel.join(', ')
    return selectedColumnsLabel
  }
})
const root = ref(null)
const show = ref(false)
const showDropdown = () => {
  if (!props.disabled) {
    show.value = !show.value
  }
}

const onSelect = column => {
  let newCols = selectedColumns.value
  const colIndex = selectedColumns.value?.indexOf(column.id)
  if (selectedColumns.value !== null) {
    if (colIndex >= 0) {
      newCols.splice(colIndex, 1)
    } else {
      newCols.push(column.id)
    }
  } else {
    newCols = []
    props.columns.forEach(item => {
      if (item.id !== column.id) {
        newCols.push(item.id)
      }
    })
  }

  selectedColumns.value = newCols
  window.localStorage.setItem('custom_cols_config', newCols.join(','))
}

const onSelectAll = () => {
  if (selectedColumns.value === null) {
    let newCols = []
    props.columns.forEach(item => {
      if (item.mandatory) {
        newCols.push(item.id)
      }
    })
    selectedColumns.value = newCols
    window.localStorage.setItem('custom_cols_config', newCols.join(','))
  } else {
    selectedColumns.value = null
    window.localStorage.removeItem('custom_cols_config')
  }
}

onMounted(() => {
  selectedColumns.value = localStorageColumns.value
  document.addEventListener('click', handleClickOutside)
})
onDeactivated(() => {
  document.removeEventListener('click', handleClickOutside)
})
const handleClickOutside = evt => {
  if (root?.value !== null && !root?.value?.contains(evt.target)) {
    show.value = false
  }
}

const getFieldClass = (show, disabled) => {
  const classes = []
  if (disabled) {
    classes.push('tw-border-gray-300 tw-text-gray-300')
  }
  if (show) {
    classes.push('tw-border-blue-700')
  }
  if (!show && !disabled) {
    classes.push('tw-border-gray-500')
  }
  return classes.join(' ')
}

const getOptionClass = (column, position) => {
  const classes = []
  const selected =
    selectedColumns.value === null || selectedColumns.value.some(item => item === column.id) || column.mandatory
  if (props.columns.length - 1 === position) {
    classes.push('tw-rounded-b')
  }
  if (selected) {
    classes.push('tw-text-blue-700 tw-bg-blue-100')
  }

  return classes.join(' ')
}
</script>
<style scoped>
.arrow-dropdown {
  top: 50%;
  transform: translateY(-45%) rotate(90deg);
  right: 6px;
  pointer-events: none;
}
.arrow-dropdown-open {
  top: 50%;
  transform: translateY(-45%) rotate(270deg);
  right: 6px;
  pointer-events: none;
}
.dropdown-card {
  box-shadow:
    0 2px 4px 0 rgba(63, 87, 189, 0.1),
    0 2px 10px 0 rgba(108, 112, 122, 0.15);
}
.nowrap {
  text-wrap: nowrap;
}
</style>
