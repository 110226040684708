<template>
  <div ref="root">
    <UiTooltip theme="material" tooltip-position="top" :label="hint">
      <div
        @click="showDropdown"
        class="tw-relative tw-cursor-pointer tw-border tw-rounded-lg tw-flex tw-box-size-44 tw-items-center tw-px-3 tw-py-1 tw-pr-8 tw-h-[41px]"
        :class="show ? 'tw-border-blue-800' : 'tw-border-gray-500'"
      >
        <span class="tw-truncate tw-absolute tw-top-[3px] tw-text-xs tw-font-semibold">{{ $t(label) }}</span>
        <span class="tw-truncate tw-absolute tw-bottom-[3px] tw-text-sm tw-max-w-[120px]">{{ valueLabel }}</span>

        <BaseIcon
          icon-name="chevron-right"
          :height="24"
          :width="24"
          :viewbox-height="24"
          :viewbox-width="24"
          class="tw-absolute"
          :class="show ? 'arrow-dropdown-open' : 'arrow-dropdown'"
        >
          <ChevronRight />
        </BaseIcon>
      </div>
    </UiTooltip>
    <div class="tw-relative tw-top-[5px] tw-z-[20]" v-if="show">
      <div class="tw-absolute tw-min-h-[50px] tw-right-0 tw-bg-white tw-rounded dropdown-card">
        <div class="tw-relative tw-p-3">
          <BaseIcon :height="17" :width="17" icon-name="search" class="tw-absolute tw-top-[25px] tw-right-[25px]">
            <Search />
          </BaseIcon>
          <input
            class="tw-border tw-box-size-44 tw-border-gray-300 tw-rounded-md tw-px-2 tw-py-1 tw-outline-none"
            type="text"
            :value="searchQuery"
            @input="e => search(e.target.value)"
            :placeholder="$t('search_placeholder')"
          />
        </div>
        <ul class="tw-max-h-[180px] tw-overflow-auto">
          <li
            v-for="(option, index) in filteredOptions"
            :key="option.name"
            @click="onSelect({ option, name })"
            :class="getOptionClass(option.id === value.id, index)"
            class="tw-flex tw-items-center tw-justify-between tw-p-4 hover:tw-bg-blue-100 hover:tw-text-blue-800 hover:tw-cursor-pointer"
          >
            <span class="tw-flex tw-flex-row tw-items-center tw-text-sm">
              <span
                v-if="option.value !== 'all'"
                class="flag tw-w-[20px] tw-h-[12px] tw-scale-150 flagImage tw-mr-3"
                :class="`flag-${option.value.toLowerCase()}`"
              />
              {{ option.label }}</span
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch, onMounted, onDeactivated } from 'vue'
import BaseIcon from '@/components/BaseIcon.vue'
import ChevronRight from '@/components/icons/ChevronRight.vue'
import Search from '@/components/icons/Search.vue'
import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'
const props = defineProps({
  options: Array,
  name: String,
  value: Array,
  label: String,
  valueLabel: String,
  hint: String,
})
const searchQuery = ref('')
const root = ref(null)
const show = ref(false)
const filteredOptions = ref(props.options)
const showDropdown = () => {
  show.value = !show.value
}
const search = text => {
  searchQuery.value = text
}
const emit = defineEmits(['select'])
const onSelect = ({ option, name }) => {
  emit('select', { option, name })
  show.value = false
}
const getOptionClass = (selected, position) => {
  const classes = []
  if (position === 0) {
    classes.push('tw-rounded-t')
  } else if (props.options.length - 1 === position) {
    classes.push('tw-rounded-b')
  }
  if (selected) {
    classes.push('tw-text-blue-700 tw-bg-blue-100')
  }

  return classes.toString().replace(',', ' ')
}
watch(searchQuery, newSearchQuery => {
  filteredOptions.value =
    newSearchQuery.length === 0
      ? props.options
      : props.options.filter(item => item.label?.toLowerCase().includes(newSearchQuery.toLowerCase()))
})
onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})
onDeactivated(() => {
  document.removeEventListener('click', handleClickOutside)
})
const handleClickOutside = evt => {
  if (root?.value !== null && !root?.value?.contains(evt.target)) {
    show.value = false
  }
}
</script>
<style scoped>
.arrow-dropdown {
  top: 50%;
  transform: translateY(-45%) rotate(90deg);
  right: 6px;
  pointer-events: none;
}
.arrow-dropdown-open {
  top: 50%;
  transform: translateY(-45%) rotate(270deg);
  right: 6px;
  pointer-events: none;
}
.dropdown-card {
  box-shadow:
    0 2px 4px 0 rgba(63, 87, 189, 0.1),
    0 2px 10px 0 rgba(108, 112, 122, 0.15);
}

.flag {
  left: 1.6rem;
  background-size: 20px auto;
  top: 1.6rem;
  background-position: 0 -30px;
}
.flagImage {
  background-image: url(https://imgcy.trivago.com/q_auto/v1/hardcodedimages/imagemap_all_flags_21@2x.png);

  background-repeat: no-repeat;
}
.flag-at {
  background-position: 0 -810px;
}
.flag-de {
  background-position: 0 0;
}
.flag-en,
.flag-uk {
  background-position: 0 -30px;
  height: 11px;
}
.flag-es {
  background-position: 0 -60px;
}
.flag-fr {
  background-position: 0 -90px;
}
.flag-se {
  background-position: 0 -120px;
}
.flag-pl {
  background-position: 0 -150px;
}
.flag-ru {
  background-position: 0 -180px;
}
.flag-it {
  background-position: 0 -210px;
}
.flag-gr {
  background-position: 0 -240px;
}
.flag-nl {
  background-position: 0 -270px;
}
.flag-rs {
  background-position: 0 -300px;
}
.flag-tr {
  background-position: 0 -330px;
}
.flag-ro {
  background-position: 0 -360px;
}
.flag-ar {
  background-position: 0 -390px;
}
.flag-ie {
  background-position: 0 -870px;
}
.flag-id {
  background-position: 0 -1290px;
}
.flag-hu {
  background-position: 0 -900px;
}
.flag-br {
  background-position: 0 -450px;
}
.flag-zh,
.flag-cn {
  background-position: 0 -480px;
}
.flag-us {
  background-position: 0 -510px;
}
.flag-fi {
  background-position: 0 -540px;
}
.flag-jp {
  background-position: 0 -570px;
}
.flag-mx {
  background-position: 0 -600px;
}
.flag-pt {
  background-position: 0 -630px;
}
.flag-cs,
.flag-cz {
  background-position: 0 -840px;
}
.flag-bg {
  background-position: 0 -420px;
}
.flag-da,
.flag-dk {
  background-position: 0 -690px;
}
.flag-no {
  background-position: 0 -720px;
}
.flag-be {
  background-position: 0 -750px;
}
.flag-so,
.flag-si {
  background-position: 0 -780px;
}
.flag-ch {
  background-position: 0 -930px;
}
.flag-ca {
  background-position: 0 -990px;
  height: 12px;
}
.flag-nz {
  background-position: 0 -1050px;
}
.flag-au {
  background-position: 0 -960px;
  height: 12px;
}
.flag-co {
  background-position: 0 -1200px;
  height: 12px;
}
.flag-ko,
.flag-kr {
  background-position: 0 -1020px;
}
.flag-cl {
  background-position: 0 -1170px;
  height: 12px;
}
.flag-sg {
  background-position: 0 -1140px;
}
.flag-in {
  background-position: 0 -1080px;
}
.flag-hk {
  background-position: 0 -1110px;
}
.flag-ae {
  background-position: 0 -1320px;
}
.flag-ms,
.flag-my {
  background-position: 0 -1230px;
}
.flag-th {
  background-position: 0 -1260px;
}
.flag-he,
.flag-il {
  background-position: 0 -1350px;
}
.flag-za {
  background-position: 0 -1380px;
}
.flag-tw {
  background-position: 0 -1410px;
}
.flag-ph {
  background-position: 0 -1470px;
}
.flag-world_wide {
  background-position: 0 -1514px;
}
.flag-vn {
  background-position: 0 -1440px;
}
.flag-aa,
.flag-shabaka {
  background-position: 0 -1530px;
}
.flag-hr {
  background-position: 0 -1560px;
}
.flag-sk {
  background-position: 0 -1590px;
}
.flag-pe {
  background-position: 0 -1620px;
}
.flag-ec {
  background-position: 0 -1650px;
}
.flag-uy {
  background-position: 0 -1680px;
}
.flag-ng {
  background-position: 0 -1710px;
}
</style>
