<template>
  <div>
    <p class="tw-font-bold" :class="{ 'tw-mb-3': name !== 'status' }">{{ label }}</p>
    <p class="tw-text-sm tw-text-gray-600 tw-mb-3" v-if="name === 'status'">{{ $t('rc_filter_info_text') }}</p>
    <ul class="tw-flex tw-flex-wrap">
      <li v-for="(option, index) in options" :key="index" class="item tw-flex tw-items-center tw-text-sm">
        <input
          type="checkbox"
          :name="name"
          :value="option.id"
          :id="`${name}_${option.id}`"
          :checked="valuesObj.some(item => item.id === option.id)"
          :disabled="valuesObj.some(item => item.id === option.id) && valuesObj.length === 1"
          @change="updateStatuses({ name, option })"
          :class="isDisabled ? 'tw-cursor-not-allowed' : 'tw-cursor-pointer'"
        />
        <label
          :for="`${name}_${option.id}`"
          class="tw-cursor-pointer tw-py-3 tw-m-0 tw-pl-4 tw-grow tw-flex tw-items-center tw-flex-wrap"
        >
          {{ $t(option.name) }}</label
        >
      </li>
    </ul>
  </div>
</template>
<script setup>
import cloneDeep from 'lodash/cloneDeep'
import { ref } from 'vue'

const props = defineProps({
  name: String,
  label: String,
  options: Array,
  value: Array,
})

const emit = defineEmits(['onSelect'])
const onSelect = value => emit('onSelect', value)
const valuesObj = ref(cloneDeep(props.value))

const updateStatuses = status => {
  const index = valuesObj.value.findIndex(item => item.id === status.option.id)
  if (index > -1) {
    valuesObj.value.splice(index, 1)
  } else {
    valuesObj.value.push(status.option)
  }

  onSelect({ name: status.name, option: valuesObj.value })
}
</script>
<style scoped>
.item {
  flex-basis: 50%;
  box-sizing: border-box;
}
</style>
