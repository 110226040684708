<template>
  <section>
    <div
      class="tw-flex tw-flex-col tw-w-full lg:tw-max-w-screen-landing md:tw-max-w-[638px] sm:tw-max-w-[480px] tw-max-w-[300px] tw-py-6 lg:tw-px-0 tw-mx-auto lg:tw-py-8"
    >
      <div class="tw-flex md:tw-flex-row tw-flex-col tw-relative">
        <div
          ref="containerRef"
          class="tw-flex tw-flex-col md:tw-flex-row tw-w-vw tw-overflow-hidden carousel-container lg:tw-w-[880px] md:tw-w-[550px] sm:tw-w-[480px] tw-w-[300px] tw-mx-auto"
        >
          <div class="inner reviews_carousel" ref="inner" :style="innerStyles">
            <div
              ref="boxesRef"
              class="line-shadow tw-border tw-border-gray-600 card tw-p-4 lg:tw-p-6"
              :id="review.id"
              v-for="review in reviews"
              :key="review.id"
            >
              <div class="tw-flex-row md:tw-gap-x-4 md:tw-flex tw-hidden">
                <div class="tw-flex md:tw-min-w-[208px] lg:tw-min-w-[304px] tw-items-stretch">
                  <img :src="review.image" :alt="$t('tbs_testimonials')" class="tw-object-cover tw-h-[221px]" />
                </div>
                <div class="tw-w-auto tw-flex tw-flex-col tw-h-full tw-justify-between tw-justify-center">
                  <h3 class="tw-font-bold md:tw-leading-[20px] lg:tw-text-2xl">
                    {{ $t('tbs_testimonials') }}
                  </h3>
                  <p class="md:tw-leading[18px] md:tw-text-xs lg:tw-text-base">{{ $t(review.text) }}</p>
                  <p class="md:tw-leading[18px] md:tw-text-sm lg:tw-text-base" v-html="$t(review.author)" />
                </div>
              </div>

              <!-- Mobile Version -->
              <div class="tw-flex md:tw-hidden tw-flex-col">
                <h3 class="tw-font-bold tw-text-xl">{{ $t('tbs_testimonials') }}</h3>
                <div class="tw-flex tw-my-4">
                  <img :src="review.image" :alt="$t('tbs_testimonials')" class="tw-object-cover tw-h-full" />
                </div>
                <p class="tw-text-sm tw-mb-4">{{ $t(review.text) }}</p>
                <p class="tw-text-sm" v-html="$t(review.author)" />
              </div>
            </div>
          </div>
        </div>

        <button class="tw-hidden md:tw-flex tw-absolute tw-left-0 middle" @click="prev">
          <img :src="arrow_left" alt="left" />
        </button>
        <button class="tw-hidden md:tw-flex tw-absolute tw-right-0 middle" @click="next">
          <img :src="arrow_right" alt="right" />
        </button>

        <div class="tw-flex md:tw-hidden tw-mx-auto tw-mt-4 tw-gap-x-6">
          <button @click="prev"><img :src="arrow_left" alt="left" /></button>
          <button @click="next"><img :src="arrow_right" alt="right" /></button>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted, nextTick, watch, computed, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import TouchEvent from '@/utils/touchEvents.js'

const store = useStore()
const containerRef = ref(null)
const boxesRef = ref([])
const language = computed(() => store.state.locale.language)

const review_slider_1 = new URL('/src/assets/img/rate-connect-landing/Testimonial_slider_image1.webp', import.meta.url)
const review_slider_2 = new URL('/src/assets/img/rate-connect-landing/Testimonial_slider_image2.webp', import.meta.url)
const review_slider_3 = new URL('/src/assets/img/rate-connect-landing/Testimonial_slider_image3.webp', import.meta.url)
const review_slider_4 = new URL('/src/assets/img/rate-connect-landing/Testimonial_slider_image4.webp', import.meta.url)
const review_slider_5 = new URL('/src/assets/img/rate-connect-landing/Testimonial_slider_image5.webp', import.meta.url)
const review_slider_6 = new URL('/src/assets/img/rate-connect-landing/Testimonial_slider_image6.jpeg', import.meta.url)

const arrow_left = new URL('/src/assets/img/rate-connect-landing/icons/arrow_left_icon.svg', import.meta.url)
const arrow_right = new URL('/src/assets/img/rate-connect-landing/icons/arrow_right_icon.svg', import.meta.url)

const reviews = ref([
  {
    image: review_slider_1,
    id: 'review_0',
    text: 'rc_mp_testimonial_1_text',
    author: 'rc_mp_testimonial_1_hotelier',
  },
  {
    image: review_slider_2,
    id: 'review_1',
    text: 'rc_mp_testimonial_2_text',
    author: 'rc_mp_testimonial_2_hotelier',
  },
  {
    image: review_slider_3,
    id: 'review_2',
    text: 'rc_mp_testimonial_3_text',
    author: 'rc_mp_testimonial_3_hotelier',
  },
  {
    image: review_slider_4,
    id: 'review_3',
    text: 'rc_mp_testimonial_4_text',
    author: 'rc_mp_testimonial_4_hotelier',
  },
  {
    image: review_slider_5,
    id: 'review_4',
    text: 'rc_mp_testimonial_5_text',
    author: 'rc_mp_testimonial_5_hotelier',
  },
  {
    image: review_slider_6,
    id: 'review_5',
    text: 'rc_mp_testimonial_6_text',
    author: 'rc_mp_testimonial_6_header',
  },
])

const touchEvent = ref(null)
const current = ref(1)
const timer = ref('')
const inner = ref(null)
const innerStyles = ref({})
const step = ref('')
const transitioning = ref(false)

const setEqualHeight = async () => {
  if (window.innerWidth < 786) {
    boxesRef.value.forEach(box => (box.style.height = 'auto'))
    return
  }
  const boxes = boxesRef.value
  if (!boxes || boxes.length === 0) return

  boxes.forEach(box => {
    box.style.height = 'auto'
  })

  await nextTick()

  let maxHeight = 0

  boxes.forEach(box => {
    const currentHeight = box.offsetHeight
    if (currentHeight > maxHeight) {
      maxHeight = box.offsetHeight
    }
  })

  boxes.forEach(box => {
    box.style.height = maxHeight + 'px'
  })
}

watch(language, () => {
  nextTick(() => setEqualHeight())
})

onMounted(async () => {
  await nextTick()
  setEqualHeight()
  setStep()
  autoplay()
  document.addEventListener('touchstart', event => {
    touchEvent.value = new TouchEvent(event)
  })

  document.addEventListener('touchend', handleSwipe)
  window.addEventListener('resize', setEqualHeight)
})

onBeforeUnmount(() => {
  clearInterval(timer)
  window.removeEventListener('resize', setEqualHeight)
  document.removeEventListener('touchend', handleSwipe)
})

const afterTransition = callback => {
  const listener = () => {
    callback()
    inner.value?.removeEventListener('transitionend', listener)
  }
  inner.value?.addEventListener('transitionend', listener)
}

const resetTranslate = () => {
  innerStyles.value = {
    transition: 'none',
    transform: `translateX(0px)`,
  }
}

const setStep = () => {
  const innerWidth = inner.value.scrollWidth
  const totalCards = reviews.value.length

  step.value = innerWidth / totalCards
}

const moveLeft = () => {
  innerStyles.value = {
    transition: 'transform 0.5s ease',
    transform: `translateX(-${step.value}px)`,
  }
}

const moveRight = () => {
  innerStyles.value = {
    transition: 'transform 0.5s ease',
    transform: `translateX(${step.value}px)`,
  }
}

const next = () => {
  if (transitioning.value) return
  transitioning.value = true
  moveLeft()
  afterTransition(() => {
    const firstElement = containerRef.value.querySelector('.reviews_carousel').firstElementChild
    containerRef.value.querySelector('.reviews_carousel').appendChild(firstElement)

    updateCurrent()
    resetTranslate()
    transitioning.value = false
  })
}

const prev = () => {
  if (transitioning.value) return
  transitioning.value = true
  moveRight()
  afterTransition(() => {
    const lastElement = containerRef.value.querySelector('.reviews_carousel').lastElementChild
    containerRef.value.querySelector('.reviews_carousel').prepend(lastElement)

    updateCurrent()
    resetTranslate()
    transitioning.value = false
  })
}

const updateCurrent = () => {
  const firstElementId = containerRef.value.querySelector('.reviews_carousel').firstElementChild.id
  current.value = Number(firstElementId.split('_')[1])
}

const autoplay = () => {
  next()
  timer.value = setTimeout(autoplay, 5000)
}

const handleSwipe = event => {
  if (!touchEvent.value) {
    return
  }
  touchEvent.value.setEndEvent(event)

  if (touchEvent.value.isSwipeRight()) {
    prev()
  } else if (touchEvent.value.isSwipeLeft()) {
    next()
  }
  touchEvent.value = null
}
</script>
<style scoped>
.middle {
  @apply tw-top-[50%] tw-translate-y-[-50%];
}
.reviews_carousel {
  padding-bottom: 1px;
  display: flex;
  margin: auto;
  white-space: nowrap;
  transition: transform 1s;
  @media (max-width: theme('screens.md')) {
    width: 100%;
  }
}

.card {
  @apply tw-bg-white lg:tw-min-w-[878px] lg:tw-w-[879px] md:tw-min-w-[543px] md:tw-w-[543px] tw-min-w-[299px] tw-min-h-[100%] sm:tw-min-w-[400px] tw-rounded-xl tw-relative;
  margin-right: 50px;
  display: inline-flex;
  white-space: wrap;
}

.line-shadow {
  box-shadow: 1px 1px 0px 0px #000;
}
</style>
