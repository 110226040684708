<template>
  <div
    v-if="isModalOpen"
    class="tw-fixed tw-inset-0 tw-top-0 tw-z-[51] tw-w-full tw-h-full tw-overflow-hidden tw-flex tw-overflow-y-auto tw-z-100"
  >
    <div
      class="tw-mx-auto tw-w-full tw-max-w-[980px] tw-bg-white tw-m-auto bbox-shadow tw-relative"
      v-click-outside="onClickAwayFn"
    >
      <div
        class="tw-fixed tw-z-50 tw-w-full tw-h-[72px] tw-bg-white tw-flex tw-flex-row tw-justify-between tw-items-center tw-py-3.5 tw-px-8 tw-text-gray-800"
      >
        <div>
          <button class="tw-outline-none tw-pr-2 tw-pt-2" @click="closeFilters()" @keydown.enter="closeFilters()">
            <BaseIcon icon-name="cross" :height="10" :width="10" :viewbox-height="12" :viewbox-width="12">
              <Close />
            </BaseIcon>
          </button>
        </div>
        <div class="tw-font-bold">{{ $t('filters') }}</div>
        <div>
          <button
            class="tw-text-blue-700 tw-font-bold disabled:tw-text-gray-400"
            :disabled="tempFiltersArray.length === 0"
            @click="resetAllFilters"
          >
            {{ $t('rc_filter_reset') }}
          </button>
        </div>
      </div>
      <div class="tw-flex tw-flex-row tw-mb-[76px] tw-mt-[72px] tw-min-h-screen">
        <div
          :key="tempFiltersArray"
          class="tw-flex tw-flex-col tw-px-8 tw-h-full tw-h-[calc(100vh-150px)] tw-w-full tw-overflow-auto"
        >
          <ModalSelectFilter
            :label="$t('rc_performance_timeframe')"
            :options="timeframeOptions"
            name="timeframe"
            :value="selectedTimeframe"
            @onSelect="onSelect"
          />
          <div class="tw-border-t tw-border-gray-200 tw-h-[1px] tw-w-full tw-my-3" />
          <ModalSelectFilter
            v-if="selectedArea === 'bookings'"
            :options="dateTypes"
            @onSelect="onSelect"
            name="dateType"
            :label="$t('rc_checkin_checkout_menu')"
            :value="selectedDateType"
          />
          <div v-if="selectedArea === 'bookings'" class="tw-border-t tw-border-gray-200 tw-h-[1px] tw-w-full tw-my-3" />
          <ModalSelectFilter
            v-if="selectedArea === 'overview'"
            :label="$t('rc_performance_group_data')"
            :options="aggregationOptions"
            name="aggregation"
            :value="selectedAggregation"
            @onSelect="onSelect"
          />
          <div v-if="selectedArea === 'overview'" class="tw-border-t tw-border-gray-200 tw-h-[1px] tw-w-full tw-my-3" />
          <ModalSelectFilter
            v-if="selectedArea === 'overview' && selectedDisplayType === 'graph'"
            :label="$t('rc_performance_category')"
            :options="showGraphOptions"
            name="category"
            :value="selectedShowGraphValue"
            @onSelect="onSelect"
          />
          <div
            class="tw-border-t tw-border-gray-200 tw-h-[1px] tw-w-full tw-my-3"
            v-if="selectedArea === 'overview' && selectedDisplayType === 'graph'"
          />
          <ModalStatusSelectFilter
            v-if="selectedArea === 'bookings'"
            name="status"
            :label="$t('rc_booking_status_header')"
            :options="statusOptions"
            :value="selectedStatus"
            @onSelect="onSelect"
          />
          <div v-if="selectedArea === 'bookings'" class="tw-border-t tw-border-gray-200 tw-h-[1px] tw-w-full tw-mt-3" />

          <ModalMarketSelectFilter
            name="markets"
            :label="$t('rc_performance_markets')"
            :options="marketsOptions"
            :value="selectedMarket"
            @onSelect="onSelect"
            :class="selectedArea === 'bookings' ? 'tw-mt-3 tw-mb-0' : 'tw-mb-6'"
          />

          <div v-if="selectedArea === 'bookings'" class="tw-border-t tw-border-gray-200 tw-h-[1px] tw-w-full tw-mt-3" />
          <ModalColumnsSelector
            v-if="selectedArea === 'bookings'"
            name="columns"
            :label="$t('rc_column_picker')"
            :options="columnsOptions"
            :value="null"
            @onSelect="onSelect"
            class="tw-mb-8"
            :class="{ 'tw-mt-3': selectedArea === 'bookings' }"
          />
        </div>
      </div>

      <div
        class="footer shadow tw-fixed tw-bottom-0 tw-w-full tw-flex tw-flex-row tw-justify-between tw-items-center tw-py-4 tw-px-8 tw-text-gray-800 tw-bg-white"
      >
        <div class="md:tw-flex lg:tw-hidden tw-w-full">
          <MainButton class="tw-w-full" @click="onApply">{{ $t('Show results') }}</MainButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import ModalSelectFilter from './ModalSelectFilter.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import Close from '@/components/icons/Close.vue'
import ModalMarketSelectFilter from './ModalMarketSelectFilter.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'

import { useRateConnectPerformance } from '@/components/rateConnect/performance/useRateConnectPerformance.js'
import ModalStatusSelectFilter from './ModalStatusSelectFilter.vue'
import ModalColumnsSelector from './ModalColumnsSelector.vue'

defineProps({
  isModalOpen: Boolean,
  selectedArea: String,
  currentFilters: Array,
  selectedDisplayType: String,
  selectedDateType: Object,
  dateTypes: Array,
  timeframeOptions: Array,
  selectedTimeframe: Object,
  aggregationOptions: Array,
  selectedAggregation: Object,
  marketsOptions: Array,
  selectedMarket: Object,
  showGraphOptions: Array,
  selectedShowGraphValue: Object,
  selectedStatus: Array,
  statusOptions: Array,
  columnsOptions: Array,
})

const { resetFilters, appliedFiltersAmount } = useRateConnectPerformance()

const emit = defineEmits(['closeFilters', 'onApply'])
const closeFilters = () => {
  emit('closeFilters')
}
const onApply = () => emit('onApply', tempFiltersArray)
const onSelect = value => {
  const index = tempFiltersArray.value.findIndex(item => item.name === value.name)
  if (index !== -1) {
    tempFiltersArray.value[index] = value
  } else {
    tempFiltersArray.value.push(value)
  }
  appliedFiltersAmount.value = tempFiltersArray.value.length
}
const resetAllFilters = () => {
  tempFiltersArray.value = []
  resetFilters()
}

const onClickAwayFn = () => {
  emit('closeFilters')
}

const tempFiltersArray = ref([])
</script>
<style scoped>
.shadow {
  box-shadow: 0px 4px 16px 0px #00000026;
}
.box-shadow {
  @media (min-width: theme('screens.landing')) {
    box-shadow: 0px 10px 30px 0px #293336bf;
  }
}
</style>
