<template>
  <div class="tw-relative">
    <div class="tw-overflow-x-auto tw-w-full" ref="tableContainer" @scroll="updateShadow">
      <table class="tw-w-full tw-border-0 tw-text-sm tw-text-gray-800 tw-border-separate tw-border-spacing-0">
        <thead class="tw-table-header-group tw-text-xs tw-text-gray-800 tw-bg-white">
          <tr class="tw-border-b-1 tw-px-6 tw-py-5">
            <th
              v-for="column in columns"
              :key="column?.id"
              scope="col"
              class="tw-px-4 tw-py-5 tw-relative tw-border-l-0 tw-border-y-gray-500"
              :class="[getHeaderClasses(column), hasShadow ? 'shadow' : 'divider']"
              @click="sortTable(column)"
              @keydown.enter="sortTable(column)"
            >
              <UiTooltip :label="getHint(column)" tooltip-position="top" theme="customdarkwithpadding">
                <span class="tw-flex tw-items-center tw-relative nowrap">
                  {{ $t(column.header).replaceAll('-', '&#x2011;') }}
                  <div v-if="column.header.length > 1 && column.sortable" class="tw-relative">
                    <BaseIcon
                      :height="10"
                      :width="11"
                      class="tw-ml-2"
                      v-if="column.id !== sortColumn || (column.id === sortColumn && sortingType === 'ascending')"
                      :class="
                        column.id === sortColumn && sortingType === 'ascending'
                          ? 'tw-stroke-blue-700'
                          : 'tw-stroke-gray-800'
                      "
                    >
                      <ChevronUp />
                    </BaseIcon>
                    <BaseIcon
                      :height="4"
                      :width="11"
                      class="tw-ml-2"
                      v-if="column.id !== sortColumn || (column.id === sortColumn && sortingType === 'descending')"
                      :class="
                        column.id === sortColumn && sortingType === 'descending'
                          ? 'tw-stroke-blue-700'
                          : 'tw-stroke-gray-800'
                      "
                    >
                      <ChevronDown />
                    </BaseIcon>
                  </div>
                </span>
              </UiTooltip>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="row in getData()" :key="row.requestId">
            <tr class="tw-text-gray-800 tw-bg-white table-row">
              <td
                v-for="column in columns"
                :key="column.id"
                scope="row"
                class="cell tw-px-3 tw-py-4 tw-whitespace-nowrap tw-border-l-0 tw-border-t-1 tw-border-b-1 tw-relative tw-border-gray-200"
                :class="[getClass(column), hasShadow ? 'shadow' : 'divider']"
              >
                <div>
                  <UiTooltip
                    v-if="column.id === 'bookingId'"
                    :label="row['bookingId'] !== null && row['bookingId'].length > 20 ? row['bookingId'] : ''"
                    tooltip-position="top"
                    theme="customdarkwithpadding"
                  >
                    <span class="tw-flex tw-justify-start">
                      <span>
                        {{ row[column.id] ? trim(row[column.id], 20) : '-' }}
                      </span>
                    </span>
                  </UiTooltip>
                  <span v-else-if="column.id === 'status'" class="badge" :class="`${row[column.id]}-badge`">
                    {{ row[column.id] !== null ? $t(bookingStatusTranslationsMap[row[column.id]].status) : '-' }}
                  </span>

                  <UiTooltip
                    v-else-if="column.id === 'market'"
                    :label="row[column.id] !== null ? $t(`locale_${row[column.id]}`) : ''"
                    tooltip-position="top"
                    theme="customdarkwithpadding"
                  >
                    <span v-if="column.id === 'market'">
                      <p v-if="row[column.id] !== '**'" class="tw-flex tw-items-center tw-justify-start">
                        <span
                          class="flag tw-w-[20px] tw-h-[12px] tw-mr-2"
                          :class="row[column.id] === null ? '' : `flagImage flag-${row[column.id]?.toLowerCase()}`"
                        />
                        <span>{{ row[column.id] !== null ? row[column.id] : '-' }}</span>
                      </p>
                      <p v-else>{{ $t('rc_bookings_list_unavailable') }}</p>
                    </span>
                  </UiTooltip>
                  <UiTooltip
                    v-else-if="column.id === 'bookingValue.amountCampaignCurrency'"
                    :label="
                      getRowValue('bookingValue.localCurrency', row) === currency
                        ? ''
                        : `${$t('rc_bookings_list_original_value', {
                            amount: new Intl.NumberFormat(language.value, {
                              style: 'currency',
                              currency: getRowValue('bookingValue.localCurrency', row),
                            }).format(getRowValue('bookingValue.amountLocalCurrency', row)),
                          })}`
                    "
                    theme="customdarkwithpadding"
                    tooltip-position="top"
                  >
                    <span class="tw-flex tw-items-center tw-justify-end">{{
                      formatTableCell(getRowValue(column.id, row), column.colType, row, column)
                    }}</span>
                  </UiTooltip>
                  <UiTooltip
                    v-else-if="column.id === 'bookingValue.commissionCost'"
                    theme="customdarkwithpadding"
                    :label="`${$t('rc_list_bookings_commission', {
                      value: formatTableCell(row['cpaLevel'], 'percentage', row, column),
                    })}
                      `"
                    tooltip-position="top"
                  >
                    <span class="tw-flex tw-items-center tw-justify-end">{{
                      formatTableCell(getRowValue(column.id, row), column.colType, row, column)
                    }}</span>
                  </UiTooltip>
                  <UiTooltip v-else label="" theme="customdarkwithpadding" tooltip-position="top">
                    <span :class="column.id === 'refundAmount' ? 'tw-flex tw-items-center tw-justify-end' : ''">
                      {{ formatTableCell(getRowValue(column.id, row), column.colType, row, column) }}
                    </span>
                  </UiTooltip>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <div v-if="hasShadow && !isScrolledToRight" class="scroll-shadow"></div>
    </div>
    <div class="tw-flex tw-justify-center tw-text-sm tw-items-center tw-px-6 tw-py-5">
      <GhostButton
        size="medium"
        :disabled="loadedElements === tableRows.length || loadedElements > tableRows.length"
        @click="loadMore()"
        @keydown.enter="loadMore()"
      >
        {{ $t('show_more') }}
      </GhostButton>
    </div>
  </div>
</template>
<script setup>
import { computed, ref, onMounted, onUnmounted, watch, onUpdated } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import ChevronUp from '@/components/icons/ChevronUp.vue'
import ChevronDown from '@/components/icons/ChevronDown.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import { useRateConnectPerformance } from '@/components/rateConnect/performance/useRateConnectPerformance.js'

const store = useStore()
const { t } = useI18n()
const props = defineProps({
  columns: {
    type: Array,
    default: () => [],
  },
  data: {
    type: Array,
    default: () => [],
  },
  currency: {
    type: String,
    required: true,
    default: '',
  },
  hasEmptyTable: Boolean,
})

const { selectedDateType } = useRateConnectPerformance()
const language = computed(() => store.state.locale.language)
const loadedElements = ref(props.data?.length < 6 ? props.data?.length : 6)
const sortingType = ref('ascending')
const sortColumn = ref(selectedDateType.value)
const tableRows = ref(props.data)
const campaignTypes = { netCpa: 'rc_pay_per_stay', cpc: 'rc_pay_per_click', cpa: 'rc_pay_per_booking', unknown: '' }
const tableContainer = ref(null)
const hasShadow = ref(false)
const isScrolledToRight = ref(false)
const bookingStatusTranslationsMap = ref({
  completed: {
    status: 'rc_completed_stay_pay_per_stay',
  },
  cancelled: {
    status: 'rc_canceled_booking_pay_per_stay',
  },
  confirmed: {
    status: 'rc_confirmed_booking_pay_per_stay',
  },
})

watch(selectedDateType, newValue => {
  sortColumn.value = newValue
})

watch(
  () => props,
  newProps => {
    tableRows.value = newProps.data
    loadedElements.value = newProps.data?.length < 6 ? newProps.data?.length : 6
  },
  { deep: true, immediate: true }
)

const updateShadow = () => {
  if (tableContainer.value) {
    hasShadow.value = tableContainer.value.scrollWidth > tableContainer.value.clientWidth
    isScrolledToRight.value =
      Math.round(tableContainer.value.scrollLeft) + Math.round(tableContainer.value.clientWidth) + 1 >=
      Math.round(tableContainer.value.scrollWidth)
  }
}

onUpdated(() => updateShadow())

onMounted(() => {
  updateShadow()

  window.addEventListener('resize', updateShadow)
})

onUnmounted(() => {
  window.removeEventListener('resize', updateShadow)
})

const trim = (value, trimLength) => {
  const shouldTrim = value && value.length > trimLength
  return shouldTrim ? value.substring(0, trimLength - 3) + '...' : value
}
const getRowValue = (key, row) => {
  if (key.includes('.')) {
    return key.split('.').reduce((acc, item) => {
      return acc[item]
    }, row)
  } else {
    return row[key] === undefined ? 0 : row[key]
  }
}

const formatTableCell = (value, type, row, column) => {
  if (props.hasEmptyTable) return '-'
  if (column.id === 'cancelledDate' && value === null) {
    return '-'
  }
  if (row['campaignType'] === 'cpc' && (column.id === 'cancelledDate' || column.id === 'refundAmount')) {
    return '-'
  } else {
    if (type === 'percentage') {
      return new Intl.NumberFormat(language.value, { style: 'percent' }).format(value / 100)
    } else if (type === 'currency') {
      return new Intl.NumberFormat(language.value, { style: 'currency', currency: props.currency }).format(value)
    } else if (type === 'date' && value !== undefined) {
      if (value !== 0) {
        value = new Date(value)
        value =
          value === 0
            ? '-'
            : new Intl.DateTimeFormat(language.value, {
                timeZone: 'UTC',
              }).format(value)
        return value
      } else return '-'
    } else if (type === 'dateshort' && value !== undefined) {
      return value === null ? '' : value
    } else if (type === 'campaignType') {
      return t(campaignTypes[value])
    } else {
      return value
    }
  }
}

const getClass = column => {
  let classes = [`${'bookingLog'}_${column.className}`]
  if (column.id === sortColumn.value) {
    classes.push('md:tw-text-blue-700')
  }

  if (column.id === 'bookingId' && hasShadow.value) {
    classes.push('sticky')
  } else if (column.id === 'bookingId' && !hasShadow.value) {
    classes.push('sticky-scroll')
  } else {
    classes.push('tw-border-r-0')
  }
  return classes.toString().replaceAll(',', ' ')
}

const getData = () => {
  return tableRows.value.slice(0, loadedElements.value)
}

const loadMore = () => {
  if (tableRows.value.length > loadedElements.value) {
    if (tableRows.value.length > loadedElements.value + 10) {
      loadedElements.value = loadedElements.value + 10
    } else {
      loadedElements.value = loadedElements.value + (tableRows.value.length - loadedElements.value)
    }
    getData()
  }
}

const sortTable = col => {
  if (col.id !== 'bookingId') {
    if (sortColumn.value === col.id) {
      sortingType.value = sortingType.value === 'ascending' ? 'descending' : 'ascending'
    } else {
      sortingType.value = 'ascending'
      sortColumn.value = col.id
    }

    var sortTypeTemp = sortingType.value

    tableRows.value.sort(function (a, b) {
      if (col.id === 'date') {
        if (a[col.id].start > b[col.id].start) {
          return sortTypeTemp === 'ascending' ? 1 : -1
        } else if (a[col.id].start < b[col.id].start) {
          return sortTypeTemp === 'ascending' ? -1 : 1
        }
      } else if (col.colType === 'date') {
        if (new Date(getRowValue(col.id, a)) > new Date(getRowValue(col.id, b))) {
          return sortTypeTemp === 'ascending' ? 1 : -1
        } else if (new Date(getRowValue(col.id, a)) < new Date(getRowValue(col.id, b))) {
          return sortTypeTemp === 'ascending' ? -1 : 1
        }
      } else {
        if (getRowValue(col.id, a) > getRowValue(col.id, b)) {
          return sortTypeTemp === 'ascending' ? 1 : -1
        } else if (getRowValue(col.id, a) < getRowValue(col.id, b)) {
          return sortTypeTemp === 'ascending' ? -1 : 1
        }
      }
      return 0
    })
  }
}

const getHint = column => {
  return column?.hint === undefined ? '' : t(column.hint)
}

const getHeaderClasses = column => {
  let classes = []

  if (column.sortable) {
    classes.push('table-header')
  }
  if (column.id === sortColumn.value) {
    classes.push('tw-text-blue-700 tw-bg-blue-100 ')
  }
  if (column.id === 'campaignType' && props.columns.length > 6) {
    classes.push('tw-min-w-[200px] tw-w-[200px] tw-border-r-0')
  } else if (column.id === 'status' && props.columns.length > 6) {
    classes.push('tw-min-w-[140px] tw-w-[140px] tw-border-r-0')
  } else if (column.id === 'bookingId' && hasShadow.value) {
    classes.push('sticky')
  } else if (column.id === 'bookingId' && !hasShadow.value) {
    classes.push('sticky-scroll')
  } else {
    classes.push('tw-border-r-0')
  }
  return classes.join(' ')
}
</script>
<style scoped>
table thead th,
table tbody td {
  font-size: 14px;
}

table::after {
  @apply tw-absolute tw-w-full tw-h-[1px] tw-bg-gray-300 tw-block;
  content: '';
}

.table-header:hover {
  cursor: pointer;
  @apply tw-bg-blue-100;
}
.icon-fill {
  fill: theme('colors.blue.700');
}

.top-sort-arrow {
  right: 0;
  top: -1px;
}

.bottom-sort-arrow {
  right: 0;
  top: 6px;
}
.table-row:hover .cell {
  @apply tw-bg-blue-100;
  cursor: pointer;
}

.bottom-shadow {
  box-shadow: 0 3px 3px -1px rgba(0, 0, 0, 0.22);
  transform: scale(1);
  @apply tw-pr-11;
}

.sticky {
  @apply tw-sticky tw-left-0 tw-z-10 tw-min-w-[150px] tw-w-[150px] tw-border-r-0 tw-bg-white;
  transition: box-shadow 0.3s ease;
}

.sticky-scroll {
  @apply tw-sticky tw-left-0 tw-z-10 tw-min-w-[150px] tw-w-[150px] tw-border-r-0;
  transition: box-shadow 0.3s ease;
}
.sticky.shadow::after {
  content: '';
  position: absolute;
  top: 0;
  right: -8px;
  width: 8px;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.09), rgba(0, 0, 0, 0));
  pointer-events: none;
}

.sticky-scroll.divider::after {
  border-right: 1px solid theme('colors.gray.200');
  content: '';
  position: absolute;
  top: 0px;
  height: 100%;
  right: -1px;
}

.scroll-shadow {
  position: absolute;
  top: 0;
  right: 0;
  width: 120px;
  height: calc(100% - 76px);
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)); /* White shadow */
  pointer-events: none;
}

.badge {
  @apply tw-rounded-full tw-text-xs tw-px-3 tw-py-1 tw-relative tw-text-black;
  line-height: 1.43rem;
  @media (max-width: theme('screens.md')) {
    padding: 0 12px;
  }
}
.cancelled-badge {
  @apply tw-bg-red-200;
}
.completed-badge {
  @apply tw-bg-green-200;
}
.cell {
  @media (max-width: theme('screens.md')) {
    width: 100%;
  }
}
.confirmed-badge {
  @apply tw-bg-gray-200;
}

.flag {
  left: 1.6rem;
  background-size: 20px auto;
  top: 1.6rem;
  background-position: 0 -30px;
}
.flagImage {
  background-image: url(https://imgcy.trivago.com/q_auto/v1/hardcodedimages/imagemap_all_flags_21@2x.png);

  background-repeat: no-repeat;
}
.flag-at {
  background-position: 0 -810px;
}
.flag-de {
  background-position: 0 0;
}
.flag-en,
.flag-uk {
  background-position: 0 -30px;
  height: 11px;
}
.flag-es {
  background-position: 0 -60px;
}
.flag-fr {
  background-position: 0 -90px;
}
.flag-se {
  background-position: 0 -120px;
}
.flag-pl {
  background-position: 0 -150px;
}
.flag-ru {
  background-position: 0 -180px;
}
.flag-it {
  background-position: 0 -210px;
}
.flag-gr {
  background-position: 0 -240px;
}
.flag-nl {
  background-position: 0 -270px;
}
.flag-rs {
  background-position: 0 -300px;
}
.flag-tr {
  background-position: 0 -330px;
}
.flag-ro {
  background-position: 0 -360px;
}
.flag-ar {
  background-position: 0 -390px;
}
.flag-ie {
  background-position: 0 -870px;
}
.flag-id {
  background-position: 0 -1290px;
}
.flag-hu {
  background-position: 0 -900px;
}
.flag-br {
  background-position: 0 -450px;
}
.flag-zh,
.flag-cn {
  background-position: 0 -480px;
}
.flag-us {
  background-position: 0 -510px;
}
.flag-fi {
  background-position: 0 -540px;
}
.flag-jp {
  background-position: 0 -570px;
}
.flag-mx {
  background-position: 0 -600px;
}
.flag-pt {
  background-position: 0 -630px;
}
.flag-cs,
.flag-cz {
  background-position: 0 -840px;
}
.flag-bg {
  background-position: 0 -420px;
}
.flag-da,
.flag-dk {
  background-position: 0 -690px;
}
.flag-no {
  background-position: 0 -720px;
}
.flag-be {
  background-position: 0 -750px;
}
.flag-so,
.flag-si {
  background-position: 0 -780px;
}
.flag-ch {
  background-position: 0 -930px;
}
.flag-ca {
  background-position: 0 -990px;
  height: 12px;
}
.flag-nz {
  background-position: 0 -1050px;
}
.flag-au {
  background-position: 0 -960px;
  height: 12px;
}
.flag-co {
  background-position: 0 -1200px;
  height: 12px;
}
.flag-ko,
.flag-kr {
  background-position: 0 -1020px;
}
.flag-cl {
  background-position: 0 -1170px;
  height: 12px;
}
.flag-sg {
  background-position: 0 -1140px;
}
.flag-in {
  background-position: 0 -1080px;
}
.flag-hk {
  background-position: 0 -1110px;
}
.flag-ae {
  background-position: 0 -1320px;
}
.flag-ms,
.flag-my {
  background-position: 0 -1230px;
}
.flag-th {
  background-position: 0 -1260px;
}
.flag-he,
.flag-il {
  background-position: 0 -1350px;
}
.flag-za {
  background-position: 0 -1380px;
}
.flag-tw {
  background-position: 0 -1410px;
}
.flag-ph {
  background-position: 0 -1470px;
}
.flag-world_wide {
  background-position: 0 -1514px;
}
.flag-vn {
  background-position: 0 -1440px;
}
.flag-aa,
.flag-shabaka {
  background-position: 0 -1530px;
}
.flag-hr {
  background-position: 0 -1560px;
}
.flag-sk {
  background-position: 0 -1590px;
}
.flag-pe {
  background-position: 0 -1620px;
}
.flag-ec {
  background-position: 0 -1650px;
}
.flag-uy {
  background-position: 0 -1680px;
}
.flag-ng {
  background-position: 0 -1710px;
}
</style>
