<template>
  <div>
    <StudioPlusReactivationModal
      v-if="studioPlusCampaign !== null && isAdminUser"
      :isOpen="isReactivationOpen"
      :expired="isExpiredSubscription"
      :cancelled="isCancelledSubscription"
      :accommodationId="{ value: selectedAccommodationId }"
      :accommodationName="currentAccommodation.name"
      source="home"
      @closeModal="toggleReactivateSubscription('close')"
    />

    <RateConnectUpsellPopup v-if="showUpsellPopup" :isAdminUser="isAdminUser" />

    <NoAccommodationCard v-if="hasNoAccommodations" />
    <div v-else class="tw-flex tw-flex-col tw-py-10">
      <div class="tw-px-4 tw-w-full md:tw-max-w-screen-lg lg:tw-max-w-screen-xl tw-mr-auto tw-ml-auto">
        <StudioPlusUpdateDataBanner
          v-if="showPlusPROSubscriptionBanner && showUpdateData && showUpdateBanner"
          @hideBanner="hideUpdateDataBanner"
        />

        <ProductCarouselContainer
          v-if="showStudioPlus || isRCEligible"
          :isAdmin="isAdminUser"
          :isRCUpsell="showUpsell"
          :isRCResume="showResumeBanner"
          :isStudioPlus="showStudioPlus"
          :isLoading="isBannersDataLoading"
        />
        <CarouselContainer v-if="!showStudioPlus && !isRCEligible && !showPlusPROSubscriptionBanner" class="tw-mb-8" />
        <StudioPlusSubscriptionInactiveBanner
          class="tw-mb-8"
          v-if="
            isCancelledSubscription &&
            studioPlusCampaign !== null &&
            isPlus &&
            studioPlusCampaign?.term.toLowerCase() !== 'monthly'
          "
          @openReactivationModal="toggleReactivateSubscription('open')"
        />

        <ProSubscriptionBanner
          class="tw-mb-8"
          v-if="showPlusPROSubscriptionBanner && !isExpiredSubscription && isPro"
        />

        <StudioPlusSubscriptionActiveBanner
          class="tw-mb-8"
          v-if="showPlusPROSubscriptionBanner && !isExpiredSubscription && !isCancelledSubscription && isPlus"
        />

        <ProfilePerformanceContainer class="tw-mb-8" />

        <StudioPlusSubscriptionInactiveBanner
          class="tw-mb-8"
          v-if="
            studioPlusCampaign?.term.toLowerCase() !== 'monthly' &&
            isExpiredSubscription &&
            studioPlusCampaign !== null &&
            isPlus
          "
          @openReactivationModal="toggleReactivateSubscription('open')"
        />
        <GuestRatingsContainer />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import ProfilePerformanceContainer from '@/components/profilePerformance/MainContainer.vue'
import CarouselContainer from '@/components/dashboard/carousel/CarouselContainer.vue'
import GuestRatingsContainer from '@/components/guestRatings/GuestRatingsContainer.vue'
import NoAccommodationCard from '@/components/dashboard/NoAccommodationCard.vue'
import RateConnectUpsellPopup from '@/components/dashboard/rateConnectUpsell/RateConnectUpsellPopup.vue'
import StudioPlusUpdateDataBanner from '@/components/studioPlus/StudioPlusUpdateDataBanner.vue'
import StudioPlusSubscriptionActiveBanner from '@/components/studioPlus/StudioPlusSubscriptionActiveBanner.vue'
import StudioPlusSubscriptionInactiveBanner from '@/components/studioPlus/StudioPlusSubscriptionInactiveBanner.vue'
import StudioPlusReactivationModal from '@/components/settings/subscriptions/reactivation/StudioPlusReactivationModal.vue'
import ProSubscriptionBanner from '@/components/pro/ProSubscriptionBanner.vue'
import ProductCarouselContainer from '@/components/dashboard/productCarousel/ProductCarouselContainer.vue'

import useRateConnect, { usePartners } from '@/components/rateConnect/queries'

import { RC_BLOCKED_COUNTRIES } from '@/constants/rcBlockedCountries.js'

import { useStudioPlusCampaign, useStudioPlusPrice } from '@/components/payment/queries'
import { selectedAccommodationId, useAccommodationsByOrgId } from '@/layouts/queries'
import appAnalytics from '@/utils/tracking'

const route = useRoute()
const store = useStore()
const showUpdateBanner = ref(true)
const isReactivationOpen = ref(false)

const toggleReactivateSubscription = action => {
  if (action === 'open') {
    isReactivationOpen.value = true
  }
  if (action === 'close') {
    isReactivationOpen.value = false
  }
}

const selectedOrganisationId = computed(() => store.state.session.selectedOrganisation?.id)
const { accommodationsData } = useAccommodationsByOrgId(selectedOrganisationId)
const currentAccommodation = computed(() =>
  accommodationsData.value?.find(accommodation => accommodation.accommodationId === selectedAccommodationId.value)
)
const { hotelierData, campaignsData, isStartUpFetching, isStartUpLoading } = useRateConnect(selectedAccommodationId)
const hotelierDataLoading = computed(() => isStartUpLoading.value || isStartUpFetching.value)
const { partnersData } = usePartners(selectedAccommodationId)
const hasNoAccommodations = computed(() => store.state.session.organisations?.length === 0)
const userRole = computed(() => store.state.session?.userRole)

const currency = computed(() => store.state.currency)
const {
  isLoading: isStudioPlusCampaignLoading,
  isFetching: isStudioPlusCampaignFetching,
  studioPlusCampaign,
  isSuccess: isStudioPlusCampaignSuccess,
  isCancelledSubscription,
  isPro,
  isPlus,
  isExpiredSubscription,
} = useStudioPlusCampaign(selectedAccommodationId)
const { isSuccess: isStudioPlusPriceSuccess } = useStudioPlusPrice({
  selectedAccommodationId,
  currency,
})
const isStudioPlusDataLoadSuccess = computed(() => isStudioPlusPriceSuccess.value && isStudioPlusCampaignSuccess.value)
const isBannersDataLoading = computed(
  () => isStudioPlusCampaignLoading.value || isStudioPlusCampaignFetching.value || hotelierDataLoading.value
)
const showPaidProduct = computed(() => !RC_BLOCKED_COUNTRIES.includes(currentAccommodation.value?.countryId))

const showResumeBanner = computed(() => {
  if (showPaidProduct?.value && campaignsData?.value?.overall?.status === 'inactive') return true
  else return false
})

const showUpsell = computed(() => {
  if (!isStudioPlusDataLoadSuccess.value) return false
  if (!hotelierData.value?.isEnabledForRateConnect) return false
  if (!partnersData?.value) return false
  if (hotelierData.value?.hasRateConnect === true) return false
  if (!isNetCPACampaign.value) return false
  if (!showPaidProduct.value) return false

  return true
})

const showUpsellPopup = computed(
  () => showUpsell.value === true && window.sessionStorage?.getItem?.('cpa_upsell_skip') !== 'true'
)

const isRCEligible = computed(() => {
  if (!isStudioPlusDataLoadSuccess.value) return false
  if (!hotelierData.value?.isEnabledForRateConnect) return false
  if (!partnersData?.value) return false
  if (!isNetCPACampaign.value) return false
  if (!showPaidProduct.value) return false
  return true
})

const isAdminUser = computed(() => userRole.value === 'admin')

const isAccommodationEligibleForStudioPlus = computed(() => {
  if (!isStudioPlusDataLoadSuccess.value) return false
  if (!isExpiredSubscription.value && (isPlus.value || isPro.value)) return false
  if (!showPaidProduct.value) return false
  return true
})

const showStudioPlus = computed(() => isAccommodationEligibleForStudioPlus.value)

const showUpdateData = computed(() => {
  const bannerInfo = window.localStorage?.getItem(`contact_info_banner_${selectedAccommodationId.value}`)
  const isBannerVisible = bannerInfo === null ? false : JSON.parse(bannerInfo)
  return isBannerVisible && studioPlusCampaign.value
})

// Checks if there is any plus or PRO subscription active
const showPlusPROSubscriptionBanner = computed(
  () =>
    studioPlusCampaign.value &&
    !(studioPlusCampaign.value?.endDate ? new Date() > new Date(studioPlusCampaign.value?.endDate) : false)
)

const isNetCPACampaign = computed(() => hotelierData.value?.eligibleCampaignTypes?.includes('netCpa'))

onMounted(() => {
  if (showStudioPlus.value) {
    const trafficSource = window.sessionStorage?.getItem?.('traffic_source')
    appAnalytics.track(appAnalytics.events.SP_HOMEPAGE_ONBOARDING_VISITED, {
      item_id: selectedAccommodationId.value,
      source: route.query ? route.query : 'SP_Eligable_Homepage',
      traffic_source: trafficSource ?? '',
    })
  }
})

watch(isPro, newIsPro => {
  if (newIsPro) {
    const isBanner = window.localStorage?.getItem(`contact_info_banner_${selectedAccommodationId.value}`)
    if (isBanner === null || isBanner === false) {
      window.localStorage.setItem(`contact_info_banner_${selectedAccommodationId.value}`, 'true')
    }
  }
})

const hideUpdateDataBanner = () => {
  showUpdateBanner.value = false
  window.localStorage.setItem(`contact_info_banner_${selectedAccommodationId.value}`, 'false')
  window.dispatchEvent(
    new CustomEvent('contact_info_banner_localstorage-changed', {
      detail: {
        storage: localStorage.getItem(`contact_info_banner_${selectedAccommodationId.value}`),
      },
    })
  )
}
</script>

<style scoped>
@media (max-width: theme('screens.mobile.max')) {
  .tw-min-h-screen {
    min-height: calc(theme('maxHeight.screen') - theme('spacing.16'));
  }
}
</style>
