<template>
  <div>
    <label :for="id" :class="{ 'tw-mb-0': !label }" class="tw-w-full">
      <div v-if="tooltip && label" class="tw-flex tw-items-center">
        <p class="tw-font-bold tw-mb-1">
          {{ label }}<span v-if="required" class="tw-text-red-700">{{ '*' }}</span>
        </p>
        <UiTooltip v-if="tooltip" tooltip-position="top" :label="tooltip" class="tw-cursor-pointer tw-ml-1">
          <BaseIcon
            icon-name="information-outline"
            :height="16"
            :width="16"
            class="tw-fill-none tw-stroke-gray-800 tw-h-[20px] tw-w-[20px]"
          >
            <InformationOutline />
          </BaseIcon>
        </UiTooltip>
      </div>
      <p v-else-if="label" class="tw-font-bold tw-mb-1">
        {{ label }}<span v-if="required" class="tw-text-red-700">{{ '*' }}</span>
      </p>
      <div class="tw-relative">
        <input
          :id="id"
          :value="value"
          :disabled="disabled"
          :type="type"
          v-bind="$attrs"
          :class="disabled ? getDisabledClass : getClass"
          @blur="$emit('onBlur', $event.target.value)"
          @change="$emit('onChange', $event.target.value)"
          @input="$emit('onInput', $event.target.value)"
          :data-testid="dataTestId"
        />
        <div v-if="withLoader" class="tw-absolute tw-top-[2px] tw-right-[20px]">
          <RCInlineLoader color="#D9D8D6" />
        </div>
        <div class="tw-absolute tw-top-[6px] tw-right-[20px]" v-if="successValidation && !withLoader">
          <BaseIcon icon-name="information-outline" class="tw-fill-none tw-stroke-green-700">
            <CheckmarkSimple />
          </BaseIcon>
        </div>
      </div>
    </label>
    <small v-if="error" class="tw-text-red-700" :errorTestId>{{ error }}</small>
    <small v-if="hint" class="tw-text-gray-700">{{ hint }}</small>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import InformationOutline from '@/components/icons/InformationOutline.vue'
import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'
import CheckmarkSimple from '@/components/icons/CheckmarkSimple.vue'

const props = defineProps({
  id: {
    type: String,
    default: 'textfield',
  },
  label: {
    type: [String, Number],
    required: false,
    default: '',
  },
  ariaLabel: {
    type: String,
    required: true,
  },
  value: {
    type: [String, null],
    default: '',
  },
  type: {
    type: String,
    required: false,
    default: 'text',
  },
  error: {
    type: [Boolean, String],
    default: false,
  },
  hint: {
    type: [String],
    required: false,
  },
  size: {
    type: String,
    default: 'medium',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  tooltip: {
    type: String,
    default: null,
  },
  required: {
    type: Boolean,
    default: false,
  },
  dataTestId: {
    type: String,
    default: '',
  },
  errorTestId: {
    type: String,
    default: '',
  },
  withLoader: {
    type: Boolean,
    default: false,
  },
  successValidation: {
    type: Boolean,
    default: false,
  },
})

const getDisabledClass = computed(() => {
  const commonClass = 'tw-appearance-none  tw-w-full focus:tw-outline-0 tw-px-3 tw-rounded-lg'
  const disableClass = 'disabled:tw-bg-gray-200 disabled:tw-text-gray-500'
  return props.error
    ? `${commonClass} ${disableClass} ${sizeClass.value} tw-bg-red-200 tw-border-red-700`
    : `${commonClass} ${disableClass} ${sizeClass.value} tw-border tw-border-gray-500`
})
const getClass = computed(() => {
  const commonClass = 'tw-appearance-none  tw-w-full focus:tw-text-gray-700 focus:tw-outline-0 tw-px-3 tw-rounded-lg'
  const baseClass = 'tw-bg-white tw-border tw-text-gray-800 focus:tw-border-blue-800 focus:tw-text-blue-800 '
  return props.error
    ? `${commonClass} ${baseClass} ${sizeClass.value} tw-bg-red-200 tw-border-red-700`
    : `${commonClass} ${baseClass} ${sizeClass.value} tw-border-gray-500`
})
const sizeClass = computed(() => {
  if (props.size === 'medium') return 'tw-px-4 tw-box-size-36 tw-text-sm tw-rounded-lg '
  else return 'tw-px-6 tw-box-size-44 tw-text-base tw-rounded-lg'
})
</script>
<style scoped>
input[type='date']::-webkit-calendar-picker-indicator {
  cursor: pointer;
  margin-inline-start: auto;
}
input[type='date'] {
  max-height: 38px;
}
label {
  @apply tw-mb-0;
}
</style>
