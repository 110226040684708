<template>
  <div class="tw-flex-col tw-flex tw-w-full md:tw-max-w-screen-lg lg:tw-max-w-screen-xl tw-mr-auto tw-ml-auto tw-mb-8">
    <RCRunningHeader class="tw-mb-8" />
    <router-view />
  </div>
</template>

<script>
import RCRunningHeader from '@/components/rateConnect/RCRunningHeader.vue'

export default {
  components: {
    RCRunningHeader,
  },
}
</script>
