import { computed } from 'vue'
import { useQuery } from '@tanstack/vue-query'

import AccommodationService from '@/services/accommodationService/Accommodation'

export const getTimeFramesTrends = async ({ accommodationId, timeframe }) => {
  const startDate = calculateStartDateFromTimeframe(timeframe)
  const response = await AccommodationService.fetchTimeFramesTrends({ accommodationId, startDate })
  return response.data
}

export function useTimeFrameTrends(accommodationId, timeframe) {
  const { data, isError, isLoading, isFetching, fetchStatus, isSuccess } = useQuery({
    enabled: !!accommodationId?.value,
    queryKey: ['timeFrameTrends', accommodationId, timeframe],
    queryFn: ({ queryKey }) =>
      getTimeFramesTrends({
        accommodationId: queryKey[1],
        timeframe: queryKey[2],
      }),
  })

  const isTimeFrameTrendsLoading = computed(() => isLoading.value && fetchStatus.value !== 'idle')

  return {
    isTimeFrameTrendsFetching: isFetching,
    isTimeFrameTrendsLoading,
    isTimeFrameTrendsSuccess: isSuccess,
    isTimeFrameTrendsError: isError,
    timeFrameTrends: data,
  }
}

export const getVisitorsProfileCountries = async ({ accommodationId, timeframe }) => {
  const startDate = calculateStartDateFromTimeframe(timeframe)
  const response = await AccommodationService.fetchVisitorsProfileCountries({ accommodationId, startDate })
  return response.data ?? []
}

export function useVisitorsProfileCountries(accommodationId, timeframe) {
  const { data, isError, isLoading, isFetching, fetchStatus, isSuccess } = useQuery({
    enabled: !!accommodationId?.value,
    queryKey: ['visitorsProfileCountries', accommodationId, timeframe],
    queryFn: ({ queryKey }) =>
      getVisitorsProfileCountries({
        accommodationId: queryKey[1],
        timeframe: queryKey[2],
      }),
  })
  const isVisitorsProfileCountriesLoading = computed(() => isLoading.value && fetchStatus.value !== 'idle')

  return {
    isVisitorsProfileCountriesFetching: isFetching,
    isVisitorsProfileCountriesLoading,
    isVisitorsProfileCountriesSuccess: isSuccess,
    isVisitorsProfileCountriesError: isError,
    visitorsProfileCountries: data,
  }
}

export const getVisitorsProfileDuration = async ({ accommodationId, timeframe }) => {
  const startDate = calculateStartDateFromTimeframe(timeframe)
  const response = await AccommodationService.fetchVisitorsProfileDuration({ accommodationId, startDate })
  return response.data
}

export function useVisitorsProfileDuration(accommodationId, timeframe) {
  const { isLoading, data, isFetching, isSuccess, isError, fetchStatus } = useQuery({
    enabled: !!accommodationId?.value,
    queryKey: ['visitorsProfileDuration', accommodationId, timeframe],
    queryFn: ({ queryKey }) => getVisitorsProfileDuration({ accommodationId: queryKey[1], timeframe: queryKey[2] }),
  })

  const isVisitorsProfileDurationLoading = computed(() => isLoading.value && fetchStatus.value !== 'idle')

  return {
    isVisitorsProfileDurationFetching: isFetching,
    isVisitorsProfileDurationLoading,
    isVisitorsProfileDurationSuccess: isSuccess,
    isVisitorsProfileDurationError: isError,
    visitorsProfileDuration: data,
  }
}

function calculateStartDateFromTimeframe(timeframe) {
  if (!timeframe) return ''

  const today = new Date()
  const date = today.setDate(today.getDate() - Number(timeframe))
  return new Date(date).toISOString().split('T')[0]
}
