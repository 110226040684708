<template>
  <div class="tw-relative">
    <div class="tw-overflow-x-auto tw-w-full table-wrapper" ref="overviewTableContainer" @scroll="updateShadow">
      <table class="tw-w-full tw-text-sm tw-text-right tw-text-gray-800 tw-border-separate tw-border-spacing-0">
        <thead class="tw-hidden md:tw-table-header-group tw-text-xs tw-text-gray-800 tw-bg-white">
          <tr class="tw-border-b-1 tw-px-6 tw-py-5">
            <th
              v-for="column in columns"
              :key="column?.id"
              scope="col"
              class="tw-px-4 tw-py-5 tw-relative tw-border-l-0 tw-border-y-gray-500 tw-min-w-[120px]"
              :class="[getHeaderClasses(column), hasShadow ? 'shadow' : 'divider']"
              @click="sortTable(column)"
              @keydown.enter="sortTable(column)"
            >
              <UiTooltip :label="getHint(column)" tooltip-position="top" theme="customdarkwithpadding">
                <span class="tw-flex tw-items-center tw-relative nowrap tw-text-left">
                  {{ $t(column?.header) }}
                  <div class="tw-relative">
                    <BaseIcon
                      :height="10"
                      :width="11"
                      class="tw-ml-2"
                      v-if="column.id !== sortColumn || (column.id === sortColumn && sortingType === 'ascending')"
                      :class="
                        column.id === sortColumn && sortingType === 'ascending'
                          ? 'tw-stroke-blue-700'
                          : 'tw-stroke-gray-800'
                      "
                    >
                      <ChevronUp />
                    </BaseIcon>
                    <BaseIcon
                      :height="4"
                      :width="11"
                      class="tw-ml-2"
                      v-if="column.id !== sortColumn || (column.id === sortColumn && sortingType === 'descending')"
                      :class="
                        column.id === sortColumn && sortingType === 'descending'
                          ? 'tw-stroke-blue-700'
                          : 'tw-stroke-gray-800'
                      "
                    >
                      <ChevronDown />
                    </BaseIcon>
                  </div>
                </span>
              </UiTooltip>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(row, index) in getData()" :key="index">
            <tr class="tw-text-gray-800 tw-bg-white table-row">
              <td
                v-for="column in columns"
                :key="column.id"
                scope="row"
                class="cell tw-px-3 tw-py-[17.5px] tw-whitespace-nowrap tw-border-l-0 tw-border-y-1 tw-relative tw-border-gray-200"
                :class="[getClass(column), hasShadow ? 'shadow' : 'divider']"
              >
                <div v-if="isIncompatibleMetric(row.campaignType, column.id)">
                  <UiTooltip
                    theme="customdarkwithpadding"
                    :label="getTooltipType(row.campaignType)"
                    tooltip-position="top"
                  >
                    <span class="tw-flex tw-items-center tw-justify-start">{{ '-' }}</span>
                  </UiTooltip>
                </div>
                <div v-else>
                  <UiTooltip
                    theme="customdarkwithpadding"
                    :label="getExplonationTip(row.campaignType, column.id)"
                    tooltip-position="top"
                  >
                    <span v-if="column.colType === 'date'" class="tw-flex tw-items-center tw-justify-start">
                      {{ getDateFormat(row[column.id].start, row[column.id].end) }}
                    </span>
                    <span
                      v-else-if="column.id === 'unavailability' && row[column.id] !== null"
                      class="tw-flex tw-items-center tw-justify-start"
                    >
                      {{ formatTableCell(row[column.id], column) }}
                    </span>
                    <span v-else-if="column.id === 'unavailability' && row[column.id] === null">
                      <UiTooltip
                        theme="customdarkwithpadding"
                        :label="$t('rc_blank_unavailability')"
                        class="unavailablity-row"
                        tooltip-position="top"
                      >
                        <span class="tw-flex tw-items-center tw-justify-start">{{ '-' }}</span></UiTooltip
                      >
                    </span>
                    <span v-else class="tw-flex tw-items-center tw-justify-start">
                      {{ formatTableCell(row[column.id], column) }}
                    </span>
                  </UiTooltip>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <div v-if="hasShadow && !isScrolledToRight" class="scroll-shadow"></div>
    </div>
    <div class="tw-flex tw-justify-center tw-text-sm tw-items-center tw-px-6 tw-py-5">
      <GhostButton
        size="medium"
        :disabled="
          loadedElementsOverviewTable === tableRows?.length ||
          loadedElementsOverviewTable > tableRows?.length ||
          tableRows?.length === 0 ||
          hasEmptyTable
        "
        @click="loadMore()"
        @keydown.enter="loadMore()"
      >
        {{ $t('show_more') }}
      </GhostButton>
      <div class="tw-hidden md:tw-flex" />
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import ChevronUp from '@/components/icons/ChevronUp.vue'
import ChevronDown from '@/components/icons/ChevronDown.vue'
import { CAMPAIGN_MODES, isCpaCampaign } from '@/utils/rateConnectUtils'
import { useRateConnectPerformance } from '../useRateConnectPerformance'

const { t } = useI18n()
const store = useStore()
const props = defineProps({
  columns: Array,
  data: Array,
  getSelectedPeriodCampaignType: String,
  getSelectedAggregation: String,
  currency: String,
  hasEmptyTable: Boolean,
})

const { loadedElementsOverviewTable } = useRateConnectPerformance()
const sortingType = ref('ascending')
const sortColumn = ref('date')
const tableRows = ref(props.data)
const hasShadow = ref(false)
const isScrolledToRight = ref(false)
const overviewTableContainer = ref(null)
const EXCLUDED_METRICS = ref({
  costs: [CAMPAIGN_MODES.CPA, CAMPAIGN_MODES.NET_CPA],
  cpa: [CAMPAIGN_MODES.CPA, CAMPAIGN_MODES.NET_CPA],
  cpc: [CAMPAIGN_MODES.CPA, CAMPAIGN_MODES.NET_CPA],
  commissionCost: [CAMPAIGN_MODES.CPC],
})

const locale = computed(() => store.state.locale.language)

const getHeaderClasses = column => {
  let classes = []

  if (column.sortable) {
    classes.push('table-header')
  }
  if (column.id === sortColumn.value) {
    classes.push('tw-text-blue-700 tw-bg-blue-100 ')
  }
  if (column.id === 'date' && hasShadow.value) {
    classes.push('sticky')
  } else if (column.id === 'date' && !hasShadow.value) {
    classes.push('sticky-scroll')
  } else {
    classes.push('tw-border-r-0')
  }
  return classes.join(' ')
}

const getClass = column => {
  let classes = [column.className]
  if (column.id === sortColumn.value) {
    classes.push('md:tw-text-blue-700')
  }

  if (column.id === 'date' && hasShadow.value) {
    classes.push('sticky')
  } else if (column.id === 'date' && !hasShadow.value) {
    classes.push('sticky-scroll')
  } else {
    classes.push('tw-border-r-0')
  }
  return classes.toString().replaceAll(',', ' ')
}

watch(
  () => props,
  newProps => {
    tableRows.value = newProps.data
    if (newProps.data?.length < 6) {
      loadedElementsOverviewTable.value = newProps.data?.length
    } else if (loadedElementsOverviewTable.value < 6 && newProps.data?.length > 6) {
      loadedElementsOverviewTable.value = 6
    }
  },
  { deep: true, immediate: true }
)

const updateShadow = () => {
  if (overviewTableContainer.value) {
    hasShadow.value = overviewTableContainer.value.scrollWidth > overviewTableContainer.value.clientWidth
    isScrolledToRight.value =
      Math.round(overviewTableContainer.value.scrollLeft) + Math.round(overviewTableContainer.value.clientWidth) + 1 >=
      Math.round(overviewTableContainer.value.scrollWidth)
  }
}

onMounted(() => {
  updateShadow()
  window.addEventListener('resize', updateShadow)
})

onUnmounted(() => {
  window.removeEventListener('resize', updateShadow)
})

const getDateFormat = (startDate, endDate) => {
  if (startDate === null && props.hasEmptyTable) return '-'
  if (endDate === null && props.hasEmptyTable) return '-'
  if (props.getSelectedAggregation === 'WEEKLY' || props.getSelectedAggregation === 'MONTHLY') {
    return `${new Date(startDate).toLocaleDateString(locale.value, {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    })} - ${new Date(endDate).toLocaleDateString(locale.value, {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    })}`
  } else {
    return new Date(startDate).toLocaleDateString(locale.value, {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    })
  }
}
const getData = () => {
  return tableRows.value?.slice(0, loadedElementsOverviewTable.value)
}
const loadMore = () => {
  if (tableRows.value?.length > loadedElementsOverviewTable.value) {
    loadedElementsOverviewTable.value = loadedElementsOverviewTable.value + 10
    getData()
  }
}

const formatTableCell = (value, column) => {
  if (props.hasEmptyTable) return '-'
  if (value !== null && value !== undefined) {
    if (column.colType === 'percentage') {
      return new Intl.NumberFormat(locale, { maximumFractionDigits: 1, style: 'percent' }).format(value)
    } else if (column.colType === 'number') {
      return new Intl.NumberFormat(locale, { style: 'decimal' }).format(value)
    } else if (column.colType === 'currency') {
      return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: props.currency,
      }).format(value)
    }
  } else return '0'
}

const sortTable = col => {
  if (sortColumn.value === col.id) {
    sortingType.value = sortingType.value === 'ascending' ? 'descending' : 'ascending'
  } else {
    sortingType.value = 'ascending'
    sortColumn.value = col.id
  }

  var sortTypeTemp = sortingType.value

  tableRows.value.sort(function (a, b) {
    if (col.id === 'date') {
      if (a[col.id].start > b[col.id].start) {
        return sortTypeTemp === 'ascending' ? 1 : -1
      } else if (a[col.id].start < b[col.id].start) {
        return sortTypeTemp === 'ascending' ? -1 : 1
      }
    } else {
      if (a[col.id] > b[col.id]) {
        return sortTypeTemp === 'ascending' ? 1 : -1
      } else if (a[col.id] < b[col.id]) {
        return sortTypeTemp === 'ascending' ? -1 : 1
      }
    }
    return 0
  })
}

const getHint = column => {
  return column?.hint === undefined ? '' : t(column.hint)
}

const isExplanationRequired = (campaignType, selectedPeriodCampaignType, metricName) => {
  return (
    campaignType !== CAMPAIGN_MODES.UNKNOWN &&
    selectedPeriodCampaignType === CAMPAIGN_MODES.BOTH &&
    EXCLUDED_METRICS.value[metricName]
  )
}
const isIncompatibleMetric = (campaignType, metricName) => {
  return EXCLUDED_METRICS.value[metricName]?.includes(campaignType)
}
const getTooltipType = campaignType => {
  return isCpaCampaign(campaignType) ? t('rc_blank_cpa') : t('rc_blank_cpc')
}
const getExplonationTip = (campaignType, metricName) => {
  const selectedPeriodCampaignType = props.getSelectedPeriodCampaignType
  const metricExcludedInCampaign = isExplanationRequired(campaignType, selectedPeriodCampaignType, metricName)
  let explanationTip = ''
  if (
    metricExcludedInCampaign &&
    (metricExcludedInCampaign.includes(CAMPAIGN_MODES.CPA) || metricExcludedInCampaign.includes(CAMPAIGN_MODES.NET_CPA))
  ) {
    explanationTip = t('rc_partial_data_cpc')
  } else if (metricExcludedInCampaign && metricExcludedInCampaign.includes(CAMPAIGN_MODES.CPC)) {
    explanationTip = t('rc_partial_data_cpa')
  }
  return explanationTip
}
</script>
<style scoped>
table thead th,
table tbody td {
  font-size: 14px;
}

table::after {
  @apply tw-absolute tw-w-full tw-h-[1px] tw-bg-gray-300 tw-block;
  content: '';
}

.table-header:hover {
  cursor: pointer;
  @apply tw-bg-blue-100;
}
.icon-fill {
  fill: theme('colors.blue.700');
}

.top-sort-arrow {
  right: 0;
  top: -1px;
}

.bottom-sort-arrow {
  right: 0;
  top: 6px;
}
.table-row:hover .cell {
  @apply tw-bg-blue-100;
  cursor: pointer;
}

.bottom-shadow {
  box-shadow: 0 3px 3px -1px rgba(0, 0, 0, 0.22);
  transform: scale(1);
  @apply tw-pr-11;
}

.sticky {
  @apply tw-sticky tw-left-0 tw-z-10 tw-min-w-[150px] tw-w-[150px] tw-border-r-0 tw-bg-white;
  transition: box-shadow 0.3s ease;
}

.sticky-scroll {
  @apply tw-sticky tw-left-0 tw-z-10 tw-min-w-[150px] tw-w-[150px] tw-border-r-0;
  transition: box-shadow 0.3s ease;
}
.sticky.shadow::after {
  content: '';
  position: absolute;
  top: 0;
  right: -8px;
  width: 8px;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.09), rgba(0, 0, 0, 0));
  pointer-events: none;
}

.sticky-scroll.divider::after {
  border-right: 1px solid theme('colors.gray.200');
  content: '';
  position: absolute;
  top: 0px;
  height: 100%;
  right: -1px;
}

.scroll-shadow {
  position: absolute;
  top: 0;
  right: 0;
  width: 120px;
  height: calc(100% - 76px);
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)); /* White shadow */
  pointer-events: none;
}

.badge {
  @apply tw-rounded-full tw-text-xs tw-px-3 tw-py-1 tw-relative tw-text-black;
  line-height: 1.43rem;
  @media (max-width: theme('screens.md')) {
    padding: 0 12px;
  }
}
.cancelled-badge {
  @apply tw-bg-red-200;
}
.completed-badge {
  @apply tw-bg-green-200;
}
.cell {
  @media (max-width: theme('screens.md')) {
    width: 100%;
  }
}
</style>
