import { reactive, toRefs } from 'vue'

const state = reactive({
  validationInProgress: false,
  editItem: null,
  phoneNumberTouched: false,
  isFormValid: true,
  errors: {
    name: false,
    address: false,
    zip: false,
    accommodationTypeId: false,
    email: false,
    homepageUrl: false,
    phone: false,
  },
})

export function usePropertyDetailsBasicInfo() {
  const setEditObject = item => {
    state.editItem = { ...item }
  }

  const clearEditObject = () => {
    state.editItem = null
    state.errors = {
      name: false,
      streetAddress: false,
      postalCode: false,
      email: false,
      web: false,
      accommodationTypeId: false,
      phone: false,
    }
    state.isFormValid = true
  }

  const setPhoneNumberTouched = payload => {
    state.phoneNumberTouched = payload
  }

  const setNewValue = payload => {
    if (state.editItem === null) {
      state.editItem = {}
    }
    if (payload.name === 'checkIn') {
      state.editItem['checkInHour'] = payload.value.split(':')[0]
    } else if (payload.name === 'checkOut') {
      state.editItem['checkOutHour'] = payload.value.split(':')[0]
    } else {
      state.editItem[payload.name] = payload.value
    }
  }

  const setValidation = payload => {
    state.errors[payload.name] = payload.value
    state.isFormValid = Object.values(state.errors).every(item => item === false)
  }

  const setValidationInProgress = payload => {
    state.validationInProgress = payload
  }

  return {
    ...toRefs(state),
    setEditObject,
    clearEditObject,
    setNewValue,
    setValidation,
    setPhoneNumberTouched,
    setValidationInProgress,
  }
}
