<template>
  <section ref="homePageCarousel">
    <div class="tw-flex tw-flex-row tw-w-vw tw-overflow-hidden carousel-container">
      <div class="inner carousel" ref="inner" :style="innerStyles">
        <div
          v-for="item in slides"
          :key="item.id"
          :id="item.id"
          class="tw-inline-block tw-align-top"
          :class="item.hasBg ? 'gradient-bg' : 'tw-bg-transparent'"
        >
          <div
            ref="sliderBoxesRef"
            class="md:tw-h-[270px] lg:tw-h-[450px] lg:tw-w-[980px] lg:tw-min-w-[980px] lg:tw-mx-[calc((100vw-980px)/2)] md:tw-w-[638px] md:tw-min-w-[638px] md:tw-mx-[calc((100vw-638px)/2)] sm:tw-w-[480px] sm:tw-min-w-[480px] sm:tw-mx-[calc((100vw-480px)/2)] tw-w-[300px] tw-min-w-[300px] tw-mx-[calc((100vw-300px)/2)] tw-m-auto"
          >
            <div
              class="md:tw-min-h-[208px] md:tw-max-h-[208px] lg:tw-min-h-[300px] lg:tw-max-h-[300px] tw-h-full tw-flex tw-flex-col md:tw-flex-row tw-whitespace-normal lg:tw-items-stretch tw-mt-7 tw-mb-11 lg:tw-my-16 tw-items-end"
            >
              <div
                class="tw-flex tw-flex-col tw-items-center md:tw-items-start md:tw-h-full"
                :class="
                  item.hasBg
                    ? 'tw-w-auto lg:tw-max-w-[456px] md:tw-max-w-[340px] md:tw-mr-[20px] lg:tw-mr-[40px] tw-justify-end'
                    : 'lg:tw-max-w-[45%] md:tw-max-w-[50%] lg:tw-max-w-[423px] md:tw-justify-center md:tw-pr-[18px] lg:tw-pr-0'
                "
              >
                <div v-if="!item.hasBg" class="lg:tw-mr-[37px]">
                  <p
                    class="tw-text-2xl lg:tw-text-3.5xl tw-font-bold tw-mb-4 tw-text-center md:tw-text-left"
                    :class="{ 'tw-w-[115%]': language === 'el-GR' }"
                    v-html="$t(item.title)"
                  />
                  <p
                    class="tw-text-xs md:tw-text-sm lg:tw-text-lg tw-text-center md:tw-text-left"
                    v-html="$t(item.text)"
                  />
                </div>

                <div v-else class="tw-flex tw-flex-col tw-items-center md:tw-items-start tw-flex-1">
                  <div
                    class="tw-font-bold tw-bg-red-700 tw-text-white tw-uppercase tw-text-[8px] md:tw-text-[10px] tw-rounded-br-[12px] tw-rounded-tl-[12px] tw-w-auto md:tw-py-[1px] tw-px-2 md:tw-flex-start"
                  >
                    {{ $t(item.badge) }}
                  </div>
                  <img
                    :src="item.logo"
                    :alt="item.title"
                    class="tw-w-auto tw-h-[18px] md:tw-h-[15px] lg:tw-h-[28px] md:tw-mb-3 md:tw-mt-[10px] tw-my-[10px] lg:tw-mb-4 lg:tw-mt-[14px]"
                  />
                  <p
                    class="tw-text-center md:tw-text-left tw-text-sm md:tw-text-base md:tw-leading-5 lg:tw-text-xl tw-font-bold"
                  >
                    {{ $t(item.text) }}
                  </p>
                </div>
                <MainButton
                  @click="onLoginClick"
                  class="tw-hidden lg:tw-flex"
                  :class="{ 'lg:tw-mt-[30px]': !item.hasBg }"
                  >{{ $t(item.buttonLabel) }}</MainButton
                >
                <MainButton @click="onLoginClick" size="medium" class="tw-mt-4 lg:tw-hidden">{{
                  $t(item.buttonLabel)
                }}</MainButton>
              </div>
              <div
                v-if="!item.hasBg && item.image"
                class="lg:tw-max-w-[520px] tw-flex tw-justify-center tw-items-center md:tw-justify-end md:tw-items-end tw-mt-4 md:tw-mt-0 tw-mx-auto"
              >
                <img
                  :src="item.image"
                  :alt="$t(item.title)"
                  class="lg:tw-max-w-[525px] md:tw-w-auto tw-h-[140px] md:tw-h-[186px] lg:tw-h-auto tw-mx-auto"
                />
              </div>
              <div
                v-else
                class="tw-w-auto tw-flex tw-justify-end tw-rounded-xl line-shadow tw-relative tw-max-h-[146px] md:tw-max-h-[190px] md:tw-max-w-[300px] tw-max-w-[250px] tw-mx-auto md:tw-max-w-[300px] lg:tw-max-h-[296px] lg:tw-max-w-[454px] tw-mt-4 md:tw-mt-0 tw-w-full"
              >
                <button @click="next" class="tw-hidden md:tw-flex">
                  <img
                    :src="SliderArrow"
                    alt="arrow"
                    class="tw-absolute tw-z-[999] tw-right-[-18px] tw-top-[50%] tw-translate-y-[-50%]"
                  />
                </button>
                <div
                  class="tw-border tw-border-black tw-object-cover tw-rounded-lg tw-w-[250px] md:tw-min-w-[300px] lg:tw-w-[454px] md:tw-w-auto tw-h-[146px] md:tw-h-[190px] lg:tw-h-[296px]"
                  :class="`${item.id}`"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { ref, onMounted, onUnmounted, nextTick, computed, watch, onUpdated } from 'vue'
import { useStore } from 'vuex'
import MainButton from '@/components/base/buttons/MainButton.vue'
import TouchEvent from '@/utils/touchEvents'

const slide_1_gb = new URL('/src/assets/img/landing/slider/home_page_uk.png', import.meta.url)
const slide_1_us = new URL('/src/assets/img/landing/slider/home_page_us.png', import.meta.url)
const slide_1_br = new URL('/src/assets/img/landing/slider/home_page_br.png', import.meta.url)
const slide_1_de = new URL('/src/assets/img/landing/slider/home_page_de.png', import.meta.url)
const slide_1_es = new URL('/src/assets/img/landing/slider/home_page_es.png', import.meta.url)
const slide_1_fr = new URL('/src/assets/img/landing/slider/home_page_fr.png', import.meta.url)
const slide_1_it = new URL('/src/assets/img/landing/slider/home_page_it.png', import.meta.url)
const slide_1_jp = new URL('/src/assets/img/landing/slider/home_page_jp.png', import.meta.url)
const slide_1_gr = new URL('/src/assets/img/landing/slider/home_page_gr.png', import.meta.url)
const slide_1_mx = new URL('/src/assets/img/landing/slider/home_page_mx.png', import.meta.url)
const slide_1_nl = new URL('/src/assets/img/landing/slider/home_page_nl.png', import.meta.url)
const slide_1_pl = new URL('/src/assets/img/landing/slider/home_page_pl.png', import.meta.url)
const slide_1_pt = new URL('/src/assets/img/landing/slider/home_page_pt.png', import.meta.url)
const slide_1_ru = new URL('/src/assets/img/landing/slider/home_page_ru.png', import.meta.url)
const slide_1_tr = new URL('/src/assets/img/landing/slider/home_page_tr.png', import.meta.url)

const slide_1_mobile_general = new URL('/src/assets/img/landing/slider/home_page_mobile.png', import.meta.url)
const slide_1_mobile_br = new URL('/src/assets/img/landing/slider/home_page_mobile_br.png', import.meta.url)
const slide_1_mobile_es = new URL('/src/assets/img/landing/slider/home_page_mobile_es.png', import.meta.url)
const slide_1_mobile_fr = new URL('/src/assets/img/landing/slider/home_page_mobile_fr.png', import.meta.url)
const slide_1_mobile_gr = new URL('/src/assets/img/landing/slider/home_page_mobile_gr.png', import.meta.url)
const slide_1_mobile_mx = new URL('/src/assets/img/landing/slider/home_page_mobile_mx.png', import.meta.url)
const slide_1_mobile_pt = new URL('/src/assets/img/landing/slider/home_page_mobile_pt.png', import.meta.url)

const BusinessPlusLogo = new URL('/src/assets/img/landing/landing_studio_plus.svg', import.meta.url)
const RateConnectLogo = new URL('/src/assets/img/landing/landing_rate_connect.svg', import.meta.url)
const BusinessStudioLogo = new URL('/src/assets/img/landing/landing_business_studio.svg', import.meta.url)

const SliderArrow = new URL('/src/assets/img/landing/icons/slider_arrow.svg', import.meta.url)

const store = useStore()
const isMobile = ref(false)
const language = computed(() => store.state.locale.language)
const langCode = computed(() => language.value.split('-')[1].toLowerCase())
const slide1Img = computed(() => {
  let image = slide_1_us
  switch (langCode.value) {
    case 'de':
      image = isMobile.value ? slide_1_mobile_general : slide_1_de
      break
    case 'gr':
      image = isMobile.value ? slide_1_mobile_gr : slide_1_gr
      break
    case 'us':
      image = isMobile.value ? slide_1_mobile_general : slide_1_us
      break
    case 'gb':
      image = isMobile.value ? slide_1_mobile_general : slide_1_gb
      break
    case 'es':
      image = isMobile.value ? slide_1_mobile_es : slide_1_es
      break
    case 'mx':
      image = isMobile.value ? slide_1_mobile_mx : slide_1_mx
      break
    case 'fr':
      image = isMobile.value ? slide_1_mobile_fr : slide_1_fr
      break
    case 'it':
      image = isMobile.value ? slide_1_mobile_general : slide_1_it
      break
    case 'pl':
      image = isMobile.value ? slide_1_mobile_general : slide_1_pl
      break
    case 'nl':
      image = isMobile.value ? slide_1_mobile_general : slide_1_nl
      break
    case 'br':
      image = isMobile.value ? slide_1_mobile_br : slide_1_br
      break
    case 'jp':
      image = isMobile.value ? slide_1_mobile_general : slide_1_jp
      break
    case 'pt':
      image = isMobile.value ? slide_1_mobile_pt : slide_1_pt
      break
    case 'ru':
      image = isMobile.value ? slide_1_mobile_general : slide_1_ru
      break
    case 'tr':
      image = isMobile.value ? slide_1_mobile_general : slide_1_tr
      break
    default:
      image = isMobile.value ? slide_1_mobile_general : slide_1_us
      break
  }
  return image
})

const slides = ref([
  {
    image: slide1Img,
    title: 'tbs_mp_headline',
    text: 'tbs_mp_header_text',
    id: 'slide_1',
    badge: '',
    logo: null,
    hasBg: false,
    buttonLabel: 'tbs_mp_cta_register',
  },
  {
    title: 'trivago Business Studio',
    text: 'tbs_mp_studio_slideout',
    id: 'slide_2',
    badge: 'tbs_mp_free_tag',
    logo: BusinessStudioLogo,
    hasBg: true,
    buttonLabel: 'tbs_mp_cta_register',
  },
  {
    title: 'trivago Business Studio+',
    text: 'tbs_mp_plus_slideout',
    id: 'slide_3',
    badge: 'best_value_tag',
    logo: BusinessPlusLogo,
    hasBg: true,
    buttonLabel: 'studio_plus_mp_cta',
  },
  {
    title: 'trivago Rate Connect',
    text: 'tbs_mp_rc_slideout',
    id: 'slide_4',
    badge: 'tbs_mp_rc_tag',
    logo: RateConnectLogo,
    hasBg: true,
    buttonLabel: 'check_out_page_rc_cta',
  },
])

const emit = defineEmits(['onLoginClick'])
const onLoginClick = () => emit('onLoginClick')
const homePageCarousel = ref(null)
const touchEvent = ref(null)
const current = ref(0)
const timer = ref('')
const inner = ref(null)
const innerStyles = ref({})
const step = ref('')
const transitioning = ref(false)
const sliderBoxesRef = ref(null)

const setEqualHeight = () => {
  if (window.innerWidth > 767) {
    return
  }

  const boxes = sliderBoxesRef.value
  if (!boxes || boxes.length === 0) return

  boxes.forEach(box => {
    box.style.height = 'auto'
  })

  let maxHeight = 0
  boxes.forEach(box => {
    if (box.offsetHeight > maxHeight) {
      maxHeight = box.offsetHeight
    }
  })

  boxes.forEach(box => {
    box.style.height = maxHeight + 24 + 'px'
  })
}
watch(language, () => {
  nextTick(() => {
    setEqualHeight()
  })
})

const checkScreenSize = () => {
  isMobile.value = window.innerWidth < 768
}

onUpdated(() => setEqualHeight())

onMounted(async () => {
  await nextTick()
  checkScreenSize()
  setEqualHeight()
  setStep()
  autoplay()
  document.addEventListener('touchstart', event => {
    touchEvent.value = new TouchEvent(event)
  })

  document.addEventListener('touchend', handleSwipe)

  window.addEventListener('resize', checkScreenSize)
})

onUnmounted(() => {
  clearInterval(timer)
  document.removeEventListener('touchend', handleSwipe)
  window.removeEventListener('resize', checkScreenSize)
})

const afterTransition = callback => {
  const listener = () => {
    callback()
    inner.value?.removeEventListener('transitionend', listener)
  }
  inner.value?.addEventListener('transitionend', listener)
}

const resetTranslate = () => {
  innerStyles.value = {
    transition: 'none',
    transform: `translateX(0)`,
  }
}

const setStep = () => {
  const innerWidth = inner.value?.scrollWidth
  const totalCards = slides.value.length

  step.value = `${innerWidth / totalCards}`
}

const next = () => {
  if (transitioning.value) return
  transitioning.value = true
  moveLeft()
  afterTransition(() => {
    const firstElementId = window.document.querySelector('.carousel').firstElementChild.id
    const item = window.document.getElementById(firstElementId)
    item.parentNode.appendChild(item)
    if (firstElementId === 'slide_1') current.value = 1
    if (firstElementId === 'slide_2') current.value = 2
    if (firstElementId === 'slide_3') current.value = 3
    if (firstElementId === 'slide_4') current.value = 0

    resetTranslate()
    transitioning.value = false
  })
}

const moveLeft = () => {
  innerStyles.value = {
    transform: `translateX(-${step.value}px)`,
  }
}

const prev = () => {
  if (transitioning.value) return
  transitioning.value = true
  moveRight()
  afterTransition(() => {
    const lastElementId = window.document.querySelector('.carousel').lastElementChild.id
    const item = window.document.getElementById(lastElementId)
    item.parentNode.prepend(item)

    if (lastElementId === 'slide_1') current.value = 0
    if (lastElementId === 'slide_2') current.value = 1
    if (lastElementId === 'slide_3') current.value = 2
    if (lastElementId === 'slide_4') current.value = 3

    resetTranslate()
    transitioning.value = false
  })
}

const moveRight = () => {
  innerStyles.value = {
    transform: `translateX(${step.value}px)`,
  }
}

const autoplay = () => {
  timer.value = setTimeout(() => {
    next()
    setInterval(next, 5000)
  }, 5000)
}

const handleSwipe = event => {
  if (homePageCarousel.value?.contains(event.target)) {
    if (!touchEvent.value) {
      return
    }
    touchEvent.value.setEndEvent(event)

    if (touchEvent.value.isSwipeRight()) {
      prev()
    } else if (touchEvent.value.isSwipeLeft()) {
      next()
    }
    touchEvent.value = null
  }
}
</script>
<style scoped>
.gradient-bg {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 58%,
    rgba(245, 251, 255, 1) 58%,
    rgba(245, 251, 255, 1) 58%
  );
  @media (max-width: theme('screens.md')) {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 58%,
      rgba(245, 251, 255, 1) 58%,
      rgba(245, 251, 255, 1) 58%
    );
  }
}

.carousel {
  display: block;
  margin: auto;
  white-space: nowrap;
  transition: transform 1s;
  max-height: 450px;
  @media (min-width: theme('screens.md')) and (max-width: theme('screens.lg')) {
    width: auto;
    max-height: 270px;
  }
  @media (max-width: theme('screens.md')) {
    width: auto;
  }
}
.line-shadow {
  box-shadow: 3px 3px 0px 0px #000;
}

.slide_2 {
  background-image: url(../../assets/img/landing/slider/landing_slide_studio.jpg);
  background-size: cover;
  background-position: center;
}

.slide_3 {
  background-image: url(../../assets/img/landing/slider/landing_slide_plus.jpg);
  background-size: cover;
  background-position: center;
}
.slide_4 {
  background-image: url(../../assets/img/landing/slider/landing_slide_rc.jpg);
  background-size: cover;
  background-position: center;
}
</style>
