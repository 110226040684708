<template>
  <div
    class="tw-w-full tw-text-gray-800 tw-p-3.5 tw-flex tw-justify-between tw-items-center tw-rounded-lg item tw-bg-white"
    :data-testid="dataTestId"
  >
    <div class="tw-flex tw-justify-start tw-items-center">
      <div class="tw-h-[40px] tw-overflow-hidden tw-w-[40px] tw-rounded tw-mr-3">
        <img :src="img" :alt="''" height="40" width="40" class="hotel-image" />
      </div>
      <div>
        <p class="tw-text-base tw-font-bold">{{ name }}</p>
        <p class="tw-text-sm address">{{ address }}</p>
      </div>
    </div>
    <div>
      <button type="button" class="tw-p-3 focus:tw-outline-0" @click="removeItem" @keydown.enter="removeItem">
        <BaseIcon
          class="cross-icon"
          icon-name="cross"
          :width="18"
          :height="18"
          :viewbox-width="24"
          :viewbox-height="24"
        >
          <Cross />
        </BaseIcon>
      </button>
    </div>
  </div>
  <div class="tw-bg-orange-200 tw-py-4 tw-px-5 tw-rounded-xl tw-mt-4" v-if="assigned && reassignable">
    <h3 class="tw-font-bold tw-text-sm tw-pb-[2px]">{{ $t('tbs_assigned_property_warning_header') }}</h3>
    <p class="tw-text-sm">{{ $t('tbs_assigned_property_warning_info') }}</p>
  </div>
</template>
<script setup>
import BaseIcon from '@/components/BaseIcon.vue'
import Cross from '@/components/icons/Cross.vue'
import { useAssignedProperty } from './useAssignedProperty.js'

const { removeHotelAssignment } = useAssignedProperty()
defineProps({
  img: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
  address: {
    type: String,
    default: '',
  },
  id: {
    type: Number,
    default: null,
  },
  assigned: Boolean,
  reassignable: Boolean,
  dataTestId: {
    type: String,
    default: '',
  },
})

const removeItem = () => {
  removeHotelAssignment()
}
</script>
<style scoped>
.item {
  box-shadow:
    0 2px 4px 0 rgba(63, 87, 189, 0.1),
    0 2px 10px 0 rgba(108, 112, 122, 0.15);
}
.cross-icon {
  height: 22px;
}
.hotel-image {
  height: 40px;
  object-fit: cover;
}
.address {
  @media (max-width: theme('screens.sm')) {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
