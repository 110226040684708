<template>
  <mask
    id="mask0_10351_6940"
    style="mask-type: luminance"
    maskUnits="userSpaceOnUse"
    x="8"
    y="8"
    width="12"
    height="12"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 8H20V20H8V8Z" fill="white" />
  </mask>
  <g mask="url(#mask0_10351_6940)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17 8H9C8.448 8 8 8.448 8 9V17C8 17.404 8.244 17.769 8.617 17.924C8.741 17.975 8.871 18 9 18C9.26 18 9.516 17.898 9.707 17.707L11 16.414L14.293 19.707C14.488 19.902 14.744 20 15 20C15.256 20 15.512 19.902 15.707 19.707L19.707 15.707C20.098 15.316 20.098 14.684 19.707 14.293L16.414 11L17.707 9.707C17.993 9.421 18.079 8.991 17.924 8.617C17.769 8.244 17.404 8 17 8ZM17 9L15 11L19 15L15 19L11 15L9 17V9H17Z"
      fill="black"
    />
  </g>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M4.5 9H0.5C0.224 9 0 8.776 0 8.5C0 8.224 0.224 8 0.5 8H4.5C4.776 8 5 8.224 5 8.5C5 8.776 4.776 9 4.5 9Z"
    fill="black"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8.5 5C8.224 5 8 4.776 8 4.5V0.5C8 0.224 8.224 0 8.5 0C8.776 0 9 0.224 9 0.5V4.5C9 4.776 8.776 5 8.5 5Z"
    fill="black"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5.49976 5.99976C5.37176 5.99976 5.24376 5.95076 5.14576 5.85376L2.14576 2.85376C1.95076 2.65776 1.95076 2.34176 2.14576 2.14576C2.34176 1.95076 2.65776 1.95076 2.85376 2.14576L5.85376 5.14576C6.04876 5.34176 6.04876 5.65776 5.85376 5.85376C5.75576 5.95076 5.62776 5.99976 5.49976 5.99976Z"
    fill="black"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M11.4998 5.99976C11.3718 5.99976 11.2438 5.95076 11.1458 5.85376C10.9508 5.65776 10.9508 5.34176 11.1458 5.14576L14.1458 2.14576C14.3418 1.95076 14.6578 1.95076 14.8538 2.14576C15.0488 2.34176 15.0488 2.65776 14.8538 2.85376L11.8538 5.85376C11.7558 5.95076 11.6278 5.99976 11.4998 5.99976Z"
    fill="black"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.49976 14.9998C2.37176 14.9998 2.24376 14.9508 2.14576 14.8538C1.95076 14.6578 1.95076 14.3418 2.14576 14.1458L5.14576 11.1458C5.34176 10.9508 5.65776 10.9508 5.85376 11.1458C6.04876 11.3418 6.04876 11.6578 5.85376 11.8538L2.85376 14.8538C2.75576 14.9508 2.62776 14.9998 2.49976 14.9998Z"
    fill="black"
  />
</template>
