<template>
  <div class="tw-relative">
    <div v-if="initialDataLoading" class="tw-rounded-xl tw-animate-pulse tw-bg-gray-300 tw-h-[658px]"></div>
    <div v-else-if="requiredDataHasErrors">
      <p class="tw-text-center tw-text-xl">{{ $t('onboarding.appLaunchError.header') }}</p>
    </div>
    <div v-else>
      <MarketSelection v-if="currentCampaignType === 'cpc'" />
      <AutomatedMarkets
        v-if="isUsingAutomatedMarkets === true"
        class="tw-mb-6"
        :currentCampaignType="currentCampaignType"
        :marketsWithCampaign="activeMarkets"
      />

      <ManualMarkets
        v-if="isUsingAutomatedMarkets === false"
        class="tw-mt-6"
        :markets="marketsData"
        :campaigns="campaigns"
        :marketsWithCampaign="activeMarkets"
      />
    </div>
    <FullScreenLoader v-show="requiredDataIsBeingRefetched" />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import MarketSelection from '@/components/rateConnect/markets/MarketSelection.vue'
import AutomatedMarkets from '@/components/rateConnect/markets/AutomatedMarkets.vue'
import ManualMarkets from '@/components/rateConnect/markets/ManualMarkets.vue'
import FullScreenLoader from '@/components/base/loaders/FullScreenLoader.vue'
import { useCampaigns, useStartUp, usePartners, useMarkets, useActiveMarkets } from '@/components/rateConnect/queries'
import { selectedAccommodationId } from '@/layouts/queries'

const store = useStore()
const language = computed(() => store.state.locale?.language)

const {
  isFetching: isCampaignsFetching,
  isLoading: isCampaignsLoading,
  isError: isCampaignsError,
  isUsingAutomatedMarkets,
  campaignsData,
  currentPartner,
} = useCampaigns(selectedAccommodationId)
const {
  isFetching: isStartUpFetching,
  isLoading: isStartUpLoading,
  isError: isStartUpError,
  currentCampaignType,
} = useStartUp(selectedAccommodationId)
const {
  isFetching: isActiveMarketsFetching,
  isLoading: isActiveMarketsLoading,
  isError: isActiveMarketsError,
  activeMarkets,
} = useActiveMarkets(selectedAccommodationId)
const {
  isFetching: isPartnersFetching,
  isLoading: isPartnersLoading,
  isError: isPartnersError,
} = usePartners(selectedAccommodationId)
const {
  isFetching: isMarketsFetching,
  isInitialLoading: isMarketsLoading,
  isError: isMarketsError,
  marketsData,
} = useMarkets(selectedAccommodationId, language, currentPartner)

const initialDataLoading = computed(
  () =>
    isCampaignsLoading.value ||
    isStartUpLoading.value ||
    isPartnersLoading.value ||
    isMarketsLoading.value ||
    isActiveMarketsLoading.value
)

const requiredDataHasErrors = computed(
  () =>
    isStartUpError.value ||
    isCampaignsError.value ||
    isPartnersError.value ||
    isMarketsError.value ||
    isActiveMarketsError.value
)

const requiredDataIsBeingRefetched = computed(
  () =>
    !initialDataLoading.value &&
    (isStartUpFetching.value ||
      isCampaignsFetching.value ||
      isPartnersFetching.value ||
      isMarketsFetching.value ||
      isActiveMarketsFetching.value)
)

const campaigns = computed(() => campaignsData.value?.campaigns || [])
</script>
