<template>
  <div v-if="!isVoucherOpen">
    <button
      @click="openVoucherInput"
      class="voucher tw-font-bold tw-text-blue-700 tw-text-base tw-flex tw-items-center"
    >
      <BaseIcon class="tw-mr-1 tw-fill-blue-700">
        <TagIcon />
      </BaseIcon>
      {{ $t('check_out_add_voucher') }}
    </button>
  </div>
  <div v-if="isVoucherOpen || voucherData?.isValid" class="tw-flex tw-items-end tw-justify-between">
    <div class="tw-flex tw-flex-col tw-w-full tw-mr-2">
      <p class="tw-font-bold tw-mb-1">
        {{ $t('check_out_voucher_field') }}<span v-if="required" class="tw-text-red-700">{{ '*' }}</span>
      </p>

      <BaseTextField
        name="voucher"
        aria-label="voucher"
        size="medium"
        type="text"
        class="tw-w-full tw-mr-2"
        :disabled="voucherData?.voucherCode || (code.length > 0 && !props.price && !props.isLoading)"
        :class="getClass()"
        :value="code"
        :successValidation="voucherData?.isValid"
        :withLoader="false"
        @onChange="setVoucherCode"
        @keydown.enter="applyVoucherCode('apply')"
      />
    </div>
    <GhostButton
      size="medium"
      class="tw-whitespace-nowrap"
      @click="applyVoucherCode('apply')"
      v-if="voucherData === null"
      >{{ $t('voucher_apply') }}</GhostButton
    >
    <GhostButton size="medium" class="tw-whitespace-nowrap" @click="applyVoucherCode('remove')" v-else>{{
      $t('voucher_remove')
    }}</GhostButton>
  </div>
  <div v-if="voucherData?.isValid" class="tw-mt-2 tw-text-green-700 tw-flex tw-items-center">
    <BaseIcon class="tw-mr-1 tw-fill-green-700">
      <TagIcon />
    </BaseIcon>
    <p>
      {{
        $t('check_out_discount_success', {
          DiscountPercentage: Intl.NumberFormat(language, { style: 'percent', maximumFractionDigits: 0 }).format(
            voucherData.discountPercentage / 100
          ),

          DiscountSavings: Intl.NumberFormat(language, { style: 'currency', currency: currency }).format(
            Math.abs(props.discountAmount) / 100
          ),
        })
      }}
    </p>
  </div>
  <p class="tw-text-red-700" v-if="!voucherData?.isValid && voucherData?.invalidReason === 'VOUCHER_NOT_ACTIVE'">
    {{ $t('voucher_error_expired') }}
  </p>
  <p class="tw-text-red-700" v-if="!voucherData?.isValid && voucherData?.invalidReason === 'VOUCHER_NOT_FOUND'">
    {{ $t('voucher_error') }}
  </p>
  <p class="tw-text-red-700" v-if="!price && code && !isLoading">
    {{ $t('something_went_wrong') }}
  </p>
</template>
<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import appAnalytics from '@/utils/tracking'

import BaseIcon from '@/components/BaseIcon.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import BaseTextField from '@/components/base/inputs/BaseTextField.vue'
import TagIcon from '@/components/icons/TagIcon.vue'
import { selectedAccommodationId } from '@/layouts/queries'

const store = useStore()
const code = ref('')
const isVoucherOpen = ref(false)

const language = computed(() => store.state.locale.language)
const currency = computed(() => store.state.currency)

const props = defineProps({
  voucherData: Object,
  discountAmount: Number,
  price: Object,
  isLoading: Boolean,
})

const emit = defineEmits(['applyVoucherCode'])
const applyVoucherCode = type => {
  if (type === 'apply') {
    appAnalytics.track(appAnalytics.events.SP_APPLY_VOUCHER, {
      item_id: selectedAccommodationId.value,
      promoCode: code.value,
    })
    emit('applyVoucherCode', code.value)
  }
  if (type === 'remove') {
    appAnalytics.track(appAnalytics.events.SP_REMOVE_VOUCHER, {
      item_id: selectedAccommodationId.value,
    })
    emit('applyVoucherCode', '')
    code.value = ''
  }
}

const setVoucherCode = e => {
  code.value = e
}
const openVoucherInput = () => {
  appAnalytics.track(appAnalytics.events.SP_ADD_VOUCHER_LINK, {
    item_id: selectedAccommodationId.value,
  })
  isVoucherOpen.value = true
}

const getClass = () => {
  const classes = []
  if (props.voucherData && props.voucherData !== null) {
    if (props.voucherData?.isValid) {
      classes.push('tw-border-green-700 tw-text-green-700')
    } else if (!props.voucherData?.isValid && code.value) {
      classes.push('tw-border-red-700')
    }
  }
  if (code.value && !props.price && !props.isLoading) {
    classes.push('tw-border-red-700')
  }
  return classes.join(' ')
}
</script>
<style scoped>
.voucher:hover svg,
.voucher:hover {
  @apply tw-fill-blue-800 tw-text-blue-800;
}
</style>
