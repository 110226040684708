<template>
  <div class="tw-relative">
    <label class="tw-font-bold tw-text-sm" for="competitors-search">{{ $t('channels_select_amount') }}</label>
    <div
      class="tw-flex tw-items-center tw-border tw-border-gray-500 tw-rounded-lg tw-py-[9px] tw-px-3 tw-gap-2 tw-font-base"
      :class="selectedValues.length >= 8 ? 'tw-bg-gray-200' : 'tw-bg-white'"
    >
      <input
        data-hj-allow
        id="competitors-search"
        type="search"
        :disabled="selectedValues.length >= 8"
        :placeholder="$t('assign_search_placeholder')"
        class="focus:tw-outline-none tw-grow"
        :value="searchValue"
        @focus="showResultsOnFocus"
        @input="setSearchValue"
        @blur="isInputFocused = false"
      />
    </div>

    <div
      v-click-outside="hideResults"
      class="tw-w-full tw-bg-white tw-absolute tw-min-h-[100px] tw-rounded-lg listing tw-flex-col tw-z-2 tw-py-2"
      v-if="openResults"
    >
      <div class="tw-w-full tw-flex tw-flex-col" v-if="filteredOptions.length">
        <div class="tw-mt-4 tw-mb-3 tw-max-h-[250px] tw-overflow-scroll">
          <ul>
            <li
              v-for="(option, index) in filteredOptions"
              :key="index"
              @click="onSelect(option)"
              class="tw-flex tw-items-center tw-justify-between tw-mx-1 tw-py-3 tw-border-b tw-px-5 last:tw-border-none"
            >
              <label class="tw-mb-0" :for="index">{{ option.fullName }}</label>
              <input type="checkbox" :id="index" :checked="isChecked(option)" :disabled="selectedValues.length >= 8" />
            </li>
          </ul>
        </div>
      </div>
      <div class="tw-w-full tw-flex tw-flex-col" v-else-if="filteredOptions.length === 0 && searchValue.length > 0">
        <div class="tw-items-center tw-justify-center tw-w-full tw-flex tw-mt-5">{{ $t('no_results') }}</div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch } from 'vue'

const props = defineProps({
  options: {
    type: Array,
    default: () => [],
  },
  selectedValues: {
    type: Array,
    default: () => [],
  },
})

const searchValue = ref('')
const openResults = ref(false)
const isInputFocused = ref(false)
const filteredOptions = ref(props.options)

const emit = defineEmits(['onSelect'])

watch(searchValue, newSearchValue => {
  filteredOptions.value =
    newSearchValue.length === 0
      ? props.options
      : props.options.filter(item => item.fullName.toLowerCase().includes(newSearchValue.toLowerCase()))
})

const onSelect = value => {
  emit('onSelect', value)
  if (props.selectedValues.length >= 8) {
    hideResults()
  }
}
const isChecked = option => {
  return props.selectedValues.find(item => item.id === option.id)
}
const setSearchValue = event => {
  searchValue.value = event.target.value
}
const hideResults = () => {
  if (!isInputFocused.value) {
    openResults.value = false
    searchValue.value = ''
  }
}
const showResultsOnFocus = () => {
  isInputFocused.value = true
  if (searchValue.value.length > 0 || props.options) {
    openResults.value = true
  }
}
</script>
<style scoped>
.listing {
  box-shadow:
    0 2px 4px 0 rgba(63, 87, 189, 0.1),
    0 2px 10px 0 rgba(108, 112, 122, 0.15);
}
</style>
