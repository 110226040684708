<template>
  <section>
    <div
      class="tw-hidden md:tw-flex tw-flex-col tw-w-full lg:tw-max-w-screen-landing md:tw-max-w-[638px] md:tw-py-6 lg:tw-px-0 tw-mx-auto lg:tw-py-8"
    >
      <p class="tw-font-bold tw-text-xl">{{ $t('tbs_mp_features_header') }}</p>

      <div class="tw-hidden md:tw-flex tw-flex-col md:tw-flex-row tw-mt-4 md:tw-gap-4 lg:tw-gap-8">
        <div
          class="line-shadow tw-bg-white tw-border tw-border-gray-600 tw-rounded-xl tw-relative tw-w-1/3 lg:tw-w-[316px] tw-mx-auto first:tw-ml-0 last:tw-mr-0"
          v-for="card in cardsWeb"
          :key="card.id"
        >
          <span
            class="tw-absolute tw-px-2 tw-text-sm tw-text-white tw-font-bold tw-bg-red-700 tw-rounded-tl-xl tw-rounded-br-xl"
            >{{ $t('tbs_mp_free_tag').toUpperCase() }}</span
          >

          <div class="tw-p-6 tw-flex tw-flex-col tw-h-full">
            <p class="tw-font-bold tw-text-sm tw-grow">{{ $t(card.text) }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile version -->
    <div class="md:tw-hidden tw-flex tw-flex-col tw-py-6">
      <p class="tw-font-bold tw-text-xl sm:tw-max-w-[480px] tw-max-w-[300px] tw-ml-2.5 xs:tw-ml-6">
        {{ $t('tbs_mp_features_header') }}
      </p>

      <div
        ref="containerRef"
        class="tw-flex tw-flex-col md:tw-flex-row tw-w-vw tw-overflow-hidden carousel-container tw-mt-1 tw-ml-2.5 xs:tw-ml-6"
      >
        <div class="inner carousel-mobile" ref="inner" :style="innerStyles">
          <div
            ref="boxesRef"
            class="line-shadow tw-border tw-border-gray-600 card"
            :id="card.id"
            v-for="card in cards"
            :key="card.id"
          >
            <span
              class="tw-absolute tw-px-2 tw-text-sm tw-text-white tw-font-bold tw-bg-red-700 tw-rounded-tl-xl tw-rounded-br-xl"
              >{{ $t('tbs_mp_free_tag').toUpperCase() }}</span
            >
            <div class="tw-p-6 tw-pt-8 tw-flex tw-flex-col tw-h-full">
              <p class="tw-font-bold tw-text-sm tw-grow">{{ $t(card.text) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted, nextTick, watch, computed, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import TouchEvent from '@/utils/touchEvents.js'

const store = useStore()
const containerRef = ref(null)
const boxesRef = ref([])
const language = computed(() => store.state.locale.language)

const cardsWeb = ref([
  {
    id: 0,
    text: 'tbs_mp_feature_1_subtext',
  },
  {
    id: 1,
    text: 'tbs_mp_feature_2_subtext',
  },
  {
    id: 2,
    text: 'tbs_mp_feature_3_subtext',
  },
])
const cards = ref([
  {
    id: 'slideItem_0',
    text: 'tbs_mp_feature_1_subtext',
  },
  {
    id: 'slideItem_1',
    text: 'tbs_mp_feature_2_subtext',
  },
  {
    id: 'slideItem_2',
    text: 'tbs_mp_feature_3_subtext',
  },
])

const touchEvent = ref(null)
const current = ref(1)
const timer = ref('')
const inner = ref(null)
const innerStyles = ref({})
const step = ref('')
const transitioning = ref(false)

const setEqualHeight = () => {
  if (window.innerWidth > 1024) {
    boxesRef.value.forEach(box => (box.style.height = 'auto'))
    return
  }

  const boxes = boxesRef.value
  if (!boxes || boxes.length === 0) return

  boxes.forEach(box => {
    box.style.height = 'auto'
  })

  let maxHeight = 0
  boxes.forEach(box => {
    if (box.offsetHeight > maxHeight) {
      maxHeight = box.offsetHeight
    }
  })

  boxes.forEach(box => {
    box.style.height = maxHeight + 'px'
  })
}

watch(language, () => {
  nextTick(() => {
    setEqualHeight()
  })
})

onMounted(async () => {
  setEqualHeight()
  await nextTick()
  setStep()
  autoplay()
  document.addEventListener('touchstart', event => {
    touchEvent.value = new TouchEvent(event)
  })

  document.addEventListener('touchend', handleSwipe)
  window.addEventListener('resize', setEqualHeight)
})

onBeforeUnmount(() => {
  clearInterval(timer)
  window.removeEventListener('resize', setEqualHeight)
  document.removeEventListener('touchend', handleSwipe)
})

const afterTransition = callback => {
  const listener = () => {
    callback()
    inner.value?.removeEventListener('transitionend', listener)
  }
  inner.value?.addEventListener('transitionend', listener)
}

const resetTranslate = () => {
  innerStyles.value = {
    transition: 'none',
    transform: `translateX(0px)`,
  }
}

const setStep = () => {
  const innerWidth = inner.value.scrollWidth
  const totalCards = cards.value.length

  step.value = innerWidth / totalCards
}

const moveLeft = () => {
  innerStyles.value = {
    transition: 'transform 0.5s ease',
    transform: `translateX(-${step.value}px)`,
  }
}

const moveRight = () => {
  innerStyles.value = {
    transition: 'transform 0.5s ease',
    transform: `translateX(${step.value}px)`,
  }
}

const next = () => {
  if (transitioning.value) return
  transitioning.value = true
  moveLeft()
  afterTransition(() => {
    const firstElement = containerRef.value.querySelector('.carousel-mobile').firstElementChild
    containerRef.value.querySelector('.carousel-mobile').appendChild(firstElement)

    updateCurrent()
    resetTranslate()
    transitioning.value = false
  })
}

const prev = () => {
  if (transitioning.value) return
  transitioning.value = true
  moveRight()
  afterTransition(() => {
    const lastElement = containerRef.value.querySelector('.carousel-mobile').lastElementChild
    containerRef.value.querySelector('.carousel-mobile').prepend(lastElement)

    updateCurrent()
    resetTranslate()
    transitioning.value = false
  })
}

const updateCurrent = () => {
  const firstElementId = containerRef.value.querySelector('.carousel-mobile').firstElementChild.id
  current.value = Number(firstElementId.split('_')[1])
}

const autoplay = () => {
  next()
  timer.value = setTimeout(autoplay, 3000)
}

const handleSwipe = event => {
  if (!touchEvent.value) {
    return
  }
  touchEvent.value.setEndEvent(event)

  if (touchEvent.value.isSwipeRight()) {
    prev()
  } else if (touchEvent.value.isSwipeLeft()) {
    next()
  }
  touchEvent.value = null
}
</script>
<style scoped>
.carousel-mobile {
  @apply tw-py-2;
  display: block;
  margin: auto;
  white-space: nowrap;
  transition: transform 1s;
  @media (max-width: theme('screens.md')) {
    width: 100%;
  }
}

.card {
  @apply tw-bg-white tw-min-w-[300px] xs:tw-min-w-[330px] tw-min-h-[100%]  tw-rounded-xl tw-relative;
  width: 330px;
  margin-right: 18px;
  display: inline-flex;
  white-space: wrap;
}

.line-shadow {
  box-shadow: 1px 1px 0px 0px #000;
}
</style>
