<template>
  <div id="feature-comparison" class="tw-flex tw-flex-col md:tw-flex-row tw-justify-center">
    <FeaturesCard
      type="free"
      :featuresList="[]"
      :basicFeaturesList="basicFeatureList"
      :title="'Business Studio'"
      :price="price"
      :isPriceLoading="isLoading"
      :planName="$t('studio.plus.compare.basic.title')"
      :currency="currency"
      :isAdmin="isAdmin"
    />
    <FeaturesCard
      type="paid"
      :featuresList="plusFeatureList"
      :basicFeaturesList="basicFeatureList"
      :title="$t('studio.plus.compare.features.businessstudioplus.title')"
      :planName="$t('studio.plus.compare.whatsnew')"
      :price="price"
      :isPriceLoading="isLoading"
      :currency="currency"
      :isAdmin="isAdmin"
      @select="upgradeSub"
    />
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import FeaturesCard from './FeaturesCard.vue'

import appAnalytics from '@/utils/tracking'
import { selectedAccommodationId } from '@/layouts/queries'

const store = useStore()
const props = defineProps({
  price: Number,
  currency: String,
  isLoading: Boolean,
  isAdmin: Boolean,
})

const emit = defineEmits(['upgradeSub'])
const upgradeSub = () => {
  if (props.isAdmin) {
    appAnalytics.track(appAnalytics.events.SP_UPGRADE_FEATURE_COMPARISON, {
      accommodationId: selectedAccommodationId.value,
    })
    emit('upgradeSub')
  } else {
    appAnalytics.track(appAnalytics.events.SP_LOGIN_AS_ADMIN_CLICKED, {
      accommodationId: selectedAccommodationId.value,
    })
    store.dispatch('session/logout', '/studio-plus/compare?loginAsAdmin=true')
  }
}

const basicFeatureList = [
  'studio_plus_mp_basic_feature_1',
  'studio_plus_mp_basic_feature_2',
  'studio_plus_mp_basic_feature_3',
]
const plusFeatureList = ['check_out_feature_4', 'check_out_feature_5', 'check_out_feature_6', 'check_out_feature_7']
</script>
