<template>
  <BaseCard class="tw-text-sm" no-padding>
    <div class="tw-px-5 tw-pt-5">
      <h3 class="tw-text-xl tw-font-bold tw-mb-4">{{ $t('rc_markets_manual_header') }}</h3>
      <p v-if="marketsWithCampaign.length > 0">{{ $t('rc_markets_manual_text') }}</p>
    </div>
    <div
      v-if="!marketsWithCampaign || marketsWithCampaign.length <= 0"
      class="tw-text-gray-700 tw-text-base tw-text-center tw-mt-4 md:tw-mt-10 tw-px-5 tw-pb-5"
    >
      <p class="tw-font-bold">{{ $t('rc_no_markets_paused_campaign_header') }}</p>
      <p>{{ $t('rc_no_markets_paused_campaign_subtext') }}</p>
    </div>
    <div v-else>
      <div class="tw-text-center tw-mt-4 md:tw-mt-10 tw-px-5 tw-pb-5">
        <div v-if="!activeMarkets || activeMarkets.length <= 0" class="tw-text-gray-700 tw-text-base">
          <p class="tw-font-bold">
            {{ $t('rc_no_active_market_head') }}
          </p>
          <p>
            {{ $t('rc_no_active_market_subhead') }}
          </p>
        </div>
        <h4 v-else class="tw-text-green-700 tw-font-bold tw-mb-3">{{ $t('rc_active_markets_head') }}</h4>
        <ul class="tw-flex tw-flex-col tw-gap-3">
          <li v-for="{ marketCode, marketName, domain, estimatedClicks } in activeMarkets" :key="marketCode">
            <div class="tw-border tw-rounded-xl tw-border-green-700 tw-py-3 md:tw-px-6 curr-markets-tile-grid">
              <MarketItem
                :market-name="$t(marketName)"
                :domain="domain"
                :market-code="marketCode"
                class="tw-ml-4 md:tw-m-0 market-item-container"
              />
              <MarketPotential
                :average-clicks="averageClicks"
                :estimated-clicks="estimatedClicks"
                class="tw-px-4 md:tw-p-0 market-potential-container"
              />
              <GhostButton
                size="small"
                class="market-cta-container"
                :disabled="activeMarkets.length <= 1"
                @click="addMarket({ marketCode, marketName: $t(marketName), status: 'inactive' })"
                >{{ $t('rc_deactivate') }}</GhostButton
              >
            </div>
          </li>
        </ul>
      </div>
      <hr class="tw-bg-gray-300 tw-h-px tw-border-0 tw-mt-3" />
      <div ref="marketContainer" class="tw-text-center tw-mt-8 md:tw-mt-11 tw-px-5 tw-pb-5">
        <div class="tw-mb-3 avail-markets-head">
          <SearchField
            id="search-country"
            size="big"
            :value="searchFilterValue"
            @onInput="updateSearchFilterValue"
            class="tw-justify-self-start md:tw-min-w-[220px] tw-text-base tw-border tw-border-gray-500 tw-rounded-lg"
            :placeholder="$t('rc_markets_search_placeholder')"
          />
          <h4 class="tw-text-gray-700 tw-font-bold tw-grow">{{ $t('rc_available_markets_head') }}</h4>
        </div>
        <ManualMarketsAvailable
          :availableMarkets="availableMarkets"
          :searchFilterValue="searchFilterValue"
          :averageClicks="averageClicks"
          @onAddMarket="addMarket"
        />
      </div>
    </div>
    <FullScreenLoader v-show="isUpdatingCampaignMarkets" />
  </BaseCard>
</template>

<script setup>
import { ref, computed } from 'vue'
import intersectionWith from 'lodash/intersectionWith'
import differenceWith from 'lodash/differenceWith'

import BaseCard from '@/components/dashboard/BaseCard.vue'
import MarketItem from '@/components/rateConnect/markets/MarketItem.vue'
import ManualMarketsAvailable from '@/components/rateConnect/markets/ManualMarketsAvailable.vue'
import MarketPotential from '@/components/rateConnect/markets/MarketPotential.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import SearchField from '@/components/base/inputs/SearchField.vue'
import FullScreenLoader from '@/components/base/loaders/FullScreenLoader.vue'
import { useCampaignsMutation } from '@/components/rateConnect/queries'
import useToastNotifications from '@/components/notifications/useToastNotifications'
import { selectedAccommodationId } from '@/layouts/queries'

const props = defineProps({
  markets: {
    type: Array,
    default: () => [],
  },
  campaigns: {
    type: Array,
    default: () => [],
  },
  marketsWithCampaign: {
    type: Array,
    default: () => [],
  },
})

const { displayNotification } = useToastNotifications()
const { updateCampaignMarkets, isUpdatingCampaignMarkets } = useCampaignsMutation()

const searchFilterValue = ref('')

const activeMarkets = computed(() => {
  if (!Array.isArray(props.markets) || !Array.isArray(props.campaigns)) return []

  const marketsCampaignsIntersection = intersectionWith(
    props.markets.filter(market => market?.hasCampaign),
    props.campaigns.filter(campaignMarket => campaignMarket?.userSelected),
    (market, campaignMarket) => market?.marketCode === campaignMarket?.marketCode
  )
  return Array.isArray(marketsCampaignsIntersection) ? marketsCampaignsIntersection : []
})

const availableMarkets = computed(() => {
  if (!Array.isArray(props.markets) || !Array.isArray(props.campaigns)) return []
  const marketsCampaignsDifference = differenceWith(
    props.markets,
    props.campaigns.filter(campaignMarket => campaignMarket?.userSelected === true),
    (market, campaignMarket) => market?.marketCode === campaignMarket?.marketCode
  )
  return Array.isArray(marketsCampaignsDifference) ? marketsCampaignsDifference : []
})
const averageClicks = computed(() => {
  if (!Array.isArray(props.markets) || !props.markets.length) return null
  const totalClicks = props.markets?.reduce((acc, market) => (acc += market?.estimatedClicks), 0)
  return totalClicks / props.markets.length
})

const updateSearchFilterValue = e => (searchFilterValue.value = e)

const addMarket = ({ marketCode, marketName, status }) => {
  const successMessage = status === 'inactive' ? 'rc_market_deactivated_success' : 'rc_market_activated_success'
  updateCampaignMarkets(
    {
      accommodationId: selectedAccommodationId.value,
      markets: [{ marketCode, status }],
    },
    {
      onSuccess: () => {
        displayNotification({
          message: successMessage,
          translationParams: { marketName: marketName },
          isTranslationKey: true,
          type: 'success',
        })
      },
      onError: () => {
        displayNotification({
          message: 'save_error',
          isTranslationKey: true,
          type: 'error',
        })
      },
    }
  )
}
</script>

<style scoped>
.market-item-container {
  grid-area: item;
}

.market-potential-container {
  grid-area: pot;
  border-top: 1px solid theme('colors.gray.300');
  padding-top: 12px;
  margin-top: 12px;
  justify-content: center;
}

.market-cta-container {
  grid-area: cta;
  min-width: 100px;
}

@media (max-width: theme('screens.md')) {
  .market-cta-container {
    color: theme('colors.blue.800');
    background-color: transparent;
    border: none;
    font-size: 14px;
    justify-self: end;
  }
  .market-cta-container:hover,
  .market-cta-container:focus,
  .market-cta-container:active {
    background-color: transparent;
    color: theme('colors.blue.800');
    border: none;
  }
}

.curr-markets-tile-grid {
  display: grid;
  grid-template-areas:
    'item cta'
    'pot pot';
}

@media (min-width: theme('screens.md')) {
  .curr-markets-tile-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-areas: 'item pot cta';
    align-items: center;
  }

  .market-potential-container {
    border: none;
    justify-self: center;
    padding: 0;
    margin: 0;
  }

  .market-cta-container {
    justify-self: end;
  }
}

.avail-markets-head {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

@media (min-width: theme('screens.md')) {
  .avail-markets-head {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 0;
  }
}
</style>
