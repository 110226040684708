<template>
  <section>
    <div
      class="tw-flex tw-flex-col lg:tw-flex-row tw-gap-5 md:tw-gap-8 tw-w-full lg:tw-max-w-screen-landing md:tw-max-w-[638px] tw-py-6 lg:tw-px-0 tw-mx-auto lg:tw-py-8"
    >
      <!-- Web Version -->
      <div class="tw-hidden tw-order-1 lg:tw-order-0 lg:tw-w-1/2 md:tw-flex tw-flex-wrap tw-gap-y-4 lg:tw-gap-6">
        <div
          v-for="item in cardsWeb"
          :key="item.id"
          class="lg:tw-ml-[30px] tw-relative shadow tw-bg-white last:tw-mb-0 tw-rounded-xl tw-px-6 tw-py-5 tw-border tw-border-gray-300 lg:tw-pl-12 tw-flex tw-flex-row tw-items-center tw-w-full"
        >
          <div
            class="lg:tw-absolute tw-bg-decorative-orange tw-w-[45px] tw-h-[45px] lg:tw-h-[60px] lg:tw-w-[60px] tw-flex tw-justify-center tw-items-center tw-rounded-full icon tw-mr-4 lg:tw-mr-0"
          >
            <img :src="item.icon" :alt="$t(item.title)" />
          </div>
          <div>
            <h2 class="tw-font-bold lg:tw-text-xl">{{ $t(item.title) }}</h2>
            <p class="tw-text-sm lg:tw-text-base tw-mt-2 lg:tw-mt-0">{{ $t(item.text) }}</p>
          </div>
        </div>
      </div>

      <div
        class="tw-order-0 lg:tw-order-1 lg:tw-w-1/2 tw-flex tw-flex-col md:tw-flex-row lg:tw-flex-col tw-items-center lg:tw-items-stretch tw-mx-2.5 xs:tw-mx-6 md:tw-mx-0"
      >
        <div class="tw-order-0 md:tw-order-1 lg:tw-order-0 tw-w-full md:tw-w-1/2 lg:tw-w-auto md:tw-ml-8 lg:tw-ml-0">
          <img :src="StudioPlusLogo" class="lg:tw-w-[280px] md:tw-w-[216px] tw-w-[183px] tw-mx-auto md:tw-mx-0" />
          <h3
            class="tw-font-bold lg:tw-text-[30px] lg:tw-leading-[40px] md:tw-text-2xl tw-text-xl tw-mt-2 md:tw-mt-4 tw-mb-4 md:tw-mb-0 tw-text-center md:tw-text-left"
            v-html="$t('plus_product_page_header')"
          />
        </div>
        <div
          class="businessStudioBg tw-order-0 lg:tw-order-1 tw-rounded-xl tw-border tw-border-black line-shadow lg:tw-mt-6 tw-flex-grow tw-flex tw-items-center tw-h-[170px] md:tw-h-[166px] lg:tw-h-[270px] md:tw-w-1/2 lg:tw-w-full tw-w-full"
        />
      </div>

      <!-- Mobile Version -->
      <div
        ref="containerRef"
        class="md:tw-hidden tw-flex tw-flex-col md:tw-flex-row tw-w-vw tw-overflow-hidden carousel-container tw-ml-2.5 xs:tw-ml-6"
      >
        <div class="inner plus_carousel-mobile" ref="inner" :style="innerStyles">
          <div
            ref="boxesStudioRef"
            v-for="item in cards"
            :key="item.id"
            :id="item.id"
            class="card shadow tw-bg-white tw-rounded-xl tw-px-6 tw-py-5 tw-border tw-border-gray-300"
          >
            <div class="tw-h-full">
              <div class="tw-flex tw-items-center">
                <div
                  class="tw-bg-decorative-orange tw-min-w-[45px] tw-w-[45px] tw-h-[45px] tw-flex tw-justify-center tw-items-center tw-rounded-full tw-mr-4"
                >
                  <img :src="item.icon" :alt="$t(item.title)" />
                </div>
                <h2 class="tw-font-bold">{{ $t(item.title) }}</h2>
              </div>
              <p class="tw-text-sm tw-mt-2">{{ $t(item?.text) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { onMounted, onBeforeUnmount, ref, watch, computed, nextTick, onUpdated } from 'vue'
import { useStore } from 'vuex'
import TouchEvent from '@/utils/touchEvents.js'

const StudioPlusLogo = new URL('/src/assets/img/landing/landing_studio_plus.svg', import.meta.url)

const HotelConnect = new URL('/src/assets/img/landing/icons/hotel_connect_icon.svg', import.meta.url)
const RateInsights = new URL('/src/assets/img/landing/icons/rate_insights_icon.svg', import.meta.url)
const VisitorsProfile = new URL('/src/assets/img/landing/icons/visitors_profile_icon.svg', import.meta.url)

const cardsWeb = [
  { id: 0, title: 'tbs_mp_plus_benefits_1', text: 'tbs_mp_plus_benefits_1_text', icon: HotelConnect },
  { id: 1, title: 'tbs_mp_plus_benefits_2', text: 'tbs_mp_plus_benefits_3_text', icon: VisitorsProfile },
  { id: 2, title: 'tbs_mp_plus_benefits_3', text: 'tbs_mp_plus_benefits_2_text', icon: RateInsights },
]
const cards = [
  { id: 0, title: 'tbs_mp_plus_benefits_1', text: 'tbs_mp_plus_benefits_1_text', icon: HotelConnect },
  { id: 1, title: 'tbs_mp_plus_benefits_2', text: 'tbs_mp_plus_benefits_2_text', icon: VisitorsProfile },
  { id: 2, title: 'tbs_mp_plus_benefits_3', text: 'tbs_mp_plus_benefits_3_text', icon: RateInsights },
]

const store = useStore()
const containerRef = ref(null)
const boxesStudioRef = ref([])
const language = computed(() => store.state.locale.language)

const touchEvent = ref(null)
const current = ref(1)
const timer = ref('')
const inner = ref(null)
const innerStyles = ref({})
const step = ref('')
const transitioning = ref(false)

const setEqualHeight = () => {
  nextTick(async () => {
    if (window.innerWidth > 1024) {
      boxesStudioRef.value.forEach(box => (box.style.height = 'auto'))
      return
    }
    const boxes = boxesStudioRef.value
    if (!boxes || boxes.length === 0) return

    boxes.forEach(box => {
      box.style.height = 'auto'
    })

    await nextTick()

    let maxHeight = 0

    boxes.forEach(box => {
      const currentHeight = box.offsetHeight

      if (currentHeight > maxHeight) {
        maxHeight = currentHeight
      }
    })

    boxes.forEach(box => {
      box.style.height = maxHeight + 'px'
    })
  })
}

watch(language, () => {
  nextTick(() => setEqualHeight())
})

onUpdated(() => {
  setEqualHeight()
})

onMounted(async () => {
  await nextTick()
  setStep()
  autoplay()
  document.addEventListener('touchstart', event => {
    touchEvent.value = new TouchEvent(event)
  })

  document.addEventListener('touchend', handleSwipe)
  window.addEventListener('resize', setEqualHeight)
})

onBeforeUnmount(() => {
  clearInterval(timer)
  window.removeEventListener('resize', setEqualHeight)
  document.removeEventListener('touchend', handleSwipe)
})

const afterTransition = callback => {
  const listener = () => {
    callback()
    inner.value?.removeEventListener('transitionend', listener)
  }
  inner.value?.addEventListener('transitionend', listener)
}

const resetTranslate = () => {
  innerStyles.value = {
    transition: 'none',
    transform: 'translateX(0)',
  }
}

const setStep = () => {
  const innerWidth = inner.value.scrollWidth
  const totalCards = cards.length

  step.value = innerWidth / totalCards
}

const moveLeft = () => {
  innerStyles.value = {
    transition: 'transform 0.5s ease',
    transform: `translateX(-${step.value}px)`,
  }
}

const moveRight = () => {
  innerStyles.value = {
    transition: 'transform 0.5s ease',
    transform: `translateX(${step.value}px)`,
  }
}

const next = () => {
  if (transitioning.value) return
  transitioning.value = true
  moveLeft()
  afterTransition(() => {
    const firstElement = containerRef.value.querySelector('.plus_carousel-mobile')?.firstElementChild
    containerRef.value.querySelector('.plus_carousel-mobile').appendChild(firstElement)

    updateCurrent()
    resetTranslate()
    transitioning.value = false
  })
}

const prev = () => {
  if (transitioning.value) return
  transitioning.value = true
  moveRight()
  afterTransition(() => {
    const lastElement = containerRef.value.querySelector('.plus_carousel-mobile').lastElementChild
    containerRef.value.querySelector('.plus_carousel-mobile').prepend(lastElement)

    updateCurrent()
    resetTranslate()
    transitioning.value = false
  })
}

const updateCurrent = () => {
  const firstElementId = containerRef.value.querySelector('.plus_carousel-mobile').firstElementChild.id
  current.value = Number(firstElementId.split('_')[1])
}

const autoplay = () => {
  next()
  timer.value = setTimeout(autoplay, 5000)
}

const handleSwipe = event => {
  if (!touchEvent.value) {
    return
  }
  touchEvent.value.setEndEvent(event)

  if (touchEvent.value.isSwipeRight()) {
    prev()
  } else if (touchEvent.value.isSwipeLeft()) {
    next()
  }
  touchEvent.value = null
}
</script>
<style scoped>
.line-shadow {
  box-shadow: 3px 3px 0px 0px #000;
}
.shadow {
  box-shadow: 2px 2px 4px 0px #00000040;
}
.icon {
  @media (min-width: theme('screens.lg')) {
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.image-column {
  flex: 0 0 auto;
}
.plus_carousel-mobile {
  padding-bottom: 2px;
  display: flex;
  margin: auto;
  white-space: nowrap;
  transition: transform 1s;
  @media (max-width: theme('screens.md')) {
    width: 100%;
  }
}

.card {
  @apply tw-bg-white tw-min-w-[300px] xs:tw-min-w-[330px] tw-min-h-[100%]  tw-rounded-xl tw-relative;
  width: 330px;
  margin-right: 18px;
  display: inline-flex;
  white-space: wrap;
}

.businessStudioBg {
  background-image: url(../../assets/img/landing/landing_studio_plus_img.jpeg);
  background-size: cover;
  background-position: center;
}
</style>
