<template>
  <BaseCard
    class="tw-mb-6 tw-flex tw-flex-col tw-gap-8 md:tw-flex-row md:tw-justify-between md:tw-items-center tw-text-sm"
  >
    <div class="md:tw-max-w-2xl">
      <h3 class="tw-text-xl tw-font-bold tw-mb-4">{{ $t('rc_markets_header') }}</h3>
      <p>{{ $t('rc_markets_subtext') }}</p>
    </div>
    <div class="tw-flex tw-gap-6 tw-items-center tw-justify-between">
      <div class="tw-max-w-[220px]">
        <strong>{{ $t('rc_markets_auto_header') }}</strong>
        <p>{{ $t('rc_ams_subtext') }}</p>
      </div>
      <MainSwitch :value="isUsingAutomatedMarkets" :disabled="isUpdatingCampaign" @onClick="onUpdateAutomatedMarkets" />
    </div>
  </BaseCard>
  <FullScreenLoader v-show="isUpdatingCampaign" />
</template>

<script setup>
import BaseCard from '@/components/dashboard/BaseCard.vue'
import MainSwitch from '@/components/base/switches/MainSwitch.vue'
import FullScreenLoader from '@/components/base/loaders/FullScreenLoader.vue'
import { useCampaigns, useCampaignsMutation } from '@/components/rateConnect/queries'
import useToastNotifications from '@/components/notifications/useToastNotifications'
import { selectedAccommodationId } from '@/layouts/queries'

const { displayNotification } = useToastNotifications()
const { isUsingAutomatedMarkets } = useCampaigns(selectedAccommodationId)
const { updateCampaign, isUpdatingCampaign } = useCampaignsMutation()

const onUpdateAutomatedMarkets = () => {
  const successMessage = isUsingAutomatedMarkets.value
    ? 'rc_markets_manual_success_message'
    : 'rc_markets_auto_success_message'
  updateCampaign(
    {
      accommodationId: selectedAccommodationId.value,
      body: { overall: { useAutomatedMarkets: !isUsingAutomatedMarkets.value } },
    },
    {
      onSuccess: () => {
        displayNotification({
          message: successMessage,
          isTranslationKey: true,
          type: 'success',
        })
      },
      onError: () => {
        displayNotification({
          message: 'save_error',
          isTranslationKey: true,
          type: 'error',
        })
      },
    }
  )
}
</script>
