const filters = [
  {
    id: 1,
    name: 'room_type',
    options: [
      { name: 'single', default: false, id: 1, label: 'room_type_single' },
      { name: 'double', default: true, id: 2, label: 'room_type_double' },
      { name: 'multi', default: false, id: 3, label: 'room_type_multi' },
    ],
  },
  {
    id: 2,
    name: 'breakfast',
    options: [
      { name: 'included', default: false, id: 4, label: 'breakfast_included' },
      { name: 'not_included', default: true, id: 5, label: 'breakfast_not_included' },
    ],
  },
  {
    id: 3,
    name: 'market_rates',
    options: [
      { name: 'LOWEST_ALL', default: true, id: 6, label: 'market_rates_lowest' },
      { name: 'HIGHEST_ALL', default: false, id: 7, label: 'market_rates_highest' },
      { name: 'LOWEST_LOCAL', default: true, id: 8, label: 'market_rates_home' },
    ],
  },
]

export { filters }
