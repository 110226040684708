import OrganisationManagementService from '@/services/OrganisationManagementService'
import AccommodationService from '@/services/accommodationService/Accommodation'
import RateInsightsService from '@/services/accommodationService/RateInsights'
import RateConnectService from '@/services/RateConnectService'
import PremiumService from '@/services/PremiumService'
import DashboardService from '@/services/DashboardService'
import PropertyDetailsService from '@/services/PropertyDetailsService'
import CountriesService from '@/services/CountriesService'
import AdminService from '@/services/AdminService'

export function getPayloadFromJwt(token) {
  if (!token) return

  const encodedPayload = token.split('.')?.[1]
  if (!encodedPayload) return

  const decodedPayload = JSON.parse(window.atob(encodedPayload))
  return decodedPayload
}

export function getTokenFromLocalStorage(tokenName = '') {
  try {
    let result = window.localStorage.getItem(tokenName)

    if (result) {
      result = JSON.parse(result)
    }
    return result
  } catch {
    return null
  }
}

export function isTokenValid(token = '') {
  if (!token) return false
  if (!token.expires_in || !token.access_token) return false

  const payload = getPayloadFromJwt(token.access_token)
  if (!payload?.exp) return false

  const expiresIn = payload.exp * 1000
  const isTokenExpired = Date.now() > expiresIn - 360000

  return !isTokenExpired
}

export function setStudioTokenInServices(token = '') {
  if (!token) return
  OrganisationManagementService.setStudioToken(token?.access_token)
  AccommodationService.setStudioToken(token?.access_token)
  RateConnectService.setStudioToken(token?.access_token)
  PremiumService.setStudioToken(token?.access_token)
  DashboardService.setStudioToken(token?.access_token)
  PropertyDetailsService.setStudioToken(token?.access_token)
  CountriesService.setStudioToken(token?.access_token)
  RateInsightsService.setStudioToken(token?.access_token)
  AdminService.setStudioToken(token?.access_token)
}
