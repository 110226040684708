<template>
  <div class="tw-flex tw-flex-col tw-gap-4">
    <div class="required-info tw-text-xs tw-text-gray-700" v-html="$t('required_information')" />
    <BaseTextField
      :label="$t('form.labels.companyName')"
      aria-label="Company Name"
      required
      :value="billingData.companyName"
      :error="billingDataErrors.companyName === null ? null : $t(billingDataErrors.companyName)"
      :placeholder="$t('field_example_hotel')"
      @onInput="e => (billingData.companyName = e)"
      @onBlur="validateField('companyName')"
      dataTestId="billing-form-company-name"
    />
    <BaseTextField
      :label="$t('form.labels.email')"
      aria-label="Email"
      required
      :value="billingData.email"
      :error="billingDataErrors.email === null ? null : $t(billingDataErrors.email)"
      :placeholder="$t('field_example_email')"
      @onInput="e => (billingData.email = e)"
      @onBlur="validateField('email')"
      dataTestId="billing-form-email"
    />
    <BaseTextField
      :label="$t('form.labels.first_name')"
      aria-label="First Name"
      required
      :value="billingData.firstName"
      :error="billingDataErrors.firstName === null ? null : $t(billingDataErrors.firstName)"
      :placeholder="$t('field_example_first_name')"
      @onInput="e => (billingData.firstName = e)"
      @onBlur="validateField('firstName')"
      dataTestId="billing-form-first-name"
    />
    <BaseTextField
      :label="$t('form.labels.last_name')"
      aria-label="Last Name"
      required
      :placeholder="$t('field_example_last_name')"
      :value="billingData.lastName"
      :error="billingDataErrors.lastName === null ? null : $t(billingDataErrors.lastName)"
      @onInput="e => (billingData.lastName = e)"
      @onBlur="validateField('lastName')"
      dataTestId="billing-form-last-name"
    />
    <BaseTextField
      :label="$t('app_hotel_details_address')"
      aria-label="Address"
      :value="billingData.address"
      required
      :error="billingDataErrors.address === null ? null : $t(billingDataErrors.address)"
      @onInput="e => (billingData.address = e)"
      @onBlur="validateField('address')"
      dataTestId="billing-form-company-address"
    />
    <BaseTextField
      class="tw-grow"
      :label="$t('form.labels.city')"
      aria-label="City or Town"
      required
      :value="billingData.city"
      :error="billingDataErrors.city === null ? null : $t(billingDataErrors.city)"
      :placeholder="$t('field_example_city')"
      @onInput="e => (billingData.city = e)"
      @onBlur="validateField('city')"
      dataTestId="billing-form-city"
    />
    <BaseTextField
      :label="$t('app_hotel_details_zip_code')"
      aria-label="Zip code"
      :value="billingData.zipCode"
      required
      :placeholder="$t('field_example_zip_code')"
      :error="billingDataErrors.zipCode === null ? null : $t(billingDataErrors.zipCode)"
      @onInput="e => (billingData.zipCode = e)"
      @onBlur="validateField('zipCode')"
      dataTestId="billing-form-zip-code"
    />
    <CountrySelect
      :countriesData="countries"
      :value="billingData.country"
      required
      :error="billingDataErrors.country === null ? null : $t(billingDataErrors.country)"
      @onSelectChange="onCountrySelectChange"
      selectTestId="billing-form-country-select"
    />
    <StateSelect
      v-if="countriesWithState.includes(billingData.country)"
      :label="$t('form.labels.state')"
      required
      :states="states"
      :value="billingData.state"
      :error="billingDataErrors.state === null ? null : $t(billingDataErrors.state)"
      @onSelectChange="onStateSelectChange"
      selectTestId="billing-form-state-select"
    />

    <BaseTextField
      :label="$t('form.labels.vatNumber')"
      aria-label="Tax ID number"
      :value="billingData.taxId"
      :error="billingDataErrors.taxId === null ? null : $t(billingDataErrors.taxId)"
      :disabled="billingData.noTaxId"
      :required="!billingData?.noTaxId"
      :tooltip="$t('form.labels.taxIdDisabledTooltip')"
      @onInput="onTaxIdChange"
      :withLoader="taxValidationProgress"
      :successValidation="
        billingDataErrors.taxId === null &&
        !billingData.noTaxId &&
        billingData.taxId !== null &&
        billingData.taxId.length > 2
      "
      dataTestId="billing-form-tax-id"
    />

    <Checkbox
      :label="$t('form.labels.taxIdDisabled')"
      name="noTaxId"
      :value="billingData.noTaxId"
      @onChange="onNoTaxIdChange"
      dataTestId="billing-form-no-tax-id-checkbox"
    />
  </div>
</template>

<script setup>
import { reactive, computed, ref } from 'vue'
import * as Yup from 'yup'
import { useI18n } from 'vue-i18n'

import StateSelect from '@/components/payment/StateSelect.vue'

import BaseTextField from '@/components/base/inputs/BaseTextField.vue'
import Checkbox from '@/components/base/inputs/Checkbox.vue'
import CountrySelect from '@/components/payment/CountrySelect.vue'
import { countries } from '@/constants/countries.js'
import PremiumService from '@/services/PremiumService'
import debounce from 'lodash/debounce'

const { t } = useI18n()
const props = defineProps({
  initialBillingFormValues: {
    type: Object,
    default: null,
  },
})

const emit = defineEmits(['onValidateAllSuccess', 'onValidateAllError', 'setBillingFormValid'])

const countriesWithState = ['US', 'MX', 'CA', 'BR']
const taxValidationProgress = ref(false)
const states = computed(() => countries.find(item => item.code === billingData.country)?.states)

let EMAIL_REGX =
  /* eslint-disable-next-line no-control-regex */
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

const BillingSchema = Yup.object().shape({
  companyName: Yup.string()
    .max(255, 'Company name cannot be longer than 255 characters.')
    .required('error_field_required'),
  email: Yup.string().matches(EMAIL_REGX, 'error_invalid_email').required('error_field_required'),
  firstName: Yup.string().max(100, 'First name cannot be longer than 100 characters.').required('error_field_required'),
  lastName: Yup.string().max(100, 'Last name cannot be longer than 100 characters.').required('error_field_required'),
  address: Yup.string().max(255, 'Address cannot be longer than 255 characters.').required('error_field_required'),
  city: Yup.string().max(40, 'City cannot be longer than 40 characters.').required('error_field_required'),
  zipCode: Yup.string().max(20, 'Zip code cannot be longer than 20 characters.').required('error_field_required'),
  country: Yup.string().required('error_field_required'),
  state: Yup.string().when('country', {
    is: country => countriesWithState.includes(country),
    then: () => Yup.string().required('error_field_required'),
  }),
  taxId: Yup.string().when('noTaxId', {
    is: false,
    then: () =>
      Yup.string()
        .required('error_field_required')
        .test('Tax ID Number format is invalid for the given country', function validateTaxId(value, { createError }) {
          if (value) {
            taxValidationProgress.value = true
            return PremiumService.validateTaxId({
              taxId: value,
              country: billingData.country,
            })
              .then(response => {
                taxValidationProgress.value = false
                let errorMessage = t('checkoutBasket.errors.vatValidation.generic')
                if (response.data?.taxIdExample) {
                  errorMessage = t('checkoutBasket.errors.vatValidation.format', {
                    format: response.data?.taxIdExample,
                  })
                }
                return response.data?.valid ? true : createError({ message: errorMessage })
              })
              .catch(() => {
                taxValidationProgress.value = false
                return createError({
                  message: t('checkoutBasket.errors.vatValidation.network'),
                })
              })
          }
        }),
  }),
  noTaxId: Yup.boolean(),
})

const VATrequiredData = reactive({
  zipCode: props.initialBillingFormValues?.zipCode ?? null,
  country: props.initialBillingFormValues?.country ?? 'DE',
  state: props.initialBillingFormValues?.state ?? '',
  taxId: props.initialBillingFormValues?.taxId ?? null,
  noTaxId: props.initialBillingFormValues?.noTaxId ?? false,
})

const billingData = reactive({
  companyName: props.initialBillingFormValues?.companyName ?? null,
  email: props.initialBillingFormValues?.email ?? null,
  firstName: props.initialBillingFormValues?.firstName ?? null,
  lastName: props.initialBillingFormValues?.lastName ?? null,
  address: props.initialBillingFormValues?.address ?? null,
  city: props.initialBillingFormValues?.city ?? null,
  zipCode: props.initialBillingFormValues?.zipCode ?? null,
  country: props.initialBillingFormValues?.country ?? 'DE',
  state: props.initialBillingFormValues?.state ?? '',
  taxId: props.initialBillingFormValues?.taxId ?? null,
  noTaxId: props.initialBillingFormValues?.noTaxId ?? false,
})

const billingDataErrors = reactive({
  companyName: null,
  email: null,
  firstName: null,
  lastName: null,
  city: null,
  address: null,
  zipCode: null,
  country: null,
  state: null,
  taxId: null,
})

const onNoTaxIdChange = e => {
  VATrequiredData.noTaxId = e
  VATrequiredData.taxId = ''
  billingData.noTaxId = e
  billingData.taxId = ''
  billingDataErrors.taxId = null
  validateField('taxId')
}
const onCountrySelectChange = e => {
  if (countriesWithState.includes(e)) {
    const selectedValue = countries?.find(item => item.code === e)?.states
    onStateSelectChange(selectedValue[0].code)
  } else {
    billingData.state = ''
    VATrequiredData.state = ''
  }
  VATrequiredData.country = e
  billingData.country = e
  validateField('country')
  validateField('taxId')
}

const onTaxIdChange = e => {
  billingData.taxId = e
  debounceTaxValidation('taxId')
}

const onStateSelectChange = e => {
  VATrequiredData.state = e
  billingData.state = e
  validateField('state')
}

const validateField = field => {
  if (field === 'zipCode') {
    VATrequiredData.zipCode = billingData.zipCode
  } else if (field === 'taxId') {
    VATrequiredData.noTaxId = billingData.noTaxId
    VATrequiredData.taxId = billingData.taxId
  }
  BillingSchema.validateAt(field, billingData)
    .then(() => {
      isFormValid()
      billingDataErrors[field] = null
    })
    .catch(err => {
      billingDataErrors[err.path] = err.message
      emit('setBillingFormValid', false)
    })
}

const debounceTaxValidation = debounce(validateField, 500)

const validateAll = () => {
  BillingSchema.validate(billingData, { abortEarly: false })
    .then(() => {
      emit('onValidateAllSuccess', billingData)
    })
    .catch(err => {
      err.inner.forEach(error => {
        billingDataErrors[error.path] = error.message
      })
      emit('onValidateAllError')
    })
}

const isFormValid = () => {
  BillingSchema.validate(billingData, { abortEarly: false })
    .then(() => {
      emit('setBillingFormValid', true)
    })
    .catch(() => {
      emit('setBillingFormValid', false)
    })
}

defineExpose({
  validateAll,
  VATrequiredData,
})
</script>
<style scoped>
.required-info :deep(span) {
  @apply tw-text-red-700;
}
</style>
