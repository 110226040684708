import axios from 'axios'

import BaseService from './BaseService.js'
import DashboardService from './DashboardService.js'

const PREMIUM_API = import.meta.env.VITE_PREMIUM_API_URL
const rateConnectApiBaseUrl = `${import.meta.env.VITE_RATE_CONNECT_API_URL}`
export const rateConnectApiBaseUrlNoSubpath = import.meta.env.VITE_RATE_CONNECT_API_URL?.replace('/rate_connect', '')

class RateConnectService extends BaseService {
  constructor() {
    super({
      baseUrl: rateConnectApiBaseUrl,
    })
  }

  newRateConnectCampaign({ accommodationId, body }) {
    return axios({
      url: `${PREMIUM_API}/api/rateconnect/${accommodationId}/future/campaign`,
      method: 'POST',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  getActiveMarkets({ accommodationId }) {
    return axios({
      url: `${PREMIUM_API}/api/rateconnect/${accommodationId}/markets`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  getCommissionLevels() {
    return axios({
      url: `${PREMIUM_API}/api/rateconnect/commission_levels`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  resumeRateConnectCampaign({ accommodationId }) {
    return axios({
      url: `${PREMIUM_API}/api/rateconnect/${accommodationId}/campaign/resume`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchBookingLog({ accommodationId, filtrationDate, currencyCode, startDate, endDate, marketCodes, status }) {
    if (!this.studioToken)
      throw new Error('Error in GET /api/rateconnect/${accommodationId}/booking_log: No studioToken provided')

    const searchParams = {
      filtrationDate,
      currencyCode,
      startDate,
      endDate,
      marketCodes,
      status,
    }

    return axios({
      url: `${PREMIUM_API}/api/rateconnect/${accommodationId}/booking-log`,
      method: 'GET',
      params: searchParams,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchStats(params) {
    if (!this.studioToken)
      throw new Error(
        'Error in GET /api/rateconnect/${accommodationId}/performance/statistics: No studioToken provided'
      )

    const searchParams = {}

    for (const key in params) {
      if (key !== 'accommodationId' && params[key]) {
        searchParams[key] = params[key]
      }
    }

    return axios({
      url: `${PREMIUM_API}/api/rateconnect/${params.accommodationId}/performance/statistics`,
      method: 'GET',
      params: searchParams,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchMarkets({ accommodationId, partnerId }) {
    if (!this.studioToken)
      throw new Error(
        'Error in GET api/rateconnect/${accommodationId}/partner/${partnerId}/markets: No studioToken provided'
      )
    return axios({
      url: `${PREMIUM_API}/api/rateconnect/${accommodationId}/partners/${partnerId}/markets`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  exportBookingLogData({ accommodationId, filtrationDate, currencyCode, startDate, endDate, marketCodes, status }) {
    if (!this.studioToken)
      throw new Error('Error in GET api/rateconnect/${accommodationId}/booking-log: No studioToken provided')

    const searchParams = {
      filtrationDate,
      currencyCode,
      startDate,
      endDate,
      marketCodes,
      status,
    }
    return axios({
      url: `${PREMIUM_API}/api/rateconnect/${accommodationId}/booking-log`,
      method: 'GET',
      params: searchParams,
      headers: {
        Accept: 'text/csv',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  exportStatisticsData(params) {
    if (!this.studioToken)
      throw new Error(
        'Error in GET /api/rateconnect/${accommodationId}/performance/statistics: No studioToken provided'
      )

    const searchParams = {}

    for (const key in params) {
      if (key !== 'accommodationId' && params[key] !== undefined && params[key] !== null && params[key] !== '') {
        searchParams[key] = params[key]
      }
    }

    return axios({
      url: `${PREMIUM_API}/api/rateconnect/${params.accommodationId}/performance/statistics`,
      method: 'GET',
      params: searchParams,
      headers: {
        Accept: 'text/csv',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchPartners(accommodationId) {
    return axios({
      url: `${PREMIUM_API}/api/rateconnect/${accommodationId}/partners`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    }).then(async response => {
      const partnerIds = []
      response.data.map(item => partnerIds.push(item.partnerId))

      const partnersData = await DashboardService.fetchPartnersName(partnerIds)
      return response.data.map(item => {
        let name
        partnersData?.data?.data?.getAdvertiserDetails?.advertiserDetails?.forEach(partner => {
          if (partner.nsid.id === item.partnerId) {
            name = partner.originalName.value
          }
        })
        return {
          canNetCpa: item.canNetCpa,
          partnerId: item.partnerId,
          partnerStatus: item.partnerStatus,
          connectionStatus: item.connectionStatus,
          partnerName: name ?? item.internalName,
        }
      })
    })
  }

  fetchHoteliersData({ accommodationId }) {
    if (!this.studioToken)
      throw new Error('Error in GET accommodation/{accommodationId}/start_up: No studioToken provided')
    return axios({
      url: `${this.baseUrl}/accommodation/${accommodationId}/start_up`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Authorization': 'Basic d333cb33a013eeac2016ceb725bf74aa',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchNotifications({ accommodationId }) {
    if (!this.studioToken)
      throw new Error('Error in GET accommodation/{accommodationId}/notifications: No studioToken provided')
    return axios({
      url: `${this.baseUrl}/accommodation/${accommodationId}/notifications`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Authorization': 'Basic d333cb33a013eeac2016ceb725bf74aa',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  dismissNotification({ accommodationId, notificationName }) {
    if (!this.studioToken)
      throw new Error(
        'Error in GET accommodation/{accommodationId}/notifications/${notificationName}/dismiss: No studioToken provided'
      )
    return axios({
      url: `${this.baseUrl}/accommodation/${accommodationId}/notifications/${notificationName}/dismiss`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Authorization': 'Basic d333cb33a013eeac2016ceb725bf74aa',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchCampaigns({ accommodationId }) {
    if (!this.studioToken)
      throw new Error('Error in GET accommodation/${accommodationId}/campaigns: No studioToken provided')
    return axios({
      url: `${this.baseUrl}/accommodation/${accommodationId}/campaigns`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Authorization': 'Basic d333cb33a013eeac2016ceb725bf74aa',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  updateCampaigns({ accommodationId, body }) {
    if (!this.studioToken)
      throw new Error('Error in GET accommodation/${accommodationId}/campaigns: No studioToken provided')
    return axios({
      url: `${this.baseUrl}/accommodation/${accommodationId}/campaigns`,
      method: 'PUT',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Authorization': 'Basic d333cb33a013eeac2016ceb725bf74aa',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  updateCampaignBudget({ accommodationId, budget, currencyCode }) {
    if (!this.studioToken)
      throw new Error('Error in PUT api/rateconnect/${accommodationId}/budget: No studioToken provided')
    return axios({
      url: `${PREMIUM_API}/api/rateconnect/${accommodationId}/budget?currencyCode=${currencyCode}&budget=${budget}`,
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchKPIS({ accommodationId, currencyCode, marketCodes, startDate, endDate }) {
    if (!this.studioToken)
      throw new Error('Error in GET accommodation/${accommodationId}/performance/kpis: No studioToken provided')

    const searchParams = {
      currencyCode,
      startDate,
      endDate,
      marketCodes,
    }

    return axios({
      url: `${PREMIUM_API}/api/rateconnect/${accommodationId}/performance/kpis`,
      method: 'GET',
      params: searchParams,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  updateCampaignType({ accommodationId, campaignType, body }) {
    if (!this.studioToken)
      throw new Error(
        'Error in GET accommodation/${accommodationId}/campaigns/type/${campaignType.toLowerCase()}: No studioToken provided'
      )
    return axios({
      url: `${this.baseUrl}/accommodation/${accommodationId}/campaigns/type/${campaignType.toLowerCase()}`,
      method: 'PUT',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Authorization': 'Basic d333cb33a013eeac2016ceb725bf74aa',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  updateCommissionLevel({ accommodationId, body }) {
    if (!this.studioToken)
      throw new Error('Error in GET accommodation/${accommodationId}/campaigns: No studioToken provided')
    return axios({
      url: `${this.baseUrl}/accommodation/${accommodationId}/campaigns`,
      method: 'PUT',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Authorization': 'Basic d333cb33a013eeac2016ceb725bf74aa',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchRecommendation({ accommodationId, currencyCode, partnerId, marketCodes }) {
    if (!this.studioToken)
      throw new Error('Error in GET accommodation/${accommodationId}/budget-recommendation: No studioToken provided')

    const params = {
      currencyCode,
      partnerId,
      marketCodes,
    }
    return axios({
      url: `${PREMIUM_API}/api/rateconnect/${accommodationId}/budget-recommendation`,
      method: 'GET',
      params,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  updatePartner({ accommodationId, body }) {
    if (!this.studioToken)
      throw new Error('Error in GET accommodation/${accommodationId}/partners: No studioToken provided')
    return axios({
      url: `${this.baseUrl}/accommodation/${accommodationId}/partners`,
      method: 'PUT',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Authorization': 'Basic d333cb33a013eeac2016ceb725bf74aa',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  toggleCampaignStatus({ accommodationId, body }) {
    if (!this.studioToken)
      throw new Error('Error in GET accommodation/${accommodationId}/campaigns: No studioToken provided')
    return axios({
      url: `${this.baseUrl}/accommodation/${accommodationId}/campaigns`,
      method: 'PUT',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Authorization': 'Basic d333cb33a013eeac2016ceb725bf74aa',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchAllPartners() {
    if (!this.studioToken) throw new Error('Error in GET /partners: No studioToken provided')
    return axios({
      url: `${this.baseUrl}/partners`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Authorization': 'Basic d333cb33a013eeac2016ceb725bf74aa',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }
}
export default new RateConnectService()
