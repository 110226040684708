<template>
  <nav
    class="tw-flex tw-items-center tw-w-full tw-justify-start md:tw-justify-start tw-flex-wrap tw-border-solid tw-border-b tw-border-gray-300"
  >
    <div
      class="tw-menu tw-flex md:tw-w-full tw-overflow-auto md:tw-mx-auto tw-justify-start md:tw-justify-start lg:tw-items-center lg:tw-w-auto lg:tw-px-3"
    >
      <button
        class="disabled:tw-text-gray-400 disabled:tw-border-none tw-w-auto tw-text-sm tw-flex tw-mt-4 lg:tw-inline-block tw-items-center lg:tw-mt-0 tw-pb-3 hover:tw-text-blue-700 tw-px-0 tw-mx-4 tw-py-2 tw-border-b-4 hover:tw-border-blue-700 tw-mr-2"
        :class="
          item.value === value
            ? 'tw-font-bold tw-border-blue-700 tw-text-blue-700'
            : 'tw-border-transparent tw-text-gray-800'
        "
        v-for="item in items"
        :key="item.value"
        @click="changePerformanceView(item.value)"
        :disabled="item.value === 'bookings' && hasNoData"
      >
        {{ item.label }}
      </button>
    </div>
  </nav>
</template>
<script setup>
defineProps({
  items: Array,
  value: Object,
  hasNoData: Boolean,
})
const emit = defineEmits(['changePerformanceView'])
const changePerformanceView = value => {
  emit('changePerformanceView', value)
}
</script>
