<template>
  <div class="tw-mx-4 lg:tw-mx-0 tw-relative tw-mb-6">
    <div v-if="initialDataLoading" class="tw-rounded-xl tw-animate-pulse tw-bg-gray-300 tw-h-[658px]"></div>
    <div v-else-if="requiredDataHasErrors">
      <p class="tw-text-center tw-text-xl">{{ $t('onboarding.appLaunchError.header') }}</p>
    </div>
    <BaseCard v-else no-padding>
      <BudgetStatus
        class="tw-p-5 md:tw-mb-3"
        :budget="budget"
        :CPCLevel="campaignCurrentCPCLevel"
        :currency="currency"
        :expectedConsumption="expectedConsumption"
      />
      <hr class="tw-bg-gray-300 tw-h-px tw-border-0" />
      <MonthlyBudget
        class="tw-p-5 tw-pb-6"
        :budget="budget"
        :currency="currency"
        :minBudget="minBudget"
        :maxBudget="maxBudget"
        :minRecommendedBudget="budgetRecommendedMin"
        :maxRecommendedBudget="budgetRecommendedMax"
        @onBudgetSave="onBudgetSave"
      />
    </BaseCard>
    <FullScreenLoader v-show="requiredDataIsBeingRefetched || isUpdatingCampaignBudget" />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import BaseCard from '@/components/dashboard/BaseCard.vue'
import BudgetStatus from '@/components/rateConnect/budget/BudgetStatus.vue'
import MonthlyBudget from '@/components/rateConnect/budget/MonthlyBudget.vue'
import FullScreenLoader from '@/components/base/loaders/FullScreenLoader.vue'
import {
  useBudgetRecommendation,
  usePartners,
  useCampaigns,
  useCampaignsMutation,
} from '@/components/rateConnect/queries'
import useToastNotifications from '@/components/notifications/useToastNotifications'
import { selectedAccommodationId } from '@/layouts/queries'

const store = useStore()
const { displayNotification } = useToastNotifications()
const currency = computed(() => store.state.currency)

const {
  isFetching: isCampaignsFetching,
  isLoading: isCampaignsLoading,
  isError: isCampaignsError,
  campaignsData,
  currentPartner,
} = useCampaigns(selectedAccommodationId)
const {
  isFetching: isPartnersFetching,
  isLoading: isPartnersLoading,
  isError: isPartnersError,
} = usePartners(selectedAccommodationId)
const {
  isFetching: isBudgetFetching,
  isInitialLoading: isBudgetLoading,
  isError: isBudgetError,
  budgetRecommendationData,
  minBudget,
  maxBudget,
  budgetRecommendedMin,
  budgetRecommendedMax,
} = useBudgetRecommendation(selectedAccommodationId, currency, currentPartner)
const { isUpdatingCampaignBudget, updateCampaignBudget } = useCampaignsMutation()

const initialDataLoading = computed(() => isPartnersLoading.value || isBudgetLoading.value || isCampaignsLoading.value)
const requiredDataHasErrors = computed(() => isPartnersError.value || isBudgetError.value || isCampaignsError.value)
const requiredDataIsBeingRefetched = computed(
  () => !initialDataLoading.value && (isBudgetFetching.value || isPartnersFetching.value || isCampaignsFetching.value)
)
const budget = computed(() => campaignsData.value?.overall?.budget)
const campaignCurrentCPCLevel = computed(() => campaignsData.value?.campaigns?.[0]?.cpcLevel)
const expectedConsumption = computed(() => budgetRecommendationData?.value?.expectedConsumption)

const onBudgetSave = newBudget => {
  updateCampaignBudget(
    {
      accommodationId: selectedAccommodationId.value,
      budget: newBudget * 100,
      currencyCode: currency.value,
    },
    {
      onSuccess: () => {
        displayNotification({
          message: 'rc_budget_success',
          isTranslationKey: true,
          isHtml: true,
          type: 'success',
        })
      },
      onError: () => {
        displayNotification({
          message: 'save_error',
          isTranslationKey: true,
          type: 'error',
        })
      },
    }
  )
}
</script>
