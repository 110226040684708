<template>
  <div class="tw-bg-blue-100 tw-px-4 tw-pt-10 tw-rounded-xl tw-border-blue-700 tw-border tw-relative">
    <div class="tw-flex tw-items-center tw-mb-3">
      <BaseIcon :height="20" :width="12" icon-name="bulb">
        <Bulb />
      </BaseIcon>
      <h4 class="tw-font-bold tw-ml-2">{{ $t(title) }}</h4>
    </div>
    <div class="tw-mt-3">
      <ul>
        <li
          v-for="(item, index) in smartTipsList"
          :key="index"
          v-html="$t(item)"
          class="tw-mb-1 tw-list-disc tw-ml-5"
        />
      </ul>
    </div>
    <div class="tw-mt-4 tw-flex">
      <div class="tw-pb-4">
        <GhostButton size="medium" @click="toggleChangeCampaignModal">{{
          $t('rc_pause_flow_cpc_change_cta')
        }}</GhostButton>
        <p class="tw-text-xs tw-text-gray-600 tw-font-normal tw-mt-2">{{ $t('rc_pause_flow_cpc_upsell_footnote') }}</p>
      </div>
      <img :src="bellboy" :alt="$t(title)" class="tw-w-[140px] -tw-mt-4" />
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import BaseIcon from '@/components/BaseIcon.vue'
import Bulb from '@/components/icons/Bulb.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'

const emit = defineEmits(['toggleChangeCampaignModal'])
const toggleChangeCampaignModal = () => emit('toggleChangeCampaignModal')

const props = defineProps({
  id: Number,
})

const bellboy = new URL('/src/assets/img/rate-connect/bellboy.svg', import.meta.url)

const title = computed(() => {
  if (props.id === 1 || props.id === 4 || props.id === 6 || props.id === 7 || props.id === 8)
    return 'rc_pause_flow_cpc_upsell_header'
  else return ''
})
const smartTipsList = computed(() => {
  if (props.id === 1) {
    return ['rc_pause_flow_upsell_point_1', 'rc_pause_flow_upsell_point_2', 'rc_pause_flow_cpc_upsell_marketing']
  } else if (props.id === 4 || props.id === 6 || props.id === 7 || props.id === 8) {
    return ['rc_pause_flow_upsell_point_1', 'rc_pause_flow_upsell_point_2', 'rc_pause_flow_upsell_point_3']
  } else {
    return []
  }
})
</script>
