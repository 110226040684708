<template>
  <div
    class="tw-flex-col tw-flex tw-w-full md:tw-max-w-screen-lg lg:tw-max-w-screen-xl tw-mr-auto tw-ml-auto tw-min-h-screen-minus-footer"
  >
    <div class="tw-w-auto lg:tw-w-[827px] tw-mx-3.5 lg:tw-mx-auto tw-mt-8">
      <div class="tw-flex tw-flex-col tw-align-start tw-mb-8">
        <div class="tw-flex tw-justify-start tw-mb-2">
          <router-link to="/" class="hover:tw-cursor-pointer tw-font-bold tw-text-blue-700"
            >← {{ $t('setup_back') }}
          </router-link>
        </div>

        <p class="tw-text-xl tw-font-bold">{{ $t('assign_header') }}</p>
      </div>
      <AddPropertyForm :isOnboarding="false" @assignPropertyClick="onAssignProperty" :key="selectedAccommodationId" />
    </div>
    <FullScreenLoader v-show="assignPropertyLoading" />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import * as Sentry from '@sentry/vue'

import AddPropertyForm from '@/components/assignedProperties/AddPropertyForm.vue'
import useToastNotifications from '@/components/notifications/useToastNotifications'
import FullScreenLoader from '@/components/base/loaders/FullScreenLoader.vue'
import { selectedAccommodationId, setSelectedAccommodationId } from '@/layouts/queries'
import { useAssignProperty } from '@/components/assignedProperties/queries/index.js'
import { useAssignedProperty } from '@/components/assignedProperties/useAssignedProperty'

const { displayNotification } = useToastNotifications()
const store = useStore()
const router = useRouter()
const organisationId = computed(() => store.state.session.selectedOrganisation?.id)
const { assignPropertyLoading, assignProperty } = useAssignProperty()
const { resetHotelAssignment } = useAssignedProperty()

const onAssignProperty = accommodationId => {
  assignProperty(
    {
      organisationId: organisationId.value,
      accommodationId: accommodationId,
    },
    {
      onSuccess: () => {
        displayNotification({
          message: 'successfully_assigned',
          isTranslationKey: true,
          type: 'success',
        })
        resetHotelAssignment()
        setSelectedAccommodationId(accommodationId)
        router.push('/')
      },
      onError: error => {
        Sentry.withScope(scope => {
          scope.setTag('feature', 'assign-accommodation')
          scope.setExtra('Response error', error)
          Sentry.captureException(error)
        })
        displayNotification({
          message: 'something_went_wrong',
          isTranslationKey: true,
          type: 'error',
        })
      },
    }
  )
}
</script>
