<template>
  <div v-if="isLoading" class="tw-my-6">
    <div class="tw-rounded tw-bg-gray-300 tw-mb-2 tw-h-10 tw-w-full" />
    <div class="tw-rounded tw-bg-gray-300 tw-mb-2 tw-h-10 tw-w-full" />
  </div>
  <div
    v-else
    :class="hasDiscount ? 'tw-items-center' : 'tw-items-end'"
    class="tw-py-8 tw-mb-8 tw-border-b tw-border-gray-300 tw-flex md:tw-flex-row tw-flex-col tw-justify-between"
  >
    <!-- For Rate Connect -->
    <div v-if="productName === 'RATE_CONNECT'">
      <div class="tw-font-bold tw-text-xl tw-mb-4" :class="isActiveSubscription ? 'tw-text-green-700' : ''">
        {{ $t('campaign_status_active') }}
      </div>
      <p v-html="$t('subscriptions.details.nextPayment.usageBased.date')" />
    </div>
    <!-- For PRO & Plus -->
    <div v-else>
      <!-- Active Subscription -->
      <div v-if="isActiveSubscription">
        <div class="tw-font-bold tw-text-xl tw-mb-4" :class="{ 'tw-text-green-700': isActiveSubscription }">
          {{ $t('subscription_status_active') }}
        </div>

        <p
          v-if="subscriptionTerm === 'monthly'"
          v-html="
            $t('subscription_renew_info_text_monthly', {
              ReactivationDate: nextPaymentDate,
              SubscriptionPrice: `${Intl.NumberFormat(language, {
                style: 'currency',
                currency: currency,
              }).format(price)}`,
            })
          "
        />
        <p
          v-else-if="subscriptionTerm === 'annual' && hasDiscount"
          v-html="
            $t('subscription_renew_info_text_discount', {
              ReactivationDate: nextPaymentDate,
              SubscriptionPrice: `${Intl.NumberFormat(language, {
                style: 'currency',
                currency: currency,
              }).format(price)}`,
            })
          "
        />

        <p
          v-else
          v-html="
            $t('subscription_renew_info_text_annual', {
              ReactivationDate: nextPaymentDate,
              SubscriptionPrice: `${Intl.NumberFormat(language, {
                style: 'currency',
                currency: currency,
              }).format(price)}`,
            })
          "
        />

        <p v-if="hasDiscount" class="tw-text-sm tw-text-gray-700 tw-mt-4">
          {{
            $t('subscription_discount_disclaimer', {
              DiscountPercentage: Intl.NumberFormat(language, { style: 'percent', maximumFractionDigits: 0 }).format(
                props.subscription.discount / 100
              ),
            })
          }}
        </p>
      </div>
      <!-- Cancelled Subscription -->
      <div v-else-if="isCancelledSubscription">
        <div class="tw-font-bold tw-text-xl tw-mb-4" :class="{ 'tw-text-orange-700': isCancelledSubscription }">
          {{ $t('subscription_status_canceled') }}
        </div>
        <p
          v-if="subscriptionTerm === 'annual'"
          v-html="
            subscription?.type === 'PLUS'
              ? $t('subscription_canceled_info_text_annual', {
                  EndDate: endDate,
                })
              : $t('subscription_canceled_info_text_annual_pro', { EndDate: endDate })
          "
        />
        <p
          v-else
          v-html="
            subscription?.type === 'PLUS'
              ? $t('subscription_canceled_info_text_monthly', {
                  EndDate: endDate,
                })
              : $t('subscription_canceled_info_text_monthly_pro', {
                  EndDate: endDate,
                })
          "
        />

        <p class="tw-text-sm tw-text-gray-700 tw-mt-4">{{ $t('discount_disclaimer_settings') }}</p>
      </div>
      <!-- Expired Subscription -->
      <div v-else-if="isExpiredSubscription">
        <div class="tw-font-bold tw-text-xl tw-mb-4" :class="{ 'tw-text-red-800': isExpiredSubscription }">
          {{ $t('subscription_status_expired') }}
        </div>
        <p
          v-if="subscriptionTerm === 'annual'"
          v-html="
            subscription?.type === 'PLUS'
              ? $t('subscription_expired_info_text_annual', {
                  EndDate: endDate,
                })
              : $t('subscription_canceled_info_text_annual_pro', {
                  EndDate: endDate,
                })
          "
        />
        <p
          v-else
          v-html="
            subscription?.type === 'PLUS'
              ? $t('subscription_expired_info_text_monthly', {
                  EndDate: endDate,
                })
              : $t('subscription_canceled_info_text_annual_pro', {
                  EndDate: endDate,
                })
          "
        />

        <p class="tw-text-sm tw-text-gray-700 tw-mt-4">{{ $t('discount_disclaimer_settings') }}</p>
      </div>
    </div>
    <DangerButton
      v-if="productName !== 'RATE_CONNECT' && isActiveSubscription"
      size="medium"
      class="tw-w-full md:tw-w-auto lg:tw-mt-2 tw-mt-6 tw-whitespace-nowrap"
      @click="() => toggleCancelSubscription(isCancellationOpen ? 'close' : 'open')"
    >
      {{ $t('subscriptions.details.cancel.cta') }}
    </DangerButton>

    <MainButton
      v-if="
        !isActiveSubscription &&
        subscription?.type === 'PLUS' &&
        !hasActiveRC &&
        subscription.term.toLowerCase() !== 'monthly'
      "
      size="medium"
      class="tw-w-full md:tw-w-auto lg:tw-mt-2 tw-mt-6"
      @click="openReactivationModal"
    >
      {{ $t('business_studio_plus_reactivate_cta') }}
    </MainButton>
  </div>
  <CancellationModal
    :isOpen="isCancellationOpen"
    :accommodationName="currentAccommodation.name"
    :accommodationId="accommodationId"
    :userDetails="userDetails"
    :partnerId="studioPlusCampaign?.partnerId"
    :language="language"
    :type="subscription?.type"
    @closeModal="toggleCancelSubscription(isCancellationOpen ? 'close' : 'open')"
  />

  <StudioPlusReactivationModal
    v-if="isReactivationModalOpen || (reactivationCompleted && accommodationId === reactivationAccommodationId)"
    :isOpen="isReactivationModalOpen"
    :expired="isExpiredSubscription"
    :cancelled="isCancelledSubscription"
    :accommodationId="{ value: accommodationId }"
    :accommodationName="currentAccommodation.name"
    source="subscription-view"
    @closeModal="closeReactivationModal"
  />
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

import { useUserDetails } from '@/layouts/queries'

import MainButton from '@/components/base/buttons/MainButton.vue'
import DangerButton from '@/components/base/buttons/DangerButton.vue'
import CancellationModal from '@/components/settings/subscriptions/cancellation/CancellationModal.vue'
import StudioPlusReactivationModal from '@/components/settings/subscriptions/reactivation/StudioPlusReactivationModal.vue'

const store = useStore()

const props = defineProps({
  subscription: Array,
  accommodationId: Number,
  productName: String,
  hasActiveRC: Boolean,
  currentAccommodation: Object,
})

const reactivationCompleted = ref(false)
const isReactivationModalOpen = ref(false)
const isCancellationOpen = ref(false)

const userId = computed(() => store.state.session.userId)
const language = computed(() => store.state.locale.language)
const currency = computed(() => store.state.currency)

const { userDetails } = useUserDetails(userId)

const reactivationAccommodationId = computed(() => store.state.studioPlus.reactivationAccommodationId)
const subscriptionTerm = computed(() => (props.subscription?.term === 'MONTHLY' ? 'monthly' : 'annual'))
const price = computed(() => props.subscription?.nextPaymentAmount)
const hasDiscount = computed(() => props.subscription?.discount !== null)

const nextPaymentDate = computed(() => {
  return new Date(props.subscription?.nextPaymentDate).toLocaleDateString(language?.value, {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  })
})

const endDate = computed(() => {
  return new Date(props.subscription?.endDate).toLocaleDateString(language?.value, {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  })
})

const isActiveSubscription = computed(() => {
  if (props.productName === 'RATE_CONNECT') {
    return props.subscription.status?.toLowerCase() === 'active'
  } else {
    return props.subscription?.endDate === null
  }
})

const isExpiredSubscription = computed(() => {
  return props.subscription?.endDate ? new Date() > new Date(props.subscription?.endDate) : false
})

const isCancelledSubscription = computed(() => {
  return props.subscription?.value?.endDate !== null && !isExpiredSubscription?.value
})

const toggleCancelSubscription = action => {
  if (action === 'open') {
    isCancellationOpen.value = true
  }
  if (action === 'close') {
    isCancellationOpen.value = false
  }
}

const openReactivationModal = () => {
  isReactivationModalOpen.value = true
}

const closeReactivationModal = () => {
  reactivationCompleted.value = false
  isReactivationModalOpen.value = false
}
</script>
<style scoped>
p :deep(a) {
  color: theme('colors.blue.700');
  text-decoration: underline;
}
</style>
