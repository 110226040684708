<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8 16H1V9L9 1L16 8L8 16ZM16.707 7.293L9.707 0.292999C9.512 0.0979996 9.256 0 9 0C8.744 0 8.488 0.0979996 8.293 0.292999L0.293 8.293C0.105 8.48 0 8.735 0 9V16C0 16.552 0.448 17 1 17H8C8.265 17 8.52 16.895 8.707 16.707L16.707 8.707C17.098 8.316 17.098 7.684 16.707 7.293Z"
    fill="#171717"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5 13C4.45 13 4 12.55 4 12C4 11.45 4.45 11 5 11C5.55 11 6 11.45 6 12C6 12.55 5.55 13 5 13ZM5 10C3.9 10 3 10.9 3 12C3 13.1 3.9 14 5 14C6.1 14 7 13.1 7 12C7 10.9 6.1 10 5 10Z"
    fill="#171717"
  />
</template>
