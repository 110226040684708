<template>
  <div
    class="tw-rounded-xl tw-flex tw-flex-row tw-flex-col tw-p-4 tw-text-gray-800 tw-w-full tw-items-center tw-gap-y-6"
    :class="getClass"
  >
    <div class="tw-text-base tw-w-full tw-font-bold tw-flex tw-items-center tw-text-gray-800">
      <div
        v-if="elementId === 'bookings'"
        class="tw-bg-decorative-lightblue tw-p-3 tw-flex tw-items-center tw-justify-center tw-mr-2 tw-rounded-full"
      >
        <BaseIcon>
          <RcBookingsIcon />
        </BaseIcon>
      </div>
      <div
        v-if="elementId === 'visits'"
        class="tw-bg-decorative-yellow tw-p-3 tw-flex tw-items-center tw-justify-center tw-mr-2 tw-rounded-full"
      >
        <BaseIcon>
          <RcClicksIcon />
        </BaseIcon>
      </div>
      <div
        v-if="elementId === 'revenue'"
        class="tw-bg-green-400 tw-p-3 tw-flex tw-items-center tw-justify-center tw-mr-2 tw-rounded-full"
      >
        <BaseIcon>
          <RcRevenueIcon />
        </BaseIcon>
      </div>
      <div
        v-if="elementId === 'cpa'"
        class="tw-bg-green-400 tw-p-3 tw-flex tw-items-center tw-justify-center tw-mr-2 tw-rounded-full"
      >
        <BaseIcon>
          <RcCpaIcon />
        </BaseIcon>
      </div>
      <p class="tw-text-left tw-font-bold tw-text-xl">{{ $t(label) }}</p>
      <UiTooltip theme="customdarkwithpadding" tooltip-position="top" :label="getTooltip" class="tw-cursor-pointer">
        <BaseIcon
          icon-name="information-outline"
          :height="16"
          :width="16"
          class="tw-fill-none tw-h-[19px] tw-w-[19px] tw-stroke-gray-500 tw-ml-1"
        >
          <InformationOutline />
        </BaseIcon>
      </UiTooltip>
    </div>
    <div class="tw-flex tw-w-full tw-justify-start tw-items-center">
      <p class="tw-text-[28px] tw-mr-3">{{ formatNumber }}</p>
      <div
        class="tw-gap-x-1 tw-font-bold tw-bg-white tw-flex tw-justify-centre tw-items-center tw-border tw-border-gray-300 tw-rounded-md tw-py-1 tw-px-2"
      >
        <BaseIcon
          :height="11"
          :width="11"
          :icon-name="isPositive ? 'arrow-up-right' : 'arrow-down-right'"
          :icon-color="isSelected ? '#fff' : '#0A1121'"
        >
          <ArrowUpRight v-if="isPositive" />
          <ArrowDownRight v-else />
        </BaseIcon>

        {{
          hasNoData || hasEmptyTable
            ? '-%'
            : Intl.NumberFormat(language.value, { style: 'percent' }).format(progression / 100)
        }}
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import InformationOutline from '@/components/icons/InformationOutline.vue'
import RcBookingsIcon from '@/components/icons/rcBookingsIcon.vue'
import RcClicksIcon from '@/components/icons/rcClicksIcon.vue'
import RcRevenueIcon from '@/components/icons/rcRevenueIcon.vue'
import ArrowUpRight from '@/components/icons/ArrowUpRight.vue'
import ArrowDownRight from '@/components/icons/ArrowDownRight.vue'
import RcCpaIcon from '@/components/icons/rcCpaIcon.vue'

const store = useStore()
const { t } = useI18n()
const props = defineProps({
  label: {
    type: String,
    required: true,
    default: '',
  },
  elementId: {
    type: String,
    required: false,
    default: '',
  },
  value: {
    type: Number,
    required: false,
    default: null,
  },
  number: {
    type: Number,
    required: false,
    default: null,
  },
  index: {
    type: Number,
    required: true,
    default: 0,
  },
  progression: Number,
  hasNoData: Boolean,
  hasEmptyTable: Boolean,
})

const tooltipTexts = {
  visits: 'rc_def_clicks',
  bookings: 'rc_def_bookings',
  revenue: 'rc_def_revenue',
  cpa: 'rc_def_ecpa',
}
const currency = computed(() => store.state.currency)
const language = computed(() => store.state.locale.language)
const getTooltip = computed(() => t(tooltipTexts[props.elementId]))
const getNumber = computed(() => props.number ?? 0)
const isPositive = computed(() => props.progression >= 0)
const formatNumber = computed(() => {
  if (props.hasEmptyTable || props.hasNoData) return '-'
  if (props.elementId === 'cpa') {
    return new Intl.NumberFormat(language.value, {
      style: 'percent',
      minimumFractionDigits: 0,
    }).format(getNumber.value / 100)
  } else if (props.elementId === 'revenue') {
    return new Intl.NumberFormat(language.value, {
      style: 'currency',
      currency: currency.value,
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    }).format(getNumber.value / 100)
  } else {
    return new Intl.NumberFormat(language.value, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(getNumber.value)
  }
})
const getClass = computed(() => {
  if (props.isSelected) {
    if (props.index === 0) {
      return 'tw-bg-blue-200 lg:tw-ml-0 '
    } else if (props.index === 3) {
      return 'tw-bg-blue-200 lg:tw-mr-0 '
    } else {
      return 'tw-bg-blue-200 '
    }
  } else {
    if (props.index === 0) {
      return 'tw-bg-gray-100 lg:tw-ml-0'
    } else if (props.index === 3) {
      return 'tw-bg-gray-100 lg:tw-mr-0 '
    } else {
      return 'tw-bg-gray-100 '
    }
  }
})
</script>
