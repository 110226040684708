<template>
  <div class="tw-flex tw-flex-col md:tw-flex-row tw-pt-3 tw-text-sm tw-text-gray-700">
    <div class="tw-flex-col lg:tw-w-1/3">
      <p class="contact-info">{{ address === null ? '' : address }}</p>
      <p class="contact-info">{{ phone === null ? '' : phone }}</p>
    </div>
    <div class="divider tw-hidden md:tw-block" />
    <div class="tw-flex-col xl:tw-w-1/2 lg:tw-w-auto">
      <p class="contact-info lg:tw-max-w-[150px] xl:tw-max-w-[300px] tw-max-w-full">
        <a class="tw-text-blue-700" rel="noopener noreferrer" target="_blank" :href="websiteUrl">{{ website }}</a>
      </p>
      <p class="contact-info">
        <a class="tw-text-blue-700" rel="noopener noreferrer" target="_blank" :href="mailAddress">{{ email }}</a>
      </p>
    </div>
    <div class="tw-flex tw-flex-col md:tw-items-end tw-items-start tw-w-full tw-mt-2 tw-mb-3 md:tw-my-0">
      <div class="tw-flex tw-items-center" v-if="propertyId">
        <UiTooltip tooltip-position="bottom" :label="$t('tBS_alternative_accommodation_ID_tooltip')">
          <BaseIcon
            icon-name="information-outline"
            :height="16"
            :width="16"
            class="tw-fill-none tw-stroke-gray-700 tw-h-[20px] tw-w-[20px] tw-mr-1"
          >
            <InformationOutline />
          </BaseIcon>
        </UiTooltip>
        <p class="license-number tw-text-sm tw-text-gray-700 tw-whitespace-nowrap md:tw-ml-2">
          {{ $t('tBS_alternative_accommodation_ID') }}: {{ propertyId }}
        </p>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import BaseIcon from '@/components/BaseIcon.vue'
import InformationOutline from '@/components/icons/InformationOutline.vue'
import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'

const props = defineProps({
  address: {
    type: String,
    default: '',
  },
  phone: {
    type: String,
    default: '',
  },
  fax: {
    type: String,
    default: '',
  },
  website: {
    type: String,
    default: '',
  },
  email: {
    type: String,
    default: '',
  },
  propertyId: {
    type: String,
    default: '',
  },
})
const mailAddress = computed(() => 'mailto:' + props.email)
const websiteUrl = computed(() =>
  props.website?.startsWith('http://') || props.website?.startsWith('https://')
    ? props.website
    : `https://${props.website}`
)
</script>
<style scoped>
.divider {
  border-right: 1px solid theme('colors.gray.300');
  height: 40px;
  @apply tw-mx-4;
}
.license-number {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 220px;
  @media (max-width: theme('screens.md')) {
    max-width: 100%;
  }
}
.contact-info {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: theme('screens.lg')) {
    max-width: 200px;
  }
  @media (max-width: theme('screens.md')) {
    max-width: 100%;
  }
}
</style>
