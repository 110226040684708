<template>
  <section>
    <div
      class="tw-flex tw-flex-col tw-gap-8 tw-w-full lg:tw-max-w-screen-landing md:tw-max-w-[638px] sm:tw-max-w-[480px] tw-max-w-[300px] tw-py-6 lg:tw-px-0 tw-mx-auto lg:tw-py-8"
    >
      <h1 class="tw-text-center tw-text-blue-800 tw-font-bold tw-text-xl md:tw-text-2xl lg:tw-text-3.5xl tw-mx-auto">
        {{ $t(title) }}
      </h1>

      <!-- Web Version -->
      <div class="tw-hidden lg:tw-flex tw-justify-center">
        <div
          class="tw-relative tw-py-9 tw-px-6 tw-rounded-xl tw-border tw-mr-[-12px] tw-flex tw-flex-col tw-justify-between tw-items-start first:tw-w-[calc(27%+12px)] tw-w-[calc(36%+12px)]"
          v-for="product in productsWeb"
          :key="product.id"
          :class="product.isPaid ? 'premium blue-shadow' : 'free grey-shadow'"
        >
          <div
            v-if="product.tag"
            class="tw-absolute tw-top-0 tw-left-0 tw-rounded-tl-[10px] tw-rounded-br-[12px] tw-font-bold tw-text-xs tw-text-white tw-bg-blue-700 tw-px-3 tw-py-[3px]"
          >
            {{ $t(product.tag) }}
          </div>
          <div>
            <div class="tw-mb-6">
              <h1 :class="{ 'tw-hidden': product.logo }" class="tw-text-gray-800 tw-font-bold tw-text-xl">
                {{ product.title }}
              </h1>
              <img v-if="product.logo" :src="product.logo" :alt="product.title" class="tw-h-[23px]" />
            </div>
            <div v-for="benefit in product.benefits" :key="benefit" class="tw-flex tw-items-center tw-mb-2">
              <img :src="Tick" class="tw-mr-4" />
              <p>{{ $t(benefit) }}</p>
            </div>
            <div v-if="product.additional?.length > 0" class="tw-mt-2">
              <h3 class="tw-font-bold tw-mb-4">{{ $t('plus_product_page_benefits_header') }}</h3>
              <div v-for="item in product.additional" :key="item" class="tw-flex tw-items-start tw-mb-2">
                <img :src="BlueTick" class="tw-mr-4 tw-mt-1" />
                <p>{{ $t(item) }}</p>
              </div>
            </div>
          </div>
          <MainButton v-if="product.additional?.length > 0" class="tw-mt-6" @click="onLoginClick">{{
            $t('rc_mp_get_started')
          }}</MainButton>
        </div>
      </div>

      <!-- Mobile Version -->
      <div class="lg:tw-hidden tw-flex tw-flex-col tw-justify-center">
        <div class="tw-bg-white tw-p-6 tw-pb-12 tw-rounded-t-xl free gray-shadow-mobile">
          <h1 class="tw-text-gray-800 tw-font-bold tw-text-xl tw-mb-4">
            {{ 'Business Studio' }}
          </h1>
          <div class="tw-flex md:tw-flex-row tw-flex-col tw-gap-4 tw-pb-4">
            <div v-for="item in businessStudioBenefits" :key="item" class="tw-flex tw-flex-row">
              <img :src="Tick" class="tw-mr-4" />
              <p>{{ $t(item) }}</p>
            </div>
          </div>
        </div>
        <div class="tw-flex tw-flex-col md:tw-flex-row tw-mt-0 md:tw-mt-[-16px]">
          <div
            class="tw-w-full tw-relative md:tw-w-[calc(50%+5px)] tw-mt-[-16px] tw-py-9 tw-px-6 tw-rounded-xl tw-border tw-mr-[-10px] tw-flex tw-flex-col tw-justify-between tw-items-start"
            v-for="product in productsMobile"
            :key="product.id"
            :class="product.isPaid ? 'premium blue-shadow' : 'free grey-shadow'"
          >
            <div
              v-if="product.tag"
              class="tw-absolute tw-top-0 tw-left-0 tw-rounded-tl-[12px] tw-rounded-br-[12px] tw-font-bold tw-text-xs tw-text-white tw-bg-blue-700 tw-px-2 tw-py-1"
            >
              {{ $t(product.tag) }}
            </div>

            <div>
              <div class="tw-mb-6">
                <img v-if="product.logo" :src="product.logo" :alt="product.title" class="tw-h-[23px]" />
              </div>
              <div v-for="benefit in product.benefits" :key="benefit" class="tw-flex tw-items-center tw-mb-2">
                <img :src="Tick" class="tw-mr-4" />
                <p>{{ $t(benefit) }}</p>
              </div>
              <div v-if="product.additional.length > 0" class="tw-mt-2">
                <h3 class="tw-font-bold tw-mb-4">{{ $t('plus_product_page_benefits_header') }}</h3>
                <div v-for="item in product.additional" :key="item" class="tw-flex tw-items-start tw-mb-2">
                  <img :src="BlueTick" class="tw-mr-4 tw-mt-1" />
                  <p>{{ $t(item) }}</p>
                </div>
              </div>
            </div>
            <MainButton v-if="product.additional.length > 0" class="tw-mt-6" @click="onLoginClick">{{
              $t('rc_mp_get_started')
            }}</MainButton>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import MainButton from '@/components/base/buttons/MainButton.vue'

const RateConnectLogo = new URL('/src/assets/img/landing/landing_rate_connect.svg', import.meta.url)
const StudioPlusLogo = new URL('/src/assets/img/landing/landing_studio_plus.svg', import.meta.url)
const Tick = new URL('/src/assets/img/landing/icons/tick.svg', import.meta.url)
const BlueTick = new URL('/src/assets/img/landing/icons/blue_tick.svg', import.meta.url)

const emit = defineEmits(['onLoginClick'])
const onLoginClick = () => emit('onLoginClick')

const props = defineProps({
  title: String,
  product: String,
})

const businessStudioBenefits = [
  'studio_plus_mp_basic_feature_1',
  'studio_plus_mp_basic_feature_2',
  'studio_plus_mp_basic_feature_3',
]
const productsWeb = [
  {
    id: 0,
    title: 'Business Studio',
    logo: null,
    benefits: ['studio_plus_mp_basic_feature_1', 'studio_plus_mp_basic_feature_2', 'studio_plus_mp_basic_feature_3'],
    tag: null,
    isPaid: false,
  },
  {
    id: 1,
    title: 'trivago Business Studio+ ',
    logo: StudioPlusLogo,
    benefits: ['studio_plus_mp_basic_feature_1', 'studio_plus_mp_basic_feature_2', 'studio_plus_mp_basic_feature_3'],
    additional: [
      'tbs_mp_studio_plus_benefit_1',
      'tbs_mp_studio_plus_benefit_2',
      'tbs_mp_studio_plus_benefit_3',
      'tbs_mp_studio_plus_benefit_4',
    ],
    tag: null,
    isPaid: props.product === 'studio-plus' || props.product === 'both' ? true : false,
  },
  {
    id: 2,
    title: 'trivago Rate Connect',
    logo: RateConnectLogo,
    benefits: ['studio_plus_mp_basic_feature_1', 'studio_plus_mp_basic_feature_2', 'studio_plus_mp_basic_feature_3'],
    additional: ['tbs_mp_rc_benefit_1', 'tbs_mp_rc_benefit_2', 'tbs_mp_rc_benefit_3', 'tbs_mp_rc_benefit_4'],
    tag: 'tbs_mp_rc_tag',
    isPaid: props.product === 'rate-connect' || props.product === 'both' ? true : false,
  },
]

const productsMobile = [
  {
    id: 1,
    title: 'trivago Business Studio+ ',
    logo: StudioPlusLogo,
    benefits: ['studio_plus_mp_basic_feature_1', 'studio_plus_mp_basic_feature_2', 'studio_plus_mp_basic_feature_3'],
    additional: [
      'tbs_mp_studio_plus_benefit_1',
      'tbs_mp_studio_plus_benefit_2',
      'tbs_mp_studio_plus_benefit_3',
      'tbs_mp_studio_plus_benefit_4',
    ],
    tag: null,
    isPaid: props.product === 'studio-plus' || props.product === 'both' ? true : false,
  },
  {
    id: 2,
    title: 'trivago Rate Connect',
    logo: RateConnectLogo,
    benefits: ['studio_plus_mp_basic_feature_1', 'studio_plus_mp_basic_feature_2', 'studio_plus_mp_basic_feature_3'],
    additional: ['tbs_mp_rc_benefit_1', 'tbs_mp_rc_benefit_2', 'tbs_mp_rc_benefit_3', 'tbs_mp_rc_benefit_4'],
    tag: 'tbs_mp_rc_tag',
    isPaid: props.product === 'rate-connect' || props.product === 'both' ? true : false,
  },
]
</script>
<style scoped>
.blue-shadow {
  box-shadow: 3px 3px 4px 0px #a6c4d6;
}
.grey-shadow {
  box-shadow: 3px 3px 4px 0px #6c707a26;
}
.gray-shadow-mobile {
  box-shadow: 0px 4px 4px 0px #6c707a26;
}
.free {
  @apply tw-bg-white tw-border-gray-300;
}
.premium {
  @apply tw-bg-blue-100 tw-border-blue-700;
}
</style>
