<template>
  <div>
    <p class="tw-font-bold tw-mb-3">{{ label }}</p>
    <ul class="tw-flex tw-flex-wrap">
      <li v-for="option in options" :key="option.id" class="item tw-flex tw-items-center tw-text-sm">
        <input
          type="radio"
          :name="name"
          :value="option.id"
          :id="`${name}_${option.id}`"
          :checked="value.id === option.id"
          @change="onSelect({ name, option })"
          class="tw-cursor-pointer"
        />
        <label
          :for="`${name}_${option.id}`"
          class="tw-cursor-pointer tw-py-2 tw-m-0 tw-pl-3 tw-grow tw-flex tw-items-center tw-flex-wrap"
          >{{ option.label }}</label
        >
      </li>
    </ul>
  </div>
</template>
<script setup>
const emit = defineEmits(['onSelect'])
const onSelect = value => emit('onSelect', value)

defineProps({
  name: String,
  label: String,
  options: Array,
  value: Object,
})
</script>
<style scoped>
.item {
  flex-basis: 50%;
  box-sizing: border-box;
}
</style>
