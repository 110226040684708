<template>
  <div class="tw-mb-6">
    <div class="tw-flex tw-items-start tw-flex-col tw-border-b tw-mb-6 tw-pb-5 tw-border-gray-300">
      <img :src="businessPlusLogo" :alt="$t('studio_plus_mp_bs_plus_features_header')" class="tw-h-[23px] tw-mb-6" />

      <div v-if="!loading && !fetching">
        <p class="tw-font-light current-price" data-testid="plus-checkout-current-price">
          <span>
            {{ Intl.NumberFormat(language, { style: 'currency', currency: currency }).format(priceNet / 100) }}</span
          >
        </p>
      </div>
      <div v-else class="tw-mb-1 tw-w-full">
        <div class="tw-animate-pulse tw-rounded tw-bg-gray-200 tw-h-[48px] tw-w-full tw-mb-4" aria-hidden="true" />
      </div>

      <p class="tw-text-gray-800">
        {{ $t('check_out_billing_info_subscription') }}
      </p>

      <div class="tw-mt-6">
        <li v-for="(feature, i) in featuresList" :key="i" class="tw-flex tw-justify-start tw-items-center tw-mb-2.5">
          <BaseIcon height="9" width="12">
            <Tick class="tw-fill-green-800 tw-h-[9px] tw-w-[12px]" />
          </BaseIcon>
          <span class="tw-ml-3">{{ $t(feature) }}</span>
        </li>
      </div>
    </div>

    <div>
      <div v-if="!loading && !fetching" class="tw-flex tw-justify-between tw-mb-2 tw-text-sm">
        <p>{{ $t('business_studio_plus_price') }}</p>
        <span>
          {{
            Intl.NumberFormat(language, { style: 'currency', currency: currency }).format(priceNetYearly / 100)
          }}</span
        >
      </div>
      <div v-else class="tw-mb-1">
        <div class="tw-animate-pulse tw-rounded tw-bg-gray-200 tw-h-[20px] tw-w-full tw-mb-4" aria-hidden="true" />
      </div>
      <div
        v-if="!loading && !fetching"
        :class="voucherData && voucherData.isValid ? 'tw-text-green-700' : ''"
        class="tw-flex tw-justify-between tw-mb-2 tw-text-sm"
      >
        <p>
          {{
            $t('business_studio_plus_discount_applied', {
              DiscountPercentage:
                voucherData && voucherData.isValid
                  ? Intl.NumberFormat(language, { style: 'percent', maximumFractionDigits: 0 }).format(
                      voucherData.discountPercentage / 100
                    )
                  : '',
            })
          }}
        </p>
        <span>
          {{
            Intl.NumberFormat(language, { style: 'currency', currency: currency }).format(discountAmount / 100)
          }}</span
        >
      </div>
      <div v-else class="tw-mb-1">
        <div class="tw-animate-pulse tw-rounded tw-bg-gray-200 tw-h-[20px] tw-w-full tw-mb-4" aria-hidden="true" />
      </div>

      <div v-if="!loading && !fetching" class="tw-flex tw-justify-between tw-text-sm">
        <p>{{ $t('check_out_page_VAT') }}</p>
        <span>{{
          Intl.NumberFormat(language, { style: 'currency', currency: currency }).format(priceTaxAmount / 100)
        }}</span>
      </div>
      <div v-else class="tw-mb-">
        <div class="tw-animate-pulse tw-rounded tw-bg-gray-200 tw-h-[20px] tw-w-full tw-mb-4" aria-hidden="true" />
      </div>
      <div class="tw-mt-6">
        <AddVoucher
          @applyVoucherCode="applyVoucherCode"
          :price="price"
          :voucherData="voucherData"
          :discountAmount="discountAmount"
          :isLoading="loading || fetching"
        />
      </div>
      <div
        v-if="!loading && !fetching"
        class="tw-flex tw-justify-between tw-font-bold tw-mt-6 tw-border-b tw-mb-1 tw-pb-5 tw-border-gray-300"
      >
        <p>{{ $t('business_studio_payment_due') }}</p>

        <span>{{
          Intl.NumberFormat(language, { style: 'currency', currency: currency }).format(priceWithTax / 100)
        }}</span>
      </div>
      <div v-else class="tw-mt-6">
        <div class="tw-animate-pulse tw-rounded tw-bg-gray-200 tw-h-[2px] tw-w-full tw-mb-4" aria-hidden="true" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import Tick from '@/components/icons/Tick.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import AddVoucher from '@/components/studioPlus/vouchers/AddVoucher.vue'

const businessPlusLogo = new URL('/src/assets/img/logos/business-plus-logo.svg', import.meta.url)

const store = useStore()
const props = defineProps({
  price: Object,
  loading: Boolean,
  fetching: Boolean,
})

const emit = defineEmits(['applyVoucherCode'])
const applyVoucherCode = code => {
  emit('applyVoucherCode', code)
}
const language = computed(() => store.state.locale.language)
const currency = computed(() => store.state.currency)

const featuresList = [
  'check_out_feature_1',
  'check_out_feature_2',
  'check_out_feature_3',
  'check_out_feature_4',
  'check_out_feature_5',
  'check_out_feature_6',
  'check_out_feature_7',
]

const priceNetYearly = computed(() => props.price?.baseSubscriptionFullTermPrice ?? 0)
const priceNet = computed(() => props.price?.baseSubscriptionMonthlyPrice ?? 0)

const priceTaxAmount = computed(() => props.price?.totalTaxAmount ?? 0)

const priceWithTax = computed(() => props.price?.totalPrice ?? 0)
const voucherData = computed(() => props.price?.itemPriceVoucher)
const discountAmount = computed(() => props.price?.discountAmount ?? 0)
</script>

<style scoped>
.current-price :deep(span) {
  font-size: 32px;
  font-weight: bold;
}
</style>
